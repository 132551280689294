import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { forbiddenIfSplCharAtStartValidator } from '../../shared/constants/input-first-char-vallidation';
import * as _ from 'lodash-es';
import { find, uniq } from 'lodash-es';
import { map, takeUntil } from 'rxjs/operators';
import { ObjectUtil } from '../../shared/utils/object-util';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnitService } from '../../shared/_services/units.service';
import { AxisType, ChartType, DerivedParameter, MenuOption, Point, TooltipFormat, UpdatedParameter, Option } from '../../shared/models/widget.model';
import { SiteService } from '../../shared/_services/site.service';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { CommonService } from '../../shared/_services/common.service';
import { axisOrientation, axisValues, chartAggregationTypes, chartYAxisTypes, chartXAxisTypes, chartTypes, chartTypeText, chartViewTypes, fixedFormatValue, formatValues, pieDonutSunBurstCharts, sortingValues, chartBuilderChartConfig, chartEnum, TimeSeriesChartTypes, operators } from '../../shared/constants/widget';
import { Subject } from 'rxjs';
import { WidgetService } from '../../shared/_services/widget.service';
import { AlertService } from '../../shared/_services/alert.service';
import { HostRoute } from '../../shared/enums/host.enum';
import { restrictedCertificationLevels } from '../../shared/constants/constants';
import { Chart } from '../../shared/models/chart';
import { WidgetLayoutService } from '../../shared/_services/widget-layout.service';
import { DashboardService } from '../../shared/_services/dashboard.service';
import { chartType } from 'src/app/shared/enums/charts.enum';
import { LocalStorageService } from 'src/app/shared/_services/local-storage.service';

@Component({
  selector: 'app-chart-builder-layout',
  templateUrl: './chart-builder-layout.component.html',
  styleUrls: ['./chart-builder-layout.component.scss']
})
export class ChartBuilderLayoutComponent implements OnInit {

  chartForm: FormGroup;
  editChartName: boolean;
  chartName: string = '';
  minLeftY: number;
  maxLeftY: number;

  @Input() showAxisOptions: boolean = false;
  axes: any[] = [];
  @Input() disableAxisOptions: boolean = false;
  showWarning: boolean = false;
  gaugeAngleConfigError: boolean = false;
  gaugeMinMaxError: boolean = false;
  namePattern = /^(?:[A-Za-z0-9\s]+)(?:[A-Za-z0-9_-\s#%,]*)$/;

  isInternalPortal = true;
  selectedSharedConfiguration = 'L1';
  shareMenuOptions: MenuOption[] = [
    { label: 'Personal', value: 'personal' },
    { label: 'Shared', value: 'shared' }
  ];
  isSharedByOthers: boolean = false;
  shareAccessType: string = 'personal';
  showBlocklyView: boolean = false;
  showChartConfigView: boolean = false;

  builderType: string = 'BUILDER';
  chartViewType: string = 'COMBINE';
  isChartBuilderTypeChanged: boolean = false;
  isChartTypeChanged: boolean = false;
  showscopeChangeWarning: boolean = false;
  showChartChangeWarning: boolean = false;
  tempscope: any = {};
  tempChartTypeScope: string = '';
  selectedTags: string[] = [];
  tagList: string[] = [];
  points: any = [];
  haystackQuery: string = 'point and his';
  fetchingPoints: boolean = false;
  buildTypeOptions: MenuOption[] = [
    {
      label: 'BUILDER',
      value: 'BUILDER'
    },
    {
      label: 'CUSTOM',
      value: 'CUSTOM'
    }
  ];
  query: string;
  numResults: number = 0;
  showTagTooltip: boolean = false;
  tooltipPos: number = 0;
  emptyLabel: string = '';

  whyDescription: string = '';
  whatDescription: string = '';

  selectedPointIndex: number | null = 0;
  selectedPointName: string;
  selectedParamIndex: number = 0;
  selectedParamName: string;
  selectedPoints: number = 0;
  selectedIndex: number | null = 0;
  parameterCount: number = 10;
  focusedInputField = false;
  updatedParameterName: UpdatedParameter;
  previousParameterName: string = '';
  deletedParameterName: string = '';
  parameterList: string[] = [];
  disablePointNameFieldConditionally: any = [];
  hasError: boolean = false;
  errMsg: string = '';
  warningMsg: string = '';
  isChartConfigViewInitialized: boolean = false;
  isDuplicate:boolean = false;
  ownerDetails:string = "";

  //Subscriptions
  subscriptions: any = {};
  unsubscribe$: Subject<boolean> = new Subject();
  apiCallInitiated: boolean = false;

  chartTypes: ChartType[] = chartTypes;
  axisValues: AxisType[] = axisValues;
  sortingValues: AxisType[] = sortingValues;
  formatValues: TooltipFormat[] = formatValues;
  formartNameValues: TooltipFormat[] = formatValues;
  displayFormatValues: TooltipFormat[] = [];
  displayFormatNameValues: TooltipFormat[] = [];
  staticFormatValue: TooltipFormat = fixedFormatValue;
  staticFormatNameValue: TooltipFormat;
  selectedChartType: string;
  selectedChartViewBy: string;
  selectedXaxisValue: string = 'time';
  selectedYaxisValue: string = 'value';
  selectedStartAngle: number = 180;
  selectedEndAngle: number = 0;
  minValue: any;
  maxValue: any;
  angleValues = Array.from({ length: 361 }, (_, index) => index);
  filteredStartAngles = [...this.angleValues];
  filteredEndAngles = [...this.angleValues];
  selectedSortingValue: string = 'sortview';
  selectedFormatValue: string[] = ["$parameter"];
  selectedNameFormatValue: string[] = [];

  selectedFormatDisplayValue: string = "$parameter";
  selectedNameFormatDisplayValue: string = "";
  viewTypeOptions: MenuOption[] = [
    {
      label: 'COMBINE SITE',
      value: 'COMBINE'
    },
    {
      label: 'SEPARATE SITE',
      value: 'SEPARATE'
    }
  ];
  chartViewTypes: Option[] = chartViewTypes;
  chartAggregationTypes: Option[] = chartAggregationTypes;
  chartAxisTypes: Option[] = chartYAxisTypes;

  pointIndex: number = 0;
  conditionIndex: number = 0;
  classlist: Array<string>;
  showColorSwatch: boolean = false;
  selectedCustomHexColor: any = '#BABABA';
  colorSelectionType: string = 'palette';
  colorSet: string[];
  uniqueColors: string[] = [];
  chartConfig: any = {
    tooltip: {},
    axis: { xaxis: {}, yaxis: {} }
  };
  selectedGradientGroup: any = 'grp1';

  selectedDerivedParameter: DerivedParameter;
  parameters: string[] = [];
  dependentPoints: string[] = [];
  xmlCode: string = "";
  defaultXmlCode: string = '<xml xmlns="https://developers.google.com/blockly/xml"/>';

  loggedInUser: any;
  isUserRestricted:boolean = false;

  @ViewChild(MatMenuTrigger) sharedMenuTrigger!: MatMenuTrigger;
  @ViewChild('swatch') colorSwatchelement: ElementRef;
  @ViewChild('chartSelect') chartSelect!: MatSelect;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  zAxisLabel: string = '';
  pieDonutChartLable: string = '';
  isOpen: boolean = true;
  dashboardScopeSites: string = '';
  dashboardDateRange: any;
  dashboardGroupBy: string = '';
  isRightYaxisAvailable: boolean = false;
  isLeftYaxisAvailable: boolean = false;
  isTopXaxisAvailable: boolean = false;
  isBottomXaxisAvailable: boolean = false;
  isLoading:boolean = true;
  previewWidgetData:any;
  showPreviewContainer:boolean = false;
  selectedZaxisValue: string = 'scope';

  //Varaibles for chart configuration
  showAxisScale:boolean = false;
  showAxisPosition:boolean = false;
  showAxisLabel:boolean = false;
  showToolTipConfig:boolean = false;
  showToolTipSorting:boolean = false;
  showToolTipFormat:boolean = false;
  showChartLabel:boolean = false;
  showPieToolTipLabel:boolean = false;
  showForTerrain:boolean = false;
  showGlobalColorGradient:boolean = false;
  showRightLeftAxisSelection:boolean = false;
  showParameterColorSelection:boolean = false;
  hideGroupBy:boolean = false;
  hideDateRange:boolean = false;
  hideViewBy:boolean = false;
  showRadioBtnParameter:boolean = false;
  showSunBurstChart:boolean = false;
  showGaugeChart:boolean = false;
  operators: any = operators.filter((op:any) => op.key !== 'EQUAL');
  gaugeConditionsMax: number = 10;

  constructor(public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChartBuilderLayoutComponent>,
    public siteService: SiteService,
    public unitService: UnitService,
    public widgetService: WidgetService,
    public widgetLayoutService: WidgetLayoutService,
    public alertService: AlertService,
    private localStorageService: LocalStorageService,
    public commonService: CommonService,
    public dashboardService: DashboardService) {
    this.colorSet = this.commonService.colorSet;
  }

  ngOnInit(): void {
    this.getTags();
    this.setWidgetType();
    this.chartName = this.data?.chartName;
    this.loggedInUser = this.commonService.loggedInUser;
    if(this.data?.isEdit) {
      this.populateWidgetDate();
      this.populateChartConfig();
    }
    if (this.data.sharedByOthers) {
      let firstName = this.data?.widgetData?.owner?.firstName != null ? this.data?.widgetData?.owner?.firstName : ""
      let lastName = this.data?.widgetData?.owner?.lastName != null ? this.data?.widgetData?.owner?.lastName : ""
      let userId = this.data?.widgetData?.owner?.userId != null ? this.data?.widgetData?.owner?.userId : ""
      this.ownerDetails = firstName + " " + lastName  + (userId == 'external' ? `(External)` : '');
    }
    this.isSharedByOthers = this.data.sharedByOthers;
    this.chartName = this.data?.name;
    this.createFormGroup();
    this.chartConfig['viewType'] = this.chartViewType;
    this.isInternalPortal = this.localStorageService.portal_type == HostRoute.INTERNAL;
    if (this.isInternalPortal) {
      this.selectedSharedConfiguration = 'L1';
    } else {
      this.selectedSharedConfiguration = 'L2';
    }
    if(!this.isInternalPortal) {
      this.isUserRestricted =  restrictedCertificationLevels.includes(this.dashboardService.certificateLevel || '');
    }

    this.dashboardScopeSites = this.dashboardService.dashboardScopeData.label;

    // let dashboardDateRange = this.dashboardService.dashboardDate;
    // if (dashboardDateRange.startDate == dashboardDateRange.endDate) {
    //   delete dashboardDateRange.endDate;
    //   this.dashboardService.dashboardDate = dashboardDateRange;
    // } else {
    //   this.dashboardDateRange = dashboardDateRange;
    // }
    this.dashboardDateRange = this.dashboardService.dashboardDate;
    this.dashboardGroupBy = this.dashboardService.dashboardGroupBy;
  }

  /**
   * Initializes the form group for the chart builder layout component.
   * 
   * The form group includes the following controls:
   * - `chartName`: A string representing the name of the chart. It is validated with a pattern, a maximum length of 35 characters, and a custom validator to forbid special characters at the start.
   * - `builderType`: A string set to 'BUILDER'.
   * - `tags`: An optional string for tags.
   * - `what`: An optional string for the 'what' field.
   * - `why`: An optional string for the 'why' field.
   * - `shared`: An optional string indicating if the chart is shared.
   * - `sharedTo`: An optional string for specifying who the chart is shared with.
   * - `pointDefinitions`: An array for point definitions, initialized as an empty array.
   */
  async createFormGroup() {
    let defaultData = this.data?.widgetData;
    // Unit conversions needs to be handled in the defaultData
    this.chartForm = this.fb.group({
      name: [defaultData?.name, [Validators.pattern(this.namePattern), Validators.maxLength(35), forbiddenIfSplCharAtStartValidator]],
      builderType: [this.data.isEdit ? defaultData?.builderType : 'BUILDER'],
      tags: [defaultData?.builderType],
      what: [defaultData?.what],
      why: [defaultData?.why],
      shared: [defaultData?.shared],
      sharedTo: [defaultData?.sharedTo],
      pointDefinitions: this.fb.array([]),
    });
    if (this.data.isEdit) {
      this.resetDataInFormEdit();
    } else {
      this.isLoading = false;
    }
  }

  /**
   * Populates the widget data by setting the builder type, what description, and why description
   * from the provided data. Additionally, it assigns a unique pointId to each point definition
   * in the widget data.
   *
   * @private
   * @memberof ChartBuilderLayoutComponent
   */
  populateWidgetDate() {
    this.builderType = this.data?.widgetData?.builderType;
    this.whatDescription = this.data?.widgetData?.what;
    this.whyDescription = this.data?.widgetData?.why;
    this.data.widgetData.pointDefinitions = this.data.widgetData.pointDefinitions.map((pt: any, i: number) => {
      pt['pointId'] = i;
      return pt;
    });
  }

  /**
   * Populates the chart configuration for the component.
   * 
   * This method initializes the chart configuration by deep cloning the widget data's chart configuration.
   * It sets the selected chart type, view by, and view type based on the chart configuration.
   * 
   * If the chart is not a pie or donut chart, it sets the x-axis and y-axis values, labels, sorting value, 
   * and format display value from the chart configuration.
   * 
   * If the chart is a pie or donut chart, it sets the chart label, format display value, and gradient group 
   * based on the chart configuration.
   */
  populateChartConfig() {
    this.chartConfig = ObjectUtil.deepClone(this.data?.widgetData?.chartConfig);
    this.selectedChartType = chartTypeText[this.chartConfig['chartType'] as keyof typeof chartTypeText];
    this.selectedChartViewBy = this.chartConfig['viewBy'];
    this.chartViewType = this.chartConfig['viewType'];

    this.setChartOptionBasedOnChart();
    this.selectedParamIndex = 0;
    if(this.showForTerrain)
      this.selectedParamName = this.data?.widgetData?.pointDefinitions[0]?.name;
    if(!this.isPieOrDonutOrSunburstChart() && !this.showForTerrain && !this.showGaugeChart) {
      this.selectedXaxisValue = this.chartConfig['axis']['xaxis']['column'];
      this.selectedYaxisValue = this.chartConfig['axis']['yaxis']['column'];
      this.xAxisLabel = this.chartConfig['axis']['xaxis']['legend label'];
      this.yAxisLabel = this.chartConfig['axis']['yaxis']['legend label'];
      this.selectedSortingValue = this.chartConfig['tooltip']['sortBy'];
      this.selectedFormatDisplayValue = this.chartConfig['tooltip']['format'];
      this.selectedFormatValue = this.selectedFormatDisplayValue.split('-');
      this.chartAxisTypes = this.selectedXaxisValue == 'time' ? chartYAxisTypes : chartXAxisTypes;
      this.axisValues = this.axisValues.filter(option => option.value !== 'scope');
    } else if(this.showForTerrain){
      this.populateTerrinChartConfig();
    } else if(this.showSunBurstChart) {
      this.selectedParamName = this.data?.widgetData?.pointDefinitions[0]?.name;
      this.pieDonutChartLable = this.chartConfig[this.selectedParamName]?.['chartLabel'] ? this.chartConfig[this.selectedParamName]?.['chartLabel']: '';
      if(this.chartConfig['color']) {
        this.selectedGradientGroup = this.commonService.findGradientGroupName(this.chartConfig['color']);
      }
    } else if (this.showGaugeChart) {
      this.selectedParamName = this.data?.widgetData?.pointDefinitions[0]?.name;
      this.selectedParamIndex = 0;
      this.pieDonutChartLable = this.chartConfig[this.selectedParamName]['chartLabel'];
      this.selectedStartAngle = this.chartConfig[this.selectedParamName]['startAngle'];
      this.selectedEndAngle = this.chartConfig[this.selectedParamName]['endAngle'];
      this.minValue = this.chartConfig[this.selectedParamName]['minValue'];
      this.maxValue = this.chartConfig[this.selectedParamName]['maxValue'];
    } else {
      this.pieDonutChartLable = this.chartConfig['chartLabel'];
      this.selectedFormatDisplayValue = this.chartConfig['tooltip']['format'];
      this.selectedFormatValue = this.selectedFormatDisplayValue.split('-');
      if(this.chartConfig['color']){
        this.selectedGradientGroup = this.commonService.findGradientGroupName(this.chartConfig['color']);
      }
      this.axisValues = this.axisValues.filter(option => option.value !== 'scope');
    }
  }

  /**this method populate the saved chart settings to component variables */
  populateTerrinChartConfig() {
    this.selectedXaxisValue = this.chartConfig[this.selectedParamName]['axis']['xaxis']['column'];
    this.selectedYaxisValue = this.chartConfig[this.selectedParamName]['axis']['yaxis']['column'];
    this.xAxisLabel = this.chartConfig[this.selectedParamName]['axis']['xaxis']['legend label'];
    this.yAxisLabel = this.chartConfig[this.selectedParamName]['axis']['yaxis']['legend label'];
    this.selectedFormatDisplayValue = this.chartConfig[this.selectedParamName]['tooltip']['format'];
    this.selectedFormatValue = this.selectedFormatDisplayValue.split('-');
    this.selectedNameFormatDisplayValue = this.chartConfig[this.selectedParamName]['scopeformat'];
    this.selectedNameFormatValue = this.selectedNameFormatDisplayValue.split('-');
    this.selectedZaxisValue = this.chartConfig[this.selectedParamName]['axis']['zaxis']['column'];
    this.zAxisLabel = this.chartConfig[this.selectedParamName]['axis']['zaxis']['legend label'];
    this.selectedGradientGroup = this.commonService.findGradientGroupName(this.chartConfig[this.selectedParamName]['color']);
    if(this.axisValues.findIndex(option => option.value === 'scope') === -1) {
      this.axisValues.push({ name: 'Scope', value: 'scope' });
    }
  }

  /**
   * Resets the data in the form when editing a chart.
   * 
   * This method performs the following actions:
   * - Finds the first non-derived point definition.
   * - Resets the selected point name and tags in the form.
   * - Generates a query with the tags and fetches points if not in edit mode.
   * - Adds point definitions to the form array, handling both derived and non-derived points.
   * - Sets up the sidebar data and form interactivity based on the edit mode and shared status.
   * 
   * @remarks
   * - If the point definitions array length is greater than or equal to 1, the point name is reset with data if not empty.
   * - If the widget data is being edited, the sidebar data is set based on the selected configuration.
   * - If the widget data is shared by others, the form is made non-interactive.
   */
  resetDataInFormEdit() {
    const pd = this.data.widgetData.pointDefinitions;
    const firstNonderivedIndex = pd.findIndex((item: any) => item.isDerived === false);
    const filteredPd = pd[firstNonderivedIndex];
    // If point definations array length is greater than or equal to 1 , point name is resetted with data if not empty
    this.selectedPointName = pd.length >= 1 ? filteredPd.name : '';
    this.chartForm.controls['tags'].reset(pd.length >= 1 ? filteredPd.tags : '');
    const query = this.generateQueryWithTags(this.chartForm.controls['tags'].value);
    if (!this.data.isEdit) {
      this.getPoints(query);
    }
    const add = <FormArray>this.pointDefs;
    let nonDerivedIndex = -1;

    this.data.widgetData.pointDefinitions.forEach((def: any, index: number) => {
      let point:any;
      if (def?.isDerived) {
        point = this.addExistingCalculatedParameterData(def);
      } else {
        point = this.addPointsDefs(def);
        if (nonDerivedIndex === -1) {
          nonDerivedIndex = index;
        }
      }
      add.push(point);
      const condtnArray = <FormArray>point.get('conditions');
      if (def.conditions) {
        def.conditions.forEach((condition: any) => {
          condtnArray.push(this.addConditionsArray(condition));
        });
      } 
      if (this.data.widgetData.pointDefinitions[this.data.widgetData.pointDefinitions.length - 1] === def) {
        setTimeout(() => {
          if (this.data.isEdit) {
            this.selectedSharedConfiguration = this.selectedConfiguration(this.data.widgetData.sharedTo);
            this.setSideBarDataOnPointChange(firstNonderivedIndex, this.selectedPointName);
            this.selectedPointIndex = firstNonderivedIndex;
          }
          let el: any = document.getElementsByClassName('chart-builder-form')[0];
          if (this.data?.sharedByOthers) {
            this.isSharedByOthers = true
            if(el && el.style && el.style.pointerEvents){
              el.style.pointerEvents = 'none';
            }
          } else if(el && el.style && el.style.pointerEvents) {
            el.style.pointerEvents = 'all';
          }
          this.isLoading = false;
        }, 1000);
      }
    });
  }

  addExistingCalculatedParameterData(pointData:any) {
    return this.fb.group({
      name: [pointData.name, [Validators.pattern(this.namePattern), forbiddenIfSplCharAtStartValidator]],
      pointId: pointData.pointId,
      conditions: this.fb.array([]),
      isDerived: pointData.isDerived,
      dependentPoints: [pointData.dependentPoints],
      xml: pointData.xml,
      snippet: pointData.snippet,
      previousName: ObjectUtil.deepClone(pointData.name),
      color: pointData.color,
      showVisualisation: pointData.showVisualisation,
      aggregateBy: pointData.aggregateBy,
      axisPosition: pointData.axisPosition,
    });
  }

    /**
 * API call to gets Points according to the tags selected by the user
 * param(query) - tags query to retrive points
 */
    getPoints(query:string) {
      this.siteService.getHaystackDataByQuery(query).pipe(
        map(this.siteService.stripHaystackTypeMapping)).subscribe(({ rows }) => {
          this.points = this.getFilteredData(rows);
        });
    }

    /**
     * Filters and processes an array of data points.
     *
     * This function iterates over the provided `points` array, processes each element to generate a `displayName`
     * and `markers` property, and then filters out duplicate elements based on the `displayName`.
     *
     * @param points - An array of data points to be filtered and processed. Each data point is expected to have a `dis` property.
     * @returns The filtered and processed array of data points.
     */
    getFilteredData(points:any) {
      points?.forEach((point: any) => {
        let pointName = point.dis.includes(this.data.siteName) ? point.dis.replace(this.data.siteName + '-', '') : point.dis;
        if (!pointName.includes('SystemEquip') && !pointName.includes('BuildingTuner')) {
          let splitNames = pointName.split('-').reverse();
          pointName = splitNames.length ? splitNames[0] : pointName;
        }
        point.markers = Object.keys(point).toString();
        point.displayName = pointName;
      });
  
      points = points?.filter((elem:any, index:number, self:any) => self.findIndex(
        (t:any) => { return (t.displayName === elem.displayName) }) === index);
      return points;
    }


  /**
   * Getter for the point definitions from the chart form.
   * 
   * @returns {any} The point definitions from the chart form if it exists.
   */
  get pointDefs(): any {
    if (this.chartForm) {
      return this.chartForm.get('pointDefinitions');
    }
  }

  /**
   * Handles changes to the widget selection.
   * 
   * @param option - The selected option from the widget change event.
   * 
   * If the selected option's value is 'shared':
   * - If the portal is internal and no shared configuration is selected, sets the shared configuration to 'L1' and opens the shared menu.
   * - If the portal is not internal and no shared configuration is selected, sets the shared configuration to 'L2' and closes the shared menu.
   * 
   * If the selected option's value is not 'shared':
   * - Resets the shared configuration to 'NONE' and closes the shared menu.
   * 
   * Finally, updates the share access type and marks the chart form as dirty.
   */
  onWidgetChange(option: MenuOption) {
    if (option?.value === 'shared') {
      if (this.isInternalPortal) {
        if (this.selectedSharedConfiguration == 'NONE') this.selectedSharedConfiguration = 'L1';
        this.sharedMenuTrigger.openMenu();
      } else {
        if (this.selectedSharedConfiguration == 'NONE') this.selectedSharedConfiguration = 'L2';
        this.sharedMenuTrigger.closeMenu();
      }
    } else {
      this.selectedSharedConfiguration = 'NONE';
      this.sharedMenuTrigger.closeMenu();
    }
    this.shareAccessType = option.value;
    this.chartForm.markAsDirty();
  }

  /**
   * Sets the widget type based on the provided data.
   * 
   * This method determines the `shareAccessType` based on the `data` object.
   * If the widget is not in edit mode, it checks the `type` property of `data`
   * to set `shareAccessType` to either 'shared' or 'personal'.
   * If the widget is in edit mode, it sets `shareAccessType` based on the 
   * `shared` property of `chartData`.
   * 
   * @remarks
   * - If `data.isEdit` is false:
   *   - If `data.type` is 'Filter Shared by me' or 'Filter Shared by others', 
   *     `shareAccessType` is set to 'shared'.
   *   - If `data.type` is 'Personal Filter', `shareAccessType` is set to 'personal'.
   * - If `data.isEdit` is true:
   *   - `shareAccessType` is set based on the `shared` property of `chartData`.
   */
  setWidgetType() {
    if (!this.data.isEdit) {
      if (this.data.type === 'Filter Shared by me' || this.data.type === 'Filter Shared by others') {
        this.shareAccessType = 'shared';
      } else if (this.data.type === 'Personal Filter') {
        this.shareAccessType = 'personal';
      }
    } else {
      this.shareAccessType = this.data?.widgetData?.shared ? 'shared' : 'personal';
    }
  }

  /**
   * Determines the corresponding configuration level based on the provided configuration string.
   *
   * @param {string} configuration - The configuration string to evaluate.
   * @returns {string} - Returns 'L2' for 'INTERNAL_AND_FACILISIGHT', 'L1' for 'INTERNAL', 
   *                     'INTERNAL_AND_FACILISIGHT' for 'L2', 'INTERNAL' for 'L1', 
   *                     and 'NONE' for any other value or if the configuration is not provided.
   */
  selectedConfiguration(configuration: string) {
    if (configuration) {
      if (configuration == "INTERNAL_AND_FACILISIGHT") {
        return 'L2'
      }
      else if (configuration == "INTERNAL") {
        return 'L1'
      }
      else if (configuration == "L2") {
        return 'INTERNAL_AND_FACILISIGHT'
      }
      else if (configuration == "L1") {
        return 'INTERNAL'
      }
      else {
        return 'NONE';
      }
    } else {
      return 'NONE';
    }
  }

  /**
   * Handles the selection of a build type from a dropdown or similar UI element.
   * 
   * @param event - The event object containing the selected build type value and label.
   * @param buildscope - Optional boolean flag indicating whether the build scope should be considered.
   * 
   * If the selected build type is different from the current one and buildscope is false,
   * a warning about scope change is shown and the temporary scope is set based on the selected value.
   * Otherwise, it updates the builder type, form control, and resets various properties and controls.
   */
  onBuildTypeSelect(event: MenuOption, buildscope = false) {
    if (this.builderType && this.builderType !== event.value && !buildscope) {
      this.showscopeChangeWarning = true;
      this.tempscope = find(this.buildTypeOptions, ['value', event.value]);
      return;
    }
    this.builderType = event.label;
    this.chartForm.get('builderType')?.setValue(event?.label);
    this.showscopeChangeWarning = false;
    this.selectedTags = [];
    this.haystackQuery = 'point and his';
    this.pointDefs.controls = [];
    this.pointDefs.value = [];
    this.onTagsClear()
  }

  onChartSelectionChange(chartType: MatSelectChange) {
    if (this.selectedChartType && this.selectedChartType !== chartType?.value) {
      this.showChartChangeWarning = true;
      this.tempChartTypeScope = chartType?.value;
    } else {
      this.selectedChartType = chartType.value;
      this.updateAggregateBy(this.selectedChartType); // Update the aggregate by based on the selected chart type.
      this.chartConfig['chartType'] = this.getChartValue(chartType.value);
      this.setChartOptionBasedOnChart();
      if (this.showRadioBtnParameter) {
        this.selectedParamIndex = 0;
        this.selectedParamName = this.pointDefs?.controls[0]?.value?.name;
      }
      if(this.showForTerrain){
        if(this.axisValues.findIndex(option => option.value === 'scope') === -1) {
          this.axisValues.push({ name: 'Scope', value: 'scope' });
        }
        this.pointDefs.controls.forEach((control:any) => {
          control.get('color').setValue(this.selectedGradientGroup);
        });
      }
      if(!this.showForTerrain) {
        this.axisValues = this.axisValues.filter(option => option.value !== 'scope');
      }
      if (this.showGaugeChart) {
        this.resetGaugeChartConfig();
      }
      if (this.isPieOrDonutOrSunburstChart()) {
        this.setDefaultConfigForPieDonutChart();
      } else {
        this.setChartAxisSettings();
      }
    }
  }

  /**
   * Sets the chart options based on the selected chart type.
   * 
   * This method updates various boolean properties that control the visibility
   * of different chart configuration options. It uses the selected chart type
   * to determine which options should be shown or hidden.
   * 
   * The properties updated by this method include:
   * - `showAxisScale`: Whether to show the axis scale configuration.
   * - `showAxisPosition`: Whether to show the axis position configuration.
   * - `showAxisLabel`: Whether to show the axis label configuration.
   * - `showToolTipConfig`: Whether to show the tooltip configuration.
   * - `showToolTipSorting`: Whether to show the tooltip sorting configuration.
   * - `shthis.isPieOrDonutChart()owToolTipFormat`: Whether to show the tooltip format configuration.
   * - `showChartLabel`: Whether to show the chart label configuration.
   * - `showPieToolTipLabel`: Whether to show the pie tooltip label configuration.
   */
  setChartOptionBasedOnChart() {
    let selectedChartConfig: any = chartBuilderChartConfig[chartEnum[this.selectedChartType as keyof typeof chartEnum]];

    this.showAxisScale = selectedChartConfig.includes('showAxisScale');
    this.showAxisPosition = selectedChartConfig.includes('showAxisPosition');
    this.showAxisLabel = selectedChartConfig.includes('showAxisLabel');
    this.showToolTipConfig = selectedChartConfig.includes('showToolTipConfig');
    this.showToolTipSorting = selectedChartConfig.includes('showToolTipSorting');
    this.showToolTipFormat = selectedChartConfig.includes('showToolTipFormat');
    this.showChartLabel = selectedChartConfig.includes('showChartLabel');
    this.showPieToolTipLabel = selectedChartConfig.includes('showPieToolTipLabel');
    this.showForTerrain = selectedChartConfig.includes('showForTerrain');
    this.showGlobalColorGradient = selectedChartConfig.includes('showGlobalColorGradient');
    this.showRightLeftAxisSelection = selectedChartConfig.includes('showRightLeftAxisSelection');
    this.showParameterColorSelection = selectedChartConfig.includes('showParameterColorSelection');
    this.hideGroupBy = selectedChartConfig.includes('hideGroupBy');
    this.hideViewBy = selectedChartConfig.includes('hideViewBy');
    this.hideDateRange = selectedChartConfig.includes('hideDateRange');
    this.showRadioBtnParameter = selectedChartConfig.includes('showRadioBtnParameter');
    this.showSunBurstChart = selectedChartConfig.includes('showSunburst');
    this.showGaugeChart = selectedChartConfig.includes('showGauge');
  }

  /**
   * Sets the default configuration for Pie and Donut charts.
   * This method initializes and configures the chart settings specific to Pie and Donut charts.
   * It sets up the tooltip, view type, view by, chart label, and color properties.
   * Additionally, it removes the axis configuration and updates the default tooltip format.
   * 
   * @returns {void}
   */
  setDefaultConfigForPieDonutChart() {
    this.chartConfig['tooltip'] = {};
    this.chartConfig['tooltip']['format'] = this.selectedFormatDisplayValue;
    this.chartConfig['viewType'] = this.chartViewType;
    this.chartConfig['viewBy'] = this.selectedChartViewBy;
    this.chartConfig['chartLabel'] = this.pieDonutChartLable;
    this.chartConfig['color'] = this.commonService.gradientColors.grp1;
    if (this.chartConfig && this.chartConfig['axis']) {
      delete this.chartConfig['axis'];
    }
    if (this.chartConfig && this.chartConfig['tooltip'] && this.showSunBurstChart) {
      delete this.chartConfig['tooltip'];
    }
    this.updateDefaultTooltipFormat();
  }

  //Resetting config if chart type is changed
  resetGaugeChartConfig() {
    delete this.chartConfig['axis'];
    delete this.chartConfig['tooltip'];
    delete this.chartConfig['viewType'];
    this.setDefaultConfigForGaugeChart();

    // Resetting the condition data
    this.pointDefs.controls.forEach((point: any) => {
      point.controls['conditions'].controls = [];
      point.controls['conditions'].value = [];
      point.controls['conditions'].updateValueAndValidity();
    });
  }

  //Default config for gauge chart parameter config
  setDefaultConfigForGaugeChart(paramName?: string) {
    paramName = paramName || this.selectedParamName;
    this.selectedStartAngle = 180;
    this.selectedEndAngle = 0;
    this.minValue = null;
    this.maxValue = null;
    this.pieDonutChartLable = '';
    this.chartConfig['viewType'] = this.chartViewType;
    this.chartConfig[paramName] = {};
    this.chartConfig[paramName]['startAngle'] = this.selectedStartAngle;
    this.chartConfig[paramName]['endAngle'] = this.selectedEndAngle;
    this.chartConfig[paramName]['minValue'] = this.minValue;
    this.chartConfig[paramName]['maxValue'] = this.maxValue;
    this.chartConfig[paramName]['defaultColor'] = '#E95E6F';
    this.chartConfig[paramName]['chartLabel'] = '';
  }

  setChartAxisSettings() {
    if (this.selectedChartType) {
      this.chartConfig['viewType'] = this.chartViewType;
      this.chartConfig['viewBy'] = this.selectedChartViewBy;
      if (!this.showForTerrain && !this.showSunBurstChart && !this.showGaugeChart) {
        this.chartConfig['tooltip'] = this.chartConfig['tooltip'] || {};
        this.chartConfig['tooltip']['enabled'] = true;
        this.chartConfig['tooltip']['sortBy'] = this.selectedSortingValue;
        this.chartConfig['tooltip']['format'] = this.selectedFormatDisplayValue;
        this.chartConfig['axis'] = this.chartConfig['axis'] || {};
        this.chartConfig['axis']['xaxis'] = this.chartConfig['axis']['xaxis'] || {};
        this.chartConfig['axis']['yaxis'] = this.chartConfig['axis']['yaxis'] || {};
        this.chartConfig['axis']['xaxis']['column'] = this.selectedXaxisValue;
        this.chartConfig['axis']['yaxis']['column'] = this.selectedYaxisValue;
      } else if (this.showForTerrain) {
        this.checkAndUpdateDefaultSettings();
      } else if (this.showSunBurstChart) {
        this.chartConfig[this.selectedParamName] = this.chartConfig[this.selectedParamName] || {};
        this.pieDonutChartLable = this.chartConfig[this.selectedParamName]?.['chartLabel'] ? this.chartConfig[this.selectedParamName]?.['chartLabel']: '';

      }
      this.setAxisOptions();
      this.updateAxisLable();
      this.updateDefaultTooltipFormat();
    }
  }

  /**this method sets the config parameters when chart type is terrain chart */
  setTerranChartAxisSettings() {
    delete this.chartConfig['axis'];
    this.chartConfig[this.selectedParamName] = this.chartConfig[this.selectedParamName] || {};
    this.chartConfig[this.selectedParamName]['tooltip'] = this.chartConfig[this.selectedParamName]['tooltip'] || {};
    this.chartConfig[this.selectedParamName]['tooltip']['enabled'] = true;
    this.chartConfig[this.selectedParamName]['tooltip']['format'] = this.selectedFormatDisplayValue;
    this.chartConfig[this.selectedParamName]['scopeformat'] = this.selectedNameFormatDisplayValue;
    this.chartConfig[this.selectedParamName]['axis'] = this.chartConfig[this.selectedParamName]['axis'] || {};
    this.chartConfig[this.selectedParamName]['axis']['xaxis'] = this.chartConfig[this.selectedParamName]['axis']['xaxis'] || {};
    this.chartConfig[this.selectedParamName]['axis']['yaxis'] = this.chartConfig[this.selectedParamName]['axis']['yaxis'] || {};
    this.chartConfig[this.selectedParamName]['axis']['zaxis'] = this.chartConfig[this.selectedParamName]['axis']['zaxis'] || {};
    this.chartConfig[this.selectedParamName]['axis']['xaxis']['column'] =  this.chartConfig[this.selectedParamName]['axis']['xaxis']['column'] || this.selectedXaxisValue;
    this.chartConfig[this.selectedParamName]['axis']['yaxis']['column'] = this.chartConfig[this.selectedParamName]['axis']['yaxis']['column'] || this.selectedYaxisValue;
    this.chartConfig[this.selectedParamName]['axis']['zaxis']['column'] = this.chartConfig[this.selectedParamName]['axis']['zaxis']['column'] || this.selectedZaxisValue;
    this.chartConfig[this.selectedParamName]['color'] = this.chartConfig[this.selectedParamName]['color'] ?  this.chartConfig[this.selectedParamName]['color'] : this.commonService.gradientColors.grp1;
  }

  checkAndUpdateDefaultSettings() {
    let tempSelectedParam = this.selectedParamName;
    this.pointDefs.controls.forEach((control: any) => {
      this.selectedParamName = control.value.name;
      this.setTerranChartAxisSettings();
    });
    this.selectedParamName = tempSelectedParam;
  }

  
  /**
   * Updates the default tooltip format based on the selected chart view type.
   * 
   * This method performs the following steps:
   * 1. Finds the index of the selected chart view type in the `chartViewTypes` array.
   * 2. Filters the `formatValues` array to include items up to the found index.
   * 3. Adds a static format value to the filtered array.
   * 4. Maps the filtered array to extract the `value` property of each `TooltipFormat` item.
   * 5. Joins the mapped values into a single string separated by hyphens and assigns it to `selectedFormatDisplayValue`.
   */
  updateDefaultTooltipFormat() {
    let ind = this.chartViewTypes.findIndex(option => option.value == this.selectedChartViewBy);
    this.displayFormatValues = this.formatValues.filter((item, index) => index <= ind);
    this.displayFormatValues.push(this.staticFormatValue);
    if (!this.showForTerrain && !this.showGaugeChart) {
      this.selectedFormatValue = this.data?.isEdit ? this.chartConfig['tooltip']['format'].split('-') : this.displayFormatValues.map((item: TooltipFormat) => item.value);
      this.selectedFormatDisplayValue = this.selectedFormatValue.map((item) => item).join('-');
    }
    if(this.showForTerrain){
      this.selectedFormatValue = (this.data?.isEdit && this.chartConfig[this.selectedParamName]) ? this.chartConfig[this.selectedParamName]['tooltip']['format'].split('-') : this.displayFormatValues.map((item: TooltipFormat) => item.value);
      this.selectedFormatDisplayValue = this.selectedFormatValue.map((item) => item).join('-');
      let checkInd = ind - 1;
      this.displayFormatNameValues = this.formartNameValues.filter((item, index) => index <= checkInd);
      this.staticFormatNameValue = this.formartNameValues[ind];
      if(this.staticFormatNameValue){
        this.staticFormatNameValue.disabled = true;
        this.displayFormatNameValues.push(this.staticFormatNameValue);
      }
      this.selectedNameFormatValue = (this.data?.isEdit && this.chartConfig[this.selectedParamName]) ? this.chartConfig[this.selectedParamName]['scopeformat'].split('-') : this.displayFormatNameValues.map((item: TooltipFormat) => item.value);
      this.selectedNameFormatDisplayValue = this.selectedNameFormatValue.map((item) => item).join('-');
    }
  }

  updateAxisLable() {
    if (!this.showForTerrain && !this.showSunBurstChart && !this.showGaugeChart) {
      this.chartConfig.axis['xaxis']['legend label'] = this.xAxisLabel;
      this.chartConfig.axis['yaxis']['legend label'] = this.yAxisLabel;
    } else if(this.showForTerrain){
      this.chartConfig[this.selectedParamName].axis['xaxis']['legend label'] = this.xAxisLabel;
      this.chartConfig[this.selectedParamName].axis['yaxis']['legend label'] = this.yAxisLabel;
      this.chartConfig[this.selectedParamName].axis['zaxis']['legend label'] = this.zAxisLabel;
    }
  }

  /**
   * Updates the label of the pie/donut chart in the chart configuration.
   * This method sets the 'chartLabel' property of the `chartConfig` object
   * to the value of the `pieDonutChartLable` property.
   * 
   * @memberof ChartBuilderLayoutComponent
   */
  updatePieDonutChartLable() {
    if (this.showSunBurstChart || this.showGaugeChart) {
      if (!this.chartConfig[this.selectedParamName]) {
        this.chartConfig[this.selectedParamName] = {};
      }
      this.chartConfig[this.selectedParamName]['chartLabel'] = this.pieDonutChartLable;
    } else {
      this.chartConfig['chartLabel'] = this.pieDonutChartLable;
    }
  }

  onChartViewBySelectionChange(chartViewBy: MatSelectChange) {
    if (this.selectedChartViewBy !== chartViewBy?.value) {
      this.selectedChartViewBy = chartViewBy?.value;
      let ind = this.chartViewTypes.findIndex(option => option.value === chartViewBy?.value);
      this.displayFormatValues = this.formatValues.filter((item, index) => index <= ind);
      this.displayFormatValues.push(this.staticFormatValue);
      this.selectedFormatValue = this.displayFormatValues.map((item: TooltipFormat) => item.value);
      this.selectedFormatDisplayValue = this.selectedFormatValue.map((item) => item).join('-');
      this.chartConfig['viewBy'] = chartViewBy?.value;
      if (!this.showForTerrain && !this.showSunBurstChart && !this.showGaugeChart) {
        this.chartConfig['tooltip']['format'] = this.selectedFormatDisplayValue;
      } else if(this.showForTerrain){
        let checkInd = ind - 1;
        this.displayFormatNameValues = this.formartNameValues.filter((item, index) => index <= checkInd);
        this.staticFormatNameValue = this.formartNameValues[ind];
        if(this.staticFormatNameValue){
          this.staticFormatNameValue.disabled = true;
          this.displayFormatNameValues.push(this.staticFormatNameValue);
        }
        this.selectedNameFormatValue = this.displayFormatNameValues.map((item: TooltipFormat) => item.value);
        this.selectedNameFormatDisplayValue = this.selectedNameFormatValue.map((item) => item).join('-');
        this.pointDefs.controls.forEach((control: any) => {
          if(this.chartConfig[control.value.name]){
            this.chartConfig[control.value.name]['tooltip']['format'] = this.selectedFormatDisplayValue;
            this.chartConfig[control.value.name]['scopeformat'] = this.selectedNameFormatDisplayValue;
          }
        });
      }
    }
  }

  onXaxisSelectionChange(chartViewBy: MatSelectChange) {
    if (this.selectedXaxisValue !== chartViewBy?.value) {
      const previousValue = this.selectedXaxisValue;
      this.selectedXaxisValue = chartViewBy?.value;
      if(!this.showForTerrain) {
        this.chartConfig['axis']['xaxis']['column'] = chartViewBy?.value;
        this.selectedYaxisValue = (chartViewBy?.value == 'time') ? 'value' : 'time';
        this.chartConfig['axis']['yaxis']['column'] = (chartViewBy?.value == 'time') ? 'value' : 'time';
      } else {
        this.chartConfig[this.selectedParamName]['axis']['xaxis']['column'] = chartViewBy?.value;
        if(this.selectedZaxisValue === chartViewBy?.value) {
          this.selectedZaxisValue = previousValue;
          this.chartConfig[this.selectedParamName]['axis']['zaxis']['column'] = previousValue;
        } else if(this.selectedYaxisValue === chartViewBy?.value) {
          this.selectedYaxisValue = previousValue;
          this.chartConfig[this.selectedParamName]['axis']['yaxis']['column'] = previousValue;
        }
      }
      this.updateParameterAxisconfig();
      this.setAxisOptions();
    }
  }

  onChartAngleSelectionChange() {
    //updating the selected Angle values to config
    this.chartConfig[this.selectedParamName]['startAngle'] = this.selectedStartAngle;
    this.chartConfig[this.selectedParamName]['endAngle'] = this.selectedEndAngle;
  }

  onYaxisSelectionChange(chartViewBy: MatSelectChange) {
    if (this.selectedYaxisValue !== chartViewBy?.value) {
      const previousValue = this.selectedYaxisValue;
      this.selectedYaxisValue = chartViewBy?.value;
      if(!this.showForTerrain){
        this.chartConfig['axis']['yaxis']['column'] = chartViewBy?.value;
        this.selectedXaxisValue = (chartViewBy?.value == 'time') ? 'value' : 'time';
        this.chartConfig['axis']['xaxis']['column'] = (chartViewBy?.value == 'time') ? 'value' : 'time';
      } else {
        this.chartConfig[this.selectedParamName]['axis']['yaxis']['column'] = chartViewBy?.value;
        if(this.selectedZaxisValue === chartViewBy?.value) {
          this.selectedZaxisValue = previousValue;
          this.chartConfig[this.selectedParamName]['axis']['zaxis']['column'] = previousValue;
        } else if(this.selectedXaxisValue === chartViewBy?.value) {
          this.selectedXaxisValue = previousValue;
          this.chartConfig[this.selectedParamName]['axis']['xaxis']['column'] = previousValue;
        }
      }
      this.updateParameterAxisconfig();
      this.setAxisOptions();
      
    }
  }

  onZaxisSelectionChange(chartViewBy: MatSelectChange) {
    if (this.selectedZaxisValue !== chartViewBy?.value) {
      const previousValue = this.selectedZaxisValue;
      this.selectedZaxisValue = chartViewBy?.value;
      this.chartConfig[this.selectedParamName]['axis']['zaxis']['column'] = chartViewBy?.value;
      if(this.selectedYaxisValue === chartViewBy?.value) {
        this.selectedYaxisValue = previousValue;
        this.chartConfig[this.selectedParamName]['axis']['yaxis']['column'] = previousValue;
      } else if(this.selectedXaxisValue === chartViewBy?.value) {
        this.selectedXaxisValue = previousValue;
        this.chartConfig[this.selectedParamName]['axis']['xaxis']['column'] = previousValue;
      }
      this.updateParameterAxisconfig();
      this.setAxisOptions();
    }
  }

  /**
   * Updates the configuration of the chart axis based on the selected X-axis value.
   * If the selected X-axis value is 'time', it sets the chart axis types to `chartYAxisTypes`
   * and updates the axis position of each point definition control to 'LEFT'.
   * Otherwise, it sets the chart axis types to `chartXAxisTypes` and updates the axis position
   * of each point definition control to 'TOP'.
   */
  updateParameterAxisconfig() {
    this.chartAxisTypes = this.selectedXaxisValue == 'time' ? chartYAxisTypes : chartXAxisTypes;
    this.pointDefs.controls.forEach((control: any) => {
      control.controls.axisPosition.setValue(this.selectedXaxisValue == 'time' ? 'LEFT' : 'TOP');
    });
  }

  onChartSortBySelectionChange(chartViewBy: MatSelectChange) {
    this.selectedSortingValue = chartViewBy?.value;
    this.chartConfig['tooltip']['sortBy'] = chartViewBy?.value;
  }

  onFormatSelectionChange(option: MatSelectChange) {
    this.selectedFormatValue = option?.value;
    if (!this.selectedFormatValue.some((item: string) => item === '$parameter')) {
      this.selectedFormatValue.push(this.staticFormatValue.value);
    }
    this.selectedFormatDisplayValue = this.selectedFormatValue.map((item) => item).join('-');
    if(!this.showForTerrain && !this.showSunBurstChart){
      this.chartConfig['tooltip']['format'] = this.selectedFormatDisplayValue;
    } else if(this.showForTerrain){
      this.chartConfig[this.selectedParamName]['tooltip']['format'] = this.selectedFormatDisplayValue;
    }
  }

  onNameFormatSelectionChange(option: MatSelectChange) {
    this.selectedNameFormatValue = option?.value;
    if (!this.selectedNameFormatValue.some((item: string) => item === this.staticFormatNameValue.value)) {
      this.selectedNameFormatValue.push(this.staticFormatNameValue.value);
    }
    this.selectedNameFormatDisplayValue = this.selectedNameFormatValue.map((item) => item).join('-');
    this.chartConfig[this.selectedParamName]['scopeformat'] = this.selectedNameFormatDisplayValue;
  }

  /**
   * Handles the change in chart scope selection.
   * 
   * @param chartName - The name of the selected chart type.
   * 
   * This method updates the selected chart type and view type, resets the selected chart view by,
   * hides the chart change warning, and updates the chart configuration based on the selected chart type.
   * If the selected chart is a pie or donut chart, it sets the default configuration for pie/donut charts.
   * Otherwise, it sets the chart axis settings.
   */
  onChartScopeSelectionChange(chartName: string) {
    this.selectedChartType = chartName;
    this.updateAggregateBy(this.selectedChartType); // update aggregate type by based on chart type.
    this.setChartOptionBasedOnChart();
    if(this.showRadioBtnParameter) {
      this.selectedParamIndex = 0;
      this.selectedParamName = this.pointDefs?.controls[0]?.value?.name;
    }
    this.chartViewType = 'COMBINE';
    this.selectedChartViewBy = '';
    this.chartConfig = {};
    this.showChartChangeWarning = false;
    this.chartConfig['chartType'] = this.getChartValue(chartName);
    this.xAxisLabel = '';
    this.yAxisLabel = '';
    this.selectedXaxisValue = 'time';
    this.selectedYaxisValue = 'value';
    this.selectedSortingValue = 'sortview';
    this.selectedFormatDisplayValue = "$parameter";
    this.selectedNameFormatDisplayValue = "";
    this.pieDonutChartLable = '';
    this.selectedChartViewBy = '';
    this.chartConfig['axis'] = { xaxis: {}, yaxis: {} };
    this.showPreviewContainer = false;
    this.selectedStartAngle = 180;
    this.selectedEndAngle = 0;
    this.minValue = null;
    this.maxValue = null;
    this.pointDefs.controls.forEach((control: any) => {
      control.controls.axisPosition.setValue('LEFT');
      if(this.showForTerrain){
        control.controls.color.setValue(this.selectedGradientGroup);
      } else {
        control.controls.color.setValue(this.generateUniqueColor());
      }
    });
    this.setChartOptionBasedOnChart();
    if(this.showForTerrain && this.axisValues.findIndex(option => option.value === 'scope') === -1) {
      this.zAxisLabel = '';
      this.axisValues.push({ name: 'Scope', value: 'scope' });
    } else if (!this.showForTerrain && !this.showGaugeChart) {
      this.axisValues = this.axisValues.filter(option => option.value !== 'scope');
    }
    if (this.isPieOrDonutOrSunburstChart()) {
      this.setDefaultConfigForPieDonutChart();
    } else {
      if (this.showGaugeChart) {
        this.resetGaugeChartConfig();
      } else if (!this.showSunBurstChart) {
        this.setChartAxisSettings();
      }
    }
  }

  /**
   * Handles the selection of a chart view type from a menu option.
   * 
   * @param event - The selected menu option containing the value and label of the chart view type.
   */
  onChartViewTypeSelect(event: MenuOption) {
    if (this.chartViewType && this.chartViewType !== event.value) {
      this.chartViewType = event.value;
      this.chartConfig['viewType'] = event.value;
    }
  }

  /**
   * Handles the cancellation of chart type selection.
   * Resets the chart builder type change flag to false.
   */
  onChartTypeSelectCancel() {
    this.isChartBuilderTypeChanged = false;
    this.showChartChangeWarning = false;
    this.chartSelect?.writeValue(this.selectedChartType);
  }

  /**
   * Handles the selection of a chart type and updates the state accordingly.
   * 
   * @param {boolean} [scopeChanged=false] - Indicates whether the scope has changed.
   * 
   * If the chart builder type has not changed, it resets the selected tags and exits.
   * If the scope has changed, it clears the point definitions and chart data, resets the points and selected tags,
   * and re-enables the chart type switch element.
   */
  onChartTypeSelect(scopeChanged = false) {
    if (!this.isChartBuilderTypeChanged) {
      this.selectedTags = [];
      return
    }
    if (scopeChanged) {
      if (this.pointDefs) {
        this.pointDefs.controls = [];
        this.pointDefs.value = [];
      }
      if (this.data?.chartsData?.pointDefinitions?.length > 0) {
        this.data.chartsData.pointDefinitions = [];
      }
      this.points = []
      this.selectedTags = []
      this.isChartBuilderTypeChanged = false;
      let el: any = document.getElementById("switchType")
      if (el) {
        el.style.pointerEvents = "auto";
      }
    }
  }

  /**
   * Handles the event when a custom Haystack query is edited.
   * Clears the current points array.
   */
  onCustomHaystackQueryEdited() {
    this.points = [];
  }

  /**
   * Executes a custom query and processes the result.
   * 
   * @param query - The custom query to be executed.
   * @param pointId - Optional ID of the point to be used.
   * @param fromParentComponent - Flag indicating if the call is from a parent component. Defaults to `false`.
   * @returns void
   * 
   * @remarks
   * - If `fetchingPoints` is true, the function returns immediately.
   * - If the query contains 'siteRef', an error is set and the function returns.
   * - Processes the query and adds points if the builder type is 'CUSTOM'.
   * - Calls `getPointsFromSide` with the processed query and point ID.
   */
  executeCustomQuery(query: string, pointId?: string, fromParentComponent = false) {
    if (this.fetchingPoints) {
      return;
    }
    if (query?.includes('siteRef')) {
      this.hasError = true;
      this.errMsg = 'TEXT.SITE_REF_ERROR';
      this.fetchingPoints = false;
      return;
    } else {
      this.hasError = false;
    }
    this.query = this.processCustomQuery(query);
    const point: any = {};
    if (this.builderType == 'CUSTOM') {
      point['haystackQuery'] = this.haystackQuery;
      point['name'] = this.filterSpecialCharacters(this.haystackQuery);
      point['originalName'] = ObjectUtil.deepCopy(this.haystackQuery);
    }
    if (!fromParentComponent) {
      this.addPoints(point);
    }
    this.getPointsFromSide(this.query, pointId);
    return;
  }

  /**
   * Processes a custom query by appending site references and additional conditions.
   *
   * @param query - The initial query string to be processed.
   * @returns The processed query string with site references and additional conditions.
   */
  processCustomQuery(query: string) {
    let processedQuery;
    const siteRefParts: string[] = [];
    this.data?.siteId.forEach((id: string) => {
      siteRefParts.push(`siteRef==@${id}`);
    });
    processedQuery = `( ${siteRefParts.join(' or ')} )`;
    processedQuery = `${processedQuery} and ${query} `;
    return processedQuery;
  }

  /**
   * Filters out special characters from the given string.
   * 
   * This function removes any character from the input string that is not
   * an alphanumeric character, a hyphen, an underscore, a space, a percent sign, or a hash.
   * 
   * @param pointname - The string from which special characters need to be filtered.
   * @returns A new string with special characters removed.
   */
  filterSpecialCharacters(pointname: string): string {
    const regex = /[^A-Za-z0-9\-_ %#]/g;
    const filteredName = pointname.replace(regex, '');
    return filteredName;
  }

  /**
   * Adds a point to the chart builder layout.
   * 
   * @param point - The point object to be added.
   * @returns void
   * 
   * @remarks
   * - If the chart is shared by others, the function will return immediately without adding the point.
   * - The `builderType` property is added to the point object.
   * - If the `builderType` is 'BUILDER', the `selectedTags` property is added to the point object.
   * - Otherwise, the `haystackQuery` property is added to the point object.
   * - The `pointId` property is set to the current `selectedIndex`.
   * - The `pointAdded` method is called with the point object.
   * - The chart form is marked as dirty.
   */
  addPoints(point: any) {
    if (this.isSharedByOthers) {
      return;
    }
    point['builderType'] = this.builderType;
    if (this.builderType == 'BUILDER') {
      point['selectedTags'] = this.selectedTags;
    } else {
      point['haystackQuery'] = this.haystackQuery;
    }
    if (this.pointDefs?.value?.length > 0 && this.pointDefs?.value.every((param: any) => param.isDerived)) {
      this.selectedIndex = this.pointDefs?.value.length;
    }
    point['pointId'] = this.selectedIndex;
    this.pointAdded(point);
    this.chartForm.markAsDirty();
  }

  /**
   * Handles the addition of a new point to the chart.
   * 
   * @param pointData - The data of the point to be added.
   * @returns void
   * 
   * If the number of points already added equals the parameter count, 
   * the function will return early without processing the new point data.
   */
  pointAdded(pointData: any) {
    let findIndex = this.pointDefs.controls?.find((_control: any) => (_control.value.pointId == pointData.pointId));
    if (findIndex == undefined && this.pointDefs.value.length == this.parameterCount) {
      return;
    }
    this.processPointData(pointData);
  }

  /**
   * Processes the given point data and updates the form controls accordingly.
   * 
   * @param pointData - The data of the point to be processed.
   * @returns A promise that resolves when the point data has been processed.
   * 
   * This method performs the following steps:
   * 1. Checks if the point already exists in the form controls.
   * 2. If the point does not exist and the form is not empty, it creates a new point object and adds it to the form controls.
   * 3. If the point exists, it updates the existing point's name, tags, and haystack query based on the builder type.
   * 4. Updates the parameter list after processing the point data.
   */
  async processPointData(pointData: any) {
    let self = this;
    let findIndex = self.pointDefs.controls?.find((_control: any) => (_control.value.pointId == pointData.pointId));
    if (findIndex == undefined) {
      if (!self.checkIfFormIsEmpty()) {
        let pointObj: Point = {
          'name': pointData.name,
          'originalName': pointData.originalName,
          'tags': pointData?.tags,
          'unit': this.unitService.getUserPrefernceUnits(),
          'builderType': pointData.builderType,
          'pointId': pointData?.pointId,
          'isDerived': false,
          'color': (self.showForTerrain) ? (this.selectedGradientGroup || '') : this.generateUniqueColor(),
          'showVisualisation': true,
          'conditions': this.fb.array([]),
          "aggregateBy": chartAggregationTypes[0].value,
          "axisPosition": this.getConfigAxisPositions()
        }
        if (pointData.builderType == 'BUILDER') {
          pointObj['selectedTags'] = [...new Set(pointData.tags as string[])];
        } else {
          pointObj['haystackQuery'] = pointData?.haystackQuery;
        }
        const point = self.addPointsDefs(pointObj);
        self.pointDefs.push(point);
        const count = (self.chartForm.controls['pointDefinitions'] as FormArray).controls.length;
        if (count) {
          self.selectedPointIndex = count - 1;
        }
      }
    } else {
      const pointControl = self.pointDefs?.controls[pointData.pointId];
      this.previousParameterName = String(pointControl?.controls?.name.value);
      self.updatedParameterName = {
        previousName: this.previousParameterName,
        currentName: pointData.name
      }
      this.pointNameEdited(pointData.name);
      pointControl?.controls?.name.setValue(pointData.name);
      if (pointData.builderType == 'BUILDER') {
        pointControl?.controls.tags.setValue(pointData.tags);
        if (findIndex.name == pointControl?.value.name) {
          pointControl?.controls?.name.setValue(pointData.name);
        }
      } else {
        if (findIndex.haystackQuery == pointControl?.value.name) {
          pointControl.controls.name.setValue(pointData.name);
        }
        pointControl?.controls.haystackQuery.setValue(pointData.haystackQuery);
      }
      pointControl?.updateValueAndValidity();
    }
    this.updateParameterList();
  }

  /**
   * Checks if the form is empty.
   * @returns {boolean} Returns true if the form is empty, false otherwise.
   */
  checkIfFormIsEmpty(): boolean {
    let isEmpty = this.pointDefs.controls?.some((formControl: any) => {
      const isDerived = formControl?.value.isDerived;
      const tags = formControl?.value?.tags?.length;
      const haystackQuery = formControl?.value?.haystackQuery;

      if (!isDerived) {
        if (this.builderType === 'BUILDER' && !tags) {
          return true;
        }
        if (!formControl.value?.aggregateBy || !formControl.value?.color || formControl.value?.showVisualisation == null) {
          return true
        }
      }
      if (!formControl.value.name) {
        return true;
      }
      return false;
    });
    this.warningMsg = isEmpty ? 'Please enter all the values to proceed.' : '';
    return isEmpty;
  }

  /**
   * Creates a FormGroup for point definitions with the provided point data.
   *
   * @param pointData - The data for the point to be added.
   * @returns A FormGroup containing the point definitions.
   *
   * The FormGroup includes the following controls:
   * - `name`: A form control for the point name, with validation for a specific pattern and a custom validator to forbid special characters at the start.
   * - `haystackQuery`: A form control for the haystack query.
   * - `tags`: A form control for the tags associated with the point.
   * - `pointId`: A form control for the point ID, which is optional.
   * - `unit`: A form control for the unit of the point.
   * - `isDerived`: A form control indicating whether the point is derived, defaulting to `false`.
   */
  addPointsDefs(pointData: Point) {
    return this.fb.group({
      name: [pointData.name, [Validators.pattern(this.namePattern), forbiddenIfSplCharAtStartValidator]],
      haystackQuery: pointData.haystackQuery,
      tags: [pointData.tags],
      conditions: this.fb.array([]),
      pointId: pointData?.pointId,
      unit: pointData.unit,
      isDerived: false,
      color: pointData.color,
      showVisualisation: pointData.showVisualisation,
      aggregateBy: pointData.aggregateBy,
      axisPosition: pointData.axisPosition
    });
  }

  /**
   * Handles the focus event on an input element.
   * 
   * This method sets a timeout to scroll the parent element with the class 
   * "ng-value-container" to the far right. It ensures that the content of 
   * the parent element is fully visible when the input gains focus.
   */
  onInputFocus() {
    setTimeout(() => {
      let parentEle = document.getElementsByClassName("ng-value-container")[0];
      parentEle.scrollLeft = parentEle?.scrollWidth;
    });
  }

  /**
   * Handles the blur event on an input element.
   * 
   * This method performs the following actions:
   * 1. Retrieves the parent element with the class "ng-value-container".
   * 2. Retrieves the child elements with the classes "ng-placeholder" and "ng-input".
   * 3. If the child element "ng-placeholder" exists and the parent element has more than one child,
   *    it removes both child elements from the parent and re-appends them.
   * 4. Sets a timeout to scroll the parent element to the far right.
   */
  onInputBlur() {
    let parentEle = document.getElementsByClassName("ng-value-container")[0];
    let childEle = document.getElementsByClassName("ng-placeholder")[0];
    let childEle1 = document.getElementsByClassName("ng-input")[0];
    if (childEle && parentEle?.childElementCount > 1) {
      parentEle.removeChild(childEle);
      parentEle.removeChild(childEle1);
      parentEle.appendChild(childEle);
      parentEle.appendChild(childEle1);
      setTimeout(() => {
        parentEle.scrollLeft = parentEle?.scrollWidth;
      });
    }
  }

  /**
   * Handles the event when a tag is deselected.
   * 
   * @param item - The deselected tag item.
   * 
   * This method updates the `selectedTags` array by removing the deselected tag.
   * If no tags remain selected, it clears the `points` array.
   */
  onTagDeSelect(item: any) {
    this.selectedTags = this.selectedTags.filter((_item) => _item !== item['value']);
    if (this.selectedTags.length === 0) {
      this.points = []
    }
  }

  /**
   * Handles changes to tags and updates the query, points, and side points accordingly.
   *
   * @param event - The event object containing the new tags.
   * @param originalName - (Optional) The original name of the tags.
   * @param fromParentComponent - (Optional) A flag indicating if the change originated from a parent component. Defaults to `false`.
   */
  onTagsChange(tags: string[], originalName?: string, fromParentComponent = false) {
    let query = this.generateQueryWithTags(tags);
    const point: any = {};
    if (this.builderType != 'CUSTOM') {
      point['tags'] = tags;
      point['name'] = this.selectedTags.join(' ');
      point['originalName'] = ObjectUtil.deepCopy(this.selectedTags.join(' '));
    }
    if (!fromParentComponent) {
      this.addPoints(point);
    }
    this.getPointsFromSide(query, originalName);
  }

  /**
   * Fetches points from the server based on the provided query and processes them.
   * 
   * @param query - The query string used to fetch points.
   * @param originalName - (Optional) The original name of a point to be marked as selected.
   * 
   * This method performs the following steps:
   * 1. Initializes the points array and sets fetching state.
   * 2. Subscribes to the siteService to fetch data based on the query.
   * 3. Processes the fetched points to add display names, tags, and other properties.
   * 4. Sorts the points in ascending order by display name.
   * 5. Removes port numbers and duplicates from the points.
   * 6. Updates the points array and sets the empty label if no points are found.
   * 7. Marks a point as selected if its original name matches the provided originalName.
   * 8. Handles errors by setting the error state and message.
   */
  getPointsFromSide(query: string, originalName?: string) {
    this.points = [];
    this.fetchingPoints = true;
    this.selectedPoints = 0;
    this.hasError = false;
    this.subscriptions['querySerice'] = this.siteService.getHaystackDataByQuery(query).pipe(
      map(this.siteService.stripHaystackTypeMapping)
    ).subscribe({
      next: (res) => {
        this.fetchingPoints = false;
        this.selectedPoints = 0;

        // Since the incoming points would have a whole level of customization,
        // check if the point exists in the selectedPointsRefMap, then replace it with the current selections
        let points = res.rows?.map((_item: any) => {
          _item['displayName'] = _item.dis.includes(this.data.siteName) ? _item.dis.replace(this.data.siteName + '-', '') : _item.dis;
          _item['tags'] = [];
          Object.keys(_item).forEach(point => {
            if (point != 'tags' && point != 'originalName' && point != 'pointId' && point != 'name' && point != 'displayName') {
              if (point != 'siteRef' && query.includes(point)) {
                let pointObj = {
                  'tagName': point,
                  'isQueryTag': true
                }
                _item['tags'].push(pointObj);
              } else {
                let pointObj = {
                  'tagName': point,
                  'isQueryTag': false
                }
                _item['tags'].push(pointObj);
              }
            }
          })
          _item['showTag'] = false;
          _item['isselected'] = false;
          _item['pointDefTags'] = _item.tags.filter((_r: any) => (_r.tagName != 'pointId' && _r.tagName != 'name' && _r.tagName != 'displayName')).map((item: any) => item.tagName);
          if (_item.checked) {
            this.selectedPoints += 1;
          }
          return _item;
        });

        // ascending order by point name
        points = points?.sort((point1: any, point2: any) => {
          return point1?.displayName.localeCompare(point2?.displayName);
        })

        // removing the port number and duplicates
        points = points?.map((point: any) => {
          const { displayName, ...rest } = point; // Destructure the object and extract the "name" property
          const transformedName = displayName
          return {
            ...rest,
            displayName: transformedName,
            originalName: transformedName
          };
        });

        this.points = points;
        if (!this.points?.length) {
          this.emptyLabel = 'NA';
        } else {
          this.emptyLabel = '';
        }
        if (originalName) {
          let findPointIndex = _.findIndex(this.points, { 'originalName': originalName })
          if (findPointIndex > -1) {
            this.points[findPointIndex]['isselected'] = true;
          }
        }
      }, error: (err) => {
        this.hasError = true;
        this.errMsg = 'There was an error in executing the Query !';
        this.fetchingPoints = false;
      }
    });
  }

  /**
   * Generates query with selected tags
   * Param(tags) - list of tags
   */
  generateQueryWithTags(tags: string[]) {
    let query = null;
    const siteRef: string[] = [];
    this.data?.siteId.forEach((siteId: string) => {
      siteRef.push(`siteRef==@${siteId}`);
    });
    query = `( ${siteRef.join(' or ')} )`;
    const queryTags = new Set();

    if (tags && Array.isArray(tags) && tags.length) {
      tags.forEach(tag => {
        queryTags.add(tag);
      });
    }

    if (queryTags.size > 0) {
      query += ` and ${[...queryTags].join(' and ')}`;
    }
    this.query = query;
    return query;
  }

  /**
   * Clears the selected tags, points, and warning message.
   * 
   * This method is typically called when the user wants to reset the tag selection
   * and any associated data points or warnings.
   */
  onTagsClear() {
    this.selectedTags = [];
    this.points = [];
    this.warningMsg = '';
  }

  /**
   * Searches for a tag based on the provided search term.
   * 
   * If the search term starts with '!', the function checks if the tag starts with the substring
   * of the search term excluding the '!'. Otherwise, it checks if the tag starts with the search term.
   * 
   * @param searchTerm - The term to search for. If it starts with '!', the search is inverted.
   * @param tag - The tag to be searched.
   * @returns `true` if the tag matches the search term criteria, otherwise `false`.
   */
  searchTag(searchTerm: string, tag: string) {
    if (searchTerm.startsWith('!')) {
      if (tag.startsWith(searchTerm.slice(1))) {
        return true;
      }
    } else {
      if (tag.startsWith(searchTerm)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Adds a new point to the chart builder layout.
   * 
   * This method performs the following actions:
   * - Checks if the form is not empty.
   * - If the builder type is "CUSTOM", sets the `haystackQuery` to a specific string.
   * - Otherwise, clears the `selectedTags` array.
   * - Updates the `selectedIndex` to the current length of `pointDefs.controls`.
   * - Resets the `points` array.
   * - Sets `selectedPointIndex` to null.
   * 
   * @returns {void}
   */
  addNewPoint() {
    if (!this.checkIfFormIsEmpty()) {
      if (this.builderType == "CUSTOM") {
        this.haystackQuery = 'point and his';
      } else {
        this.selectedTags = [];
      }
      this.selectedIndex = this.pointDefs.controls.length;
      this.points = [];
      this.selectedPointIndex = null;
    }
  }

  /**
   * Determines if the current result is the first one.
   *
   * @returns {boolean} - Returns `true` if there are no results, otherwise `false`.
   */
  markFirst() {
    return this.numResults === 0;
  }

  /**
   * Handles the mouse out event for a point on the chart.
   * 
   * @param point - The point object that the mouse has moved out from.
   */
  pointHoverMouseOut(point: any) {
    point.showTag = false
    this.showTagTooltip = false
  }

  /**
   * Handles the hover event on a point in the chart.
   * 
   * @param event - The hover event object containing details about the event.
   * @param point - The point object that is being hovered over.
   * 
   * This function sets the `showTag` property of the point to `true` and 
   * displays a tooltip after a short delay. It also calculates the position 
   * of the tooltip based on the `y` coordinate of the event and the number 
   * of tags associated with the point.
   */
  pointHover(event: MouseEvent, point: any) {
    point.showTag = true
    setTimeout(() => {
      this.showTagTooltip = true
    }, 20);
    let y = event.y
    let len = point?.tags?.length
    if (len < 20) {
      this.tooltipPos = y - 190
    } else {
      this.tooltipPos = y - 220
    }
  }

  /**
   * Generates a style object for positioning a tooltip.
   *
   * @returns An object containing the CSS style for the tooltip's top position.
   */
  toolTipPosition() {
    return {
      'top': `${this.tooltipPos}px`
    };
  }

  /**
   * Updates the selected point's name and index, and triggers the sidebar data update.
   *
   * @param pointName - The name of the selected point.
   * @param index - The index of the selected point.
   */
  changeSelectedPoint(pointName: string, index: number) {
    this.selectedPointIndex = index;
    this.selectedPointName = pointName;
    this.setSideBarDataOnPointChange(index, pointName);
  }

  changeSelectedParam(paramName: string, index: number) {
    this.selectedParamIndex = index;
    this.selectedParamName = paramName;
    this.updateChartConfig(paramName);
  }

  updateChartConfig(selectedParamName:any) {
    if (this.chartConfig[selectedParamName]) {
      if (this.showSunBurstChart) {
        this.pieDonutChartLable = this.chartConfig[this.selectedParamName]?.['chartLabel'] ? this.chartConfig[this.selectedParamName]?.['chartLabel']: '';
        this.showPreviewContainer = false;
      } else if (this.showGaugeChart) {
        this.pieDonutChartLable = this.chartConfig[selectedParamName]['chartLabel'];
        this.selectedStartAngle = this.chartConfig[selectedParamName]['startAngle'];
        this.selectedEndAngle = this.chartConfig[selectedParamName]['endAngle'];
        this.minValue = this.chartConfig[selectedParamName]['minValue'];
        this.maxValue = this.chartConfig[selectedParamName]['maxValue'];
        this.showPreviewContainer = false;
      } else {
        this.selectedXaxisValue = this.chartConfig[selectedParamName]['axis']['xaxis']['column'];
        this.selectedYaxisValue = this.chartConfig[selectedParamName]['axis']['yaxis']['column'];
        this.xAxisLabel = this.chartConfig[selectedParamName]['axis']['xaxis']['legend label'];
        this.yAxisLabel = this.chartConfig[selectedParamName]['axis']['yaxis']['legend label'];
        this.selectedFormatDisplayValue = this.chartConfig[selectedParamName]['tooltip']['format'];
        this.selectedFormatValue = this.selectedFormatDisplayValue.split('-');
        this.selectedNameFormatDisplayValue = this.chartConfig[selectedParamName]['scopeformat'];
        this.selectedNameFormatValue = this.selectedNameFormatDisplayValue.split('-');
        this.selectedZaxisValue = this.chartConfig[selectedParamName]['axis']['zaxis']['column'];
        this.zAxisLabel = this.chartConfig[selectedParamName]['axis']['zaxis']['legend label'];
        this.setAxisOptions();
      }
    } else {
      if (this.showGaugeChart) {
        this.selectedStartAngle = 180;
        this.selectedEndAngle = 0;
        this.minValue = null;
        this.maxValue = null;
        this.setDefaultConfigForGaugeChart();
      } else {
        if (!this.showSunBurstChart) {
        this.selectedXaxisValue = 'time';
          this.selectedYaxisValue = 'value';
          this.xAxisLabel = '';
          this.yAxisLabel = '';
          this.selectedZaxisValue = 'scope';
          this.zAxisLabel = '';
          this.pieDonutChartLable = '';
          this.updateDefaultTooltipFormat();
          this.setChartAxisSettings();
        } else {
          this.pieDonutChartLable = '';
        }
      }
    }

  }

  /**
   * Updates the sidebar data based on the selected point index and optional point name.
   * 
   * @param index - The index of the selected point.
   * @param pointName - (Optional) The name of the point.
   * 
   * This method performs the following actions based on the builder type:
   * - If the builder type is 'BUILDER':
   *   - Clears the selected tags and points.
   *   - Finds the point definition by the given index.
   *   - Updates the selected tags and triggers the `onTagsChange` method.
   *   - Calls `onInputBlur` after a short delay.
   * - If the builder type is not 'BUILDER':
   *   - Sets the `haystackQuery` from the point definition.
   *   - Executes the custom query using the `executeCustomQuery` method.
   */
  setSideBarDataOnPointChange(index: number | null, pointName?: string) {
    const point = this.pointDefs.controls.find((_pt: any) => _pt.value.pointId == index);
    this.selectedIndex = index;
    this.points = [];
    if (this.builderType == 'BUILDER') {
      this.selectedTags = [];
      if (point) {
        point.value.tags = point?.value?.tags ? point?.value?.tags : [];
        this.selectedTags = [...(point?.value?.tags)];
        this.onTagsChange([...(point?.value?.tags)], pointName, true);
        setTimeout(() => {
          this.onInputBlur();
        });
      }
    } else {
      this.haystackQuery = point?.value?.haystackQuery;
      this.executeCustomQuery(point?.value?.haystackQuery, pointName, true);
    }
  }

  /**
   * Edits the name of a point in the chart.
   * 
   * @param index - The index of the point to be edited.
   * @param focusout - A boolean indicating whether the input field has lost focus.
   * @param pointName - The new name of the point.
   * 
   * When `focusout` is true, the function will:
   * - Set `focusedInputField` to false.
   * - Disable the point name input field.
   * - Change the border style of the point border element to white.
   * - Mark the chart form as dirty.
   * - Call `pointNameEdited` with the new point name.
   * 
   * When `focusout` is false, the function will:
   * - Store the previous parameter name.
   * - Set `focusedInputField` to true.
   * - Enable the point name input field.
   * - Change the border style of the point border element to a specific color.
   * - Focus the point input element.
   */
  editPointName(index: number, focusout: boolean, pointName: string) {
    const pointBorder = document.getElementById(`charts-point-border-${index}`);
    const pointInput = document.getElementById(`charts-point-input-${index}`);
    if (focusout) {
      this.focusedInputField = false;
      this.setPointNameDisable(index, true);
      if (pointBorder) {
        pointBorder.style.borderBottom = '1px solid white';
      }
      this.chartForm.markAsDirty();
      this.pointNameEdited(pointName);
      this.checkCircularDependencyOnSaveAndSetErrors();
    } else {
      this.previousParameterName = this.pointDefs.controls[index].value.name;
      this.focusedInputField = true;
      this.setPointNameDisable(index, false);
      if (pointBorder) {
        pointBorder.style.borderBottom = '1px solid var(--line)';
      }

      if (pointInput) {
        pointInput.focus();
      }
    }
  }

  /**
   * Sets the disable state for the point name field at the specified index.
   * 
   * @param index - The index of the point name field to disable or enable.
   * @param disable - A boolean indicating whether to disable (true) or enable (false) the point name field.
   */
  setPointNameDisable(index: number, disable: boolean) {
    if (!this.disablePointNameFieldConditionally[index]) {
      this.disablePointNameFieldConditionally[index] = {};
    }
    this.disablePointNameFieldConditionally[index]['disable'] = disable;
  }

  /**
   * Handles the event when a point name is edited.
   * Updates the parameter list and sets the updated parameter name.
   *
   * @param pointName - The new name of the point.
   */
  pointNameEdited(pointName: string) {
    this.updateParameterList();
    this.updatedParameterName = {
      previousName: this.previousParameterName,
      currentName: pointName
    }
    if(this.showForTerrain || this.showGaugeChart || this.showSunBurstChart) {
      let configObj = this.chartConfig[this.updatedParameterName.previousName];
      this.chartConfig[this.updatedParameterName.currentName] = configObj;
      delete this.chartConfig[this.updatedParameterName.previousName];
      this.selectedParamName = (this.selectedParamName == this.updatedParameterName.previousName) ? this.updatedParameterName.currentName : this.selectedParamName;
    }
  }

  /**
   * Updates the `parameterList` property by mapping over the `pointDefs` controls
   * and extracting the `name` value from each `pointData`.
   *
   * @remarks
   * This method assumes that `pointDefs.controls` is an array of objects where each
   * object has a `value` property containing a `name` field.
   */
  updateParameterList() {
    this.parameterList = this.pointDefs.controls.map((pointData: any) => {
      return pointData.value.name;
    });
  }

  addNewCalculatedParameter() {
    const index = this.pointDefs.controls.length;
    const totalCalculatedParameters = this.pointDefs.controls.filter((control: any) => control.value.isDerived).length;
    const defaultCalculatedParamName = 'calculatedParameter_' + (totalCalculatedParameters + 1)
    const point = this.fb.group({
      name: [defaultCalculatedParamName, [Validators.pattern(this.namePattern), forbiddenIfSplCharAtStartValidator]],
      pointId: index,
      conditions: this.fb.array([]),
      isDerived: true,
      dependentPoints: [],
      xml: '',
      snippet: '',
      previousName: '',
      color: (this.showForTerrain) ? (this.selectedGradientGroup || '') : this.generateUniqueColor(),
      showVisualisation: true,
      aggregateBy: chartAggregationTypes[0].value,
      axisPosition: this.getConfigAxisPositions()
    });
    this.pointDefs.push(point);
    this.updateParameterList();
    this.chartForm.markAsDirty();
  }

  clickedCalulatedParameter(point: any) {
    // Allowing to enter code block, if the derived parameter has value.
    this.chartForm.markAsDirty();
    if (point?.value?.name) {
      this.selectedDerivedParameter = { pointId: point?.value.pointId, name: point?.value.name.replace(/\s/g, '_') };
      if (point?.controls['name']?.value.length) {
        this.showBlocklyView = true;
        this.parameters = this.pointDefs.controls.filter((pt: any) => pt.value.name != point.value.name).map((_pt: any) => {
          return { pointId: _pt.value.pointId, name: _pt.value.name.replace(/\s/g, '_'), evaluationMode: _pt.value.multipointEvaluationMode }
        });
      }
      this.dependentPoints = point?.value?.dependentPoints ?? [];
      this.xmlCode = point.value.xml == '' ? this.defaultXmlCode : point.value.xml;
    }

  }

  /**
   * Deletes a point from the point definitions array at the specified index.
   * 
   * @param pIndex - The index of the point to be deleted.
   * 
   * This method performs the following actions:
   * - Stores the name of the deleted parameter.
   * - Removes the point from the point definitions array.
   * - Updates the point IDs for the remaining points.
   * - Resets the chart form if no points remain.
   * - Updates the selected point index based on the deleted point.
   * - Updates the parameter list and sidebar data.
   * - Marks the chart form as dirty and updates its validity.
   */
  deletePoint(pIndex: number) {
    this.deletedParameterName = this.pointDefs?.value[pIndex]?.name;
    <FormArray>this.pointDefs?.removeAt(pIndex);
    const point = this.pointDefs?.controls?.find((_pt: any) => _pt.value.pointId == this.selectedPointIndex);
    this.pointDefs?.controls?.forEach((_control: any, i: number) => {
      _control.controls.pointId.setValue(i);
      _control.updateValueAndValidity();
    });

    if (this.pointDefs?.length == 0) {
      this.chartForm?.controls['tags'].reset();
      this.chartForm?.controls['pointDefinitions'].reset();
      this.resetSideBar();
    } else if (pIndex == this.selectedPointIndex) {
      const nextSelectedIdx = this.pointDefs?.value.findIndex((_pt: Point) => !_pt.isDerived);
      this.selectedPointIndex = nextSelectedIdx;
    } else {
      const nextSelectedIdx = this.pointDefs.value.findIndex((_pt: Point) => point?.value?.name == _pt.name);
      this.selectedPointIndex = nextSelectedIdx;
    }
    if (this.pointDefs?.length == 1) {
      this.selectedPointIndex = 0;
    }
    
    //delete the config for the deleted parameter
    if (this.chartConfig[this.deletedParameterName]) {
      delete this.chartConfig[this.deletedParameterName];
    }
    //updated the selectedParamName & index if deleted
    if (this.selectedParamName == this.deletedParameterName) {
      this.selectedParamName = this.pointDefs.controls[0]?.value?.name;
      this.selectedParamIndex = 0;
    }

    this.updateParameterList();
    this.setSideBarDataOnPointChange(this.selectedPointIndex);
    this.chartForm.markAsDirty();
    this.chartForm.updateValueAndValidity();
    // deleting chart config for the deleted parameter
    if(this.chartConfig[this.deletedParameterName]) {
      delete this.chartConfig[this.deletedParameterName];
    }
  }

  /**
   * Resets the sidebar by clearing the points, selected point name, and selected tags.
   * This method is typically used to reset the state of the sidebar to its initial state.
   */
  resetSideBar() {
    this.points = [];
    this.selectedPointName = '';
    this.selectedTags = [];
  }

  /**
   * Saves the current widget configuration.
   * 
   * This method performs the following steps:
   * 1. Checks for circular dependencies and sets any errors.
   * 2. If the form is invalid, it exits early.
   * 3. Initiates the API call process.
   * 4. Processes the form data before saving.
   * 5. Creates or updates the widget with the processed data.
   * 
   * @returns {void}
   */
  saveWidget(isEdit:boolean) {
    this.checkCircularDependencyOnSaveAndSetErrors();
    if (this.chartForm?.invalid) {
      return;
    }
    this.apiCallInitiated = true;
    let saveWidgetData = this.chartForm.value;
    saveWidgetData['chartType'] = this.chartConfig['chartType'];
    saveWidgetData = this.processDataBeforeSave(saveWidgetData, isEdit);
    if(saveWidgetData.chartConfig.chartType == chartType.TERRAIN) {
      saveWidgetData.chartConfig['selectedParam'] = this.selectedParamName;
    }
    this.createOrUpdateWidget(saveWidgetData, isEdit);
  }

  /**
   * Processes the widget data before saving it.
   * 
   * @param widgetData - The data of the widget to be processed.
   * @returns The processed widget data.
   * 
   * The function performs the following operations:
   * - Sets the `id` of the widget data based on whether it is in edit mode.
   * - Sets the `shared` property based on the `shareAccessType`.
   * - Sets the `sharedTo` property based on the `shared` value.
   * - Assigns the `chartConfig` to the widget data.
   * - Iterates over `pointDefinitions` and deletes specific properties based on the `builderType`.
   */
  processDataBeforeSave(widgetData: any , isEdit : boolean) {
    widgetData.id = isEdit ? this.data?.widgetData.id : null;
    widgetData.shared = this.shareAccessType == 'shared' ? true : false;
    widgetData.sharedTo = widgetData.shared ? this.selectedConfiguration(this.selectedSharedConfiguration) : 'NONE';
    delete widgetData?.tags;
    this.deleteUnusedAxisValues();
    widgetData.chartConfig = this.chartConfig;
    widgetData.pointDefinitions?.forEach((val: any) => {
      delete val.pointId;
      if (this.builderType == 'BUILDER') {
        delete val.haystackQuery;
      } else {
        delete val.tags;
      }
      if (!this.showGaugeChart) {
        delete val.conditions;
      }
    });
    if(!this.isPieOrDonutOrSunburstChart()) {
      delete widgetData.chartConfig?.color;
    }
    return widgetData;
  }

  /**
   * Deletes unused axis values from the chart configuration.
   * 
   * This method checks the availability of axis positions (right Y-axis, left Y-axis, 
   * bottom X-axis, and top X-axis) based on the `pointDefs` values. If an axis position 
   * is not available, it deletes the corresponding maximum and minimum values from the 
   * `chartConfig` object.
   * 
   * @remarks
   * - `isRightYaxisAvailable`, `isLeftYaxisAvailable`, `isBottomXaxisAvailable`, and 
   *   `isTopXaxisAvailable` are boolean flags indicating the availability of the respective axes.
   * - The method uses optional chaining to safely access nested properties in the 
   *   `chartConfig` object.
   * **/
  deleteUnusedAxisValues() {
    this.isRightYaxisAvailable = this.pointDefs?.value.some((arr: any) => arr.axisPosition == axisOrientation.right);
    this.isLeftYaxisAvailable = this.pointDefs?.value.some((arr: any) => arr.axisPosition == axisOrientation.left);
    this.isBottomXaxisAvailable = this.pointDefs?.value.some((arr: any) => arr.axisPosition == axisOrientation.bottom);
    this.isTopXaxisAvailable = this.pointDefs?.value.some((arr: any) => arr.axisPosition == axisOrientation.top);

    if (!this.isRightYaxisAvailable) {
      delete this.chartConfig.axis?.yaxis?.yaxisRightMax;
      delete this.chartConfig.axis?.yaxis?.yaxisRightMin;
    }

    if (!this.isLeftYaxisAvailable) {
      delete this.chartConfig.axis?.yaxis?.yaxisLeftMax;
      delete this.chartConfig.axis?.yaxis?.yaxisLeftMin;
    }

    if (!this.isBottomXaxisAvailable) {
      delete this.chartConfig.axis?.xaxis?.xaxisBottomMax;
      delete this.chartConfig.axis?.xaxis?.xaxisBottomMin;
    }

    if (!this.isTopXaxisAvailable) {
      delete this.chartConfig.axis?.xaxis?.xaxisTopMax;
      delete this.chartConfig.axis?.xaxis?.xaxisTopMin;
    }
  }

  /**
   * Creates or updates a widget based on the provided widget data.
   * 
   * If the widget is not in edit mode, it will remove the `id` from the widget data
   * and call the `addWidget` method from the `widgetService`. Upon successful creation,
   * it will close the dialog, mark the form as pristine, and show a success alert.
   * In case of an error, it will reset the API call state and show an error alert.
   * 
   * @param widgetData - The data of the widget to be created or updated.
   */
  createOrUpdateWidget(widgetData: any, isEdit: boolean) {
    if (!isEdit) {
      delete widgetData.id;
      this.widgetService.addWidget(widgetData).pipe(
        takeUntil(this.unsubscribe$)).subscribe({
          next: (val: any) => {
            this.dialogRef.close({
              isNew: true,
              chartData: val,
              widgetData: widgetData
            });
            this.chartForm.markAsPristine();
            this.alertService.success('Chart has been created successfully');
          }, error: (_error) => {
            this.apiCallInitiated = false;
            this.alertService.error(_error.error.error || `Failed to create Chart`);
          }
        });
    } else {
      this.widgetService.addWidget(widgetData).pipe(
        takeUntil(this.unsubscribe$)).subscribe({
          next: (val: any) => {
            this.dialogRef.close({
              isNew: false,
              chartData: val,
              widgetData: widgetData
            });
            this.chartForm.markAsPristine();
            this.alertService.success('Chart has been updated successfully');
          }, error: (_error) => {
            this.apiCallInitiated = false;
            this.alertService.error(_error.error.error || `Failed to update Chart`);
          }
        });
    }
  }

  /**
   * Closes the dialog referenced by `dialogRef`.
   * This method is typically used to cancel or dismiss the dialog without taking any action.
   */
  cancelDialog() {
    this.dialogRef.close();
  }

  /**
   * Fetches the site details using the provided site ID and extracts unique tags from the response.
   * @remarks
   * This method calls the `getSiteDetails` method of `siteService` with site ID.
   * It subscribes to the observable returned by `getSiteDetails` and processes the response to
   * extract unique tags from the first row of the response data.
   */
  getTags() {
    this.siteService.getSiteDetails(this.data.siteId)
      .subscribe(({ rows }) => {
        this.tagList = uniq(rows[0]?.tags);
      });
  }

  /**
   * Retrieves the image URL for a specified chart type.
   *
   * @param chartName - The name of the chart type to retrieve the image for.
   * @returns The image URL of the specified chart type, or an empty string if the chart type is not found.
   */
  getChartImage(chartName: string): string {
    const chart = this.chartTypes.find(c => c.name === chartName);
    return chart ? chart.image : '';
  }

  /**
   * Retrieves the value associated with a given chart name.
   *
   * @param chartName - The name of the chart to look up.
   * @returns The value of the chart if found, otherwise an empty string.
   */
  getChartValue(chartName: string): string {
    const chart = this.chartTypes.find(c => c.name === chartName);
    return chart ? chart.value : '';
  }

  /**
   * Function to show the color swatch container
   * Returns Condition Array with pointDefinition Index
   * Param(event) - to set top and left styles to the color swatch
   * Param(pIndex) - point index
   */
  showColorSwatchBox(event: any, pIndex: number) {
    this.pointIndex = pIndex;
    this.selectedCustomHexColor = this.getColorSelection(event?.currentTarget?.style?.backgroundColor)
    this.colorSelectionType = this.colorSet.includes(this.selectedCustomHexColor) ? 'palette' : 'custom';
    setTimeout(() => { this.showColorSwatch = true; }, 200);
    this.colorSwatchelement.nativeElement.style.left = event.clientX + 'px';
    this.colorSwatchelement.nativeElement.style.top = event.clientY + 'px';
  }

  getColorSelection(rgbString: string) {
    const rgb: any = rgbString.match(/\d+/g)?.map(Number);
    return `#${rgb.map((color: any) => color.toString(16).padStart(2, '0')).join('').toUpperCase()}`;
  }


  /**
   * Function to show the color swatch container
   * Returns Condition Array with pointDefinition Index
   * Param(event) - to set top and left styles to the color swatch
   * Param(pIndex) - point index
   */
  showColorSwatchBoxForGauge(event: any, index: number) {
    this.selectedCustomHexColor = this.getColorSelection(event?.currentTarget?.style?.backgroundColor)
    this.colorSelectionType = this.colorSet.includes(this.selectedCustomHexColor) ? 'palette' : 'custom';
   setTimeout(() => { this.showColorSwatch = true; }, 200);
    this.conditionIndex = index;
    this.colorSwatchelement.nativeElement.style.left = event.clientX + 'px';
    this.colorSwatchelement.nativeElement.style.top = event.clientY + 'px';
  }

  /**
   * Fired if color is selected in swatch
   * Param(color) - selected color
   */
  onColorChange(color: string | null, event?: any) {
    this.classlist = Array.from(event?.$event?.target?.classList || []);
    this.classlist.push('sketch-controls','sketch-fields','color-hue-pointer','condition-color-box','color-hue-container','color-hue-slider','wrap','sketch-active');
    if (this.showGaugeChart) {
      if (this.conditionIndex != undefined) {
        const conditionArray = this.getCondition() as any;
        conditionArray?.[this.conditionIndex].controls['color'].reset(color);
      } else {
        this.chartConfig[this.selectedParamName]['defaultColor'] = color;
      }
    }
    if (color) {
      this.pointDefs.controls[this.pointIndex]?.controls.color.setValue(color);
      this.chartForm.markAsDirty();
    }
  }

  /**
   * Function to close color swatch
   * Param(event) - event fired when user clicks outside the swatch/selects a color from swatch
   */
  closeSwatch(event: any) {
    if (event) {
      this.showColorSwatch = false;
    }
  }

  /**
   * Generates a random hex color code.
   * @returns {string} A string representing a hex color code in the format `#RRGGBB`.
   */
  generateColor() {
    return '#' + Math.floor(Math.random() * 0xFFFFFF).toString(16).padStart(6, '0').toUpperCase();
  }

  /**
   * Generates a unique color that is not already present in the `uniqueColors` array.
   * This method repeatedly calls `generateColor` until a unique color is found.
   * The generated unique color is then added to the `uniqueColors` array.
   *
   * @returns {string} The generated unique color.
   */
  generateUniqueColor() {
    let color;
    do {
      color = this.generateColor();
    } while (this.uniqueColors.includes(color));
    this.uniqueColors.push(color);
    return color;
  }

  /**
   * Updates the visibility of the visualisation for a specific point definition.
   * @param index - The index of the point definition to update.
   * @param showVisualisation - A boolean indicating whether to show or hide the visualisation.
   */
  updateshowVisualisation(index: number, showVisualisation: boolean) {
    this.chartForm.markAsDirty();
    this.pointDefs.controls[index]?.controls?.showVisualisation.setValue(showVisualisation);
  }

  /**
   * Updates the aggregation type for a specific point definition.
   * @param index - The index of the point definition to update.
   * @param aggregateBy - The new aggregation type to set.
   */
  UpdateAggregationType(index: number, aggregateBy: string) {
    this.pointDefs.controls[index]?.controls?.aggregateBy.setValue(aggregateBy);
    this.chartForm.markAsDirty();
  }

  /**
   * Updates the position of the axis for a specific point definition.
   * @param index - The index of the point definition to update.
   * @param axisPosition - The new position of the axis to set.
   */
  UpdateAxisPosition(index: number, axisPosition: string) {
    this.pointDefs.controls[index]?.controls?.axisPosition.setValue(axisPosition);
    this.setAxisOptions();
  }

  /**
   * Navigates back to the builder page by hiding the chart configuration view.
   * This method sets `showChartConfigView` to `false` to hide the chart configuration view.
   * Additionally, it triggers the `onInputBlur` method after a short delay to handle
   * ng-select placeholder alignment.
   */
  backToBuilderPage() {
    this.showChartConfigView = false;
    //added for ng-select placeholder alignment
    setTimeout(() => {
      this.onInputBlur();
    });
  }

  /**
   * Determines if the selected chart type is either a pie or donut chart.
   * @returns {boolean} True if the selected chart type is a pie or donut chart, otherwise false.
   */
  isPieOrDonutOrSunburstChart() {
    return pieDonutSunBurstCharts.includes(chartEnum[this.selectedChartType as keyof typeof chartEnum]);
  }

  colorSelectionChange(event: Event, paramName?: string, index?: number) {
    if (this.showForTerrain && paramName && index != undefined) {
      if (this.chartConfig[paramName]) {
        this.chartConfig[paramName]['color'] = event;
      } else {
        this.updateChartConfig(paramName);
        this.chartConfig[paramName]['color'] = event;
      }
      this.pointDefs.controls[index]?.controls?.color.setValue(this.getGradientColor(event));
    } else {
      this.selectedGradientGroup = this.getGradientColor(event);
      this.chartConfig['color'] = event;
    }
  }

  backToParameters() {
    this.showBlocklyView = false;
    setTimeout(() => {
      this.setSideBarDataOnPointChange(this.selectedPointIndex);
      this.checkCircularDependencyOnSaveAndSetErrors();
    }, 100);
  }

  checkCircularDependencyOnSaveAndSetErrors() {
    const dependentPts: any[] = [];
    const parameters = this.pointDefs?.controls?.map((_point: any) => this.convertSpaceHyphenToUnderscore(_point.value.name));
    this.pointDefs?.controls?.forEach((_control: any) => {
      if (_control.value.isDerived) {
        const name = this.convertSpaceHyphenToUnderscore(_control.value.name);
        dependentPts.push({ id: name, dependencies: _control.value.dependentPoints, idx: _control.value.id });
      }
    });
    const haveAnyCircularDependency = this.checkCircularDependencyOnDerivedParameters(dependentPts);
    this.pointDefs?.controls?.forEach((_control: any) => {
      if (_control.value.isDerived) {
        const errors: any = {};
        _control.controls.isDerived.setErrors(null);
        if (haveAnyCircularDependency == _control.value.name) {
          errors.circularDependency = true;
        }
        if (_control.value.previousName == '') {
          _control.controls.previousName.setValue(ObjectUtil.deepCopy(_control.value.name));
        }
        if (_control?.value?.dependentPoints?.find((_pt: any) => !parameters.includes(this.convertSpaceHyphenToUnderscore(_pt))) || ((_control.value.previousName != _control.value.name) && _control.value.xml)) {
          errors.updateRule = true;
          _control.controls.previousName.setValue(ObjectUtil.deepCopy(_control.value.name));
        }

        if (!Object.keys(errors)?.length) {
          _control.controls.isDerived.setErrors(null);
        } else {
          _control.controls.isDerived.setErrors(errors);
        }
      }
    });
  }

  convertSpaceHyphenToUnderscore(str: any) {
    return str.replaceAll(' ', '_').replaceAll('-', '_').replaceAll('%', '_25').replaceAll('#', '_').replaceAll(',', '_');
  }

  checkCircularDependencyOnDerivedParameters(array: Array<any>) {
    const visited = new Set();
    const recursionStack = new Set();
    // Helper function to perform DFS traversal
    function isCyclic(objId: any) {
      if (!visited.has(objId)) {
        visited.add(objId);
        recursionStack.add(objId);

        // Recursively visit each dependency
        for (const dependencyId of array.find(obj => obj.id === objId)?.dependencies || []) {
          if (!visited.has(dependencyId) && isCyclic(dependencyId)) {
            return true;
          } else if (recursionStack.has(dependencyId)) {
            return true; // Cycle detected
          }
        }
      }
      recursionStack.delete(objId);
      return false;
    }
    // Iterate through each object in the array and check for cycles
    for (const obj of array) {
      if (isCyclic(obj.id)) {
        return obj.id;
      }
    }
    return false; // No cycles found
  }

  /** Method checks derived parameter which has dependent paramters selected correct combination of modules are not. */
  saveBlocklyDerivedParameter(data: any) {
    setTimeout(() => {
      this.pointDefs.controls[this.selectedDerivedParameter.pointId].controls.dependentPoints.setValue(data.dependentPoints);
      this.pointDefs.controls[this.selectedDerivedParameter.pointId].controls.snippet.setValue(data.snippet);
      this.pointDefs.controls[this.selectedDerivedParameter.pointId].controls.xml.setValue(data.xmlCode);
    }, 0);
  }

  /**
   * Toggles the preview state.
   * When called, this method will switch the `isOpen` property
   * between `true` and `false`.
   */
  openPreview() {
    this.isOpen = !this.isOpen;
  }

  setAxisOptions() {
    this.axes = [];
    if (!this.isPieOrDonutOrSunburstChart() && !this.showGaugeChart) {
      if (!this.showForTerrain) {
        if (this.chartConfig['axis']['yaxis']['column'] != 'time') {
          this.isRightYaxisAvailable = this.pointDefs?.value.some((arr: any) => arr.axisPosition == axisOrientation.right);
          this.isLeftYaxisAvailable = this.pointDefs?.value.some((arr: any) => arr.axisPosition == axisOrientation.left);

          if (this.isLeftYaxisAvailable) {
            const min = this.chartConfig?.axis?.yaxis?.['yaxisLeftMin'] ?? null;
            const max = this.chartConfig?.axis?.yaxis?.['yaxisLeftMax'] ?? null;
            this.axes.push({
              name: "Left Y Axis",
              min: min ? min : 'AUTO',
              max: max ? max : 'AUTO'
            });
          }
          if (this.isRightYaxisAvailable) {
            const min = this.chartConfig?.axis?.yaxis?.['yaxisRightMin'];
            const max = this.chartConfig?.axis?.yaxis?.['yaxisRightMax'];
            this.axes.push({
              name: "Right Y Axis",
              min: min ? min : 'AUTO',
              max: max ? max : 'AUTO'
            });
          }
          this.mapAxisValues();
        } else {
          this.isTopXaxisAvailable = this.pointDefs?.value.some((arr: any) => arr.axisPosition == axisOrientation.top);
          this.isBottomXaxisAvailable = this.pointDefs?.value.some((arr: any) => arr.axisPosition == axisOrientation.bottom);

          if (this.isTopXaxisAvailable) {
            const min = this.chartConfig?.axis?.xaxis?.['xaxisTopMin'];
            const max = this.chartConfig?.axis?.xaxis?.['xaxisTopMax'];
            this.axes.push({
              name: "Top X Axis",
              min: min ? min : 'AUTO',
              max: max ? max : 'AUTO'
            });
          }
          if (this.isBottomXaxisAvailable) {
            const min = this.chartConfig?.axis?.xaxis?.['xaxisBottomMin'];
            const max = this.chartConfig?.axis?.xaxis?.['xaxisBottomMax'];
            this.axes.push({
              name: "Bottom X Axis",
              min: min ? min : 'AUTO',
              max: max ? max : 'AUTO'
            });
          }
          this.mapAxisValues();
        }
      } else {
        this.axes = [];
        let axisWithValue = Object.entries(this.chartConfig[this.selectedParamName]?.axis)?.find(([key, val]: [string, any]) => val.column === 'value');
        let axisValue = axisWithValue ? axisWithValue[0] : undefined;
        if (axisValue) {
          const min = this.chartConfig[this.selectedParamName]?.axis?.[axisValue]?.['min'];
          const max = this.chartConfig[this.selectedParamName]?.axis?.[axisValue]?.['max'];
          this.axes.push({
            name: axisValue.charAt(0).toUpperCase() + " " + axisValue.charAt(1).toUpperCase() + axisValue.slice(2),
            min: min ? min : 'AUTO',
            max: max ? max : 'AUTO'
          });
        }
        this.mapAxisValues();
      }
    }
  }

  mapAxisValues() {
    if(!this.showForTerrain) {
      if (this.chartConfig?.axis?.yaxis?.column != 'time') {
        this.axes?.map((axis: any) => {
          if (axis?.name == "Left Y Axis") {
            this.chartConfig['axis']['yaxis'] = this.chartConfig['axis']['yaxis'] || {};
            this.chartConfig['axis']['yaxis']['yaxisLeftMin'] = axis.min;
            this.chartConfig['axis']['yaxis']['yaxisLeftMax'] = axis.max;
          } else if (axis?.name == "Right Y Axis") {
            this.chartConfig['axis']['yaxis'] = this.chartConfig['axis']['yaxis'] || {};
            this.chartConfig['axis']['yaxis']['yaxisRightMin'] = axis.min;
            this.chartConfig['axis']['yaxis']['yaxisRightMax'] = axis.max;
          }
        });
      } else {
        this.axes?.map((axis: any) => {
          if (axis?.name == "Top X Axis") {
            this.chartConfig['axis']['xaxis'] = this.chartConfig['axis']['xaxis'] || {};
            this.chartConfig['axis']['xaxis']['xaxisTopMin'] = axis.min;
            this.chartConfig['axis']['xaxis']['xaxisTopMax'] = axis.max;
          } else if (axis?.name == "Bottom X Axis") {
            this.chartConfig['axis']['xaxis'] = this.chartConfig['axis']['xaxis'] || {};
            this.chartConfig['axis']['xaxis']['xaxisBottomMin'] = axis.min;
            this.chartConfig['axis']['xaxis']['xaxisBottomMax'] = axis.max;
          }
        });
      }
    } else {
      this.axes?.map((axis: any) => {
        let axisValue = axis.name.split(' ')[0].toLowerCase() + axis.name.split(' ')[1].toLowerCase();
        this.chartConfig[this.selectedParamName]['axis'][axisValue] = this.chartConfig[this.selectedParamName]['axis'][axisValue] || {};
        this.chartConfig[this.selectedParamName]['axis'][axisValue]['min'] = axis.min;
        this.chartConfig[this.selectedParamName]['axis'][axisValue]['max'] = axis.max;
      });
    }
    
  }

  getAxisName(name: string, type: string): string {
    if (type == 'min') {
      return 'Min ' + name;
    } else {
      return 'Max ' + name;
    }
  }

  /**
   * Updates numeric input for the specified axis field.
   * 
   * @param event - The event object.
   * @param axis - The axis object.
   * @param field - The field to enforce numeric input for.
   */
  enforceNumericInput(event: Event, axis: any, field: string) {
    event?.stopPropagation();
    const value = axis[field]?.toString() || 'AUTO';
    axis[field] = value;
    this.mapAxisValues();
    this.validateAxes(axis, this.axes);
  }

  /**
   * Restricts certain keys from being input in a form field.
   * 
   * This method prevents the default action for the keys 'e', 'E', and '+'.
   * It is typically used to restrict input in number fields to ensure only valid numeric characters are entered.
   * 
   * @param event - The keyboard event triggered by the user's input.
   */
  restrictInput(event: KeyboardEvent) {
    if(event.key === 'e' || event.key === 'E' || event.key === '+') {
      event.preventDefault();
    }
  }

  /**
   * Validates the axes of a chart.
   * @param currentAxis - The current axis to validate.
   * @param axes - The array of axes to validate.
   * @returns True if any of the axes have invalid values, otherwise false.
   */
  validateAxes(currentaxis: any, axes: any) {
    this.showWarning = false;
    axes.forEach((axis: any) => {
      if (this.validateAxis(axis)) {
        if (Number(axis.min) >= Number(axis.max)) {
          this.showWarning = true;
        }
      }
    });
    if (this.validateAxis(currentaxis)) {
      if (Number(currentaxis.min) >= Number(currentaxis.max)) {
        this.showWarning = true;
      }
    }
  }

  validateGaugeMinMax() {
    if (this.minValue?.toString() == '' || this.maxValue?.toString() == '' || !this.minValue || !this.maxValue) {
      return false;
    }
    this.gaugeMinMaxError = Number(this.minValue) >= Number(this.maxValue);
    return this.gaugeMinMaxError;
  }

  updateMinMaxForGauge() {
    this.chartConfig[this.selectedParamName]['minValue'] = this.minValue;
    this.chartConfig[this.selectedParamName]['maxValue'] = this.maxValue;
  }

  validateAxis(axis: any) {
    return axis.min !== '' && axis.max !== '' && axis.min !== null && axis.max !== null && axis.min !== "AUTO" && axis.max !== "AUTO";
  }

  resetValues(axes: any) {
    axes.forEach((axis: any) => {
      if (axis?.min)
        axis.min = 'AUTO';
      if (axis?.max)
        axis.max = 'AUTO';
    });
    this.mapAxisValues();
  }

  disableAxisResetButton() {
    return (this.axes.some(axis => axis.min !== 'AUTO' || axis.max !== 'AUTO'));
  }

  /**
   * Checks if the chart configuration is valid.
   * This method verifies that the `chartConfig` object has the required properties:
   * - `chartType`
   * - `viewBy`
   * - `viewType`
   * @returns {boolean} `true` if all required properties are present, otherwise `false`.
   */
  isChartAndBuilderConfigValid(): boolean {
    let { chartType, viewBy, viewType, axis, tooltip } = this.chartConfig || {};
    if(this.showForTerrain) {
      axis = this.chartConfig[this.selectedParamName].axis;
      tooltip = this.chartConfig[this.selectedParamName].tooltip;
      
    }
    const isPieOrDonutOrSunburstChart = this.isPieOrDonutOrSunburstChart();
    const hasValidChartConfig = (this.showGaugeChart) ? !!(chartType && viewType) : !!(chartType && viewBy && viewType);
    const hasValidAxisPosition = axis?.xaxis?.column && axis?.yaxis?.column && ['time', 'value', 'scope'].includes(axis.xaxis.column) && ['time', 'value', 'scope'].includes(axis.yaxis.column);
    const hasValidTooltipConfig = (this.showForTerrain) ? !!(tooltip?.format) : !!(tooltip?.format && tooltip?.sortBy);
    const allDerived = this.pointDefs.controls?.every((formControl: any) => formControl?.controls['isDerived']?.value);
    const hasValidPieOrDonutOrSunburstConfig = isPieOrDonutOrSunburstChart ? this.selectedChartType == chartTypeText.SUNBURST ? true: (this.chartConfig['chartLabel'] && tooltip?.format ): true;
    const validTerrianChart = this.showForTerrain ? this.checkValidTerrianConfig() : true;
    const hasValidGaugeChartConfig = this.isValidGaugeChartConfig();
    const isValid = hasValidChartConfig && (!isPieOrDonutOrSunburstChart ? this.showGaugeChart ? hasValidGaugeChartConfig : hasValidAxisPosition && hasValidTooltipConfig : hasValidPieOrDonutOrSunburstConfig) && !allDerived && validTerrianChart;
    this.warningMsg = !isValid && this.isChartConfigViewInitialized ? 'Please enter all the chart config values.' : '';
    return isValid;
  }


  checkValidTerrianConfig() {
    let parameterName = this.pointDefs?.value.map((point: any) => point.name);
    let isValid: boolean = true;
    parameterName.map((selectedParamName: any) => {
      const config = this.chartConfig[selectedParamName] ?? {};
      // If the parameter itself doesn't exist, mark as invalid
      if (!config) {
        isValid = false;
      }

      // Check for 'tooltip' properties
      if (
        !config['tooltip'] ||
        config['tooltip']['enabled'] === undefined ||
        config['tooltip']['format'] === undefined
      ) {
        isValid = false;
      }

      // Check 'scopeformat'
      if (!config['scopeformat']) {
        isValid = false;
      }

      // Check 'axis' and its nested properties
      if (
        !config['axis'] ||
        !config['axis']['xaxis'] || !config['axis']['xaxis']['column'] ||
        !config['axis']['yaxis'] || !config['axis']['yaxis']['column'] ||
        !config['axis']['zaxis'] || !config['axis']['zaxis']['column']
      ) {
        isValid = false;
      }

      // Check 'color' and its properties
      if (
        !config['color'] ||
        !config['color']['stopColor0'] ||
        !config['color']['stopColor100']
      ) {
        isValid = false;
      }
    });
    return isValid;
  }

  // Check the gauge chart configuration is valid or not
  isValidGaugeChartConfig(): boolean {
    if (!this.pointDefs?.value?.length) return false;

    for (const point of this.pointDefs.value) {
      const parameter = point.name;
      const { startAngle, endAngle, chartLabel, minValue, maxValue } = this.chartConfig[parameter] || {};

      // Validate chart configuration
      if (!chartLabel?.trim() || startAngle == null || endAngle == null || minValue == null || maxValue == null) {
        return false;
      }
      // Validate point conditions
      for (const condition of point.conditions || []) {
        const { color, legendLabel, comparisonOperator } = condition;
        const value = condition.value ?? null;
        const minValue = condition.minValue ?? null;
        const maxValue = condition.maxValue ?? null;

        if (!color?.trim() || !legendLabel?.trim()) {
          return false;
        }

        if (
          (comparisonOperator !== 'IN_BETWEEN' && value == null) ||
          (comparisonOperator === 'IN_BETWEEN' && (minValue == null || maxValue == null))
        ) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Opens the chart configuration view.
   * 
   * This method sets the `showChartConfigView` flag to true, indicating that the chart configuration view should be displayed.
   * If the chart configuration view has not been initialized yet, it initializes the chart axis settings by calling `setChartAxisSettings`
   * and sets the `isChartConfigViewInitialized` flag to true to prevent re-initialization on subsequent calls.
   */
  openChartConfigView() {
    this.selectedParamName = this.pointDefs?.value[0]?.name;
    this.changeSelectedParam( this.selectedParamName, 0);
    this.showChartConfigView = true;
    this.showPreviewContainer = false;
    this.updateAggregateBy(this.selectedChartType); // Update aggregate by based on chart type.
    if (this.selectedChartType == chartTypeText.TERRAIN) {
      this.setChartAxisSettings();
    } else if (this.selectedChartType == chartTypeText.SUNBURST) {
      this.updateSunburstLabel();
    } else if (this.selectedChartType == chartTypeText.PIE || this.selectedChartType == chartTypeText.DONUT) {
      this.updatePieDonutChartLableFromConfig();
    }
    if (!this.isChartConfigViewInitialized) {
      this.setChartAxisSettings();
      this.isChartConfigViewInitialized = true
    }
    if (this.showGaugeChart) {
      //Set default values for new added points
      this.pointDefs.controls.forEach((point: any) => {
        const paramName = point.value.name;
        if (!this.chartConfig[paramName]) {
          this.setDefaultConfigForGaugeChart(paramName);
        }
      });
      this.selectedParamName = this.pointDefs.controls[0]?.value?.name;
      this.selectedParamIndex = 0;
      this.updateChartConfig(this.selectedParamName);
    }
  }

  // update chart lable based on the available point 
  updateSunburstLabel() {
    this.pieDonutChartLable = this.chartConfig[this.selectedParamName]?.['chartLabel'] ? this.chartConfig[this.selectedParamName]?.['chartLabel']: '';
  }

  // update chart lable based for pie and donut chart
  updatePieDonutChartLableFromConfig() {
    this.pieDonutChartLable = this.chartConfig['chartLabel'] ? this.chartConfig['chartLabel'] : '';
  }

  /**
   * Returns a tooltip string based on the provided axis position.
   *
   * @param axisPosition - The position of the axis. Expected values are 'LEFT', 'RIGHT', 'TOP', or 'BOTTOM'.
   * @returns A string representing the tooltip for the specified axis position.
   */
  getAxisTooltip(axisPosition: string): string {    
    if (axisPosition === 'LEFT') {
      return 'Left Y Axis';
    } else if (axisPosition === 'RIGHT') {
      return 'Right Y Axis';
    } else if (axisPosition === 'TOP') {
      return 'Top X Axis';
    } else if (axisPosition === 'BOTTOM') {
      return 'Bottom X Axis';
    }
    return '';
  }

  /**
   * Opens a confirmation modal to delete the specified chart.
   * 
   * @param {Chart} chart - The chart object to be deleted.
   * @returns {void}
   */
  confirmDelete(chart:Chart) {
    this.widgetLayoutService.openDeleteModal(chart);
  }

  /**
   * Formats a given date using the common service.
   *
   * @param date - The date to be formatted.
   * @returns The formatted date string.
   */
  formatDate(date:Date) {
    return this.commonService.formatDate(date);
  }

  /**
   * Duplicates the current chart configuration and assigns it to the logged-in user.
   * 
   * This method performs the following actions:
   * - Sets `isSharedByOthers` to `false`.
   * - Sets `data.sharedByOthers` to `false`.
   * - Sets `isDuplicate` to `true`.
   * - Sets `shareAccessType` to `'personal'`.
   * - Sets `selectedSharedConfiguration` to `'NONE'`.
   * - Updates the `data.owner` with the logged-in user's details.
   * 
   * @remarks
   * This method is used to create a personal copy of a chart that may have been shared by others.
   */
  duplicateChart() {
    this.isSharedByOthers = false;
    this.data.sharedByOthers = false;
    this.isDuplicate = true;
    this.shareAccessType = 'personal';
    this.selectedSharedConfiguration = 'NONE';
    this.data.owner = {
      userId: this.loggedInUser.userId,
      firstName: this.loggedInUser.firstName,
      lastName: this.loggedInUser.lastName,
      emailAddress: this.loggedInUser.emailId
    }
  }

  /**
   * Checks if there are any changes in the widget data.
   *
   * This method compares the current chart configuration with the original widget data's chart configuration
   * and checks if the form is dirty. If the form is not dirty and the configurations are equal, it returns true,
   * indicating that there are no changes in the widget data.
   * @returns {boolean} - Returns true if there are no changes in the widget data, otherwise false.
   */
  checkWidgetDataChanges(): boolean {
    return (!this.chartForm.dirty && ObjectUtil.isEqual(this.chartConfig, this.data?.widgetData?.chartConfig));
  }

  refreshPreview(event:any) { 
    event.stopPropagation();
    this.generatePreview();
  }

  generatePreview() {
    let saveWidgetData = this.chartForm.value;
    saveWidgetData = this.processDataBeforeSave(saveWidgetData, this.data?.isEdit);
    saveWidgetData.builderWidgetPreview = true;
    saveWidgetData['chartType'] = this.chartConfig['chartType'];
    if (saveWidgetData.chartConfig.chartType == chartType.TERRAIN || saveWidgetData.chartConfig.chartType == chartType.SUNBURST || this.chartConfig.chartType == chartType.GAUGE) {
      saveWidgetData.chartConfig['selectedParam'] = this.selectedParamName;
    }
    this.previewWidgetData = _.cloneDeep(saveWidgetData);
    this.showPreviewContainer = true;
  }

  getGradientColor(groupName: any) {
    return this.commonService.findGradientGroupName(groupName) || 'grp1';
  }

  /**
   * Retrieves the configuration for axis positions based on the defined points.
   * This method analyzes the `axisPosition` property of each point in `this.pointDefs`
   * and determines the appropriate axis type to return. It checks for the presence
   * of axis positions that match the predefined orientations (`left`, `right`, `top`, `bottom`)
   * and returns the corresponding axis type value.
   * @returns {string} The value of the appropriate axis type based on the positions found.
   *                   Defaults to the first value in `chartYAxisTypes` if no specific positions are found.
   */
  getConfigAxisPositions() {
    if (this.pointDefs?.length) {
      const axisPositions = [...new Set(this.pointDefs?.controls?.map((pointData: any) => pointData.value.axisPosition))];
      if (axisPositions.length) {
        if (axisPositions.some(pos => pos == axisOrientation.left || pos == axisOrientation.right)) {
          return chartYAxisTypes[0].value;
        }
        if (axisPositions.some(pos => pos == axisOrientation.top || pos == axisOrientation.bottom)) {
          return chartXAxisTypes[0].value;
        }
      }
    }
    return chartYAxisTypes[0].value;
  }

  onSelectionChangeForShareOption(value: string) {
    this.selectedSharedConfiguration = value;
  }


  getChartAggregationTypes(point: any) {
    if (this.showSunBurstChart && point.controls.isDerived.value) {
      return this.chartAggregationTypes.filter(type => type.value != 'COUNT');
    }
    return this.chartAggregationTypes;
  }

  updateAggregateBy(chartType: string) {
    const timeSeriesChartTypes = TimeSeriesChartTypes;
    const selectedChartType = chartEnum[chartType as keyof typeof chartEnum];
    const foundTimeSeriesChart = timeSeriesChartTypes.find((type: any) => type == selectedChartType);
    if(foundTimeSeriesChart) {
      // Hide the count option for time series charts.
      this.chartAggregationTypes = this.chartAggregationTypes.filter(_type => _type.value != 'COUNT');
      // FOr time series charts, set the aggregate by to AVG if it is COUNT.
      this.pointDefs.controls.forEach((control: any) => {
        if (control.value.aggregateBy == 'COUNT') { // Set the aggregate by to AVG if it is COUNT.
          control.controls.aggregateBy.setValue('AVG');
        }
      });
    } else {
      this.chartAggregationTypes = chartAggregationTypes;
    }
  }

  validateAngles() {
    if (this.selectedStartAngle.toString() == '' || this.selectedEndAngle.toString() == '') {
      return;
    }
    this.gaugeAngleConfigError = (this.selectedStartAngle == this.selectedEndAngle) ? true : false;
    return this.gaugeAngleConfigError;
  }

  filterAngles(from: string): void {
    if (from == 'start') {
      this.filteredStartAngles = this.angleValues.filter(angle =>
        angle.toString().includes(this.selectedStartAngle.toString())
      );
    } else {
      this.filteredEndAngles = this.angleValues.filter(angle =>
        angle.toString().includes(this.selectedEndAngle.toString())
      );
    }
  }

  addConditions() {
    const conditionArray = this.addConditionsArray(this.setDefaultDataForFormGroup());
    const conditionsControl = (<FormArray>this.pointDefs.controls[this.selectedParamIndex]).get('conditions') as FormArray;
    conditionsControl.push(conditionArray);
  }

  addConditionsArray(conditionData: any) {
    const formGroup = this.fb.group({
      comparisonOperator: [conditionData.comparisonOperator, Validators.required],
      color: [conditionData.color, Validators.required],
      legendLabel: [conditionData?.legendLabel, Validators.required],
      value: conditionData.value !== undefined ? [conditionData.value, Validators.required] : [null, Validators.required],
      minValue: conditionData.minValue !== undefined ? [conditionData.minValue, Validators.required] : [null, Validators.required],
      maxValue: conditionData.maxValue !== undefined ? [conditionData.maxValue, Validators.required] : [null, Validators.required]
    }) as FormGroup<any>;

    // Initial setup based on `comparisonOperator`
    const operator = conditionData.comparisonOperator;
    if (operator === 'IN_BETWEEN') {
      // Remove `value` and add `minValue` and `maxValue`
      formGroup.removeControl('value');
      formGroup.addControl('minValue', this.fb.control(conditionData.minValue, Validators.required));
      formGroup.addControl('maxValue', this.fb.control(conditionData.maxValue, Validators.required));
    } else {
      // Remove `minValue` and `maxValue` and add `value`
      formGroup.removeControl('minValue');
      formGroup.removeControl('maxValue');
      formGroup.addControl('value', this.fb.control(conditionData.value, Validators.required));
    }
    // Dynamically manage `minvalue` and `maxvalue` based on `comparisonOperator`
    formGroup.get('comparisonOperator')?.valueChanges.subscribe((operator: any) => {
      if (operator === 'IN_BETWEEN') {
        // Remove `value` and add `minvalue` and `maxvalue`, set both to null
        formGroup.removeControl('value');
        formGroup.addControl('minValue', this.fb.control(conditionData.minValue, Validators.required));
        formGroup.addControl('maxValue', this.fb.control(conditionData.maxvalue, Validators.required));
      } else {
        // Remove `minvalue` and `maxvalue`, and add `value`, set to null
        formGroup.removeControl('minValue');
        formGroup.removeControl('maxValue');
        formGroup.addControl('value', this.fb.control(conditionData.value, Validators.required));
      }
    });
    return formGroup;
  }

  setDefaultDataForFormGroup() {
    return {
      comparisonOperator: 'LESS_THAN',
      color: this.generateUniqueColor(),
      legendLabel: null,
      value: null,
      minValue: null,
      maxValue: null
    }
  }

  getCondition() {
    const conditionsArray = <FormArray>((this.pointDefs)?.controls[this.selectedParamIndex])?.controls['conditions']?.controls;
    return conditionsArray;
  }

  deleteCondition(cIndex: number) {
    (<FormArray>(<FormGroup>(<FormArray>this.pointDefs)?.controls[this.selectedParamIndex]).controls['conditions']).removeAt(cIndex);
    delete this.data.pointSummaryData.pointDefinitions[this.selectedParamIndex].conditions[cIndex];
  }
}

