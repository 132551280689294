<div class="create-dashboard-layout">

    <div class="layout-header" id="create-dashboard-header">
        <span class="layout-title" id="{{ title }}">{{ title }}</span>
        <span class="layout-description">
            Fill in the details below and hit 'Continue' to {{actionLabel}} your dashboard.
        </span>
    </div>
    
    <div class="layout-dashboard-name" id="dashboard-name-section">
        <mat-form-field (click)="$event.stopPropagation()" class="dashboard-search-input" [floatLabel]="'never'"
            id="dashboardNameInputField">
            <input [(ngModel)]='dashboardName' maxlength="100" autocomplete="off" spellcheck="false" autofocus="false" trim="blur" matInput placeholder="Dashboard Name"
                (keydown.Space)="$event.stopPropagation()" (keydown.Enter)="$event.stopPropagation()"
                pattern="^[A-Za-z0-9][A-Za-z0-9_#%,-\s]*$"
                id="dashboardNameInput" #dashboardNameInput="ngModel"/>
        </mat-form-field>
    </div>

    <!-- Validation message -->
    <div *ngIf="dashboardNameInput.invalid && dashboardNameInput.dirty && !validateDashboardName()" class="error-message">
        Special characters are not allowed in title. Allowed characters[hyphen(-), underscore(_),
        space(),
        percentage(%), hash(#)].
    </div>

    <div *ngIf="dashboardNameInput.invalid && dashboardNameInput.dirty && validateDashboardName()" class="error-message">
        Dashboard name cannot start with special characters.
    </div>

    <div class="dashboard-configuration">
        <div class="config-button">
            <div *ngFor="let option of configurationOptions" class="p-r-17">
                <button class="btns btnstyle"
                    [ngClass]="{
                        'shared-btn': option?.value == 'shared', 
                        'personal-btn': option?.value == 'personal', 
                        'active':widgetType == option?.value, 
                        'personal-btn-active': widgetType == 'personal' && widgetType == option?.value,
                        'shared-btn-active': widgetType == 'shared' && widgetType == option?.value }"
                         (click)="onWidgetChange(option)" value="{{option?.value}}"  
                         [matMenuTriggerFor]="sharedMenu">
                    {{option?.label}}
                </button>
            </div>
        </div>
    </div>

    <div class="dashboard-details">

        <div class="w-100 label-area">
            <label class="description-label"><b>Why </b>({{whyDescription.length}}/1000)</label>
        </div>
        <div class="description-area">
            <textarea class="text-decription"  [(ngModel)]="whyDescription" maxlength="1000" spellcheck="false"
                placeholder="Type in description here"></textarea>
        </div>

        <div class="w-100">
            <label class="description-label"><b>What </b> ({{whatDescription.length}}/1000)</label>
        </div>
        <div class="description-area">
            <textarea class="text-decription" [(ngModel)]="whatDescription" maxlength="1000" spellcheck="false"
                placeholder="Type in description here"></textarea>
        </div>
    </div>
</div>

<div class="dashboard-layout-footer">
    <button #cancel id="cancelButton" class="btnfooter text-uppercase btn btn-outline-primary color-primary"
        (click)="cancelDialog()">Cancel</button>

    <!-- Vertical separator line -->
    <div class="vertical-separator"></div>
    <button #continue id="continueButton" [disabled]="!isDataValid || dashboardNameInput.invalid" class="btnfooter text-uppercase btn btn-outline-primary color-primary"
        (click)="updateDashboard()">Continue</button>
</div>

<mat-menu class="sharing-options-dashboard" #sharedMenu="matMenu">
    <div class="configurations">
        <label class="lbl-text">Sharing Options</label>
        <mat-radio-group id="analytics-sharing-options" class="p-t-4 row configuration-radio-group select-sharing-type"
            [(ngModel)]="selectedSharedConfiguration">
            <div>
                <mat-radio-button (click)="$event.stopPropagation()" value='personal'>Internal Support Users
                </mat-radio-button>
            </div>
            <div>
                <mat-radio-button (click)="$event.stopPropagation()" value='shared'>Internal Support Users + Facilisight
                    Users</mat-radio-button>
            </div>
        </mat-radio-group>
    </div>
</mat-menu>