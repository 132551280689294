<ng-container *ngIf="widgetObj.isEmbedded">
    <iframe class="chart-iframe" [src]="externalUrl" frameborder="0"></iframe>
</ng-container>
<ng-container *ngIf="!widgetObj.isEmbedded">
    <div class="loader-img" *ngIf="!isLoaded">
        <img alt="" src="./assets/images/loader.gif">
    </div>

    <ng-container *ngIf="showWarningMessage">
        <div class="no-data-container"><span>{{warningMessage}}</span></div>
    </ng-container>

    <div class="chart-container" *ngIf="isLoaded && transformedChartData?.length && !showWarningMessage">
        <div class="chart" *ngFor="let chartOption of transformedChartData;let chartOptionIndex= index">
    
            <div class="sitename-text" *ngIf="widgetObj?.chartConfig?.viewType == 'SEPARATE' && chartOption?.siteName">
                {{chartOption?.siteName}}</div>
            <ng-container *ngIf="!seperateChartForEachParam(widgetObj?.chartConfig?.chartType)">
                <ng-container *ngIf="chartOption.series.length" [ngSwitch]="widgetObj?.chartConfig?.chartType">
                    <ng-container *ngSwitchCase="'LINE'">
                        <app-linechart [chartOptions]="chartOption"
                            [chartAxisPositions]="chartAxisPositions"></app-linechart>
                    </ng-container>
    
                    <ng-container *ngSwitchCase="'DOTTED_LINE'">
                        <app-linechart [chartOptions]="chartOption"
                            [chartAxisPositions]="chartAxisPositions"></app-linechart>
                    </ng-container>
    
                    <ng-container *ngSwitchCase="'AREA'">
                        <app-areachart [chartOptions]="chartOption"
                            [chartAxisPositions]="chartAxisPositions"></app-areachart>
                    </ng-container>
    
                    <ng-container *ngSwitchCase="'BAR'">
                        <app-barchart [chartOptions]="chartOption" [chartAxisPositions]="chartAxisPositions"></app-barchart>
                    </ng-container>
    
                    <ng-container *ngSwitchCase="'PIE'">
                        <app-piechart [chartOptions]="chartOption"></app-piechart>
                    </ng-container>
    
                    <ng-container *ngSwitchCase="'DONUT'">
                        <app-piechart [chartOptions]="chartOption"></app-piechart>
                    </ng-container>
    
                    <ng-container *ngSwitchCase="'BOX_PLOT'">
                        <app-boxchart [chartOptions]="chartOption" [chartAxisPositions]="chartAxisPositions"></app-boxchart>
                    </ng-container>
                    
                </ng-container>
                
            </ng-container>
    
            <ng-container [ngSwitch]="widgetObj?.chartConfig?.chartType"
                *ngIf="seperateChartForEachParam(widgetObj?.chartConfig?.chartType)">
                <div class="sitename-text" *ngIf="widgetObj?.chartConfig?.viewType == 'SEPARATE' && chartOption[0]?.siteName">
                    {{chartOption[0]?.siteName}}</div>
                <div class="legend-container m-l-20 m-t-10" [ngClass]="{'no-pointer-events':widgetObj?.builderWidgetPreview}">
                    <span class="parameter-selection-container"
                        (click)="loadSelectedParameterChart(chartOptionIndex,chartObjIndex, chartOption)"
                        *ngFor="let chartObj of chartOption;let chartObjIndex = index;"
                        [ngClass]="{'disabled-state': (chartObj?.parameterName != chartOption[0].selectedParameterName)}">
                        <span class="parameter-name-content" *ngIf="chartObj.showVisualisation">
                            <span *ngIf="widgetObj?.chartConfig?.chartType=='SUNBURST'" class="selected-color-box sunburst-gradient-color"></span>
                            <span *ngIf="widgetObj?.chartConfig?.chartType=='TERRAIN'" class="selected-color-box"
                                [ngStyle]="{ 'background' : linearGradient(widgetObj?.chartConfig?.[chartObj.parameterName].color['stopColor0'], widgetObj?.chartConfig?.[chartObj.parameterName].color['stopColor100']) }">
                            </span>
                            <span class="selected-color-box" *ngIf="widgetObj?.chartConfig?.chartType == 'GAUGE'"
                                [ngStyle]="{ 'background' : linearGradientForGaugeChart(widgetObj?.pointDefinitions, widgetObj?.chartConfig?.[chartObj.parameterName], chartObj.parameterName) }">
                            </span>
                            <span class="parameter-name">{{chartObj.parameterName}}</span>
                        </span>
                    </span>
                </div>
                <div class="loader-img" *ngIf="!isParamsDataLoaded">
                    <img alt="" src="./assets/images/loader.gif">
                </div>
                <ng-container *ngIf="isParamsDataLoaded">
                    <ng-container *ngSwitchCase="'SUNBURST'">
                        <app-sunburstchart [chartOptions]="chartOption[0]?.selectedChartData"></app-sunburstchart>
                    </ng-container>
                    <ng-container *ngSwitchCase="'TERRAIN'">
                        <app-terrainchart [chartOptions]="chartOption[0]?.selectedChartData"></app-terrainchart>
                    </ng-container>
                    <ng-container *ngSwitchCase="'GAUGE'">
                        <app-gaugechart [chartOptions]="chartOption[0]?.selectedChartData"></app-gaugechart>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>