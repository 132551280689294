import { Component, OnInit } from '@angular/core';
import { DashboardService } from './shared/_services/dashboard.service';
import { LocalStorageService } from './shared/_services/local-storage.service';
import { HostRoute } from './shared/enums/host.enum';
import { FeatureToggleService } from './shared/_services/feature-toggle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'custom-dashboard-ui';

  constructor(public dashboardService:DashboardService,
    private featureToggleService: FeatureToggleService,
    private localStorageService: LocalStorageService,
  ) {}
  
  ngOnInit(): void {
    const url = new URL(window.location.href);
    const queryParams = url.searchParams;
    this.handleQueryParams(queryParams);
  }

  async handleQueryParams(queryParams: URLSearchParams) {
    if (queryParams.has('token')) {
      this.localStorageService.bearer_token = queryParams.get('token') || '';
    }

    if (queryParams.has('userId')) {
      const userId = queryParams.get('userId') || '';
      this.localStorageService.user_id = userId;
      this.dashboardService.getUserPreferences();
      this.featureToggleService.devCycleInit();
    }

    if (queryParams.has('token') || this.localStorageService.bearer_token) {
      // Save this token in a service, which is used for subsequent calls
      const token = queryParams.get('token') || this.localStorageService.bearer_token;
      this.dashboardService.setCurrentOwner(token);
      this.localStorageService.bearer_token = token;
    }

    const portal = queryParams.get('portal');
    const isInternalPortal = portal !== HostRoute.FACILISIGHT;
    isInternalPortal ? this.localStorageService.portal_type = HostRoute.INTERNAL : this.localStorageService.portal_type = portal;  }

}
