import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { ConfigurationService } from '../_services/configuration.service';
import { LocalStorageService } from '../_services/local-storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  paramIds = new Set();

  constructor(private configService: ConfigurationService, private localStorageService: LocalStorageService) { }

  private handleAuthError(errResponse: HttpErrorResponse): Observable<any> {
    let errorMessage = '';
    if (errResponse.error instanceof ErrorEvent) { // client-side error

      errorMessage = `Error: ${errResponse.error.message}`;
      console.log(errorMessage);

      return throwError(errResponse);

    } else {

      if (errResponse.status == 401) {
        return throwError(errResponse);

      } else {

        if (errResponse.error?.msg) {
          errorMessage = errResponse.error.msg;
        } else if (errResponse.error?.suberror) {
          errorMessage = 'Missing consent to access resource';
        }

        return throwError(errResponse);
      }
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const bearerToken = this.localStorageService.bearer_token || '';
    const hayStackUrl = this.configService.getConfig('haystackUrl');
    const appName = this.localStorageService.portal_type;
    if (request.url.startsWith(hayStackUrl)) {
      request = request.clone({
        setHeaders: {
          'content-type': 'text/zinc'
        }
      });
    }

    const tokenReq = request.clone({
      setHeaders: { Authorization: `Bearer ${bearerToken}`, '75F-Application': appName?.toUpperCase() || '' }
    });

    return next.handle(tokenReq)
      .pipe(
        catchError(err => this.handleAuthError(err))
      );
  }
}
