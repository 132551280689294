import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import BlocklyToolBox from '../../constants/blockly-common-pool';
import * as Blockly from 'blockly';
import '@blockly/toolbox-search';
import { blocks } from '../../constants/predefined-blocks';
import { CodeGenerate } from '../../constants/predefined-code-generator';
import { javascriptGenerator } from 'blockly/javascript';
import { BlockTheme, categoryTheme } from '../../constants/blockly-theme';
import { BlocklyValidators } from '../../constants/validators';
import { BlocklyLanguage } from '../../models/common.model';
import { AlertService } from '../../_services/alert.service';
import { UNIT_CONVERSION_BLOCKS } from '../../constants/unit-conversions';
import { SEQUENCE_TEMPLATES } from '../../constants/sequence-templates';

@Component({
  selector: 'app-blockly-common-pool',
  templateUrl: './blockly-common-pool.component.html',
  styleUrls: ['./blockly-common-pool.component.scss']
})

export class BlocklyCommonPoolComponent implements OnInit {

  blocklyWorkSpace!: any;
	isCodeBlockVisible: boolean = false;
	codeBlockTooltipText = "Expand Code Snippet"
	initLanguageName = 'Select a language';
	languages: BlocklyLanguage[] = [{ "name": "JavaScript", "id": "javascript" }];
	filteredLanguages = [...this.languages];
	selectedLanguageId: any = 'javascript';
	code: string = '';
	codeOptions = {
		lineNumbers: true,
		theme: 'default',
		mode: 'markdown',
		readOnly: true
	};
	isLoading: boolean = true;
	showToolBox:boolean = true;
	showToolBoxToggleButton: boolean = false;
	errorBlocks: any = [];
	invalidInputBlocks: any = [];
	alertBlock:any = null;
	saveCancelBtnDisabled: boolean = true;
	blocksToInit: any;
	searchText:string = '';
	excludedBlockCategories: any = ['Custom Primitives', 'Custom Functions'];

	// Input Events
	@Input() parameters: any;
	@Input() selectedDerivedParameter: any;
	@Input() xmlCode: any;
	@Input() dependentPoints: any = [];
	@Input() viewOnly: boolean = false;
	// Output Events
	@Output() typeChanged: EventEmitter<any> = new EventEmitter();
	@Output() backToParametersEvt: EventEmitter<any> = new EventEmitter();
	@Output() onBlocklySave: EventEmitter<any> = new EventEmitter();

  constructor(public alertService:AlertService) { }

	ngOnInit(): void {
		setTimeout(() => {
			this.isLoading = false;
			this.initializeBlockly();
		}, 500);
	}

	initializeBlockly() {
		let toolBox = BlocklyToolBox;
		this.blocksToInit = blocks;
		this.blocksToInit = this.blocksToInit.concat(UNIT_CONVERSION_BLOCKS);
		this.blocksToInit = this.blocksToInit.concat(SEQUENCE_TEMPLATES);
		Blockly.defineBlocksWithJsonArray(this.blocksToInit);
		CodeGenerate.addCodeGenerators();
		CodeGenerate.sequenceTemplates();
		CodeGenerate.unitConversionTemplates();
		BlocklyValidators.addValidators();

		if (!this.blocklyWorkSpace) {
			toolBox.contents = toolBox.contents.filter(blocklyCategory => {
				return !this.excludedBlockCategories.includes(blocklyCategory.name);
			});
			this.blocklyWorkSpace = Blockly.inject('blockly-container', {
				toolbox: toolBox,
				grid: {
					spacing: 10,
					length: 2,
					snap: true,
					colour: '#ccc'
				},
				zoom:
				{
					controls: true,
					startScale: 1.0,
					maxScale: 3,
					minScale: 0.3,
					scaleSpeed: 1.2,
					pinch: true
				},
				move: {
					drag: true,
					wheel: true
				},
				trashcan: true,
			});
		}

		let blockStyle = BlockTheme.blockStyles;
		let categorySyle = categoryTheme.categoryStyle;
		var theme = Blockly.Theme.defineTheme('customTheme', {
			blockStyles: blockStyle,
			categoryStyles: categorySyle,
			name: ''
		});

		this.blocklyWorkSpace.setTheme(theme);
		this.initializeToolboxSearch();
		this.subscribeToBlocklyEvents();
    	this.createVariablesProgramatically();
		this.showToolBoxToggleButton = true;
		this.formatCategoryNames();
		setTimeout(() => {
			this.blocklyWorkSpace.render();
			Blockly.svgResize(this.blocklyWorkSpace);
			const blocklyDropDownDiv = <HTMLElement>document.getElementsByClassName('blocklyDropDownDiv')?.[0];
			if (blocklyDropDownDiv) {
				blocklyDropDownDiv.style.setProperty('position', 'absolute', 'important');
			}
		}, 200);
	}

	  /** Method to format the category names to append the blocks count to the category names */
	  formatCategoryNames() {
		const toolboxBlocks = this.blocklyWorkSpace?.getToolbox().getToolboxItems();
		toolboxBlocks.forEach((blockObj: any) => {
		  if (blockObj.toolboxItemDef_?.kind == 'category') {
			const target = blockObj['rowDiv_'].querySelector('.blocklyTreeLabel');
			if (target) {
			  let catergoryName = blockObj.getName();
			  let count = (catergoryName != 'Variables' && catergoryName != 'Functions') ? `(${blockObj.flyoutItems_.length})` : '';
			  target.textContent = `${catergoryName} ${count}`;
			}
		  }
		});
	  }

	// Save the blockly and emit to parent component.
	saveBlockly() {
		// Generating the code snippet.
		const snippet = this.getJSCodeForBlockly();
		const xmlCode = this.getXmlCodeForBlockly();
		this.xmlCode = xmlCode;
		this.saveCancelBtnDisabled = true; 
		const warningText = "The module data format is not consistent in the selected parameters";
		const dependentPoints:string[] = [];
		this.parameters.forEach((_parameter: any) => {
			if (this.isStringExactMatched(xmlCode, `variable-${_parameter.name}`)) {
				dependentPoints.push(_parameter.name);
			}
		});

		// get all blocks from workspace
		const allBlocks = this.blocklyWorkSpace.getAllBlocks();
		// get all variables  
		const allVariables = this.blocklyWorkSpace.getAllVariables();

		// filter out and get derived variables 
		const derivedVars = allVariables.filter((item:any) => item.id_.includes('derived'));

		// get derived block / only one derived block per one blockly instance
		const derivedBlock = allBlocks.filter((blockObj: any) => blockObj.getFieldValue('VAR') === derivedVars.length && derivedVars[0].id_);

		// check for unique evaluation mode for the normal parameters
		const uniqueParametersByMode = [...new Set(this.parameters.map((item:any) => item.evaluationMode))];

		if (uniqueParametersByMode.length > 1) {
			derivedBlock.forEach((dBlock: any) => {
				dBlock.setWarningText(warningText);
				this.toggleBlockErrorState(warningText, dBlock);
			})
		}

		setTimeout(() => {
			if (!this.errorBlocks.length) {
				this.onBlocklySave.emit({ snippet, xmlCode, dependentPoints });
			}
		}, 300)
    
		if (!this.errorBlocks.length) {
			this.alertService.success('Saved successfully.');
		}
	}

	toggleBlockErrorState(warningText: any, block: any) {
		const targetIndex = this.errorBlocks.indexOf(block.id);
		if(warningText) {
		  this.errorBlocks.push(block.id); 
		  block.setHighlighted(true);
		} else {
		  targetIndex >= 0 ? this.errorBlocks.splice(targetIndex, 1) : '';
		  block.setHighlighted(false);
		}
	  }

	  toggleAlertBlockErrorState(warningText: any, block: any) {
		const targetIndex = this.invalidInputBlocks.indexOf(block.id);
		if(warningText) { 
		  block.setHighlighted(true);
		  this.invalidInputBlocks.push(block.id);
		} else {
		  targetIndex >= 0 ? this.invalidInputBlocks.splice(targetIndex, 1) : '';
		  block.setHighlighted(false);
		}
	  }

	// Cancel clear the blocks from the canvas.
	cancel() {
		// If blockly already saved and clicked cancel. The new changes will clear from canvas and reverted to last saved state.
		this.blocklyWorkSpace.clear();
		this.createVariablesProgramatically();
	}

	backToParameters() {
		this.blocklyWorkSpace.clear();
		this.blocklyWorkSpace.dispose();
		this.backToParametersEvt.emit(true);
	}

	initializeToolboxSearch() {
		const toolboxBlocks = this.blocklyWorkSpace?.getToolbox().getToolboxItems();
		const searchObj = toolboxBlocks[0]['rowDiv_'].querySelector('input');
		if (searchObj) {
			searchObj.placeholder = 'Search for Blocks/Sequence';
		}
	}

	showHideCodeSnippetBlock() {
		this.isCodeBlockVisible = !this.isCodeBlockVisible;
		this.codeBlockTooltipText = this.isCodeBlockVisible ? "Collapse Code Snippet" : "Expand Code Snippet";
	}

	subscribeToBlocklyEvents() {
		this.blocklyWorkSpace.addChangeListener((event: any) => {
			this.blocklyWorkSpace.render();
			this.validateBlocklyLogic();
			this.handleFunctionBlock(event);
		});
	}

	handleFunctionBlock(event: any) {
		const targetBlock = this.blocklyWorkSpace.getBlockById(event.blockId);
		const currentBlockType = event.type === 'create' ? event.json?.type : (event.type === 'delete' ? event.oldJson?.type : '');
		if ((targetBlock && targetBlock.type === 'alert') || currentBlockType === 'alert') {
			if (event.type === 'create') {
				this.handleFunctionCreation(event, targetBlock);
			} else if (event.type === 'delete') {
				this.alertBlock = null;
			}
		}
	} 

	validateInputField(block: any) {
		let warningText = '';
		const allInputfieldsInBlocks = block?.inputList.flatMap((inputListObj: any) => Array.isArray(inputListObj.fieldRow) ? inputListObj.fieldRow : [inputListObj.fieldRow]);
		const textfields = allInputfieldsInBlocks.filter((inputObj: any) => inputObj instanceof Blockly.FieldTextInput);
		let blockInputFields: any[] = [];
		blockInputFields = textfields.filter((fieldObj: any) => fieldObj.name && !fieldObj.value_);
		if(blockInputFields.length > 0) {
		  warningText = 'Input field cannot be empty';
		}
		block.setWarningText(warningText);
		this.toggleAlertBlockErrorState(warningText, block);
	}

	handleFunctionCreation(event: any, targetBlock: any) {
		if (!this.alertBlock) {
			this.alertBlock = targetBlock;
		} else {
			targetBlock.dispose();
		}
	}

	validateBlocklyLogic() {
		const jsCode = javascriptGenerator.workspaceToCode(this.blocklyWorkSpace);
		this.code = jsCode;
		const xmlCode = this.getXmlCodeForBlockly();
		if (xmlCode == this.xmlCode) {
			this.saveCancelBtnDisabled = true;
		} else {
			this.saveCancelBtnDisabled = false;
		}
	}

	onLanguageChange(evt:any) {
		this.selectedLanguageId = evt.id;
	}

	createVariablesProgramatically() {
		// Can be renamed the variable using method
		// this.blocklyWorkSpace.getVariableById(variableId).name = 'new variable name';
		if (!this.isStringExactMatched(this.xmlCode, this.selectedDerivedParameter.name)) {
			Blockly.Variables.getOrCreateVariablePackage(this.blocklyWorkSpace, `derived-param-${this.selectedDerivedParameter.name}`, `${this.selectedDerivedParameter.name}`, '');
		}
		this.parameters.forEach((_param:any) => {
			// Finding the variable which is already used in the canvas. So, we can prevent creating the duplicate variable.
			const foundParam = this.dependentPoints?.find((_dependentParam:any) => _dependentParam == _param.name);
			if (!foundParam && !this.isStringExactMatched(this.xmlCode, _param.name)) {
				// Creating the variables to toolbox.
				Blockly.Variables.getOrCreateVariablePackage(this.blocklyWorkSpace, `variable-${_param.name}`, `${_param.name}`, '');
			}
		});
		this.loadExistingBlockly();
	}

	loadExistingBlockly() {
		// Method passes the xml code to blockly to create the blockly on canvas.
        if (this.xmlCode) {
			// Parse the xml code and load xml code to blockly.
            const parser = new DOMParser();
            const xmlDoc:any = parser.parseFromString(this.xmlCode || '', "text/xml");
            Blockly.Xml.domToWorkspace(xmlDoc.documentElement, this.blocklyWorkSpace);
			this.blocklyWorkSpace.getAllVariables().forEach((_variable: any) => {
				const isVariableInParameters = this.parameters.map((_param: any) => _param.name).includes(_variable.name);
				const isVariableInCalculated = this.selectedDerivedParameter && this.selectedDerivedParameter.name === _variable.name;
				const isVariableRemoved = !(isVariableInParameters || isVariableInCalculated);
				if (isVariableRemoved) {
					this.blocklyWorkSpace.deleteVariableById(_variable.id_, _variable.name);
				}
			});
        }
	}

	getJSCodeForBlockly() {
		// Generates the js code from the blockly.
		return javascriptGenerator.workspaceToCode(this.blocklyWorkSpace);
	}

	getXmlCodeForBlockly() {
		// Generates the xml code from the blockly.
		const xml = Blockly.Xml.workspaceToDom(this.blocklyWorkSpace);
		const xmlString = new XMLSerializer().serializeToString(xml);
		return xmlString;
	}

	/** Method to show or hide toolbox in blockly workspace on button click in UI */
	showHideToolBox(value: boolean) {
		this.showToolBox = value;
		!value ? this.blocklyWorkSpace.getFlyout().setVisible(value) : '';
		this.blocklyWorkSpace.getToolbox()?.setVisible(value);
	}

	escapeRegExpMatch(s:string) {
		return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
	};

	isStringExactMatched(str:string, match:string) {
	  return new RegExp(`\\b${this.escapeRegExpMatch(match)}\\b`).test(str)
	}

	searchInputChanged(): void {
		const searchTerm = this.searchText.toLowerCase();
		this.filteredLanguages = this.languages.filter(language =>
		  language.name.toLowerCase().includes(searchTerm)
		);
	}

	clearSearch() {
		this.searchText = '';
    	this.filteredLanguages = [...this.languages];
	}
}
