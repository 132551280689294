import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import html2canvas from 'html2canvas';
import { Partner } from '../enums/partners.enum';
import { HttpClient } from '@angular/common/http';
// @ts-ignore
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { environment } from 'src/environments/environment';
import dayjs from 'dayjs';
import { CHART_PLOT_OPTIONS, ExportChartTypes, pieDonutSunBurstCharts, TimeSeriesChartTypes } from '../constants/widget';
import { DashboardService } from './dashboard.service';
export type SupportedExtensions = 'png' | 'xlsx' | 'xls';

// Define an interface for the element details
export interface ElementDetails {
  id: string; // or the appropriate type for your ID
  xAxis: number; // or the appropriate type for x-axis value
  yAxis: number; // or the appropriate type for y-axis value
  chartName: string; // or the appropriate type for the chart name
}

// Update ExportAsConfig to use ElementDetails for elementIds
export interface ExportAsConfig {
  type: SupportedExtensions;
  elements: Array<ElementDetails>; // Now it uses the ElementDetails interface
  download?: boolean;
  dashboardName?: string;
  fileName?: string;
  options?: any;
}

(window as any)['html2canvas'] = html2canvas;

@Injectable({
  providedIn: 'root'
})

export class ExportAsService {

  constructor(private http: HttpClient, private dashboardService: DashboardService) { }

  /**
   * Main base64 get method, it will return the file as base64 string
   * @param config your config
   */
  get(config: ExportAsConfig): Observable<string | null> {
    // Define the method name dynamically
    const func = 'get' + config.type.toUpperCase();

    // Ensure TypeScript knows this is a key of the class
    if (typeof (this as any)[func] === 'function') {
      return (this as any)[func](config);
    }

    // Handle unsupported types
    return new Observable((observer) => {
      observer.error('Export type is not supported.');
    });
  }


  /**
   * Save exported file in old javascript way
   * @param config your custom config
   * @param fileName Name of the file to be saved as
   */
  save(config: ExportAsConfig, fileName: string): Observable<string | null> {
    // set download
    config.download = true;
    // get file name with type
    config.fileName = fileName + '.' + config.type;
    return this.get(config);
  }

  // Method to get the PNG

  private getPNG(config: ExportAsConfig): Observable<string | null> {
    return new Observable((observer) => {

      if (config.elements.length > 0) {
       
        const containerWidth: number = document.getElementById('dashboard-layout-body')?.clientWidth ?? 1000; // Width of the container
        const isMultiElement = config.elements.length > 1; // Check if multiple elements exist
        let currentRow: HTMLElement[] = [];
        let currentRowWidth = 0;
        let isPartner: Boolean = environment.config.appDisplayName != Partner.SeventyFiveF; // Check if the partner is 75F

        // Create a temporary container div
        const tempContainer = document.createElement('div');
        tempContainer.style.position = 'absolute'; // Position it off-screen
        tempContainer.style.top = '-9999px';
        tempContainer.style.left = '-9999px';

        // Create Header
        const header = this.createHeader(isPartner); // Pass the `isPartner` flag
        tempContainer.appendChild(header); // Append to the temporary container

        if (isMultiElement) {
          // Create and style the text element
          const textElement = this.createStyledDashboardNameElement(config?.dashboardName ?? ''); // Pass the dashboard name
          tempContainer.appendChild(textElement); // Append to the temporary container
        }

        // Create the body container
        const body = document.createElement('div');

        config.elements.forEach((chart, index) => {
          const element: HTMLElement | null = document.getElementById(chart?.id);
          if (!element) return;
        
          // Clone the element
          const clonedElement = element.cloneNode(true) as HTMLElement;
        
          // Copy computed styles
          this.copyStyles(element, clonedElement);
        
          // Remove unwanted child elements
          this.removeElements(clonedElement, [
            '#widget-type-icon',
            '#widget-action-container',
            '#widget-info-icon',
          ]);
        
          // Get computed styles for width/height
          const computedStyles: any = element.getBoundingClientRect();
          const elementBaseWidth = parseFloat(computedStyles?.width);
          const elementWidth = elementBaseWidth + 32; // Include padding (16px on each side)
        
          // Apply styles to cloned element
          clonedElement.style.width = `${elementBaseWidth+32}px` ; // Exclude padding
          clonedElement.style.height = `${parseFloat(computedStyles?.height)}px`;
         
          clonedElement.style.paddingLeft = '16px'; // Padding on the left for alignment
          clonedElement.style.paddingRight = '16px'; // Padding on the right for alignment

          const chartheader = this.createChartHeader(chart?.chartName); 
          
          // Wrap header and clonedElement into a container
          const elementContainer = document.createElement('div');
          elementContainer.style.paddingLeft = '32px'; // Match the padding of the header
          elementContainer.style.width = clonedElement?.style?.width // Match the padding of the header
          
          elementContainer.appendChild(chartheader);
          elementContainer.appendChild(clonedElement);
        
          if (isMultiElement) {
            const isXZero = chart.xAxis === 0; // Check if the current element has x-axis 0
        
            // If xAxis === 0, start a new row
            if (isXZero) {
              if (currentRow.length > 0) {
                // Append the current row to the body
                this.appendRowToBody(currentRow, containerWidth, body);
              }
        
              // Start a new row with the current element
              currentRow = [elementContainer];
              currentRowWidth = elementWidth;
            } else {
              // Add element to the current row
              currentRow.push(elementContainer);
              currentRowWidth += elementWidth;
            }
        
            // Append the last row at the end of the loop
            if (index === config.elements.length - 1 && currentRow.length > 0) {
              this.appendRowToBody(currentRow, containerWidth, body);
            }
          } else {
            // Directly append the single element to the body
            body.appendChild(elementContainer);
          }
        });
        
        // Append the body to the temporary container
        tempContainer.appendChild(body);
        
        // Add the temporary container to the document
        document.body.appendChild(tempContainer);

        // Pass the container to html2canvas
        html2canvas(tempContainer, {
          ...config.options,
          scale: window.devicePixelRatio || 2, // Use devicePixelRatio for better DPI
          useCORS: true, // Enable CORS if necessary
        }).then(
          (canvas) => {
            // Adjust canvas resolution for higher DPI
            const dpi = 300; // Set desired DPI
            const scaleFactor = dpi / 96; // Standard CSS assumes 96 DPI
            const highResCanvas = document.createElement('canvas');
            highResCanvas.width = canvas.width * scaleFactor;
            highResCanvas.height = canvas.height * scaleFactor;
        
            const context = highResCanvas.getContext('2d');
            if (context) {
              // Scale the content
              context.scale(scaleFactor, scaleFactor);
              context.drawImage(canvas, 0, 0);
            }
        
            const imgData = highResCanvas.toDataURL('image/PNG');
        
            if (config.type === 'png' && config.download) {
              // Directly download the high-resolution image
              const link = document.createElement('a');
              link.href = imgData;
              link.download = config.fileName ?? 'download.png'; // Specify the file name
              link.click(); // Trigger the download
        
              observer.next(''); // Notify observer of success
            } else {
              observer.next(imgData); // Send the generated image data to the observer
            }
        
            observer.complete(); // Mark observer as completed
        
            // Cleanup: Remove the temporary container from the document
            document.body.removeChild(tempContainer);
          },
          (err) => {
            observer.error(err); // Notify observer of an error
          }
        );

      } else {
        observer.error(new Error("No valid elements found."));
      }

    });
  }

  copyStyles(source: HTMLElement, target: HTMLElement): void {
    const computedStyle = getComputedStyle(source);
    Array.from(computedStyle).forEach((key) => {
      target.style.setProperty(key, computedStyle.getPropertyValue(key));
    });

    // Handle special cases (e.g., canvas elements)
    const sourceCanvases = source.querySelectorAll('canvas');
    const targetCanvases = target.querySelectorAll('canvas');
    sourceCanvases.forEach((srcCanvas, index) => {
      const destCanvas = targetCanvases[index];
      if (destCanvas) {
        const context = destCanvas.getContext('2d');
        if (context) {
          context.drawImage(srcCanvas, 0, 0);
        }
      }
    });
  }

  removeElements(parent: HTMLElement, selectors: string[]): void {
    selectors.forEach((selector) => {
      const elements = parent.querySelectorAll(selector);
      elements.forEach((element) => element.remove());
    });
  }

  appendRowToBody(rowElements: HTMLElement[], rowWidth: number, body: HTMLElement) {
    const rowDiv = document.createElement('div');
    rowDiv.style.display = 'flex';
    rowDiv.style.width = `${rowWidth}px`;
    rowDiv.style.marginBottom = '16px'; // Spacing between rows

    rowElements.forEach((element) => rowDiv.appendChild(element));
    body.appendChild(rowDiv);
  }

  /**
   * Fetches an image from the given URL.
   * @param {string} url - The URL of the image to fetch.
   * @param {any} [options] - Optional HTTP request options.
   * @returns {Observable<any>} An observable that emits the fetched image data.
   */
  getImgUrl(url: string, options?: any): Observable<any> {
    return this.http.get(url, options).pipe(
      catchError((err) => err)
    );
  }

  /**
   * Exports the dashboard level data to an Excel file.
   * This method creates a new Excel workbook and iterates over the provided widget layout components,
   * exporting each widget's data to the workbook. Once all widget data is added, the workbook is 
   * downloaded as an Excel file with the specified file name.
   */
  exportExcelForDashboardLevel(imagebase64: any, widgetLayoutComponents: any, fileName: string) {
    const workbook = new Excel.Workbook();
    widgetLayoutComponents.forEach((widgetLayoutComponent: any) => {
      this.exportExcelForWidgetLevel(imagebase64, widgetLayoutComponent, widgetLayoutComponent?.widgetObj?.name, 'dashboard', workbook);
    });

    // Download the workbook after all widget Level data is added
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}.xlsx`;
      a.click();
    });
  }

  /**
   * Exports the chart data for a widget to an Excel file.
   * @param imagebase64 - The base64 encoded image of the chart.
   * @param widgetLayoutComponent - The component containing the widget's layout and data.
   * @param fileName - The name of the file to be exported.
   */
  exportExcelForWidgetLevel(imagebase64: any, widgetLayoutComponent: any, fileName: string, from?: string, workbook?: any) {
    const chartData = widgetLayoutComponent?.transformedChartData ?? [];
    const widgetObj = widgetLayoutComponent?.widgetObj;
    const chartType = widgetObj?.chartConfig?.chartType;
    if (TimeSeriesChartTypes.includes(chartType)) {
      this.exportTimeSeriesChartData(imagebase64, chartData, widgetObj, fileName, from, workbook);
    } else if (pieDonutSunBurstCharts.includes(chartType)) {
      this.exportPieDonutOrSunburstChartData(imagebase64, chartData, widgetObj, fileName, from, workbook);
    } else if (chartType == ExportChartTypes.GAUGE) {
      this.exportGaugeChartData(imagebase64, chartData, widgetObj, fileName, from, workbook);
    }
  }

  /**
   * Exports time series chart data to an Excel file.
   * This function creates an Excel workbook with the provided chart data and adds a logo image to it.
   * It supports both combined and separate chart views. The generated Excel file is then downloaded
   * with the specified file name.
   */
  exportTimeSeriesChartData(imagebase64: any, chartData: any, widgetObj: any, fileName: string, from?: string, dashboardWorkbook?: any) {
    const fromPartner = environment.partner;
    const viewType = widgetObj?.chartConfig?.viewType;
    const chartType = widgetObj?.chartConfig?.chartType;
    let rowsData;

    //Creating workbook with sheetname as fileName
    const workbook = dashboardWorkbook || new Excel.Workbook();
    let uniqueName = widgetObj?.name;
    //Appending _1,2,3... if the sheetname already exists
    let counter = 1;
    while (workbook.getWorksheet(uniqueName)) {
      uniqueName = `${widgetObj?.name}_${counter}`;
      counter++;
    }
    const worksheet = workbook.addWorksheet(uniqueName);
    let currentColumn = 0;
    let startRow:number;

    for (let i = 0; i < 3; i++) {
      worksheet.addRow([]);
    };

    //Adding this note only for Box Plot chart
    if(chartType == ExportChartTypes.BOX_PLOT) {
      const noteRow = worksheet.getRow(4);
      noteRow.getCell(1).value = "Note: Values are arranged min, Q1, medium, Q3, max";
      noteRow.getCell(1).font = {
        size: 12,
        name: 'Calibri',
      };
      noteRow.getCell(1).alignment = {
        vertical: 'middle',
        horizontal: 'left',
      };
    }

    if (chartData && chartData?.length > 0) {
      if (viewType == CHART_PLOT_OPTIONS.COMBINE) {
        // If its a combine chart
        startRow = (chartType == ExportChartTypes.BOX_PLOT) ? 6 : 4;
        if (chartType == ExportChartTypes.BOX_PLOT) {
          rowsData = this.getRowsForBoxPlotChartData(chartData[0], widgetObj);
        } else if (chartType == ExportChartTypes.TERRAIN) {
          rowsData = this.getRowsForTerrainChartData(chartData[0], widgetObj);
        } else {
          rowsData = this.getRowsForTimeSeriesChartData(chartData[0], widgetObj);
        }
        this.addDataToWorksheet(worksheet, rowsData, 0, startRow, chartType);
      } else {
        // If its a separate charts
        startRow = (chartType == ExportChartTypes.BOX_PLOT) ? 7 : 5;
        let rowsData;
        chartData.forEach((chart: any) => {
          if (chartType == ExportChartTypes.BOX_PLOT) {
            rowsData = this.getRowsForBoxPlotChartData(chart, widgetObj);
          } else if (chartType == ExportChartTypes.TERRAIN) {
            rowsData = this.getRowsForTerrainChartData(chart, widgetObj);
          } else {
            rowsData = this.getRowsForTimeSeriesChartData(chart, widgetObj);
          }
          if (rowsData) {
            this.addDataToWorksheet(worksheet, rowsData, currentColumn, startRow, chartType);

            // Add the site name before the table row
            const siteNameRow = worksheet.getRow(startRow - 1);
            siteNameRow.getCell(currentColumn + 1).value = (chartType == ExportChartTypes.TERRAIN) ? chart[0]?.siteName : chart?.siteName;
            siteNameRow.getCell(currentColumn + 1).font = {
              bold: true,
              size: 14,
              name: 'Lato',
            };
            siteNameRow.getCell(currentColumn + 1).alignment = {
              vertical: 'middle',
              horizontal: 'left',
            };
            // Add 1 column space after each table
            currentColumn += rowsData.rowsHeader.length + 1;
          }
        });
      }
    }

    // Add logo image
    let logoImage = workbook.addImage({
      base64: imagebase64,
      extension: 'svg',
    });
    const options = fromPartner
      ? { tl: { col: 0, row: 0 }, ext: { width: 751, height: 45 } }
      : { tl: { col: 0, row: 0 }, ext: { width: 120, height: 45 } };
    worksheet.addImage(logoImage, options);

    //Downloads the workbook if its a widget level export
    if (from != 'dashboard') {
      workbook.xlsx.writeBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.xlsx`;
        a.click();
      });
    }
  }

  /**
   * Processes chart data and widget data to generate rows for chart export.
   **/
  getRowsForTimeSeriesChartData(chartData: any, widgetData:any) {
    let rowsHeader: any = [];
    let rows: any = [];
    
    if (chartData?.series?.length == 0) {
      return
    }
    rowsHeader.push('Date | Time');

    // Sort the chartData[0].series list based on parameter name
    chartData.series.sort((a: any, b: any) => {
      const nameA = a.defaultNameForExports || '';
      const nameB = b.defaultNameForExports || '';
      return nameA.localeCompare(nameB);
    });

    //Updating header row values 
    chartData.series.forEach((point: any) => {
      if (point.unit) {
        rowsHeader.push(`${point.defaultNameForExports} (${point.unit})`);   //Taking the tooltip name of the first data point + unit
      } else {
        rowsHeader.push(point.defaultNameForExports);
      }
    });
    
    if (chartData?.series?.length > 0) {
      // calculating index based the axis column
      const timeStampIndex = widgetData?.chartConfig?.axis?.xaxis?.column === 'time' ? 0 : 1;
      const valueIndex = widgetData?.chartConfig?.axis?.xaxis?.column === 'time' ? 1 : 0;
      
      const timestamps = chartData.series[0].data.map((dataPoint: any) => dataPoint[timeStampIndex]);
      timestamps.forEach((timestamp: string, index: number) => {
        let row: any = [];
        // update timestamp to local time and update as the first column
        timestamp = this.convertUTCTimeTOTimeZone(timestamp);
        row.push(timestamp);
        chartData.series.forEach((series: any) => {
          const dataValue = series.data[index]?.[valueIndex] != null ? Number(series.data[index]?.[valueIndex]) : '-';
          row.push(dataValue);
        });
        rows.push(row);
      });
    }

    return {
      rowsHeader,
      rows
    }
  }

  // Method to get the Get the rows for terrain chart data
  getRowsForTerrainChartData(chartData: any, widgetData: any) {
    let rowsHeader: any = [];
    let rows: any = [];
    let repeatedSitenames = widgetData?.data[0]?.entityData.map((entity: any) => entity.siteName);
    let siteNames = Array.from(new Set(repeatedSitenames));
    if (chartData?.series?.length == 0) {
      return
    }
    rowsHeader.push('Date | Time');

    if (chartData && chartData.length > 0) {
      let axisArr = ['xaxis', 'yaxis', 'zaxis'];
      let axisValueArr = ['xAxis3D', 'yAxis3D', 'zAxis3D'];
      const timeAxisKey = this.findAxisKeyByColumn('time', widgetData?.chartConfig[chartData[0]?.parameterName]?.axis);
      let timeIndex = axisArr.indexOf(timeAxisKey);
      let timeKey = axisValueArr[timeIndex];
      const timestamps = chartData[0]?.chartOptions?.[timeKey].data;

      //Updating header row values
      chartData.map((data: any) => data?.chartOptions?.series?.map((series: any) => {
        if (series.unit) {
          rowsHeader.push(`${series.defaultNameForExports} (${series.unit})`);
        } else {
          rowsHeader.push(series.defaultNameForExports);
        }
      }));

      const zerothElement = rowsHeader[0];
      const sortedData = rowsHeader.slice(1).sort((a:any, b:any) => {
        const siteA = siteNames.findIndex(site => a.startsWith(site));
        const siteB = siteNames.findIndex(site => b.startsWith(site));
        return siteA - siteB;
      });

      rowsHeader = [zerothElement, ...sortedData];

      timestamps.forEach((timestamp: string, index: number) => {
        let row: any = [];
        // update timestamp to local time and update as the first column
        timestamp = this.convertUTCTimeTOTimeZone(timestamp);
        row.push(timestamp);
        chartData.forEach((parameter: any) => {
          const valueAxisKey = this.findAxisKeyByColumn('value', widgetData?.chartConfig[parameter?.parameterName]?.axis);
          let valueIndex = axisArr.indexOf(valueAxisKey);
          //updating rows values
          parameter?.chartOptions?.series?.forEach((series: any) => {
            const dataValue = series.data[index]?.[valueIndex] != null ? Number(series.data[index]?.[valueIndex]) : '-';
            row.push(dataValue);
          });
        });
        rows.push(row);
      });
    }

    return {
      rowsHeader,
      rows
    }
  }

  findAxisKeyByColumn(targetColumn: string, axisConfig: any) {
    for (const key in axisConfig) {
      if (axisConfig[key]?.column === targetColumn) {
        return key;
      }
    }
    return 'No matching key found';
  }


   /**
   * Processes chart data and widget data to generate rows for box-plot chart export.
   **/
   getRowsForBoxPlotChartData(chartData: any, widgetData:any) {
    let rowsHeader: any = [];
    let rows: any = [];
    
    if (chartData?.series?.length == 0) {
      return
    }
    rowsHeader.push('Date | Time');

    // Sort the chartData[0].series list based on parameter name
    chartData.series.sort((a: any, b: any) => {
      const nameA = a.defaultNameForExports || '';
      const nameB = b.defaultNameForExports || '';
      return nameA.localeCompare(nameB);
    });

    //Updating header row values 
    chartData.series.forEach((point: any) => {
      if (point.unit) {
        rowsHeader.push(`${point.defaultNameForExports} (${point.unit})`);   //Taking the tooltip name of the first data point + unit
      } else {
        rowsHeader.push(point.defaultNameForExports);
      }
    });
    
    if (chartData?.series?.length > 0) {
      // calculating index based the axis column      
      const timestamps = chartData.xAxis.data.map((timestamp: any) => timestamp);
      timestamps.forEach((timestamp: string, index: number) => {
        let row: any = [];
        // update timestamp to local time and update as the first column
        timestamp = this.convertUTCTimeTOTimeZone(timestamp);
        row.push(timestamp);
        chartData.series.forEach((series: any) => {
          const dataValues = [0, 1, 2, 3, 4]
            .map((i) => (series.data[index]?.[i] != null ? Number(series.data[index][i]) : '-'))
            .join(', ');
          row.push(dataValues);
        });
        rows.push(row);
      });
    }

    return {
      rowsHeader,
      rows
    }
  }
  /**
   * Adds data to a worksheet starting at the specified column and row.
   * @remarks
   * - Headers are styled with a bold font, centered alignment, and a background color.
   * - Data rows are styled with a default font and left alignment.
   * - Alternate rows are given a different background color for better readability.
   * - Row height is dynamically adjusted based on the length of the text and column width.
   */
  addDataToWorksheet(worksheet: any, rowsData: any, startColumn: number, startRow: number, from?: string) {
    const { rowsHeader, rows } = rowsData;
    // Add headers starting at specified row and column
    rowsHeader.forEach((header: string, colIndex: number) => {
      worksheet.getCell(startRow, startColumn + colIndex + 1).value = header;
      worksheet.getCell(startRow, startColumn + colIndex + 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '888888' },
      };
      worksheet.getCell(startRow, startColumn + colIndex + 1).font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11,
        name: 'Lato Regular',
      };
      worksheet.getCell(startRow, startColumn + colIndex + 1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      };
    });

    // Set column width based on the header content length - Dynamic width for headers
    const maxAllowedWidth = 40; // Maximum allowed width for a column
    rowsData.rowsHeader.forEach((header: string, colIndex: number) => {
      if (colIndex == 0) {
        worksheet.getColumn(startColumn + colIndex + 1).width = 30;
      } else {
        const calculatedWidth = Math.min(header.length + 2, maxAllowedWidth);
        worksheet.getColumn(startColumn + colIndex + 1).width = (from == ExportChartTypes.BOX_PLOT) ? calculatedWidth + 10 : calculatedWidth + 2;
      }
    });

    //Updating the width of first column (Entity) based on the maximum length of the first column data
    if (from == ExportChartTypes.PIE || ExportChartTypes.DONUT || from == ExportChartTypes.SUNBURST) {
      let maxFirstColumnLength = 30;
      rowsData.rows.forEach((row: any) => {
        const firstColumnData = row[0] || ''; // Assuming first column is at index 0
        maxFirstColumnLength = Math.max(maxFirstColumnLength, firstColumnData.toString().length);
      });
      maxFirstColumnLength = (ExportChartTypes.PIE || ExportChartTypes.DONUT) ? maxFirstColumnLength + 2 : maxFirstColumnLength + 5;
      worksheet.getColumn(startColumn + 1).width = maxFirstColumnLength + 2;
    }
    
    if (from == ExportChartTypes.GAUGE) {
      rowsData.rowsHeader.forEach((header: string, colIndex: number) => {
        const calculatedWidth = Math.min(header.length + 2, maxAllowedWidth);
        worksheet.getColumn(startColumn + colIndex + 1).width = calculatedWidth + 2;
      });
    }
  
    // Add data rows
    rows.forEach((row: any, rowIndex: number) => {
      row.forEach((cell: any, colIndex: number) => {
        const targetCell = worksheet.getCell(startRow + rowIndex + 1, startColumn + colIndex + 1);
        targetCell.value = cell;
        targetCell.font = {
          name: 'Lato Regular',
          size: 12,
        };
        targetCell.alignment = {
          vertical: 'middle',
          horizontal: 'left',
          indent: 1,
        };
        // Alternate row styling
        if ((startRow + rowIndex + 1) % 2 === 0) {
          targetCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'F2F2F2' },
          };
        }
      });
    });
  }

  /**
   * Converts a given UTC date to the browser's local time zone.
   * @param date - The UTC date to be converted.
   * @returns The date formatted as a string in the browser's local time zone.
   */
  convertUTCTimeTOTimeZone(date: any) {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedDate = dayjs(date).tz(browserTimezone).format('YYYY-MM-DDTHH:mm:ss')
    return formattedDate;
  }

  /**
   * Exports pie or donut chart data to an Excel file.
   */
  exportPieDonutOrSunburstChartData(imagebase64: any, chartData: any, widgetObj: any, fileName: string, from?: string, dashboardWorkbook?: any) {
    const fromPartner = environment.partner;
    const viewType = widgetObj?.chartConfig?.viewType;
    const dataRange = (widgetObj?.startDateTime && widgetObj?.endDateTime)
      ? `Date Range: ${widgetObj.startDateTime.format('MMMM D, YYYY')} - ${widgetObj.endDateTime.format('MMMM D, YYYY')}`
      : '';
    let rowsData;
    const chartType = widgetObj?.chartConfig?.chartType;
    //Creating workbook with sheetname as fileName
    const workbook = dashboardWorkbook || new Excel.Workbook();
    let uniqueName = widgetObj?.name;
    //Appending _1,2,3... if the sheetname already exists
    let counter = 1;
    while (workbook.getWorksheet(uniqueName)) {
      uniqueName = `${widgetObj?.name}_${counter}`;
      counter++;
    }
    const worksheet = workbook.addWorksheet(uniqueName);
    let currentColumn = 0;
    let startRow = 6;

    for (let i = 0; i < 3; i++) {
      worksheet.addRow([]);
    };

    if (chartData && chartData.length > 0) {
      if (viewType == CHART_PLOT_OPTIONS.COMBINE) {
        // If its a combine chart
        rowsData = (chartType == ExportChartTypes.SUNBURST ? this.getRowsForSunburstChartData(chartData[0]) : this.getRowsForPieDonutChartData(chartData[0]));
        this.addDataToWorksheet(worksheet, rowsData, 0, startRow, chartType);
      } else {
        startRow = 7;
        chartData.forEach((chart: any) => {
          const rowsData = (chartType == ExportChartTypes.SUNBURST ? this.getRowsForSunburstChartData(chart) : this.getRowsForPieDonutChartData(chart));
          if (rowsData) {
            this.addDataToWorksheet(worksheet, rowsData, currentColumn, startRow, chartType);

            // Add the site name before the table row
            const siteNameRow = worksheet.getRow(startRow - 1);
            siteNameRow.getCell(currentColumn + 1).value = (chartType == ExportChartTypes.SUNBURST ? chart[0]?.siteName : chart?.siteName);
            siteNameRow.getCell(currentColumn + 1).font = {
              bold: true,
              size: 14,
              name: 'Lato',
            };
            siteNameRow.getCell(currentColumn + 1).alignment = {
              vertical: 'middle',
              horizontal: 'left',
            };
            // Add 1 column space after each table
            currentColumn += rowsData.rowsHeader.length + 1;
          }
        });
      }
    }

    // Add the Date Range before the table row
    const dateRangeRow = worksheet.getRow(4);
    dateRangeRow.getCell(1).value = dataRange;
    dateRangeRow.getCell(1).font = {
      size: 12,
      name: 'Lato Regular',
    };
    dateRangeRow.getCell(1).alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };

    // Add logo image
    let logoImage = workbook.addImage({
      base64: imagebase64,
      extension: 'svg',
    });
    const options = fromPartner
      ? { tl: { col: 0, row: 0 }, ext: { width: 751, height: 45 } }
      : { tl: { col: 0, row: 0 }, ext: { width: 120, height: 45 } };
    worksheet.addImage(logoImage, options);

    //Downloads the workbook if its a widget level export
    if (from != 'dashboard') {
      workbook.xlsx.writeBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.xlsx`;
        a.click();
      });
    }
  }

  /**
   * Exports gauge chart data to an Excel workbook.
   * @param imagebase64 - The base64 string of the logo image to be added to the workbook.
   * @param chartData - The data to be exported to the Excel sheet.
   * @param widgetObj - The widget object containing chart configuration and other details.
   * @param fileName - The name of the file to be downloaded.
   * @param from - Optional parameter to specify the source of the export (e.g., 'dashboard').
   * @param dashboardWorkbook - Optional parameter to provide an existing workbook to which the data will be added.
   */
  exportGaugeChartData(imagebase64: any, chartData: any, widgetObj: any, fileName: string, from?: string, dashboardWorkbook?: any) {
    const fromPartner = environment.partner;
    const viewType = widgetObj?.chartConfig?.viewType;
    let rowsData;
    const chartType = widgetObj?.chartConfig?.chartType;
    //Creating workbook with sheetname as fileName
    const workbook = dashboardWorkbook || new Excel.Workbook();
    let uniqueName = widgetObj?.name;
    //Appending _1,2,3... if the sheetname already exists
    let counter = 1;
    while (workbook.getWorksheet(uniqueName)) {
      uniqueName = `${widgetObj?.name}_${counter}`;
      counter++;
    }
    const worksheet = workbook.addWorksheet(uniqueName);
    let currentColumn = 0;
    let startRow = 6;

    if (chartData && chartData.length > 0) {
      if (viewType == CHART_PLOT_OPTIONS.COMBINE) {
        rowsData = this.getRowsForGaugeChartData(chartData[0]);
        this.addDataToWorksheet(worksheet, rowsData, 0, startRow, chartType);
        // Add the site name before the table row
        const siteNameRow = worksheet.getRow(startRow - 1);
        const siteNamesArray = chartData[0].map((data: any) => data.siteNames);
        const siteNames = siteNamesArray?.length ? Array.from(new Set(siteNamesArray.flat())).join(', ') : '';
        siteNameRow.getCell(currentColumn + 1).value = "Site List: " + siteNames;
        siteNameRow.getCell(currentColumn + 1).font = {
          bold: false,
          size: 12,
          name: 'Lato Regular',
        };
        siteNameRow.getCell(currentColumn + 1).alignment = {
          vertical: 'middle',
          horizontal: 'left',
        };
      } else {
        startRow = 7;
        chartData.forEach((chart: any) => {
          const rowsData = this.getRowsForGaugeChartData(chart);
          if (rowsData) {
            this.addDataToWorksheet(worksheet, rowsData, currentColumn, startRow, chartType);

            // Add the site name before the table row
            const siteNameRow = worksheet.getRow(startRow - 1);
            siteNameRow.getCell(currentColumn + 1).value = chart[0]?.siteName;
            siteNameRow.getCell(currentColumn + 1).font = {
              bold: true,
              size: 14,
              name: 'Lato',
            };
            siteNameRow.getCell(currentColumn + 1).alignment = {
              vertical: 'middle',
              horizontal: 'left',
            };
            // Add 1 column space after each table
            currentColumn += rowsData.rowsHeader.length + 1;
          }
        });
      }
    }

    // Add logo image
    let logoImage = workbook.addImage({
      base64: imagebase64,
      extension: 'svg',
    });
    const options = fromPartner
      ? { tl: { col: 0, row: 0 }, ext: { width: 751, height: 45 } }
      : { tl: { col: 0, row: 0 }, ext: { width: 120, height: 45 } };
    worksheet.addImage(logoImage, options);

    //Downloads the workbook if its a widget level export
    if (from != 'dashboard') {
      workbook.xlsx.writeBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.xlsx`;
        a.click();
      });
    }
  }

  getRowsForSunburstChartData(chartData: any) {
    let rowsHeader: any = [];
    let rows: any = [];
    let groupedData = new Map<string, any>();
    let unit:string;
    let siteNames = chartData[0].chartOptions?.series?.data?.map((data: any) => data.name);

    // Initialize the header row with 'Entity'
    rowsHeader.push('Entity');

    chartData.forEach((parameter: any) => {
      parameter?.chartOptions?.series?.data.forEach((hierarchyData: any) => {
        unit = hierarchyData?.unit?.value;
        this.traverseHierarchy(hierarchyData, '', groupedData, parameter.parameterName);
      });
      const headerName = unit ? `${parameter.parameterName} (${unit})` : parameter.parameterName;
      rowsHeader.push(headerName);
    });

    groupedData.forEach((values, entityName) => {
      const row: any[] = [entityName];
      rowsHeader.slice(1).forEach((header: any) => {
        const paramName = header.includes('(') ? header.split(' (')[0] : header;
        row.push(values[paramName] != undefined ? values[paramName] : '-');
      });
      rows.push(row);
    });

    rows?.sort((a:any, b:any) => {
      const siteA = siteNames.findIndex((site:string) => a[0].startsWith(site));
      const siteB = siteNames.findIndex((site:string) => b[0].startsWith(site));
      return siteA - siteB;
    });

    return {
      rowsHeader,
      rows
    };
  }


  traverseHierarchy(entity: any, parentName: string = '', groupedData: any, parameterName: string) {
    // Ensure the entity is valid and has valueData
    if (entity && entity.valueData !== undefined && entity.valueData !== null) {
      const name = parentName ? `${parentName} - ${entity.name}` : entity.name;
      const value = entity.valueData ? entity.valueData : '-';
      if (!groupedData.has(name)) {
        groupedData.set(name, {});
      }
      groupedData.get(name)[parameterName] = value;
    }

    // If entity has children, recurse for each child, ensuring they are valid
    if (entity && entity.children && Array.isArray(entity.children) && entity.children.length > 0) {
      entity.children.forEach((child: any) => {
        if (child && child.name && child.valueData !== undefined && child.valueData !== null) {
          this.traverseHierarchy(child, `${parentName ? parentName + ' - ' : ''}${entity.name}`, groupedData, parameterName);
        }
      });
    }

    return groupedData;
  }

  /**
   * Processes the given chart data to generate rows for a Pie/Donut chart.
   * 1. Initializes the header row with 'Entity'.
   * 2. Calculates the total sum of all values in the series data.
   * 3. Updates the header row with unique parameter names.
   * 4. Groups the series data by entity.
   * 5. Constructs rows for each entity, including the value and its percentage of the total.
   */
  getRowsForPieDonutChartData(chartData: any) {
    let rowsHeader: any = [];
    let rows: any = [];
    const parameterNames = new Set<string>();

    // Initialize the header row with 'Entity'
    rowsHeader.push('Entity');

    const seriesData = chartData?.series[0]?.data ?? [];

    if (seriesData.length == 0) {
      return;
    }
    // Calculate the total sum of all values (this will be used to calculate percentages)
    const totalValue = seriesData.reduce((sum: number, point: any) => sum + Number(point.value || 0), 0);

    // Updating header row values with unique param names
    seriesData.forEach((point: any) => {
      const paramNameWithUnit = point.unit ? `${point.paramName} (${point.unit})` : point.paramName;
      parameterNames.add(paramNameWithUnit);
    });
    rowsHeader.push(...Array.from(parameterNames));

    if (seriesData.length > 0) {
      const groupedByEntity = new Map<string, any[]>();

      // Group seriesData by defaultNameForExports (entity)
      seriesData.forEach((point: any) => {
        const entityName = point.defaultNameForExports || '';
        if (!groupedByEntity.has(entityName)) {
          groupedByEntity.set(entityName, []);
        }
        groupedByEntity.get(entityName)?.push(point);
      });

      // Construct rows for each entity
      groupedByEntity.forEach((points, entityName) => {
        const row = [entityName];
        rowsHeader.slice(1).forEach((header: string) => {
          // Extract paramName from the header
          const paramName = header.includes('(') ? header.split(' (')[0] : header;
          const matchingPoint = points.find(p => p.paramName === paramName);

          //Adding value with %
          if (matchingPoint) {
            const percentage = totalValue > 0 ? ((matchingPoint.value / totalValue) * 100).toFixed(2) : '0';
            row.push(`${matchingPoint.value} (${percentage}%)`);
          } else {
            row.push('-');
          }
        });
        rows.push(row);
      });
    }

    return {
      rowsHeader,
      rows
    };
  }

  /**
   * Processes the provided chart data to extract rows for a gauge chart.
   * @returns {{ rowsHeader: any, rows: any }} An object containing the headers and rows for the gauge chart.
   * - `rowsHeader`: An array of strings representing the headers for each parameter, including units if available.
   * - `rows`: A 2D array where each sub-array represents a row of data values for the gauge chart.
   */
  getRowsForGaugeChartData(chartData: any) {
    let rowsHeader: any = [];
    let rows: any = [];

    if (chartData && chartData.length > 0) {
      rowsHeader = chartData.map((data: any) => data.parameterName + (data?.unit ? ` (${data?.unit})` : ''));
      rows = [chartData.map((data: any) => data.chartOptions?.series[0]?.data[0]?.value ?? '-')];
    }

    return {
      rowsHeader,
      rows
    }
  }

  createChartHeader(text: string): HTMLElement {
    const chartheader = document.createElement('div');
    chartheader.textContent = text; // Set the provided text
  
    // Apply styles
    chartheader.style.width = '100%'; // Ensure it takes the full width
    chartheader.style.display = 'flex'; // Ensure it behaves as a flex element
    chartheader.style.fontSize = '14px';
    chartheader.style.lineHeight = '16px';
    chartheader.style.fontWeight = '600';
    chartheader.style.height = '16px';
    chartheader.style.color = '#333333'; // Text color
    chartheader.style.fontFamily = 'Lato, sans-serif'; // Font family
    chartheader.style.alignContent = 'center'; // Center the text vertically
  
    return chartheader; // Return the fully styled element
  }

  createStyledDashboardNameElement(dashboardName: string): HTMLElement {
    const textElement = document.createElement('div');
    textElement.textContent = dashboardName ?? 'Default Dashboard'; // Fallback to default text
  
    // Apply styles
    textElement.style.fontSize = '16px';
    textElement.style.fontWeight = '600';
    textElement.style.paddingLeft = '32px'; // Match header's left padding
    textElement.style.display = 'block'; // Ensure it behaves as a block element
    textElement.style.color = '#333333'; // Text color
    textElement.style.fontFamily = 'Lato, sans-serif'; // Font family
    textElement.style.marginBottom = '5px'; // Add spacing between the header and the dashboard name
  
    return textElement; // Return the fully styled element
  }

  createHeader(isPartner: Boolean): HTMLElement {
    // Create the header
    const header = document.createElement('div');
    header.style.height = '46px';
    header.style.backgroundColor = '#ffffff'; // Background color for the header
    header.style.display = 'flex';
    header.style.alignItems = 'center';
    header.style.justifyContent = 'space-between'; // Ensure logos are at opposite ends
    header.style.padding = '0 32px'; // Add padding on both sides
    header.style.width = '100%'; // Ensure it takes the full width
  
    // Create the primary logo element (left side)
    const logo = document.createElement('img');
    logo.src = './assets/images/chart-header-logo.svg';
    logo.alt = 'Chart Header Logo';
    logo.style.height = '15px';
    logo.style.width = 'auto';
  
    // Append the primary logo to the header (left side)
    header.appendChild(logo);
  
    // Check if isPartner is true and add the extra logo (right side)
    if (isPartner) {
      const partnerLogo = document.createElement('img');
      partnerLogo.src = './assets/images/poweredbylogo.svg'; // Replace with the actual partner logo path
      partnerLogo.alt = 'Partner Logo';
      partnerLogo.style.height = '10px';
      partnerLogo.style.width = 'auto';
  
      // Add the partner logo to the header (right side)
      header.appendChild(partnerLogo);
    }
  
    return header; // Return the complete header
  }
  
}
