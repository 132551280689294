import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from "../_services/configuration.service";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
    providedIn: 'root'
})

export class SiteService {
    
    bearer_token: string = '';

    constructor(private http: HttpClient,
        private localStorageService: LocalStorageService,
        private configService: ConfigurationService
    ) {
        this.bearer_token = this.localStorageService.bearer_token;
    }

    getSiteDetails(siteRefs:any): Observable<any> {
        let data = `ver:"3.0"\nid`;
        siteRefs.forEach((siteRef:any) => {
            data += `\n@${siteRef}`;
        });
        return this.http.post(`${this.configService.getConfig('tagsUrl')}`, data).pipe(catchError(this.handleError));
    }

    getHaystackDataByQuery(query:string) {
        const data = `ver:"3.0"\nfilter\n"${query}"`
        return this.http.post(`${this.configService.getConfig('haystackUrl')}v1/read/`, data).pipe(catchError(this.handleError));
    }

    stripHaystackTypeMapping(response: any) {
        return (response) ? JSON.parse(JSON.stringify(response).replace(/"r:|"n:|"t:|"b:|"m:|"z:|"s:/g, '"')) : '';
    }

    private handleError(error: any, _wrapperParameters:any): Promise<any> {
        console.error('An error occurred', error);
        return of([]).toPromise();
    }
}