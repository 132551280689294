<div class="chart-builder-form"  (click)="closeSwatch($event)">
    <form [formGroup]="chartForm">
        <div class="d-flex flex-column">
            <!-- Top Header portion -->
            <div class="flex-head">
                <div class="d-flex row justify-content-between align-self-center">
                    <div class="col-3 d-flex align-items-center">
                        <div class="display-chart-name limit-chart-name" matTooltip="{{chartName}}" matTooltipPosition="above"
                            [matTooltipClass]="'chart-name-tooltip'" *ngIf="!editChartName">{{chartName}}</div>
                        <mat-form-field *ngIf="editChartName" class="no-input">
                            <mat-label></mat-label>
                            <input required maxlength="35" matInput type="text" trim="blur" id="widgetNameInputField"
                                class="chart-name" formControlName="name" autocomplete="off"
                                [(ngModel)]="chartName" placeholder="Enter Widget name" (focusout)="editChartName = false" [readonly]="isSharedByOthers"/>
                        </mat-form-field>
                        <i class="fas fa-pen btn-icon color-primary" matSuffix aria-hidden="true" (click)="editChartName =!editChartName" [ngClass]="{'no-display':isSharedByOthers}"></i>
                    </div>
                    <div *ngIf="!isSharedByOthers" class="share-btn-group d-flex justify-content-end col-9 p-r-20">
                        <div *ngFor="let option of shareMenuOptions" class="p-r-17">
                            <button class="btns btnstyle"
                                [ngClass]="{'shared-btn': option?.value == 'shared', 'personal-btn': option?.value == 'personal', 'active':shareAccessType == option?.value, 'position-active': shareAccessType == 'personal' && shareAccessType == option?.value}"
                                id="shared-btn- + {{option?.value}}"
                                (click)="onWidgetChange(option)" value="{{option?.value}}"
                                [matMenuTriggerFor]="sharedMenu">
                                {{option?.label}}
                            </button>
                        </div>
                    </div>
                    <div *ngIf="isSharedByOthers && !showChartConfigView" class="btn-group d-flex justify-content-end col-9 owner-details-div"
                        [ngClass]="{'all-pointer-events':isSharedByOthers}">
                        <span class="created-by"><b>Created by: </b></span> <span class="owner-details"
                            matTooltip="{{ownerDetails}}" matTooltipPosition="above"
                            [matTooltipClass]="'pointnametooltip'">{{ ownerDetails}}</span>
                        <span class="last-updated-text"><b>Last updated: </b></span> <span
                            class="last-updated-by">{{formatDate(data?.widgetData?.lastModifiedDateTime)}}</span>
                    </div>
                </div>
            </div>
            
            <!-- Content -->
            <div class="flex-body relative">
                <ng-container *ngIf="!showBlocklyView && !showChartConfigView">
                    <!-- Error message for title -->
                    <ng-container>
                        <div *ngIf="!chartForm.get('name')?.hasError('forbiddenName') && chartForm.get('name')?.hasError('pattern')" id="chart-custom-name-error"
                            class="row col-12 p-l-25 error-txt">
                            Special characters are not allowed in title. Allowed characters[hyphen(-), underscore(_),
                            space(),
                            percentage(%), hash(#)].
                        </div>
                        <div id="chart-custom-name-error1" *ngIf="chartForm.get('name')?.hasError('forbiddenName')"
                            class="row col-12 p-l-25 error-txt">
                            Title cannot start with special characters.
                        </div>
                        <div id="chart-custom-name-error2" class="row col-12 p-l-25 error-txt"
                            *ngIf="chartForm.get('name')?.hasError('maxlength')">
                            Title must be at most 35 characters long.
                        </div>
                    </ng-container>


                    <div class="row">
                        <!-- Left Content -->
                        <div class="col-3 p-l-0 p-r-0 custom-col-left">
                            <div class="col-12 chart-side-bar-border p-l-24 p-r-0">
                                <div class="chart-side-bar-configuration"[ngClass]="{'chart-side-bar-configuration-readOnly':isSharedByOthers}">
                                    <label class="lbl">Select Build Type</label>
                                    <div class="p-l-0 p-t-5" id="chart-buildType" [ngClass]="{'no-pointer-events':isSharedByOthers}">
                                        <div class="builder-type btn-group d-flex">
                                            <ng-container *ngFor="let option of buildTypeOptions; let first = first; let last = last;">
                                                <button class="btn w-100 level-btn-width" (click)="onBuildTypeSelect(option)"
                                                    [disabled]='isChartBuilderTypeChanged'
                                                    [ngClass]="{'btn-outline-read-only color-primary read-only-cursor': (builderType === (option?.label) && isSharedByOthers), 'btn-outline-primary color-primary no-pointer-events': (builderType === (option?.label) && !isSharedByOthers), 'btnGroup-leftstyle': first, 'btnGroup-rightstyle': last}"
                                                    value="{{option?.value}}" id="{{'customParameterOptionBtn_' + option?.value}}">
                                                    {{option?.label}}
                                                </button>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <ng-template *ngIf="builderType && showscopeChangeWarning && !isChartBuilderTypeChanged"
                                        [ngTemplateOutlet]="warningTplscope" [ngTemplateOutletContext]="{type: 'Build Type'}"></ng-template>
                        
                                    <div [ngClass]="{'background-blur-overlay': showscopeChangeWarning}">
                                        <!-- custom query section -->
                                        <ng-container *ngIf="builderType == 'CUSTOM'">
                                            <div class="lbl p-t-10" id="chart-custom-query-label">Type in your query</div>
                                            <div class="p-t-5">
                                                <textarea #queryElem class="query-textarea" autofocus spellcheck="false"
                                                    [(ngModel)]="haystackQuery" (ngModelChange)="onCustomHaystackQueryEdited()"
                                                    placeholder="Enter your query here" [ngModelOptions]="{standalone: true}"
                                                    id="chart-query-text-area">
                                                                    </textarea>
                                            </div>
                        
                                            <div class="execute-query-btn-sec">
                                                <button class="text-uppercase excute-query-button"
                                                    [ngClass]="{'disabled': !haystackQuery?.length}" (click)="executeCustomQuery(haystackQuery)"
                                                    id="chart-execute-query-button">
                                                    <span class="cursor-pointer" *ngIf="!fetchingPoints">Execute
                                                        Query</span>
                                                    <span *ngIf="fetchingPoints">Executing ...</span>
                                                </button>
                                            </div>
                        
                                        </ng-container>
                        
                                        <!-- Builder select tag section -->
                                        <ng-container *ngIf="builderType == 'BUILDER'">
                                            <div class="p-t-12 p-b-12 point-selection-container" id="charts-select-tags-section" [ngClass]="{'no-pointer-events':isSharedByOthers}">
                                                <label class="lbl">Select Tags</label>
                                                <p class="sub-lable m-b-10">Tags will only filter parameters with the 'point' tag</p>
                                                <ng-select class="custom-select-point" [ngClass]="{'update-line-height': !selectedTags?.length}"
                                                    (focus)="onInputFocus()" (change)="onInputBlur()" (blur)="onInputBlur()" [items]="tagList"
                                                    color="primary" [multiple]="true" #tagSelectChart [(ngModel)]="selectedTags"
                                                    (remove)="onTagDeSelect($event)" (clear)="onTagsClear()" (change)="onTagsChange($event)"
                                                    [searchFn]="searchTag" addTagText="" [clearOnBackspace]="true" [markFirst]="markFirst()"
                                                    dropdownPosition="bottom" [placeholder]="isSharedByOthers ? '' : 'Search For Tags'"
                                                    [clearSearchOnAdd]="false" formControlName="tags" id="tags-dropdown">
                                                </ng-select>
                                            </div>
                                        </ng-container>
                
                                        <!-- Builder matching points section -->
                                        <ng-container>
                                            <div class="lbl">Matching Points</div>
                                            <div class="sub-lable">Hover on parameters to view the tags</div>
                                            <div id="charts-matching-points"
                                                [ngClass]="{'all-pointer-events': isSharedByOthers, 'custom-matching-pts-ht': builderType == 'CUSTOM', 'builder-matching-pts-ht': builderType == 'BUILDER'}">
                                                <div class="m-t-6 builder-matching-pts-container"
                                                    *ngIf="selectedTags.length || builderType == 'CUSTOM'"
                                                    id="charts-matching-points-container">
                                                    <div *ngFor="let point of points" id="charts-pointContainer_{{point?.id}}">
                                                        <div class="point-description bgclor" (mouseover)="pointHover($event, point)"
                                                            (mouseout)="pointHoverMouseOut(point)">
                                                            {{point?.displayName}}
                                                            <div *ngIf="point.showTag && showTagTooltip" [ngStyle]="toolTipPosition()"
                                                                class="tags-popup d-flex flex-wrap" id="charts-tagsPopup_{{point?.id}}">
                                                                <div *ngFor="let tag of point.tags; let last = last;"
                                                                    id="charts-tag_{{point?.pointId}}_{{tag?.pointId}}">
                                                                    <div class="query-tag-text" *ngIf="tag.isQueryTag">
                                                                        {{tag.tagName}}<span *ngIf="!last">,&nbsp;
                                                                        </span>
                                                                    </div>
                                                                    <div class="nonQueryTag" *ngIf="!tag.isQueryTag">
                                                                        {{tag.tagName}}<span *ngIf="!last">,&nbsp;</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="!points?.length && emptyLabel != ''" id="charts-empty-points-label">
                                                        <div class="point-description">{{emptyLabel}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Right Content -->
                        <div class="col-9 p-l-0 p-r-0 custom-col-right" [ngClass]="{'background-blur-overlay': showscopeChangeWarning}">
                            <div class="builder-middle-content">
                                <div>
                                    <app-accordion [blockTitle]="'Description'" [isOpen]="true">
                                        <div>
                                            <div class="w-100  label-area"><label class="description-label"><b>Why</b>
                                                    ({{whyDescription?.length}}/1000)</label></div>
                                            <div class="description-area">
                                                <textarea class="text-description" maxlength="1000" autofocus spellcheck="false" [(ngModel)]="whyDescription" required
                                                    formControlName="why" placeholder="Type in description here" id="widget-why-description" 
                                                    [ngClass]="{'read-only-text read-only-cursor': isSharedByOthers}" [disabled]="isSharedByOthers">
                                                </textarea>
                                            </div>
                                            <div class="w-100 "><label class="description-label"><b>What</b>
                                                    ({{whatDescription?.length}}/1000)</label></div>
                                            <div class="description-area">
                                                <textarea class="text-description" maxlength="1000" autofocus spellcheck="false" [(ngModel)]="whatDescription" required
                                                    formControlName="what" placeholder="Type in description here" id="widget-what-description" 
                                                    [ngClass]="{'read-only-text read-only-cursor': isSharedByOthers}" [disabled]="isSharedByOthers">
                                                </textarea>
                                            </div>
                                        </div>
                                    </app-accordion>
                                </div>

                                <div class="parameter-options d-flex">
                                    <span class="parameter-title">Parameters</span>
                                    <div>
                                        <button class="button-cta options-btn" 
                                            id="widget-add-parameter-cta"
                                            *ngIf="!isSharedByOthers"
                                            [disabled]="pointDefs.value.length == parameterCount"
                                            (click)="addNewPoint()" 
                                            [matTooltip]="pointDefs.value.length != parameterCount ? 'You can add ' + (parameterCount - pointDefs.value.length) + ' more parameters' : 'You can add up to 10 parameters only'"
                                            [matTooltipClass]="'chart-parameter-count-tooltip'"
                                            [matTooltipPosition]="'above'" >
                                            ADD PARAMETERS</button>
                                        <span class="seperator" *ngIf="!isSharedByOthers">|</span>
                                        <button class="button-cta options-btn"
                                         *ngIf="!isSharedByOthers"
                                         [matTooltip]="pointDefs.value.length == 0 ? '' : (pointDefs.value.length != parameterCount ? 'You can add ' + (parameterCount - pointDefs.value.length) + ' more parameters' : 'You can add up to 10 parameters only')"
                                         [matTooltipClass]="'chart-parameter-count-tooltip'"
                                         [matTooltipPosition]="'above'"
                                         (click)="addNewCalculatedParameter()"
                                         [disabled]="(!pointDefs.value.length || pointDefs.value.length == parameterCount)"
                                         id="widget-add-cal-parameter-cta">ADD CALCULATED PARAMETERS</button>
                                        <span class="seperator" *ngIf="!isSharedByOthers">|</span>
                                        <button class="button-cta options-btn" id="widget-add-chart-cta" 
                                        (click)="openChartConfigView()" [disabled]="!pointDefs.value.length || checkIfFormIsEmpty()">{{ data?.isEdit || isSharedByOthers ? 'VIEW CHART' : 'ADD CHART' }}</button>
                                    </div>
                                </div>
                                <ng-container>
                                <div class="parameter-container m-t-9" *ngIf="!isLoading">
                                    <div class="list-none ulwidth m-l-15" [ngClass]="{'all-pointer-events':isSharedByOthers}">
                                        <div class="list m-b-12" [ngClass]="{'no-pointer-events':isSharedByOthers}"
                                            *ngFor="let point of pointDefs?.controls;let i = index" [formGroup]="point">
                                            <div class="col-11 row nowrap">
                                                <div class="parameters d-flex flex-column">
                                                    <div class="justify-content-between d-flex">
                                                        <div class="d-flex">
                                                            <label class="parameter-label" [ngClass]="{'all-pointer-events':isSharedByOthers}">
                                                                <mat-radio-group class="builder-radio-group"
                                                                    (change)="changeSelectedPoint(point?.controls['name']?.value,i)">
                                                                    <mat-radio-button *ngIf="!point?.controls['isDerived'].value"
                                                                        value="{{point?.controls['name']?.value}}" color="primary" class=""
                                                                        id="chart-point-radio-input-{{i}}" checked="{{selectedPointIndex == i}}">
                                                                    </mat-radio-button>
                                                                    <mat-radio-button *ngIf="point?.controls['isDerived'].value"
                                                                        value="{{point?.controls['name']?.value}}" color="primary" class=""
                                                                        id="chart-point-radio-input-{{i}}" checked="point?.controls['isDerived'].value"
                                                                        [disabled]="point?.controls['isDerived'].value">
                                                                    </mat-radio-button>
                                                                </mat-radio-group>
                                                                <div *ngIf="!disablePointNameFieldConditionally[i]?.disable && disablePointNameFieldConditionally[i]"
                                                                    class="edit-input-name" [ngClass]="{'read-only-input':isSharedByOthers}"
                                                                    id="charts-point-border-{{i}}">
                                                                    <input required minlength="1" maxlength="100" trim="blur"
                                                                        matInput type="text"
                                                                        class="width400 border-bottom-update" formControlName="name"
                                                                        autocomplete="off" [ngClass]="{'no-pointer-events':isSharedByOthers}"
                                                                        placeholder="Enter title" id="charts-point-input-{{i}}"
                                                                        [readOnly]="disablePointNameFieldConditionally[i]?.disable"
                                                                        (focusout)="editPointName(i,true,point?.controls['name']?.value)" />
                                                                </div>
                                                                <div class="edit-input-name display-input-name"
                                                                    matTooltip="" showTooltipOnOverflow
                                                                    [matTooltipClass]="'parameter-name-tooltip'" [matTooltipPosition]="'above'"
                                                                    *ngIf="!disablePointNameFieldConditionally[i] || disablePointNameFieldConditionally[i]?.disable">
                                                                    {{point?.controls['name']?.value}}
                                                                </div>
                                                            </label>
                                                            <div class="d-flex parameter-icons p-l-6">
                                                                <span>
                                                                    <i class="fa fa-pen btn-icon iconcolor p-r-5 p-l-5 m-r-4 align-self-center"
                                                                        aria-hidden="true"
                                                                        (click)="editPointName(i,false,point?.controls['name']?.value)"
                                                                        [ngClass]="{'no-display':isSharedByOthers}"></i>
                                                                    <i (click)="clickedCalulatedParameter(point)"
                                                                        [ngClass]="{'all-pointer-events': isSharedByOthers,'icon-disable-state': !point?.controls['name']?.value.length && point?.controls['isDerived'].value }"
                                                                        class="fa fa-solid fa-calculator p-r-6 p-l-6 m-r-4 btn-icon iconcolor align-self-center"
                                                                        *ngIf="point?.controls['isDerived'].value">
                                                                    </i>
                                                                    <i class="fa fa fa-trash btn-icon iconcolor p-l-6 p-r-6 align-self-center"
                                                                        aria-hidden="true" (click)="deletePoint(i)"
                                                                        [ngClass]="{'no-display':isSharedByOthers}"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="chart-parameter-name-required-error" *ngIf="point?.controls['name'].hasError('required')"
                                                        class="parameter-error-txt"
                                                        [ngClass]="{'error-text': point?.controls['name'].hasError('required')}">
                                                        Parameter name is required.
                                                    </div>
                                                    <div id="chart-parameter-name-error"
                                                        *ngIf="!point?.controls['name'].hasError('forbiddenName') && point?.controls['name'].hasError('pattern')"
                                                        class="parameter-error-txt"
                                                        [ngClass]="{'error-text' : !point?.controls['name'].hasError('forbiddenName') && point?.controls['name'].hasError('pattern') }">
                                                        Special characters are not allowed. Allowed
                                                        characters[hyphen(-), underscore(_),
                                                        space(),
                                                        percentage(%), hash(#), comma(,)].
                                                    </div>
                                                    <div id="chart-custom-name-error1" *ngIf="point?.controls['name'].hasError('forbiddenName')"
                                                        class="parameter-error-txt"
                                                        [ngClass]="{'error-text' : point?.controls['name'].hasError('forbiddenName') }">
                                                        Name cannot start with special characters.
                                                    </div>
                                                    <div id="circular-dependency-error"
                                                        *ngIf="point?.controls['isDerived'].value &&  point?.controls['isDerived'].hasError('circularDependency')"
                                                        class="parameter-error-txt"
                                                        [ngClass]="{'error-text' : point?.controls['isDerived'].value &&  point?.controls['isDerived'].hasError('circularDependency')}">
                                                        The derived parameter has the circular dependency.
                                                    </div>
                                                    <div id="circular-dependency-error"
                                                        *ngIf="point?.controls['isDerived'].value && point?.controls['isDerived'].hasError('updateRule')"
                                                        class="parameter-error-txt"
                                                        [ngClass]="{'error-text' : point?.controls['isDerived'].value && point?.controls['isDerived'].hasError('updateRule')}">
                                                        One or more parameters have been updated or removed.
                                                        Please update the rule accordingly.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </ng-container>
                                <div class="m-t-10" id="widget-builder-skeleton-loader" *ngIf="isLoading">
                                        <ngx-skeleton-loader count="8" appearance="line" animation="progress"
                                            [theme]="{height: '20px', margin: '5px 0', 'background-color': '#eff0f1'}"></ngx-skeleton-loader>
                                </div>
                            </div>
                            <div class="action-buttons m-b-4" *ngIf="!isLoading">
                                <button *ngIf="data?.isEdit && !isDuplicate && !isSharedByOthers"
                                (click)="confirmDelete(data?.widgetData)"
                                    [ngClass]="{'all-pointer-events':isSharedByOthers}"
                                    class=" btn btn-outline-primary color-primary text-uppercase delete-btn">Delete</button>
                                <button *ngIf="!isSharedByOthers"
                                    class="btn btn-outline-primary color-primary text-uppercase action-btn"
                                    id="widget-save-chart-cta"
                                    (click)="saveWidget(false)"
                                    [disabled]="pointDefs?.value?.length == 0 || pointDefs?.controls?.length == 0  || chartName?.length == 0 ||  checkIfFormIsEmpty() || chartForm?.invalid || apiCallInitiated ||!isChartAndBuilderConfigValid()">
                                    {{data?.isEdit ? 'CREATE AS A NEW CHART' : 'SAVE CHART'}}</button>
                                <button *ngIf="data?.isEdit && !isSharedByOthers && !isDuplicate"
                                    class=" btn btn-outline-primary color-primary text-uppercase action-btn"
                                    (click)="saveWidget(data?.isEdit)"
                                    [disabled]="pointDefs?.value?.length == 0 || pointDefs?.controls?.length == 0  || chartName?.length == 0   || checkIfFormIsEmpty() || chartForm?.invalid || apiCallInitiated || !isChartAndBuilderConfigValid() || checkWidgetDataChanges()"
                                    cdkFocusInitial>
                                    UPDATE CHART</button>
                                <button *ngIf="!isSharedByOthers" (click)="cancelDialog()"
                                    id="widget-cancel-chart-cta"
                                    [ngClass]="{'all-pointer-events':isSharedByOthers}"
                                    class=" btn btn-outline-primary color-primary text-uppercase action-btn">CANCEL</button>
                                <button *ngIf="isSharedByOthers && !isUserRestricted"
                                    [ngClass]="{'all-pointer-events':isSharedByOthers}"
                                    class="btn btn-outline-primary color-primary text-uppercase analytics-btn action-btn"
                                    (click)="duplicateChart()">
                                    DUPLICATE CHART</button>
                                <button *ngIf="isSharedByOthers" (click)="cancelDialog()" [ngClass]="{'all-pointer-events':isSharedByOthers}"
                                    class=" btn btn-outline-primary color-primary text-uppercase action-btn">CLOSE</button>
                            </div>
                            
                        </div>


                    </div>
                </ng-container>
                <ng-container *ngIf="!showBlocklyView && !showChartConfigView">
                    <div id="widget-builder-error-msg" class="warning-msg-text" *ngIf="warningMsg != ''" [ngClass]="{'warning-msg-spacing':!data?.isEdit}">
                        {{warningMsg}}
                    </div>
                </ng-container>
                <ng-container *ngIf="showChartConfigView">
                    <div class="row">
                        <!-- Left Content -->
                        <div class="col-3 p-l-0 p-r-0 custom-col-left">
                            <div class="col-12 chart-side-bar-border p-l-24 p-r-0">
                                <div class="chart-side-bar-configuration">
                                    <div class="chart-selection-container" [ngClass]="{'no-pointer-events disabled-state': isSharedByOthers}">
                                        <label class="lbl">Select Chart Type</label>
                                        <mat-form-field class="chart-selection-dropdown" [floatLabel]="'never'" [ngClass]="{ 'chart-selection-value': selectedChartType }">
                                            <mat-select #chartSelect [value]="selectedChartType" disableOptionCentering panelClass="chart-custom-select-panel" placeholder="Select Chart" id="chartType_selection_dropdown" (selectionChange)="onChartSelectionChange($event)">
                                                <!-- Custom trigger for selected value with image-->
                                                <mat-select-trigger class="chart-custom-select-trigger">
                                                    <ng-container *ngIf="selectedChartType">
                                                        <img [src]="'assets/images/' + getChartImage(selectedChartType)" alt="{{ selectedChartType }} icon" class="selected-chart-icon chart-icon"/>{{ selectedChartType }}
                                                    </ng-container>
                                                    <ng-container *ngIf="!selectedChartType">
                                                        Select Chart Type
                                                    </ng-container>
                                                </mat-select-trigger>
                                    
                                                <mat-option *ngFor="let chart of chartTypes" [value]="chart.name" class="chart-option">
                                                    <img [src]="'assets/images/' + chart.image" alt="{{ chart.name }} icon" class="chart-icon"/>
                                                    {{ chart.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <ng-template *ngIf="showChartChangeWarning"
                                        [ngTemplateOutlet]="warningTplscope" [ngTemplateOutletContext]="{type: 'Chart Type'}"></ng-template>
                                    <div [ngClass]="{'background-blur-overlay': showChartChangeWarning}" *ngIf="selectedChartType">
                                        <div class="chart-viewtype-selection-container">
                                            <label class="lbl">Select View Type</label>
                                            <div class="p-l-0 p-t-5" id="chart-viewType" [ngClass]="{'no-pointer-events':isSharedByOthers}">
                                                <div class="builder-type btn-group d-flex">
                                                    <ng-container *ngFor="let option of viewTypeOptions; let first = first; let last = last;">
                                                        <button class="btn w-100 level-btn-width" (click)="onChartViewTypeSelect(option)"
                                                            [ngClass]="{'btn-outline-read-only color-primary read-only-cursor': (chartViewType === (option?.value) && isSharedByOthers), 'btn-outline-primary color-primary': (chartViewType === (option?.value) && !isSharedByOthers), 'btnGroup-leftstyle': first, 'btnGroup-rightstyle': last}"
                                                            value="{{option?.value}}" id="{{'chartViewOptionBtn_' + option?.value}}">
                                                            {{option?.label}}
                                                        </button>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="chart-viewBy-selection-container" [ngClass]="{'no-pointer-events disabled-state':isSharedByOthers}" *ngIf="!hideViewBy">
                                            <label class="lbl">Select View By</label>
                                            <mat-form-field class="chart-selection-dropdown" [floatLabel]="'never'">
                                                <mat-select [value]="selectedChartViewBy" disableOptionCentering panelClass="chart-custom-select-panel" (selectionChange)="onChartViewBySelectionChange($event)"
                                                    placeholder="Select View By" id="chartViewBy_selection_dropdown">
                                                    <mat-option *ngFor="let viewType of chartViewTypes" [value]="viewType.value" class="chart-option">
                                                        {{ viewType.name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="chart-config-parameter-section" *ngIf="this.pointDefs.controls?.length">
                                            <div class="paramter-header-section d-flex m-b-10">
                                                <label class="lbl">Parameters</label>
                                                <div *ngIf="isPieOrDonutOrSunburstChart() && !showSunBurstChart"  class="m-b-5" [ngClass]="{'no-pointer-events': isSharedByOthers}">
                                                    <app-gradient-swatch (colorSelectionChange)=colorSelectionChange($event) [selectedGroup]="selectedGradientGroup"></app-gradient-swatch>
                                                </div>
                                                <div *ngIf="showSunBurstChart" class="m-b-5 no-pointer-events">
                                                    <div class="sunburst-gradient-box"></div>
                                                </div>
                                            </div>

                                            <div class="chart-config-parameter-list-container">
                                                <div class="chart-config-parameter-list d-flex" *ngFor="let point of this.pointDefs.controls;let i = index">
                                                    <div *ngIf="!showRadioBtnParameter" class="chart-config-parameter-name">
                                                        <span matTooltip="" showTooltipOnOverflow [matTooltipPosition]="'above'" [matTooltipClass]="'chartConfig-parameter-name-tooltip'">{{point.value?.name}}</span>
                                                        <i class="fas fa-calculator calc-icon" *ngIf="point.value?.isDerived"></i>
                                                    </div>

                                                    <div *ngIf="showRadioBtnParameter" class="chart-config-parameter-name">
                                                        <mat-radio-group class="chart-config-parameter-name-radio-group" (change)="changeSelectedParam(point?.value?.name, i)">
                                                            <mat-radio-button [value]="point.value?.name" name="terrainRadioGroup" checked="{{selectedParamIndex == i}}">
                                                            <span class="parameter-radio-name" matTooltip="" showTooltipOnOverflow [matTooltipPosition]="'above'"
                                                                [matTooltipClass]="'chartConfig-parameter-name-tooltip'">{{point.value?.name}}</span>
                                                            </mat-radio-button>
                                                            <i class="fas fa-calculator calc-icon" *ngIf="point.value?.isDerived"></i>
                                                        </mat-radio-group>
                                                    </div>
                                                    
                                                    <div class="chart-config-parameter-icons" clickOutside [ignore]="classlist">
                                                        <img src="assets/images/aggregation.svg" alt="aggregation " width="26px" height="26px" class="m-r-4" [matMenuTriggerFor]="!isSharedByOthers? chartAggregationMenu: null" class="chart-config-icons" 
                                                        [matTooltip]="'Aggregate By: ' + point.value?.aggregateBy | titlecase" matTooltipPosition="above" [matTooltipClass]="'chart-config-icons-tooltip'">
                                                        <mat-menu #chartAggregationMenu="matMenu" class="chart-aggregation-panel" xPosition="before" id="chart-aggregation-mainMenu">
                                                            <button *ngFor="let type of getChartAggregationTypes(point)" (click)="UpdateAggregationType(i,type.value)" mat-menu-item id="aggregation-options-button" class="sub-menu-btn">
                                                                <span>{{type.name}}</span>
                                                            </button>
                                                        </mat-menu>

                                                        <img *ngIf="showRightLeftAxisSelection" [src]="selectedXaxisValue == 'time' ? 'assets/images/yaxis.svg' : 'assets/images/xaxis.svg'" alt="aggregation " width="26px" height="26px" class="m-r-4" class="chart-config-icons" [matMenuTriggerFor]="!isSharedByOthers? chartAxisMenu: null"
                                                        [matTooltip]="getAxisTooltip(point.value?.axisPosition)" matTooltipPosition="above" [matTooltipClass]="'chart-config-icons-tooltip'">
                                                        <mat-menu #chartAxisMenu="matMenu" class="chart-aggregation-panel" xPosition="before" id="chart-aggregation-mainMenu">
                                                            <button *ngFor="let type of chartAxisTypes" (click)="UpdateAxisPosition(i,type.value)" mat-menu-item id="axis-options-button" class="sub-menu-btn">
                                                                <span>{{type.name}}</span>
                                                            </button>
                                                        </mat-menu>

                                                        <div class="eye-icon-section" [ngClass]="{'eye-icon-margin':showForTerrain}">
                                                            <i [ngClass]="{'fa-eye': point.value?.showVisualisation, 'fa-eye-slash': !point.value?.showVisualisation, 'color-disabled no-pointer-events' : isSharedByOthers}"
                                                                class="fas color-primary" (click)="updateshowVisualisation(i, !point.value?.showVisualisation)"
                                                                [matTooltip]="(point.value?.showVisualisation ? 'Show' : 'Hide') + ' on Dashboard'" matTooltipPosition="above" [matTooltipClass]="'chart-config-eye-icon-tooltip'">
                                                            </i>
                                                        </div>
                                                        <div *ngIf="showParameterColorSelection" class="selected-color-box m-l-12" (click)="showColorSwatchBox($event,i)" [ngClass]="{'no-pointer-events': isSharedByOthers}"
                                                            [ngStyle]="{'background-color' : point?.value.color}">
                                                        </div>
                                                        <div *ngIf="showForTerrain" class="m-l-12" [ngClass]="{'no-pointer-events': isSharedByOthers}">
                                                            <app-gradient-swatch (colorSelectionChange)="colorSelectionChange($event, point.value?.name, i)" [selectedGroup]="point?.value.color"></app-gradient-swatch>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <!-- right Content -->
                        <div class="col-9 p-l-0 p-r-0 custom-col-right" [ngClass]="{'background-blur-overlay': showChartChangeWarning}">
                            <div class="builder-config-middle-content" [ngClass]="{'no-pointer-events disabled-state':isSharedByOthers}">
                                <ng-container *ngIf="selectedChartType">
                                    <app-accordion [blockTitle]="'Chart Advanced Settings'" [isOpen]="true" [ngClass]="{'all-pointer-events':isSharedByOthers}">
                                        <!-- Advance options for line, dotted, bar, area charts -->
                                        <ng-container *ngIf="!showGaugeChart">
                                            <div [ngClass]="{'no-pointer-events':isSharedByOthers}">
                                                <div class="chart-axis-properties-container" [ngClass]="{'chart-terrain-axis-properties': showForTerrain}">
                                                    <div *ngIf="showAxisPosition" class="chart-axis-position-selection-container">
                                                        <label class="lbl">Axis Position</label>
                                                        <div class="chart-axis-containers">
                                                            <div class="chart-xaxis-selection-container">
                                                                <label class="lbl">X Axis</label>
                                                                <mat-form-field class="chart-selection-dropdown-config" [floatLabel]="'never'">
                                                                    <mat-select [value]="selectedXaxisValue" disableOptionCentering
                                                                        panelClass="chart-custom-select-panel"
                                                                        (selectionChange)="onXaxisSelectionChange($event)" placeholder="Date & Time"
                                                                        id="chartconfig_xaxis_selection_dropdown">
                                                                        <mat-option *ngFor="let axis of axisValues" [value]="axis.value" class="chart-option">
                                                                            {{ axis.name }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="chart-yaxis-selection-container" [ngClass]="{'chart-axis-right': showForTerrain}">
                                                                <label class="lbl">Y Axis</label>
                                                                <mat-form-field class="chart-selection-dropdown-config" [floatLabel]="'never'">
                                                                    <mat-select [value]="selectedYaxisValue" disableOptionCentering
                                                                        panelClass="chart-custom-select-panel"
                                                                        (selectionChange)="onYaxisSelectionChange($event)" placeholder="Value"
                                                                        id="chartconfig_yaxis_selection_dropdown">
                                                                        <mat-option *ngFor="let axis of axisValues" [value]="axis.value" class="chart-option">
                                                                            {{ axis.name }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                            <div *ngIf="showForTerrain" class="chart-zaxis-selection-container">
                                                                <label class="lbl">Z Axis</label>
                                                                <mat-form-field class="chart-selection-dropdown-config" [floatLabel]="'never'">
                                                                    <mat-select [value]="selectedZaxisValue" disableOptionCentering
                                                                        panelClass="chart-custom-select-panel"
                                                                        (selectionChange)="onZaxisSelectionChange($event)" placeholder="Value"
                                                                        id="chartconfig_zaxis_selection_dropdown">
                                                                        <mat-option *ngFor="let axis of axisValues" [value]="axis.value" class="chart-option">
                                                                            {{ axis.name }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div [ngClass]="{'chart-axis-scope-container': showForTerrain}">
                                                        <div *ngIf="showAxisScale" class="chart-axis-scale-selection-container" [ngClass]="{'chart-axis-scale-container-terrain': showForTerrain}">
                                                            <label class="lbl">Axis Scale</label>
                                                            <div class="chart-axis-scale">
                                                                <div *ngFor="let axis of axes; let i = index; let last = last" class="chart-axis-options"
                                                                    [ngClass]="{'no-pointer-events': disableAxisOptions}">
                                                                    <div class="column">
                                                                        <label class="row axis-label">{{getAxisName(axes[i].name, 'min')}}</label>
                                                                        <input class="row axis-value" [ngClass]="{'warning-axis': validateAxes(axes[i], axes)}"
                                                                            type="number" id="{{ axes[i].name }}-minimum" [(ngModel)]="axes[i].min"
                                                                            [ngModelOptions]="{standalone: true}" placeholder="Auto"
                                                                            (keydown)="restrictInput($event)" (input)="enforceNumericInput($event, axes[i], 'min')"
                                                                            (click)="$event.stopPropagation()">
                                                                    </div>
                                                                    <div class="column">
                                                                        <label class="row axis-label">{{getAxisName(axes[i].name, 'max')}}</label>
                                                                        <input class="row axis-value" [ngClass]="{'warning-axis': validateAxes(axes[i], axes)}"
                                                                            type="number" id="{{ axes[i].name }}-maximum" [(ngModel)]="axes[i].max"
                                                                            [ngModelOptions]="{standalone: true}" placeholder="Auto"
                                                                            (keydown)="restrictInput($event)" (input)="enforceNumericInput($event, axes[i], 'max')"
                                                                            (click)="$event.stopPropagation()">
                                                                    </div>
                                                                </div>
                                                                <button class="btn-icon axis-reset-button" (click)="resetValues(axes)"
                                                                    [ngClass]="{'color-disabled': isSharedByOthers}" [disabled]="!disableAxisResetButton()">
                                                                    <i class="fa fa-undo" aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                            <div *ngIf="showWarning" class="warning-text">
                                                                Minimum value must be less than maximum
                                                            </div>
                                                        </div>
                                                        <div *ngIf="showForTerrain" class="chart-tooltip-configuration-properties chart-axis-scale-container-terrain">
                                                            <label class="lbl">Scope Name Configuration</label>
                                                            <div class="chart-tooltip-configuration-containers">
                                                                <div class="chart-tooltip-format-container">
                                                                    <label class="lbl">Name Format</label>
                                                                    <mat-form-field class="chart-selection-dropdown-config" [floatLabel]="'never'">
                                                                        <mat-select [(value)]="selectedNameFormatValue" multiple disableOptionCentering
                                                                            panelClass="chart-custom-select-panel-config"
                                                                            (selectionChange)="onNameFormatSelectionChange($event)" placeholder="Value"
                                                                            id="tooltip_format_dropdown">
                                                                            <mat-select-trigger class="chart-custom-select-trigger">
                                                                                <ng-container *ngIf="selectedNameFormatDisplayValue">
                                                                                    {{ selectedNameFormatDisplayValue }}
                                                                                </ng-container>
                                                                            </mat-select-trigger>
                                                                            <mat-option *ngFor="let formatValue of displayFormatNameValues; let i = index"
                                                                                [value]="formatValue.value" [disabled]="i === displayFormatNameValues.length - 1"
                                                                                class="chart-option chart-multi-drop-down">
                                                                                {{ formatValue.name }}
                                                                            </mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div *ngIf="showAxisLabel" class="chart-axis-labels">
                                                    <label class="lbl">Axis Label</label>
                                                    <div class="chart-axis-labels-container">
                                                        <div class="column chart-xaxis-label-container" [ngClass]="{'chart-axis-lable-width': showForTerrain}">
                                                            <label class="lbl">X Axis Label</label>
                                                            <div>
                                                                <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="xAxisLabel" maxlength="30"
                                                                    class="chart-xaxis-label" [ngClass]="{'chart-axis-lable-width': showForTerrain}" placeholder="Type in label (Optional)"
                                                                    (ngModelChange)="updateAxisLable()" />
                                                            </div>
                                                        </div>
                                                        <div class="column chart-yaxis-label-container" [ngClass]="{'chart-axis-lable-width': showForTerrain}">
                                                            <label class="lbl">Y Axis Label</label>
                                                            <div>
                                                                <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="yAxisLabel" maxlength="30"
                                                                    class="chart-yaxis-label" [ngClass]="{'chart-axis-lable-width': showForTerrain}" placeholder="Type in label(Optional)"
                                                                    (ngModelChange)="updateAxisLable()" />
                                                            </div>
                                                        </div>
                                                        <div *ngIf="showForTerrain" class="column chart-zaxis-label-container" [ngClass]="{'chart-axis-lable-width': showForTerrain}">
                                                            <label class="lbl">Z Axis Label</label>
                                                            <div>
                                                                <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="zAxisLabel" maxlength="30"
                                                                    class="chart-zaxis-label" [ngClass]="{'chart-axis-lable-width': showForTerrain}" placeholder="Type in label(Optional)"
                                                                    (ngModelChange)="updateAxisLable()" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="showToolTipConfig" class="chart-tooltip-configuration-properties">
                                                    <label class="lbl">Tooltip Configuration</label>
                                                    <div class="chart-tooltip-configuration-containers">
                                                        <div *ngIf="showToolTipSorting" class="chart-tooltip-sorting-container">
                                                            <label class="lbl">Sorting</label>
                                                            <mat-form-field class="chart-selection-dropdown-config" [floatLabel]="'never'">
                                                                <mat-select [(value)]="selectedSortingValue" disableOptionCentering
                                                                    panelClass="chart-custom-select-panel"
                                                                    (selectionChange)="onChartSortBySelectionChange($event)" id="tooltip_sorting_dropdown">
                                                                    <mat-option *ngFor="let sortValue of sortingValues" [value]="sortValue.value"
                                                                        class="chart-option chart-multi-drop-down">
                                                                        {{ sortValue.name }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div *ngIf="showToolTipFormat" class="chart-tooltip-format-container">
                                                            <label class="lbl">Tooltip Format</label>
                                                            <mat-form-field class="chart-selection-dropdown-config" [floatLabel]="'never'">
                                                                <mat-select [(value)]="selectedFormatValue" multiple disableOptionCentering
                                                                    panelClass="chart-custom-select-panel-config"
                                                                    (selectionChange)="onFormatSelectionChange($event)" placeholder="Value"
                                                                    id="tooltip_format_dropdown">
                                                                    <mat-select-trigger class="chart-custom-select-trigger">
                                                                        <ng-container *ngIf="selectedFormatDisplayValue">
                                                                            {{ selectedFormatDisplayValue }}
                                                                        </ng-container>
                                                                    </mat-select-trigger>
                                                                    <mat-option *ngFor="let formatValue of displayFormatValues; let i = index"
                                                                        [value]="formatValue.value" [disabled]="i === displayFormatValues.length - 1"
                                                                        class="chart-option chart-multi-drop-down">
                                                                        {{ formatValue.name }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="piedonut-chart-configuration-containers" [ngClass]="{'no-pointer-events':isSharedByOthers, 'p-b-16': showSunBurstChart}">
                                                <div *ngIf="showChartLabel" class="chart-axis-labels">
                                                    <div class="chart-axis-labels-container">
                                                        <div class="column chart-xaxis-label-container">
                                                            <label class="lbl">Chart Label</label>
                                                            <div class="input-container">
                                                                <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="pieDonutChartLable" [maxlength]="30"
                                                                    class="chart-xaxis-label width-225" [placeholder]="showSunBurstChart ? 'Type in label (Optional)' :'Type in label'"
                                                                    (ngModelChange)="updatePieDonutChartLable()" id="piedonut_chart_label"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="showPieToolTipLabel" class="chart-tooltip-format-container">
                                                    <label class="lbl">Tooltip Format</label>
                                                    <mat-form-field class="chart-selection-dropdown-config" [floatLabel]="'never'" id="piedont_tooltip_selection">
                                                        <mat-select [(value)]="selectedFormatValue" multiple disableOptionCentering
                                                            panelClass="chart-custom-select-panel-config" (selectionChange)="onFormatSelectionChange($event)"
                                                            placeholder="Value" id="tooltip_format_dropdown">
                                                            <mat-select-trigger class="chart-custom-select-trigger">
                                                                <ng-container *ngIf="selectedFormatDisplayValue">
                                                                    {{ selectedFormatDisplayValue }}
                                                                </ng-container>
                                                            </mat-select-trigger>
                                                            <mat-option *ngFor="let formatValue of displayFormatValues; let i = index"
                                                                [value]="formatValue.value" [disabled]="i === displayFormatValues.length - 1"
                                                                class="chart-option chart-multi-drop-down">
                                                                {{ formatValue.name }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <!-- Configurations for Gauge chart -->
                                        <ng-container *ngIf="showGaugeChart">
                                            <div class="p-r-5 m-b-16" [ngClass]="{'no-pointer-events':isSharedByOthers}">
                                                <div class="chart-axis-properties-container">
                                                    <div class="chart-axis-position-selection-container">
                                                        <label class="lbl">Chart Angle</label>
                                                        <div class="chart-angle-containers m-r-18">
                                                            <ng-container *ngTemplateOutlet="gaugeAngleSelectionTemplate; context: { label: 'Start Angle', filteredAngles: filteredStartAngles, angleType: 'start' }"></ng-container>
                                                            <ng-container *ngTemplateOutlet="gaugeAngleSelectionTemplate; context: { label: 'End Angle', filteredAngles: filteredEndAngles, angleType: 'end' }"></ng-container>
                                                            <div *ngIf="gaugeAngleConfigError" class="warning-text gauge-warning-text">
                                                                Start and End angles should not be same
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="chart-axis-scale-selection-container">
                                                        <label class="lbl">Scale</label>
                                                        <div class="chart-axis-scale">
                                                            <div class="chart-axis-options" [ngClass]="{'no-pointer-events': isSharedByOthers}">
                                                                <div class="column">
                                                                    <label class="row axis-label">Min Value</label>
                                                                    <input class="row axis-value" [ngClass]="{ 'warning-axis': validateGaugeMinMax() }" type="number"
                                                                        id="gauge-min-value" [(ngModel)]="minValue" [ngModelOptions]="{standalone: true}"
                                                                        placeholder="Value" (keydown)="restrictInput($event)"
                                                                        (input)="updateMinMaxForGauge()">
                                                                </div>
                                                                <div class="column">
                                                                    <label class="row axis-label">Max Value</label>
                                                                    <input class="row axis-value" [ngClass]="{ 'warning-axis': validateGaugeMinMax() }" type="number"
                                                                        id="gauge-max-value" [(ngModel)]="maxValue" [ngModelOptions]="{standalone: true}"
                                                                        placeholder="Value" (keydown)="restrictInput($event)"
                                                                        (input)="updateMinMaxForGauge()">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="gaugeMinMaxError" class="warning-text gauge-warning-text">
                                                            Minimum value must be less than maximum
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- conditions sections for gauge chart -->
                                                <div class="gauge-conditions-container">
                                                    <div class="conditions-lables-div d-flex">
                                                        <label class="lbl">Conditions</label>
                                                        <div class="icon-section">
                                                            <div class="selected-color-box m-r-8" (click)="showColorSwatchBoxForGauge($event, null)"
                                                                *ngIf="!pointDefs?.controls?.[selectedParamIndex]?.controls['conditions']?.value.length"
                                                                [ngClass]="{'no-pointer-events': isSharedByOthers}" [ngStyle]="{'background-color' : chartConfig[selectedParamName]?.['defaultColor'] }">
                                                            </div>
                                                            <button class="condition-icon-btn" (click)="addConditions()"
                                                                [matTooltip]="pointDefs?.controls?.[selectedParamIndex]?.controls['noCondition']?.value? '' :pointDefs?.controls?.[selectedParamIndex]?.controls['conditions']?.value.length != gaugeConditionsMax ? 'You can add ' + (gaugeConditionsMax - pointDefs?.controls?.[selectedParamIndex]?.controls['conditions']?.value.length) + ' more conditions' : 'You can add up to ' +gaugeConditionsMax+  ' conditions only'"
                                                                [matTooltipClass]="'gauge-condition-count-tooltip'" [matTooltipPosition]="'above'"
                                                                [disabled]="pointDefs?.controls?.[selectedParamIndex]?.controls['noCondition']?.value || pointDefs?.controls?.[selectedParamIndex]?.controls['conditions']?.value.length == gaugeConditionsMax">
                                                                <i class="fa fa-plus plus-icon" aria-hidden="true"
                                                                    [ngClass]="{'disable-condition-icon': pointDefs?.controls?.[selectedParamIndex]?.controls['noCondition']?.value || pointDefs?.controls?.[selectedParamIndex]?.controls['conditions']?.value.length == gaugeConditionsMax}"></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div class="conditions-list-container">
                                                        <ng-container *ngIf="pointDefs?.controls?.[selectedParamIndex] && pointDefs?.controls?.[selectedParamIndex]?.controls['conditions']?.value.length > 0">
                                                            <div class="d-flex align-items-center m-t-2" *ngFor="let control of getCondition();let ind=index" [formGroup]="control">   
                                                                <label class="m-r-12 lbl">Is</label>
                                                                <div class="m-r-12 conditions-dropdown-container">
                                                                    <mat-form-field class="chart-selection-dropdown condition-dropdown">
                                                                        <mat-select class="conditions_dropdown_value" disableOptionCentering="true" [floatLabel]="'never'"
                                                                            formControlName="comparisonOperator"
                                                                            panelClass="chart-custom-select-panel">
                                                                            <mat-option *ngFor="let operator of operators" [value]="operator.key">
                                                                                {{operator.value}}</mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                </div>
                                                                
                                                                <div class="m-r-12" *ngIf="control.controls['comparisonOperator'].value != 'IN_BETWEEN'" required>
                                                                    <input class="value-input-field" id="condition-value" placeholder="Select Value" formControlName="value"
                                                                        type="number" (keydown)="restrictInput($event)" (click)="$event.stopPropagation()" [ngClass]="{'error-border': control.controls['value'].invalid}">
                                                                </div>

                                                                <div class="m-r-5" *ngIf="control.controls['comparisonOperator'].value == 'IN_BETWEEN'">
                                                                    <input class="value-input-field inbetween-field" id="condition-value" placeholder="Select Value" formControlName="minValue" type="number"
                                                                        (keydown)="restrictInput($event)" (click)="$event.stopPropagation()" [ngClass]="{'error-border': control.controls['minValue'].invalid}">
                                                                </div>

                                                                <div class="m-r-6" *ngIf="control.controls['comparisonOperator'].value == 'IN_BETWEEN'">
                                                                    <span>&lt;&gt;</span>
                                                                </div>

                                                                <div class="m-r-12" *ngIf="control.controls['comparisonOperator'].value == 'IN_BETWEEN'">
                                                                    <input class="value-input-field inbetween-field" id="condition-value" placeholder="Select Value"
                                                                        formControlName="maxValue" type="number" (keydown)="restrictInput($event)" (click)="$event.stopPropagation()" [ngClass]="{'error-border': control.controls['maxValue'].invalid}">
                                                                </div>
                                                                
                                                                <div class="selected-color-box m-r-8" (click)="showColorSwatchBoxForGauge($event,ind)"
                                                                    [ngClass]="{'no-pointer-events': isSharedByOthers}" [ngStyle]="{'background-color' : control.controls['color'].value }">
                                                                </div>
                                                                
                                                                <div class="m-r-10 ">
                                                                    <input class="displayName" matInput type="text"
                                                                    title="{{control.controls['legendLabel'].value}}" [maxLength]="100"
                                                                    formControlName="legendLabel" placeholder="Display Name" [ngClass]="{'error-border': control.controls['legendLabel'].invalid}">
                                                                </div>
                                                                
                                                                <div *ngIf="!isSharedByOthers">
                                                                    <i class="fa fa-times align-self-center cross-icon" aria-hidden="true" (click)="deleteCondition(ind)"></i>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        
                                                        <ng-container *ngIf="!pointDefs?.controls?.[selectedParamIndex]?.controls['conditions']?.value.length">
                                                            <div class="no-conditions-text">You have not added any conditions</div>
                                                        </ng-container>
                                                    </div>
                                                </div>

                                                <div class="chart-axis-labels">
                                                    <div class="chart-axis-labels-container">
                                                        <div class="column chart-xaxis-label-container">
                                                            <label class="lbl">Chart Label</label>
                                                            <div class="input-container">
                                                                <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="pieDonutChartLable"
                                                                    [maxlength]="30" class="chart-xaxis-label width-225" placeholder="Type in label"
                                                                    (ngModelChange)="updatePieDonutChartLable()" id="piedonut_chart_label" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </app-accordion>
                                    <div class="previewaccordion" [ngClass]="{'all-pointer-events': isSharedByOthers }">
                                        <div class="previewaccordion__item">
                                            <div class="previewaccordion__item__title" [ngClass]="{ 'divide': !isOpen}">
                                                <ng-container>
                                                    <h3 id="previewaccordion_{{'graphId'}}" class="block-title-wrapper" (click)="openPreview()">
                                                        <div class="block-title-container">
                                                            <div>
                                                                <span class="preview-text">Preview</span>
                                                                <span class="site-details">Dashboard Scope : <span>{{dashboardScopeSites +';'}}</span></span>
                                                                <span *ngIf="!hideDateRange" class="site-details">Date Range: <span>{{ dashboardDateRange.startDate |  date:'MMM d, y' }}</span><span *ngIf="dashboardDateRange.startDate != dashboardDateRange.endDate">to {{ dashboardDateRange.endDate |  date:'MMM d, y' }}</span></span>
                                                                <span *ngIf="!hideGroupBy" class="site-details">;&nbsp; Group By: <span>{{dashboardGroupBy}}</span></span>                                                            
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i  *ngIf="showPreviewContainer" (click)="refreshPreview($event)" class="fas fa-sync-alt refresh-icon"></i>
                                                            <i [ngClass]="(isOpen) ? 'fa fa-angle-down arrow-down' : 'fa fa-angle-right arrow-right'"
                                                                aria-hidden="true"></i>
                                                        </div>
                                                    </h3>
                                                </ng-container>
                                            </div>
                                            <div *ngIf="isOpen"
                                                [ngClass]="(isOpen) ? 'previewaccordion__item__body--open' : 'previewaccordion__item__body'">
                                                <div *ngIf="!showPreviewContainer" class="chart-preview-container">
                                                    <div  class="chart-preview">
                                                        <div class="chart-preview-header">
                                                            <div  (click)="generatePreview()" class="chart-preview-title">
                                                                GENERATE PREVIEW
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="showPreviewContainer">
                                                    <app-widget-layout [widgetObj]="previewWidgetData"></app-widget-layout>     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <div *ngIf="!selectedChartType" class="empty-chart-text">
                                    <span>Select Chart type to proceed</span>
                                </div>
                            </div>

                            <div class="action-buttons m-b-4">
                                <button
                                    id="widget-chart-config-back-cta"
                                    [ngClass]="{'all-pointer-events':isSharedByOthers}"
                                    class=" btn btn-outline-primary color-primary text-uppercase action-btn-back" (click)="backToBuilderPage()">BACK TO PARAMETERS</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="showBlocklyView">
                    <div>
                        <app-blockly-common-pool [viewOnly]="isSharedByOthers" [dependentPoints]="dependentPoints" [xmlCode]="xmlCode"
                            [selectedDerivedParameter]="selectedDerivedParameter" [parameters]="parameters"
                            (backToParametersEvt)="backToParameters()" (onBlocklySave)="saveBlocklyDerivedParameter($event)">
                        </app-blockly-common-pool>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</div>


<mat-menu class="sharing-options" #sharedMenu="matMenu">
    <div class="configurations">
        <label class="lbl-text">Sharing Options</label>
        <mat-radio-group
            class="p-t-4 row configuration-radio-group" [value]="selectedSharedConfiguration" (change)="onSelectionChangeForShareOption($event.value)">
            <div>
                <mat-radio-button (click)="$event.stopPropagation()" value='L1' id="internl-radio-selction">Internal Support Users
                </mat-radio-button>
            </div>
            <div>
                <mat-radio-button (click)="$event.stopPropagation()" value='L2' id="internl & Facilisight-radio-selction">Internal Support Users + Facilisight
                    Users</mat-radio-button>
            </div>
        </mat-radio-group>
    </div>
</mat-menu>

<ng-template #warningTplscope let-type="type">
    <div class="row align-items-start conditions-text" [ngClass]="{'m-t-12': type == 'Build Type'}">
        <div class="col-1">
            <i class="fa fa-exclamation-triangle color-primary fa-lg m-t-10 exclamation-icon" aria-hidden="true"></i>
        </div>
        <div class="col-11 p-l-10 pre-wrap warning-section">
            <p class="m-t-4 confirm-text">Are you sure you want to change <span id="chart-type-placeholder">{{type | lowercase}}</span> to
                <strong *ngIf="!isChartBuilderTypeChanged || !isChartTypeChanged"><span class="text-capitalize"
                        id="chart-label-placeholder">{{type === 'Build Type' ? (tempscope?.label | lowercase) : tempChartTypeScope}}</span>
                    ?</strong>
            </p>
            <p class="m-t-6 m-b-8 confirm-text">Changes you made so far will be lost.</p>
            <div class="d-flex justify-content-end action-btn-section">
                <button class="button-cta color-primary action-btn" id="widget-change-conditon-cancel-btn"
                    (click)=" (isChartBuilderTypeChanged || showChartChangeWarning) ? onChartTypeSelectCancel() : showscopeChangeWarning = false;">
                    CANCEL</button>
                <span class="seperator">|</span>
                <button class="button-cta btn-outline-primary color-primary action-btn" id="widget-change-conditon-change-btn"
                (click)="type == 'Build Type' ? (isChartBuilderTypeChanged ? onChartTypeSelect(true) : onBuildTypeSelect(tempscope, true)) : (showChartChangeWarning ? onChartScopeSelectionChange(tempChartTypeScope) : '')">
                    CHANGE</button>
            </div>
        </div>
    </div>
</ng-template>

<!-- color-palette code -->
<ng-container>
    <div [ngClass]="{'visibility-hidden': !showColorSwatch}" class="swatch-container" id="chart-config-swatch" #swatch>
        <div class="d-flex color m-b-10">
            <span class="p-r-5" (click)="colorSelectionType='palette';$event.stopPropagation()"
                [ngClass]="{'selected_color': colorSelectionType == 'palette'}">PALETTE</span>
            |
            <span class="p-l-5" [ngClass]="{'selected_color': colorSelectionType == 'custom'}"
                (click)="colorSelectionType='custom';$event.stopPropagation()">CUSTOM</span>
        </div>
        <ul *ngIf="colorSelectionType == 'palette'" class="list-none d-flex row text-center">
            <li class="color-box-list-item col-4" *ngFor="let color of colorSet;" (click)="onColorChange(color)">
                <span [ngStyle]="{'background-color' : color}" class="color-box"></span>
            </li>
        </ul>
        <div *ngIf="colorSelectionType == 'custom'">
            <color-sketch [presetColors]=null color="{{selectedCustomHexColor}}" (onChangeComplete)="onColorChange($event.color.hex, $event)">
            </color-sketch>
        </div>
    </div>
</ng-container>

<!-- start & End angle mat-auto-complete template -->
<ng-template #gaugeAngleSelectionTemplate let-label="label" let-filteredAngles="filteredAngles"
    let-angleType="angleType">
    <div class="chart-angle-selection-container" [ngClass]="{'m-r-12': angleType == 'start'}">
        <label class="lbl">{{ label }}</label>
        <mat-form-field class="chart-selection-dropdown-config" [ngClass]="{'warning-border': validateAngles()}">
            <input *ngIf="angleType == 'start'" type="number" matInput [matAutocomplete]="angleAuto" [(ngModel)]="selectedStartAngle"
                (ngModelChange)="filterAngles('start')" [ngModelOptions]="{standalone: true}" (keydown.enter)="onChartAngleSelectionChange()"/>
            
            <input *ngIf="angleType == 'end'" type="number" matInput [matAutocomplete]="angleAuto" [(ngModel)]="selectedEndAngle"
                (ngModelChange)="filterAngles('end')" [ngModelOptions]="{standalone: true}"  (keydown.enter)="onChartAngleSelectionChange()"/>
            
            <div class="dropdown-icon" matSuffix>
                <i class="fas fa-chevron-down" aria-hidden="true" id="dropdownarrow"></i>
            </div>
            <mat-autocomplete #angleAuto="matAutocomplete"
                (optionSelected)="onChartAngleSelectionChange()" class="chart-custom-select-panel">
                <mat-option *ngFor="let angle of filteredAngles" [value]="angle"
                [ngClass]="{'selected-option': angle == (angleType === 'start' ? selectedStartAngle : selectedEndAngle)}">
                {{ angle }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</ng-template>