import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  bearerToken: string;
  userId: string;
  portal: string;
  userPreferences: any = {};
  certificateLevel: string;
  userData: any;

  set bearer_token(token: any) {
    this.bearerToken = token;
  }

  get bearer_token() {
    return this.bearerToken;
  }

  set user_id(userId: any) {
    this.userId = userId;
  }

  get user_id() {
    return this.userId;
  }

  set portal_type(portal: any) {
    this.portal = portal;
  }

  get portal_type() {
    return this.portal;
  }

  set user_preferences(userPreferences: any) {
    this.userPreferences = userPreferences;
  }

  get user_preferences() {
    return this.userPreferences;
  }

  get certificate_level() {
    return this.certificateLevel;
  }

  set certificate_level(certificateLevel: string) {
    this.certificateLevel = certificateLevel;
  }

  set user_data(userData: any) {
    this.userData = userData;
  }

  get user_data() {
    return this.userData;
  }
}
