import { AbstractControl } from "@angular/forms";

/** A hero's name can't match the given regular expression */
export function forbiddenIfSplCharAtStartValidator(control: AbstractControl) {
    const specialCharPattern: any = /^[A-Za-z0-9][A-Za-z0-9\s\-_]*$/;
    if (specialCharPattern.test(control.value?.toString()[0]) || control.value == '') {
        return false;
    } else {
        return { forbiddenName: true };
    }
}