import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatMenuTrigger } from '@angular/material/menu';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { SCOPE_SELECTION, siteSelectionConfig } from '../../constants/constants';

@Component({
  selector: 'multi-selection-dropdown',
  templateUrl: './multi-selection-dropdown.component.html',
  styleUrls: ['./multi-selection-dropdown.component.scss'],
})
export class MultiSelectionDropdownComponent implements OnInit {
  @Input() nodes: any;
  @Input() entityListResponse: any;
  @Input() inputPlaceholder: any;
  @Input() selectPanelPlaceholder: any;
  @Input() disabledState:any;
  @Input() dataFromEdit:any;
  @Input() preselectedSiteIds:string[] = [];
  @Output() selectedchild: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchSelectInput') searchInput!: ElementRef<HTMLInputElement>;

  selectedItemstoDisplay: any;
  filteredNodes: any[] = [];
  _searchText: string = '';
  searchTextChanged: Subject<string> = new Subject<string>();
  siteSelectionConfig: any = siteSelectionConfig;
  selectedData: any[] = [];
  isFilteringData: boolean = false;
  private sitSelectorWorker: Worker

  @ViewChild(MatSelect) select!: MatSelect;
  @ViewChild(MatSelectTrigger) selectTrigger!: MatSelectTrigger;
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  @ViewChild('menu', { read: ElementRef }) matMenu!: ElementRef;
  isMatPanelOpen: boolean = false;
  selectedSiteCount: any;

  constructor() {
    this.initializeWorker();
  }

  ngOnInit(): void {
    this.searchTextChanged.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.applyScopeFilter(text);
    });
  }

  // Setter for search text
  set searchText(value: string) {
    this._searchText = value;
    this.searchTextChanged.next(value);
  }

  // Getter for search text
  get searchText(): string {
    return this._searchText;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectPanelPlaceholder'] && changes['selectPanelPlaceholder'].currentValue) {
      this.selectedItemstoDisplay = this.selectPanelPlaceholder; 
    }
    if(changes['preselectedSiteIds'] && changes['preselectedSiteIds'].currentValue) {
      this.applyPreselectedSiteIds();
    }
  }
  
  /**
   * Initializes a web worker for handling site selection operations.
   * This method checks if the `Worker` API is available in the current environment.
   * If available, it creates a new worker from the specified URL and sets up a message
   * handler to process messages received from the worker.
   * 
   **/
  initializeWorker() {
    if (typeof Worker !== 'undefined') {
      this.sitSelectorWorker = new Worker(new URL('src/app/shared/_workers/site-selector.worker.ts', import.meta.url), {
        type: 'module',
      });
      this.sitSelectorWorker.onmessage = ({ data }) => {
        data = JSON.parse(data);
        if (data.from === 'applyScopeFilter') {
          this.isFilteringData = data.isFilteringData;
          this.filteredNodes = data.filteredNodes;
        } else if (data.from === 'updateSelectedData') {
          this.selectedData = data.selectedData;
          this.filteredNodes = data.filteredNodes;
          this.selectedItemstoDisplay = data.selectedItemstoDisplay;
          if (data.isFilteringData) {
            this.nodes = data.nodes;
          }
          this.updateSelectedSitesCount();
        } else if (data.from === 'applyPreselectedSiteIds') {
          this.selectedData = data.selectedData;
          this.selectedItemstoDisplay = data.selectedItemstoDisplay;
          this.nodes = data.nodes;
          this.updatePreselectedData();
        } else if (data.from === 'toggleOrgCheck') {
          this.selectedData = data.selectedData;
          this.selectedItemstoDisplay = data.selectedItemstoDisplay;
          this.nodes = data.nodes;
          this.filteredNodes = data.filteredNodes;
          this.updateSelectedSitesCount();
        }
      };
    }
  }

  /**
   * Applies the preselected site IDs by sending a message to the site selector worker.
   * The message includes the preselected site IDs, the nodes, and the source of the message.
   * @remarks
   * This method is used to initialize or update the site selector with a predefined set of site IDs.
   */
  applyPreselectedSiteIds() {
    this.sitSelectorWorker.postMessage({
      preselectedSiteIds: this.preselectedSiteIds,
      nodes: this.nodes,
      from: 'applyPreselectedSiteIds'
    });
  }

  /**
   * Updates the preselected data by extracting the site IDs from the selected data,
   * updates the count of selected sites, and emits the selected child event with the
   * label and value of the selected items to display.
   */
  updatePreselectedData() {
    const selectedSiteIds = this.selectedData.flatMap((org: any) => org.children.map((site: any) => site.id));
    this.updateSelectedSitesCount();
    this.selectedchild.emit({ label: this.selectedItemstoDisplay, value: selectedSiteIds });
  }
 
  @HostListener('window:click', ['$event'])

  /**
   *  Method to handle the close event when clicked outside the dropdown menu panel.
   *
   * @param event - The event object.
   */
  closeMenuOnOutsideClick(event: MouseEvent) {
    if (this.isMatPanelOpen) {
      const clickedElement = event.target as HTMLElement;
      const scopeSelectionMenu: HTMLElement | null = document.getElementById('scope-selection-menu');

      // Checking if the clicked element is not a descendant of the element with id "custom-blocks-dropdown"
      if (!scopeSelectionMenu?.contains(clickedElement)) {
        if (this.menuTrigger) {
          this.menuTrigger.closeMenu();
        }
      }
    }
  }

  /**
   *  Method to emit the selected scope value.
   * @param event - The event object.
   */
  onCloseSelectPanel() {
    this.clearScopeSearch();
    const selectedSiteIds = this.selectedData.flatMap((org: any) => org.children.map((site: any) => site.id));
    this.selectedchild.emit({label: this.selectedItemstoDisplay, value: selectedSiteIds});
  }

  // Clearing the search input field in the dropdown
  clearScopeSearch(){
    this.searchInput.nativeElement.value = '';
    this.applyScopeFilter('');
  }

  // Method to toggle the org selection
  toggleOrgCheck(checked: boolean, selectedOrg: any, selectionFrom?: string) {
    selectedOrg.checked = checked;
    if (selectionFrom == SCOPE_SELECTION.selectedSites) {
      selectedOrg.children.forEach((site: any) => {
        site.checked = checked;
      });
    }
    this.sitSelectorWorker.postMessage({
      selectedOrg: selectedOrg,
      checked: checked,
      nodes: this.nodes,
      isFilteringData: this.isFilteringData,
      filteredNodes: this.filteredNodes,
      selectedSiteCount: this.selectedSiteCount,
      siteSelectionConfig: this.siteSelectionConfig,
      selectedData: this.selectedData,
      from: 'toggleOrgCheck'
    });
  }

  // Method to update the site selection
  toggleSiteCheck(selectedOrg: any, selectedOrgSite: any, checked: any, from: string) {
    selectedOrgSite.checked = checked;
    selectedOrg.checked = selectedOrg.children.every((site: any) => site.checked);
    
    if (from == SCOPE_SELECTION.allSites) {
      selectedOrg.indeterminate = selectedOrg.children.some((site: any) => site.checked) && !selectedOrg.children.every((site: any) => site.checked);
      // if the search and selection is there, neeed to sync the selection data in nodes
      if (this.isFilteringData) {
        this.updateNodesData(this.nodes, selectedOrg, selectedOrgSite, checked);
      }
    } else {
      if (this.isFilteringData) {
        this.updateNodesData(this.filteredNodes, selectedOrg, selectedOrgSite, checked);
      }
      this.updateNodesData(this.nodes, selectedOrg, selectedOrgSite, checked);
    }
    this.updateSelectedData();
  }

  /**
   * Updates the nodes data with the checked status of a selected organization site.
   */
  updateNodesData(nodesData: any, selectedOrg: any, selectedOrgSite: any, checked: any) {
    const orgIndex = nodesData.findIndex((org: any) => org.id == selectedOrg.id);
    if (orgIndex !== -1) {
      const siteIndex = nodesData[orgIndex].children.findIndex((site: any) => site.id == selectedOrgSite.id);
      if (siteIndex !== -1) {
        nodesData[orgIndex].children[siteIndex].checked = checked;
        nodesData[orgIndex].indeterminate = nodesData[orgIndex].children.some((site: any) => site.checked) && !nodesData[orgIndex].children.every((site: any) => site.checked);
        nodesData[orgIndex].checked = nodesData[orgIndex].children.every((site: any) => site.checked);
      }
    }
  }

  // Method to update the selected data based on the filtered results
  updateSelectedData() {
    this.sitSelectorWorker.postMessage({
      selectedData: this.selectedData,
      nodes: this.nodes,
      filteredNodes: this.filteredNodes,
      isFilteringData: this.isFilteringData,
      from: 'updateSelectedData'
    });
  }


  /**
   *  Method to filter the sites based on the search term.
   *
   * @param filterValue - The search term.
   */
  applyScopeFilter(filterValue: any) {
    this.sitSelectorWorker.postMessage({
      selectedData: this.selectedData,
      filterValue: filterValue,
      nodes: this.nodes,
      from: 'applyScopeFilter'
    });
  }

  /**
   * Updates the count of selected sites.
   * 
   * This method calculates the total number of selected sites by iterating over
   * the `selectedData` array. For each organization in the array, it filters the
   * sites that are checked and sums up their counts. The result is stored in the
   * `selectedSiteCount` property.
   */
  updateSelectedSitesCount() {
    this.selectedSiteCount = this.selectedData
      .map((org: any) => org.children.filter((site: any) => site.checked).length)
      .reduce((total, count) => total + count, 0);
  }
}
