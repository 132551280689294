import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, HostListener, ViewChildren, QueryList } from '@angular/core';
import { CommonService } from 'src/app/shared/_services/common.service';
import { Dashboard, DashboardConfig, DashboardCreationData, WidgetConfig, WidgetData } from 'src/app/shared/models/dashboard';
import { MatDialog } from '@angular/material/dialog';
import { CommonPoolComponent } from '../common-pool/common-pool.component';
import { ObjectUtil } from 'src/app/shared/utils/object-util';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ScopeSelectionModalComponent } from 'src/app/shared/_components/scope-selection-modal/scope-selection-modal.component';

import { switchMap, map, catchError } from 'rxjs/operators';
import dayjs from 'dayjs';
import { DaterangepickerComponent, DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { groupByOptions, timeIntervals, viewByOptions } from 'src/app/shared/constants/data-duration-options';
import { DateRangeEnum, DateRangeEnumLabel, DateRangeLabel, timeIntervalsEnum, timeIntervalsEnumLabel } from 'src/app/shared/enums/dateRange.enum';
import { ConfirmationPopupComponent } from 'src/app/shared/_components/confirmation-popup/confirmation-popup.component';
import { DashboardExportLayoutComponent } from '../dashboard-export-layout/dashboard-export-layout.component';
import { Chart } from 'src/app/shared/models/chart';
import { DashboardService } from 'src/app/shared/_services/dashboard.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { AlertService } from 'src/app/shared/_services/alert.service';
import { of, Subscription } from 'rxjs';
import { EmbedExternalLayoutComponent } from 'src/app/shared/_components/embed-external-layout/embed-external-layout.component';
import { WidgetService } from 'src/app/shared/_services/widget.service';
import { CERTIFICATION_LEVEL, chartLimitReached, CRUDOperationList, LimitReached, PORTALS } from 'src/app/shared/constants/constants';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { GridsterConfig, GridsterComponent } from 'angular-gridster2';
import { GridsterService } from 'src/app/shared/_services/gridster.service';
import { WidgetLayoutService } from 'src/app/shared/_services/widget-layout.service';
import { ChartCommonPoolComponent } from '../chart-common-pool/chart-common-pool.component';
import { configurationLevels } from '../create-dashboard-layout/create-dashboard-layout.component';
import { viewByEnum, viewByEnumLabel } from 'src/app/shared/enums/charts.enum';
import * as _ from 'lodash-es';
import { clickFromValues, componentType } from 'src/app/shared/enums/dashboard.enum';
import { dashBoardCtaLabel, userConfirmationState } from 'src/app/shared/enums/dashboard.enum';
import { WidgetLayoutComponent } from '../widget-layout/widget-layout.component';
import { chartBuilderChartConfig } from 'src/app/shared/constants/widget';
import { FeatureToggleService } from 'src/app/shared/_services/feature-toggle.service';
import { LocalStorageService } from 'src/app/shared/_services/local-storage.service';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent implements OnInit {

  maxDate: dayjs.Dayjs;
  dateRangeSelected: boolean = false;
  isQuickSelect: boolean = false;
  clickedCharts: boolean = false;
  noDashboardSelected: boolean = false;
  dashboardSearchText: string = '';
  showLeftPanel: boolean = true;
  ranges: any = {};
  dashboardDateSelected: any = { startDate: dayjs(), endDate: dayjs() };
  hourOptions = timeIntervals;
  dashboardGroupBy: string = 'Hourly';
  dashboardSelections: any = {};
  @ViewChild('showHideButton', { read: ElementRef }) showHideButton!: ElementRef;
  @ViewChild(DaterangepickerDirective, { static: false }) dateRangePicker!: DaterangepickerDirective;
  @ViewChild(DaterangepickerComponent) dateRangePickerComponent!: DaterangepickerComponent;
  @ViewChild('dateRangePicker', { static: false }) dateRangePickerInput!: ElementRef;
  @ViewChild('openDateRangeTrigger') openDateRangeTrigger: MatMenuTrigger;
  @ViewChild('dateRangeSubMenuTrigger') dateRangeSubMenuTrigger: MatMenuTrigger;
  @ViewChild('widgetMenuSettingsTrigger') widgetMenuSettingsTrigger: MatMenuTrigger;
  @ViewChild(ChartCommonPoolComponent) chartCommonPoolComponent: ChartCommonPoolComponent;
  @ViewChild(GridsterComponent) gridster: GridsterComponent;
  @ViewChild('dashBoardSettingsTrigger') dashBoardSettingsTrigger: MatMenuTrigger;
  @ViewChildren(WidgetLayoutComponent) widgetLayoutComponents: QueryList<WidgetLayoutComponent>;
  private dashboardChangeSubscription!: Subscription;
  private gridsterChangeSubscription: Subscription;
  widgetCount: number = 0;
  selectedDashboard: Dashboard;
  duplicateSelectedDashboard: Dashboard;
  selectedDashboardOption: Dashboard;
  scopedSelectedPlaceholder: string = 'Select Scope';
  selectedScopeLabel: string;
  selectedScopeValue: any;
  tooltip: boolean = false;
  dashboardList: Dashboard[] = [];
  chartsList: any = [];
  filteredDashboardList: any = [];
  isDraggingDashboard: boolean = false;
  hoveredDashboard: any;
  enableTableEdit: boolean = false;
  selectedRangeLabel: string = 'Today';
  timeIntervals = groupByOptions;
  rawDashboardData: any = [];
  dashboardListloading: boolean = true;
  portalParam: string;
  viewByMenu = viewByOptions;
  dateRangePlaceholder: string = 'Same as Dashboard';
  personalDashBoardList: any = ['My Dashboard 1', 'My Dashboard 2', 'My Dashboard 3', 'My Dashboard 4', 'My Dashboard 5', 'My Dashboard 6', 'My Dashboard 7', 'My Dashboard 8', 'My Dashboard 9', 'My Dashboard 10', 'My Dashboard 11', 'My Dashboard 12', 'My Dashboard 13', 'My Dashboard 14', 'My Dashboard 15'];
  openCalendar: boolean = false;
  selectedChart: any = {};
  scopeSelectionSites = ['EIT LAB 1 75F', 'EIT LAB 2 75F', 'EIT LAB 3 75F', 'EIT LAB 4 75F'];
  selectedDateonSettings: any;
  selectedChartDateIndex: number;
  isVofmUser: boolean = false;
  loggedInUserDashboardList: any = [];
  copiedDashBoardsMap: { [key: string]: string[] } = {};
  siteIds: string[] = [];
  siteRef: string;
  subscriptions: any = {};
  isGridsterVisible: boolean = true;
  crudActionLabel: string = 'NEW';
  crudOperationList = CRUDOperationList;
  searchTextForDashBoard: string = '';
  duplicateDashboards: any;
  dashBoardForMoveCopy: any;
  isDashboardUpdated: boolean = false;
  scopeDropdownSitesList: any[] = [];
  saveDashboardLabel: string = 'SAVE DASHBOARD';
  viewMode: boolean = true;
  previewMode: boolean = false;
  tempSelectedDashboard: Dashboard;
  isDasboardLoading: boolean = false;
  previewModeLimitReachedMsg: string = LimitReached;
  chartsLimitReachedMsg: string = chartLimitReached;
  dashboardCalendarChanged: boolean = false;
  hideDataRange: boolean = false;
  hideGroupBy: boolean = false;
  hideViewBy: boolean = false;

  constructor(
    public commonService: CommonService,
    public dialogRef: MatDialog,
    public dashboardService: DashboardService,
    public alertService: AlertService,
    public chartsService: WidgetService,
    public gridsterService: GridsterService,
    public widgetLayoutService: WidgetLayoutService,
    private localStorageService: LocalStorageService,
    public widgetService: WidgetService,
    private cdr: ChangeDetectorRef,
    private featureToggleService: FeatureToggleService
  ) {
    this.portalParam = this.localStorageService.portal_type || '';
    this.handleQueryParam();
    this.getScopeDropDownData();
  }

  get isReadOnly(): boolean {
    return (this.localStorageService.portal_type !== PORTALS.INTERNAL) ? this.dashboardService.certificateLevel == CERTIFICATION_LEVEL.L1 ? true : false : false;
  }

  ngOnInit(): void {
    this.dashboardService.dasboardCrudOperation$.subscribe({
      next: (value: any) => {
        if (value == CRUDOperationList.new) {
          this.crudActionLabel = CRUDOperationList.new;
          this.saveDashboardLabel = 'CREATE NEW DASHBOARD';
          this.noDashboardSelected = false; // while creating new dashboard, set nodashboardselected to false.
          this.dashboardService.isDashboardEdited = false; // Reset the flag to false when a new dashboard is created.
          this.viewMode = false;
          this.previewMode = false;
          this.setDashboardData();
          window.parent.postMessage(JSON.stringify({isDashBoardSaved:true}), '*');
        } else if (value == CRUDOperationList.edit) {
          this.crudActionLabel = CRUDOperationList.edit;
          this.viewMode = false;
          this.previewMode = false;
          if (this.dashboardService.isDashboardEdited) { // If the dashboard is edited, set the selected dashboard to the edited dashboard.
            this.isDashboardUpdated = true;
            this.selectedDashboard = this.dashboardService.dashboardData as Dashboard;
          } else {
            this.selectDashboard(this.dashboardService.dashboardData);
            this.saveDashboardLabel = 'SAVE DASHBOARD';
          }
          this.noDashboardSelected = false; // while editing dashboard, set nodashboardselected to false.
        } else if (value == CRUDOperationList.preview) {
          this.crudActionLabel = CRUDOperationList.preview;
          this.saveDashboardLabel = 'ADD DASHBOARD';
          this.dashboardService.isDashboardEdited = false;
          this.viewMode = false;
          this.previewMode = true;
          this.noDashboardSelected = false; // while previewing dashboard, set nodashboardselected to false.
          this.selectDashboard(this.dashboardService.dashboardData);
        } else if (value == CRUDOperationList.duplicate) {
          this.crudActionLabel = CRUDOperationList.duplicate;
          this.saveAsNewDashboard();
          this.isDashboardUpdatedInDuplicateMode();
          this.previewMode = false;
        }
        this.dashboardService.crudActionLabel = this.crudActionLabel;
      }
    });

    this.useFeatureToggles();

    // Subscribe to the event on selection change from common pool
    this.dashboardChangeSubscription = this.dashboardService.eventTriggered$.subscribe((eventType) => {
      // Get the list of applied dashboards
      this.getDefaultDashboardList(eventType);
    });

    this.subscriptions['dashboardScopeData'] = this.widgetLayoutService.refreshWidgetListSubject.subscribe((data: any) => {
      if (data.chartData) {
        if (this.crudActionLabel == CRUDOperationList.new) {
          // If the dashboard creation is new, updating the widget data in the charts list, if it is updated in builder.
          this.updateWigetDetails(data.chartData.id, data.widgetData);
        } else {
          this.getWidgetDetails(data.chartData.id, data.widgetData);
        }
      } else if (data.chartDetails && data.likeCountUpdated) {
        this.updateWigetDetails(data.chartDetails.id, data.chartDetails , data.likeCountUpdated);
      }
    });
    // Get the list of applied dashboards
    this.getDefaultDashboardList();

    //Sets ranges for quick select in calendar
    this.ranges = {
      'Today': [dayjs().startOf('day'), dayjs().endOf('day')],
      'Last 3 Days': [dayjs().subtract(2, 'days'), dayjs().endOf('day')],
      'Last Week': [dayjs().subtract(6, 'days').startOf('day'), dayjs().endOf('day')],
      'Last Month': [dayjs().subtract(1, 'month').add(1, 'days').startOf('day'), dayjs().endOf('day')],
      'Last 6 Months': [dayjs().subtract(6, 'month').add(1, 'days').startOf('day'), dayjs().endOf('day')],
      'Last Year': [dayjs().subtract(12, 'month').add(1, 'days').startOf('day'), dayjs().endOf('day')]
    };

    this.selectedDateonSettings = {
      startDate: dayjs().startOf('day'),
      endDate: dayjs().endOf('day'),
    };
    //Calendar date will be disabled after current date
    this.maxDate = dayjs()

    // this.generateLineChartData();
    // this.generateLineYDateChartData();
    this.isVofmUser = this.dashboardService.certificateLevel === CERTIFICATION_LEVEL.L0;
  
    this.setDefaultWidgetData();

    this.gridsterChangeSubscription = this.gridsterService.gridItem$.subscribe({
      next: (value: any) => {
        if (Object.keys(value).length > 0) {
          this.isDashboardUpdated = true; // Set the flag to true when the dashboard is updated.
          if (this.viewMode) {
            this.updateLayoutOnViewMode(value);
          }
        }
      },
      error: (error) => console.error('Error:', error),
      complete: () => console.log('Subscription completed')
    });

    if (this.widgetLayoutService.refreshDashboardChartSubject) {
      this.subscriptions['refreshDashboardChartSubject'] = this.widgetLayoutService.refreshDashboardChartSubject.subscribe((id: string) => {
        this.updateChartList(id);
      });
    }

    //Handle message from Internal or Facilisight to create a new dashboard:
    window.addEventListener('message', (event) => {
      if (event?.data?.createDashBoard)  this.saveDashboard();
    });
  }

  //Method to handle unsaved dashboard on refresh
  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: Event) {
    if (this.crudActionLabel == CRUDOperationList.new) {
      event.preventDefault();
    }
  }


  ngAfterViewInit(): void {
    const today = dayjs().format('MMM DD, YYYY');
    setTimeout(() => {
      this.dateRangeSelected = true;
      this.dateRangePickerInput.nativeElement.value = `Today (${today})`;
      this.selectedRangeLabel = DateRangeLabel.Today;
    }, 0);
  }


  /**
   * Method to open the scope selection modal for selecting the scope of the dashboard or chart.
   *
   * @param componentType - The Component type for which the scope is being applied ('dashboard' or 'chart').
   * @param componentName - The name of the dashboard or chart for which the scope is being selected.
   */
  openScopeSelectionModal(componentType: string, chart?: any) {
    if (this.previewMode) return; // If the preview mode is enabled, do not open the scope selection modal.
    const self = this;
    const dialogRef = self.dialogRef.open(ScopeSelectionModalComponent, {
      panelClass: 'popupModalConfig',
      disableClose: false,
      data: this.getDataForScopeSelectionModal(componentType, chart)
    });
    dialogRef?.updatePosition({ top: '20px' });
    dialogRef?.afterClosed().subscribe((data: any) => {
      this.isDashboardUpdated = true; // Set the flag to true when the dashboard is updated.
      if (data?.componentType == 'chart' && (data.action == CRUDOperationList.new || data.action == CRUDOperationList.edit || data?.action == CRUDOperationList.duplicate || this.viewMode)) {
        const chartIndex = this.chartsList.findIndex((chartItem: any) => chartItem?.widgetId === data?.configData.widgetId);
        if (this.chartsList[chartIndex]) {
          this.chartsList[chartIndex].scopeSelectionData = {
            'excludedCcuRefs': data?.configData.excludedCcuRefs,
            'excludedDeviceRefs': data?.configData.excludedDeviceRefs,
            'excludedEquipRefs': data?.configData.excludedEquipRefs,
            'excludedFloorRefs': data?.configData.excludedFloorRefs,
            'excludedZoneRefs': data?.configData.excludedZoneRefs,
            'siteRefs': data?.configData.siteRefs
          };
          this.chartsList[chartIndex].scopeSameAsDashboard = data?.configData?.scopeSameAsDashboard;
          this.chartsList[chartIndex].scopeLabel = data?.scopeData?.label;
          if (this.chartsList[chartIndex]?.widgetConfig && !this.chartsList[chartIndex]?.widgetConfig?.widgetId) {
            this.chartsList[chartIndex].widgetConfig.configId = data?.configData?.configId;
            this.chartsList[chartIndex].widgetConfig.widgetId = data?.configData?.widgetId;
          }
          this.chartsList[chartIndex] = ObjectUtil.deepClone(this.chartsList[chartIndex]);          
        }
      } else if (data?.componentType == 'dashboard') {
        if (data?.scopeData && data?.scopeData?.label) {
          self.selectedScopeLabel = data.scopeData.label;
          self.selectedScopeValue = data.scopeData.value;
          this.dashboardService.dashboardScopeData = {'label':self.selectedScopeLabel , 'siteRefs': self.selectedScopeValue};
          this.siteIds = data.scopeData.value;
        }
        if (this.viewMode) {
          this.selectDashboard(this.selectedDashboard);
        }
        this.chartsList= ObjectUtil.deepClone(this.chartsList);          
      }
    });
  }

  /**
   * Generates the payload data for the scope selection modal based on the component type.
   *
   * @param componentType - The type of the component, either 'dashboard' or other types.
   * @param chart - Optional parameter representing the chart data, used when the component type is not 'dashboard'.
   * @returns The payload object containing relevant data for the scope selection modal.
   */
  getDataForScopeSelectionModal(componentType: string, chart?: any) {
    let payload;
    if (componentType == 'dashboard') {
      payload = {
        componentType: componentType,
        componentName: this.selectedDashboard?.name,
        dashboard: this.selectedDashboard,
        date: this.dashboardService.dashboardDate,
        groupBy: this.dashboardService.dashboardGroupBy,
        action: this.crudActionLabel,
        viewMode: this.viewMode
      }
    } else {
      payload = {
        componentType: componentType,
        componentName: chart?.name,
        chart: chart,
        dashboard: this.selectedDashboard,
        dashboardScopeData: { 'label': this.selectedScopeLabel, 'value': this.selectedScopeValue },
        action: this.crudActionLabel,
        viewMode: this.viewMode
      }
    }
    return payload;
  }

  /**
   * Updates the charts list by removing the chart with the specified ID.
   * @param id - The ID of the chart to be removed from the charts list.
   */
  updateChartList(id: string) {
    this.chartsList = this.chartsList?.filter((item: Chart) => item?.id !== id);
    if(!this.selectedDashboard?.widgetList) {
      this.selectedDashboard.widgetList = [];
    }
    this.selectedDashboard.widgetList = ObjectUtil.deepClone(this.chartsList);
    this.filterWidgetListOnDashboard(this.selectedDashboard.widgetList);
    this.commonService.setSelectDashboard(this.selectedDashboard);
  }

  // Method to get the default dashboard list
  getDefaultDashboardList(eventType?:string) {
     
    // Get the list of applied dashboards
    this.dashboardService.getAppliedDashboardsList().pipe(
      map((applieddashboardList: any) => {
        this.rawDashboardData = applieddashboardList;
        return applieddashboardList;
      }),
      switchMap(() => this.dashboardService.getDashboardList())).subscribe((dashboardList: any) => {
        this.dashboardListloading = true;

        // Combine `createdByMeList` and `sharedByOthersList`
        const allDashboards = [...dashboardList?.createdByMeList, ...dashboardList?.sharedByOthersList];

        // Step 3: Filter dashboards where IDs match with `rawDashboardData`
        let dashboardListForView = allDashboards.filter((dashboard) =>
          this.rawDashboardData.some((appliedDashboard: any) => appliedDashboard.dashboardId === dashboard.id)
        );

        // Sort `dashboardListForView` to maintain the order of `rawDashboardData`
        dashboardListForView = dashboardListForView.sort((a, b) => {
          const orderA = this.rawDashboardData.find((d: any) => d.dashboardId === a.id)?.order || 0;
          const orderB = this.rawDashboardData.find((d: any) => d.dashboardId === b.id)?.order || 0;
          return orderA - orderB;
        });

        this.dashboardList = dashboardListForView;
        const isSelectedDashboardAvailable = this.dashboardList.find((dashboard: any) => dashboard.id === this.selectedDashboard?.dashboardId);
        if (this.dashboardList.length > 0 && !isSelectedDashboardAvailable) {
          this.selectDashboardFromNavigation(this.dashboardList[0]);
        } else if(eventType != 'LIKE' && eventType != 'BOOKMARK') {
          this.selectDashboardFromNavigation(isSelectedDashboardAvailable);
        }

        if (this.dashboardList.length === 0) {
          this.chartsList = []; // Clear the charts list if no dashboards are available.
          this.noDashboardSelected = true;
        } else {
          this.noDashboardSelected = false;
        }

        //Initializes the filtered dashboard list with the dashboard list
        this.filteredDashboardList = ObjectUtil.deepClone(this.dashboardList);

        this.dashboardListloading = false;
        this.isDashboardUpdated = false;
        this.dashboardService.isDashboardEdited = false;
        this.dashboardService.isSaveasnewOrDuplicate = false;
        this.getDashboardListOfLoggedInUser();
      });
  }

  /**
   * Sets the default dashboard data on preview.
   */
  setDefaultDashboardDataOnPreview() {
    let dashboardSelectedDate = {
      startDate: dayjs(),
      endDate: dayjs()
    };
    this.dashboardDateSelected = dashboardSelectedDate;
    this.dashboardGroupBy = timeIntervalsEnumLabel.HOURLY;
    this.updateDashboardScopeData();
  }

  /**
   * Handles the change event for the dashboard search input.
   *
   * @param dashboardSearchTex - The current value of the dashboard search input.
   */
  dashboardSearchInputChanged(dashboardSearchText: string) {
    this.filteredDashboardList = this.dashboardList.filter((dashboard: any) => dashboard.name.toLowerCase().includes(dashboardSearchText.toLowerCase()));
  }

  async selectDashboardFromNavigation(dashboard: any) {
    if (dashboard) {
      /**Check if Dashboard is unsaved state.If user tries to exit bring confirm modal*/
      if (this.crudActionLabel == CRUDOperationList.new && this.saveDashboardLabel == dashBoardCtaLabel.CREATE_NEW_DASHBOARD) {
        this.isDasboardLoading = false;
        const confirmSwitch = await this.getConfirmationOnSwitchDashboard();
        if (confirmSwitch == userConfirmationState.CONFIRM) {
          if (this.chartsList?.length > 0) {
            this.saveDashboard();
          } else {
            this.isDasboardLoading = false;
            return;
          }
        } else if (confirmSwitch == userConfirmationState.CANCEL) {
          this.navigateToSelectedDashboard(dashboard);
          return;
        }

      } else {
        this.navigateToSelectedDashboard(dashboard);
      }
    }
  }

  navigateToSelectedDashboard(dashboard: any) {

    if (this.isPersonal(dashboard) || this.isSharedByMe(dashboard)) {
      this.dashboardService.tempDashboardData = this.dashboardService.dashboardData;
      this.dashboardService.dashboardData = dashboard;
      this.dashboardService.isSaveasnewOrDuplicate = false;
      this.dashboardService.isDashboardEdited = false;
      this.viewMode = false; // setting view mode to false
      this.dashboardService.dasboardCrudOperation$.next(this.crudOperationList.edit);
    } else {
      this.crudActionLabel = '';
      this.dashboardService.isSaveasnewOrDuplicate = false;
      this.dashboardService.isDashboardEdited = false;
      this.viewMode = true; // If the dashboard is shared by others, set the view mode to true.
      this.previewMode = false; // Resetting the preview mode
      this.selectDashboard(dashboard);
    }
  }  

  /**
   * Handles the click event for the dashboard option.
   */
  selectDashboard(dashboard: any) {
    this.isDasboardLoading =  true;
    this.dashboardCalendarChanged = false;
    const dashboardId = dashboard?.id ? dashboard?.id : dashboard?.dashboardId;

    this.dashboardService.crudActionLabel = this.crudActionLabel;
    
    this.updateGridSterOptions(); // Update the gridster options when a new dashboard is selected.
    this.isDashboardUpdated = false; // Reset the flag to false when a new dashboard is selected.
    this.duplicateDashboards = this.loggedInUserDashboardList && this.loggedInUserDashboardList?.length ? this.loggedInUserDashboardList?.filter((item: any) => item.id !== dashboard?.id): [];
    //Get the widget details of selected dashboard
    this.dashboardService.getSelectedDashboardData(dashboardId).subscribe((selectedDashboardData) => {
      this.selectedDashboard = selectedDashboardData;
      this.selectedDashboard.widgetList = dashboard?.widgetList ?? [];
      const dataRangeLabel: { [key: string]: string } = DateRangeEnumLabel;
      const groubyLabel: { [key: string]: string } = timeIntervalsEnumLabel;
      const viewByLabel: { [key: string]: string } = viewByEnumLabel;
      if (selectedDashboardData.dashboardConfig) {
        let dashboardSelectedDate: any;
  
        if (selectedDashboardData.dashboardConfig.dateRange != DateRangeEnum.CUSTOM) {
          const updatedRange = this.getDateRangeWithLabel(selectedDashboardData.dashboardConfig.dateRange);
          this.dateRangeSelected = false;
          this.selectedRangeLabel = updatedRange.label;
          this.updateInputValue(updatedRange.label);
    
          dashboardSelectedDate = {
            startDate: dayjs(updatedRange.startDate),
            endDate: dayjs(updatedRange.endDate),
          };
         
        } else {
          this.dateRangeSelected = true;
          this.selectedRangeLabel = DateRangeEnum.CUSTOM;
          dashboardSelectedDate = {
            startDate: dayjs(selectedDashboardData.dashboardConfig.startDate),
            endDate: dayjs(selectedDashboardData.dashboardConfig.endDate),
          };
        }

        this.dashboardService.dashboardConfig = selectedDashboardData.dashboardConfig;
        this.updateDashboardScopeData(selectedDashboardData.dashboardConfig);
        this.dashboardDateSelected = dashboardSelectedDate;
        this.dashboardService.dashboardDate = dashboardSelectedDate;
        this.selectedRangeLabel = dataRangeLabel[selectedDashboardData?.dashboardConfig?.dateRange];
        this.dashboardGroupBy = groubyLabel[selectedDashboardData?.dashboardConfig?.groupBy];
        this.dashboardGroupByChanged(false); // Update the groupBy label in the dashboard header.
      } else {
        if (this.crudActionLabel === CRUDOperationList.preview) {
          this.setDefaultDashboardDataOnPreview(); // when dashboardConfig is not available, set the default data.
        }
        this.updateDashboardScopeData();
      }
      // Get the widget config for the selected dashboard only if the sharedWidgetList is available.
      if (selectedDashboardData?.sharedWidgetList?.length) {
        this.dashboardService.getWidgetConfigForDashboard(selectedDashboardData?.dashboardId, selectedDashboardData?.sharedWidgetList).subscribe((widgetConfigData: any) => {

          if (widgetConfigData.widgetList?.length) {
            widgetConfigData.widgetList = widgetConfigData.widgetList.map((widget: any) => {
              widget.id = widget?.widgetId || widget?.id;
              if (widget.widgetConfig != undefined) {
                widget.startDateTime = dayjs(widget.widgetConfig?.startDate);
                widget.endDateTime = dayjs(widget.widgetConfig?.endDate);
                widget.groupBySameAsDashboard = widget.widgetConfig?.groupBySameAsDashboard;
                widget.dateRangeSameAsDashboard = widget.widgetConfig?.dateRangeSameAsDashboard;
                widget.scopeSameAsDashboard = widget.widgetConfig?.scopeSameAsDashboard;
                widget.dateRange = dataRangeLabel[widget.widgetConfig?.dateRange];
                if (!widget.widgetConfig?.groupBySameAsDashboard) { // If groupBy is not same as dashboard
                  widget.groupBy = groubyLabel[widget.widgetConfig?.groupBy];
                } else {
                  widget.groupBy = 'Same as Dashboard'; // Set the groupBy label to 'Same as Dashboard'
                }
                widget.viewBy = viewByLabel[widget.widgetConfig?.viewBy];
                widget.configId = widget.widgetConfig?.configId;
                widget.scopeLabel = this.updateWidgetScopeLabel(widget.widgetConfig);
                widget.chartDateFormatted = this.setDateRangeLabel(widget.dateRange, false, true);
                widget.dashboardConfig = selectedDashboardData.dashboardConfig;

                if (!widget.chartDateFormatted) {
                  widget.chartDateFormatted = `${widget.startDateTime?.format('MMM DD, YYYY')} to ${widget.endDateTime?.format('MMM DD, YYYY')}`;
                }
                if (this.selectedDashboard?.layout?.[widget?.widgetId]) {
                  widget.x = this.selectedDashboard?.layout[widget?.widgetId].x;
                  widget.y = this.selectedDashboard?.layout[widget?.widgetId].y;
                  widget.cols = this.selectedDashboard?.layout[widget?.widgetId].cols;
                  widget.rows = this.selectedDashboard?.layout[widget?.widgetId].rows;
                }
                // Set the scope selection data for the widget.
                widget.scopeSelectionData = {
                  excludedCcuRefs: widget.widgetConfig?.excludedCcuRefs,
                  excludedDeviceRefs: widget.widgetConfig?.excludedDeviceRefs,
                  excludedEquipRefs: widget.widgetConfig?.excludedEquipRefs,
                  excludedFloorRefs: widget.widgetConfig?.excludedFloorRefs,
                  excludedZoneRefs: widget.widgetConfig?.excludedZoneRefs,
                  siteRefs: widget.widgetConfig?.siteRefs
                };
                if (!widget.chartDateFormatted) {
                  widget.chartDateFormatted = `${widget.startDateTime?.format('MMM DD, YYYY')} to ${widget.endDateTime?.format('MMM DD, YYYY')}`;
                  widget.selectedRangeLabel = `${widget.startDateTime?.format('MMM DD, YYYY')} to ${widget.endDateTime?.format('MMM DD, YYYY')}`;
                }
              } else {
                widget.scopeSameAsDashboard = true;
                widget.groupBySameAsDashboard = true;
                widget.dateRangeSameAsDashboard = true;
                widget.viewBy = viewByEnumLabel.SITE;
              }
              return widget;
            });
          }
          this.chartsList = widgetConfigData.widgetList;
          this.chartsList?.forEach((chart: any) => {
            chart.id = chart?.widgetId;
            chart.isEmbedded = false;
            this.dashBoardForMoveCopy = ObjectUtil.deepClone(this.duplicateDashboards);
          });
          if (widgetConfigData.externalWidgetList?.length) {
            widgetConfigData.externalWidgetList.forEach((widget: any) => {
              widget.widgetId = widget?.id;
              widget.isEmbedded = true;
              if (this.selectedDashboard?.layout?.[widget?.widgetId]) {
                widget.x = this.selectedDashboard?.layout[widget?.widgetId].x;
                widget.y = this.selectedDashboard?.layout[widget?.widgetId].y;
                widget.cols = this.selectedDashboard?.layout[widget?.widgetId].cols;
                widget.rows = this.selectedDashboard?.layout[widget?.widgetId].rows;
              }
              this.chartsList.push(widget);
            });
          }
          this.filterWidgetListOnDashboard(widgetConfigData.widgetList);
          this.selectedDashboard.widgetList = ObjectUtil.deepClone(this.chartsList);
          this.commonService.setSelectDashboard(this.selectedDashboard);
          this.duplicateSelectedDashboard = ObjectUtil.deepClone(this.selectedDashboard); // Duplicate the selected dashboard for future reference.
          this.duplicateSelectedDashboard.widgetList = ObjectUtil.deepClone(this.chartsList); // Duplicate the widget list for the selected dashboard.
          if (!selectedDashboardData.dashboardConfig) {
            if (this.viewMode) {
              this.saveDashboardConfig(dashboardId, false, false);
            }
            this.isDasboardLoading = false;
          } else {
            this.isDasboardLoading = false;
          }
        }, (error) => {
          this.alertService.error(error?.error?.error || 'Something went wrong, please try again');
          this.isDasboardLoading = false;
        });
      } else { // If the sharedWidgetList is not available, set the chartList to an empty array.
        this.chartsList = [];
        this.commonService.setSelectDashboard(this.selectedDashboard);
        this.isDasboardLoading = false;
      }

    },error => {
      this.alertService.error(error?.error?.error || 'Something went wrong, please try again');
      this.isDasboardLoading = false;
    });
  }

  getDateRangeWithLabel(range: DateRangeEnum): { startDate: string, endDate: string, label: string } {
    const endDate = dayjs().format('YYYY-MM-DD'); // Always today
    let startDate: string = endDate; // Initialize to avoid "used before assigned" error
    let label: string = '';

    switch (range) {
      case DateRangeEnum.Today:
        label = `Today (${dayjs(startDate).format('MMM DD, YYYY')})`;
        break;

      case DateRangeEnum['Last 3 Days']:
        startDate = dayjs().subtract(2, 'days').format('YYYY-MM-DD');
        label = `Last 3 Days (${dayjs(startDate).format('MMM DD, YYYY')} - ${dayjs(endDate).format('MMM DD, YYYY')})`;
        break;

      case DateRangeEnum['Last Week']:
        startDate = dayjs().subtract(6, 'days').format('YYYY-MM-DD');
        label = `Last Week (${dayjs(startDate).format('MMM DD, YYYY')} - ${dayjs(endDate).format('MMM DD, YYYY')})`;
        break;

      case DateRangeEnum['Last Month']:
        startDate = dayjs().subtract(1, 'month').add(1, 'days').format('YYYY-MM-DD');
        label = `Last Month (${dayjs(startDate).format('MMM DD, YYYY')} - ${dayjs(endDate).format('MMM DD, YYYY')})`;
        break;

      case DateRangeEnum['Last 6 Months']:
        startDate = dayjs().subtract(6, 'months').add(1, 'days').format('YYYY-MM-DD');
        label = `Last 6 Months (${dayjs(startDate).format('MMM DD, YYYY')} - ${dayjs(endDate).format('MMM DD, YYYY')})`;
        break;

      case DateRangeEnum['Last Year']:
        startDate = dayjs().subtract(12, 'months').add(1, 'days').format('YYYY-MM-DD');
        label = `Last Year (${dayjs(startDate).format('MMM DD, YYYY')} - ${dayjs(endDate).format('MMM DD, YYYY')})`;
        break;
    }

    return { startDate, endDate, label };
  }


  /**
   * Toggles the visibility of the toolbox (left panel).
   *
   * @param value - A boolean indicating whether to show (true) or hide (false) the toolbox.
   */
  showHideToolBox(value: boolean) {
    this.showLeftPanel = value;
    this.isGridsterVisible = false;
    this.cdr.detectChanges();

    setTimeout(() => {
      this.isGridsterVisible = true;
      this.cdr.detectChanges();
    });
  }

  /**
   * Handles the click event for the add new dashboard button.
   */
  async onAddNewDashboard() {

    if (this.crudActionLabel == CRUDOperationList.new &&  this.saveDashboardLabel == dashBoardCtaLabel.CREATE_NEW_DASHBOARD) {

      /**Check if Dashboard is unsaved state.If user tries to exit bring confirm modal*/
      const confirmSwitch = await this.getConfirmationOnSwitchDashboard();
      if (confirmSwitch == userConfirmationState.CONFIRM) {
        this.saveDashboard();
      } else if (confirmSwitch == userConfirmationState.CANCEL) {
        const self = this;
        this.crudActionLabel = '';
        if (self.filteredDashboardList.length > 0) {
          this.selectedDashboard = ObjectUtil.deepClone(self.filteredDashboardList[0]);
          this.selectDashboard(this.selectedDashboard)
        } else {
          this.noDashboardSelected = true;
        }
        this.openDashboardListPopup();
      } else {
        return;
      }

    } else {
      this.openDashboardListPopup();
    }

  }

  openDashboardListPopup() {
    const self = this;
    const dialogRef = this.dialogRef.open(CommonPoolComponent, {
      position: { top: '50px' },
      panelClass: 'dashboards-padding-dialog',
    });

    dialogRef.afterClosed().subscribe(() => {
      // this.clickMenu();
    });
  }

  /**
   * Handles the click event for the custom chart button.
   * Opens a dialog for selecting a chart from the common pool.
   * 
   * The dialog is positioned 50px from the top and has a custom panel class for styling.
   * After the dialog is closed, additional actions can be performed (currently commented out).
   */
  onClickCustomChart() {
    const self = this;
    const dialogRef = this.dialogRef.open(ChartCommonPoolComponent, {
      position: { top: '50px' },
      panelClass: 'dashboards-padding-dialog',
    });

    dialogRef.afterClosed().subscribe(() => {

    });
  }

  // Method to handle the drag and drop of the dashboard list
  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.filteredDashboardList, event.previousIndex, event.currentIndex);
    this.dashboardService.reorderDashboardList(this.filteredDashboardList);
    //Updating the dashboardList once the reorder is done
    this.dashboardList = [...this.filteredDashboardList];
  }

  // Method to handle the dashboard option hover
  onOptionHover(item: any) {
    this.enableTableEdit = true;
    this.hoveredDashboard = item;
  }

  // Method to handle the tooltip hide
  unhoveredDashboard() {
    this.tooltip = false;
  }

  // Method to handle the dashboard option click
  async updateSelectedDashboard(dashboard: any) {

    /**Check if Dashboard is unsaved state.If user tries to exit bring confirm modal*/
    if (this.crudActionLabel == CRUDOperationList.new && this.saveDashboardLabel == dashBoardCtaLabel.CREATE_NEW_DASHBOARD) {
      const confirmSwitch = await this.getConfirmationOnSwitchDashboard();
      if (confirmSwitch == userConfirmationState.CONFIRM) {
        this.dashBoardSettingsTrigger.closeMenu();
        this.saveDashboard();
      } else if (confirmSwitch == userConfirmationState.CANCEL) {
        this.selectedDashboardOption = dashboard;
        this.selectedDashboard = dashboard;
        this.crudActionLabel = '';
      } else {
        this.dashBoardSettingsTrigger.closeMenu();
        return;
      }
    } else {
      this.selectedDashboardOption = dashboard;
    }

  }

  // Method to handle removing of dashboard
  removeDashboard(clickFrom: string) {
    const dashboardId = (clickFrom === clickFromValues.LEFTPANEL) ? this.selectedDashboardOption?.id : this.selectedDashboard?.dashboardId;
    // API call for unapply
    if(dashboardId) {
      this.dashboardService.unApplyDashboardToView(dashboardId).subscribe({
        next: (response: any) => {
          this.alertService.success('Dashboard removed successfully');
          this.getDefaultDashboardList();
        },
        error: (error) => {
          this.alertService.error(error.error.error || 'Something went wrong, please try again');
        },
      });
    }
  }

  // Method to handle the dashboard delete
  deleteDashbooard(clickFrom: string) {
    const dashboardId = (clickFrom === clickFromValues.LEFTPANEL) ? this.selectedDashboardOption?.id : this.selectedDashboard?.dashboardId || this.selectedDashboard?.id;
    const dashboardName = this.selectedDashboardOption?.name || this.selectedDashboard?.name;
    //Get the user count, likes count and bookmarks count
    let userCount: number;
    let likesCount: number;
    let bookmarksCount: number;
    if(dashboardId) {
      this.dashboardService.getDashboardUsageData(dashboardId).subscribe({
        next: (res: any) => {
          bookmarksCount = res.bookmarkCount;
          likesCount = res.likeCount;
          userCount = res.userCount;
  
          //Dialog data to be passed to the confirmation popup
          const dialogData = {
            headerText: `Are you sure you want to delete ${dashboardName}?`,
            actionMsg: `Deleting the dashboard will impact`,
            users: `(${userCount} Dashboard${userCount !== 1 ? 's' : ''})`,
            likes: `(${this.formatLikes(likesCount)} likes)`,
            bookmarks:`(${bookmarksCount} Individual${bookmarksCount !== 1 ? 's' : ''})`,
            confirmBtnText: 'Continue',
            cancelBtnText: 'Cancel',
            subMessageRequired: true,
            isCancelBtnRequired: true,
            editOrDelete: true,
            action: 'delete'
          };
  
          this.handleEditOrDeteteDashboard(dialogData, dashboardId);
        },
        error: (error) => {
          this.alertService.error(error.error.error || 'Something went wrong, please try again');
        },
      });
    }

  }

  //Opens calendar on clicking the icon
  handleOpenDateRangePicker(): void {
    setTimeout(() => {
      this.dateRangePicker.open();
    }, 0);
  }

  //method which helps to set the placeholder based on quick select range
  handleDateRangeChange(event: any) {
    const selectedRangeLabel = event.label;
    this.dashboardDateSelected = {
      startDate: dayjs(event.dates[0]),
      endDate: dayjs(event.dates[1])
    }
    this.dateRangeSelected = false;
    this.isQuickSelect = true;
    this.isDashboardUpdated = true; // Set the flag to true when the dashboard is updated.
    this.selectedRangeLabel = selectedRangeLabel;
    this.setDateRangeLabel(selectedRangeLabel);
    let dashboardSelectedDate = {
      startDate: dayjs(event.dates[0]).format('YYYY-MM-DD'),
      endDate: dayjs(event.dates[1]).format('YYYY-MM-DD')
    }
    this.dashboardService.dashboardDate = dashboardSelectedDate;
    if (this.viewMode) { // Save the dashboard configuration if the view mode is enabled.
      this.saveDashboardConfig(this.selectedDashboard.dashboardId);
    }
  }

  setDateRangeLabel(selectedRangeLabel: string, isFromSettings?: boolean, fromWidgetConfig?: boolean) {
    let formattedValue = '';
    if (selectedRangeLabel === DateRangeLabel.Today) {
      this.dateRangeSelected = isFromSettings ? false : true;
      const today = dayjs().format('MMM DD, YYYY');
      formattedValue = `Today (${today})`;
    }
    else if (selectedRangeLabel === DateRangeLabel.Last3Days) {
      const startDate = dayjs().subtract(2, 'days').format('MMM DD, YYYY');
      const endDate = dayjs().format('MMM DD, YYYY');
      formattedValue = `Last 3 Days (${startDate} - ${endDate})`;
    } else if (selectedRangeLabel === DateRangeLabel.LastWeek) {
      const startDate = dayjs().subtract(6, 'days').format('MMM DD, YYYY');
      const endDate = dayjs().format('MMM DD, YYYY');
      formattedValue = `Last Week (${startDate} - ${endDate})`;
    } else if (selectedRangeLabel === DateRangeLabel.LastMonth) {
      const startDate = dayjs().subtract(1, 'month').add(1, 'days').format('MMM DD, YYYY');
      const endDate = dayjs().format('MMM DD, YYYY');
      formattedValue = `Last Month (${startDate} - ${endDate})`;
    } else if (selectedRangeLabel === DateRangeLabel.Last6Months) {
      const startDate = dayjs().subtract(6, 'months').add(1, 'days').format('MMM DD, YYYY');
      const endDate = dayjs().format('MMM DD, YYYY');
      formattedValue = `Last 6 Months (${startDate} - ${endDate})`;
    } else if (selectedRangeLabel === DateRangeLabel.LastYear) {
      const startDate = dayjs().subtract(12, 'months').add(1, 'days').format('MMM DD, YYYY');
      const endDate = dayjs().format('MMM DD, YYYY');
      formattedValue = `Last Year (${startDate} - ${endDate})`;
    }
    if (!fromWidgetConfig) {
      this.updateInputValue(formattedValue, isFromSettings);
    }
    return formattedValue;
  }

  //format of placeholder
  updateInputValue(formattedValue: string, isFromSettings?: boolean) {
    setTimeout(() => {
      if (!isFromSettings) {
        if (this.dateRangePickerInput) {
          this.dateRangePickerInput.nativeElement.value = formattedValue;
        }
      } else {
        this.chartsList[this.selectedChartDateIndex].chartDateFormatted = formattedValue;
      }
    }, 0);
  }

  //Dates updated manually
  onDatesUpdated(): void {
    if (!this.isQuickSelect) {
      //increasing the width of placeholder to accomodate entire text if reqquired.
      this.dateRangeSelected = true;
      this.selectedRangeLabel = DateRangeEnum.CUSTOM;
      let dashboardSelectedDate = {
        startDate: dayjs(this.dashboardDateSelected.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(this.dashboardDateSelected.endDate).format('YYYY-MM-DD')
      }
      this.dashboardService.dashboardDate = dashboardSelectedDate;
      if (this.dashboardCalendarChanged && !this.viewMode) {
        this.isDashboardUpdated = true; // Set the flag to true when the dashboard is updated.
      }
      if (this.viewMode && this.dashboardCalendarChanged) { // Save the dashboard configuration if the view mode is enabled.
        this.saveDashboardConfig(this.selectedDashboard.dashboardId);
      }
    }
    setTimeout(() => this.isQuickSelect = false, 0);
    this.chartsList = ObjectUtil.deepClone(this.chartsList);
  }

  calendarClicked() {
    this.dashboardCalendarChanged = true; // Set the flag to true when the calendar is clicked.
  }

  //Method to handle the dashboard add charts
  clickedAddCharts() {
    this.clickedCharts = true;
  }

  //Method to handle the dashboard close
  onMenuClose() {
    this.clickedCharts = false;
  }

  //Method to handle the dashboard edit
  handleEditOrDeteteDashboard(dialogData: any, dashboardId:string) {
    let popupConfig: any = {
      panelClass: 'fs-mat-dialog-container-confirmation',
      width: '432px',
      height: 'auto',
      overflow: "hidden !important"
    };
    const dialogRef = this.dialogRef.open(ConfirmationPopupComponent, { data: dialogData, ...popupConfig });

    dialogRef.afterClosed().subscribe(result => {
      //If user has confirmed the change it gets inside here and confirm popup will be closed else, the pop-up model will be closed from confirm model component.
      if (result == 'confirm') {
        if (dialogData.action === 'delete') {
          this.dashboardService.deleteDashboard(dashboardId).subscribe({
            next: (res: any) => {
              const dashboardName = this.selectedDashboardOption?.name || this.selectedDashboard?.name;
              this.getDefaultDashboardList();
              this.alertService.success(`Deleted ${dashboardName} successfully`);
            },
            error: (error) => {
              this.alertService.error(error.error.error || 'Something went wrong, please try again');
            },
          });
        }
      }
    });
  }

  //Method to handle the dashboard export
  export(exportType: componentType, chart?: Chart) {
    
    let popupConfig: any = {
      position: { top: '100px' },
      panelClass: 'export-dashboard-dialog',
      width: '600px',
      height: '420px',
      maxHeight: '420px',
      overflow: "auto !important"
    };

    let chartdata: any = {};
    let selectedChart: any;
    
    if (exportType != componentType.DASHBOARD && chart) {

      const widgetLayoutComponent = this.widgetLayoutComponents.find(component => {
        return component.widgetObj.id == chart.id;
      });
      
      selectedChart = {
        id: chart?.id,
        name: chart?.name,
        checked: true
      }

      chartdata = {
        isIndividual: true,
        dashBoardInfo: this.selectedDashboard,
        charts: [selectedChart],
        widgetLayoutComponent: widgetLayoutComponent ?? null
      };

    } else {
      
      chartdata = {
        isIndividual: false,
        dashBoardInfo: this.selectedDashboard,
        charts: this.chartsList
        .slice() // Create a shallow copy to avoid mutating the original array
        .sort((previousChart: any, nextChart: any) => previousChart.y - nextChart.y || previousChart.x - nextChart.x) // Compact sorting logic
        .map((chart: any) => ({
          id: chart.id,
          name: chart.name,
          checked: true,
          xaxis: chart.x,
          yaxis: chart.y
        })),
        widgetLayoutComponents: this.widgetLayoutComponents
      };
    }

    this.dialogRef.open(DashboardExportLayoutComponent, { data: chartdata, ...popupConfig });

  }

  editDashboard() {

    if (this.selectedDashboard) {
      this.dashboardService.createNewDashboard(this.crudOperationList.edit, this.selectedDashboard, false);
      this.saveDashboardLabel = 'SAVE DASHBOARD';
      }
  }

  // ******************************** Common Framework Methods ******************************** //

  // Please add the below methods in the common service and use it in the component for common framework methods

  /**
   * Determines if the given dashboard is personal.
   *
   * @param dashboard - The dashboard object to check.
   * @returns A boolean indicating whether the dashboard is personal.
   */
  isPersonal(dashboard: Dashboard | Chart | any) {
    return this.commonService.isEntityPersonal(dashboard);
  }

  /**
   * Determines if the given chart is personal to the user.
   * @param {Chart} chart - The chart to check.
   * @returns {boolean} - True if the chart is personal, false otherwise.
   */
  isChartPersonal(chart: Chart) {
    return this.commonService.isChartPersonal(chart);
  }

  /**
   * Checks if the given dashboard is shared by others.
   *
   * @param dashboard - The dashboard object to check.
   * @returns A boolean indicating whether the dashboard is shared by others.
   */
  isSharedByOthers(dashboard: Dashboard | Chart | any) {
    return this.commonService.isEntitySharedByOthers(dashboard);
  }

  /**
   * Checks if the given entity is shared by me.
   *
   * @param dashboard - The dashboard object to check.
   * or
   * @param chart - The chart object to check. 
   * @returns A boolean indicating whether the entity is shared by me.
   */
  isSharedByMe(entity: Dashboard | Chart | any) {
    return this.commonService.isEntitySharedByMe(entity);
  }

  /**
   * Checks if the given dashboard is user's dashboard.
   *
   * @param dashboard - The dashboard object to check.
   * @returns A boolean indicating whether the dashboard is owned.
   */
  isUsersDashboard(dashboard: Dashboard | Chart | any) {
    return this.commonService.isUsersEntity(dashboard);
  }

  /**
   * Formats the given date using a specific tooltip date format.
   *
   * @param date - The date to be formatted.
   * @returns The formatted date string.
   */
  formatDescDate(date: any) {
    return this.commonService.descToolTipDateFormat(date);
  }

  // Method to handle the like count format
  formatLikes(count: number) {
    return this.commonService.likeCountFormat(count);
  }

  // Method to handle the dashboard option hover
  onMouseOver(item: any) {
    this.hoveredDashboard = item;
  }

  // Method to handle the dashboard option hover out
  onMouseOut() {
    this.enableTableEdit = false;
    this.hoveredDashboard = null;
  }

  //Method to handle the like
  updateEntityLike(entity: any, from:string) {
    if (!this.previewMode) {
      const id = entity.id ? entity.id : '';

      entity.likedByLoggedInUser = !entity.likedByLoggedInUser;
      if (entity.likedByLoggedInUser) {
        entity.noOfLikes += 1;
      } else {
        entity.noOfLikes -= 1;
      }

      let payload = {
        component: from == 'dashboard' ? "TABLEMAKER_DASHBOARDS" : "TABLEMAKER_DASHBOARD_WIDGETS",
        category: 'LIKE',
        value: entity.likedByLoggedInUser
      };

      this.dashboardService.updateLikeOrBookmark(id, payload).subscribe({
        next: (res: any) => { },
        error: (error) => {
          this.alertService.error(error.error.error || 'Something went wrong, please try again');
        }
      });
    }
  }

  // ****************** Common methods for common framework ends here ****************************//

  //Save the details for chart selected
  openSettingsMenu(chart: Chart) {
    this.selectedChart = chart;
    this.setChartWidgetMenuSettingsOptions(chart?.chartType ?? '');
  }

  setChartWidgetMenuSettingsOptions(chartType: string) {
    let widgetMenuConfig: any = chartBuilderChartConfig[chartType as keyof typeof chartBuilderChartConfig];
    
    this.hideDataRange = (widgetMenuConfig) ? widgetMenuConfig.includes('hideDataRange') : false;
    this.hideGroupBy = (widgetMenuConfig) ? widgetMenuConfig.includes('hideGroupBy') : false;
    this.hideViewBy = (widgetMenuConfig) ? widgetMenuConfig.includes('hideViewBy') : false;
  }

  //Method which sets placeholder is same as dashbaord is selected
  setDateOnSettings(event: any, chart: Chart) {
    const chartIndex = this.chartsList.findIndex((chartItem: any) => chartItem?.id === chart?.id);
    this.chartsList[chartIndex].dateRangeSameAsDashboard = event;
    if (event) {
      this.isDashboardUpdated = true; // Set the flag to true when the dashboard is updated.
      if (this.viewMode) { // Save the widget configuration if the view mode is enabled.
        this.saveWidgetConfig(this.selectedDashboard.dashboardId, chart, chartIndex);
      } else {
        // deepcloning to call the preview API
        this.chartsList[chartIndex] = ObjectUtil.deepClone(this.chartsList[chartIndex]);
      }
    }
  }

  //Method which sets the placeholder for viewby and groupby
  setGroupAndView(interval: string, isGroupBy: boolean, chart: Chart) {
    const chartIndex = this.chartsList.findIndex((chartItem: any) => chartItem?.id === chart?.id);
    if (isGroupBy) {
      this.chartsList[chartIndex].groupBy = interval;
      this.chartsList[chartIndex].groupBySameAsDashboard = interval === 'Same as Dashboard';
    } else {
      this.chartsList[chartIndex].viewBy = interval;
    }  
    if (this.viewMode) { // Save the widget configuration if the view mode is enabled.
      this.saveWidgetConfig(this.selectedDashboard.dashboardId, chart, chartIndex);
    } else {
      // deepcloning to call the preview API
      this.chartsList[chartIndex] = ObjectUtil.deepClone(this.chartsList[chartIndex]);
    }
    this.isDashboardUpdated = true; // Set the flag to true when the dashboard is updated.
  }

  //Method to handle the date change on settings
  handleDateChangeOnSettings(event: any, chart: Chart) {
    const selectedRangeLabel = event.label;
    if (event?.dates && event?.dates?.length === 2) {
      const chartIndex = this.chartsList.findIndex((chartItem: Chart) => chartItem?.id === chart?.id);
      this.chartsList[chartIndex].dateRangeSameAsDashboard = false;
      this.chartsList[chartIndex].startDateTime = dayjs(event?.dates[0]);
      this.chartsList[chartIndex].endDateTime = dayjs(event?.dates[1]);
      this.chartsList[chartIndex].selectedRangeLabel = selectedRangeLabel;
      this.selectedChartDateIndex = chartIndex;
      this.isDashboardUpdated = true; //  Set the flag to true when the dashboard is updated.
    }

    this.setDateRangeLabel(selectedRangeLabel, true);
  }

  //Method which triggers on edit chart
  openEditOrViewChart() {
    if (this.selectedChart?.isEmbedded) {
      this.openEmbedExternal(true);
    } else {
      this.widgetLayoutService.createOrUpdateCharts(true, this.siteIds, this.selectedChart);
    }
  }

  //When date range isselected manually updated the placeholder
  onDatesUpdatedOnSettings(event: any, chart: Chart) {
    const startDate = event?.startDate ? dayjs(event.startDate) : null;
    const endDate = event?.endDate ? dayjs(event.endDate) : null;

    // Update the placeholder format: 'MMM DD, YYYY to MMM DD, YYYY'
    if (startDate && endDate) {
      const chartIndex = this.chartsList.findIndex((chartItem: any) => chartItem?.id === chart?.id);
      this.chartsList[chartIndex].dateRangeSameAsDashboard = false;
      this.chartsList[chartIndex].startDateTime = dayjs(event?.startDate);
      this.chartsList[chartIndex].endDateTime = dayjs(event?.endDate);
      this.chartsList[chartIndex].chartDateFormatted = `${startDate?.format('MMM DD, YYYY')} to ${endDate?.format('MMM DD, YYYY')}`;
      this.isDashboardUpdated = true; // Set the flag to true when the dashboard is updated.
      if (this.viewMode) { // Save the widget configuration if the view mode is enabled.
        this.saveWidgetConfig(this.selectedDashboard.dashboardId, chart, chartIndex);
      } else {
        // deepcloning to call the preview API
        this.chartsList[chartIndex] = ObjectUtil.deepClone(this.chartsList[chartIndex]);
      }
    }
  }

  //Once date range is selected close the sub-panel
  closeMenu() {
    this.openDateRangeTrigger.closeMenu();
    this.dateRangeSubMenuTrigger.closeMenu();
    this.widgetMenuSettingsTrigger.closeMenu();
  }

  openEmbedExternal(isEdit: boolean) {
    if (this.chartsList?.length >= 15) return;
    const dialogRef = this.dialogRef.open(EmbedExternalLayoutComponent, {
      panelClass: 'externalBuilderModal',
      data: {
        isEdit: isEdit,
        selectedDashboard: this.selectedDashboard,
        widgetData: isEdit ? this.selectedChart : null
      }
    })
    dialogRef?.updatePosition({ top: '20px' });
    dialogRef.afterClosed().subscribe(result => {
      //If user has confirmed the change it gets inside here and the API to add the widget in dasboard should be called here.
      if (result.confirm) {
        let externalWidget = {
          id: result.widgetData?.id,
          widgetId: result.widgetData?.id,
          name: result.externalWidgetData?.name,
          owner: ObjectUtil.deepClone(result.widgetData?.owner),
          widgetUrl: result.externalWidgetData?.externalWidgetUrl,
          isEmbedded: true,
          shared: false,
          startDateTime: dayjs().startOf('day'),
          endDateTime: dayjs().endOf('day'),
          viewBy: viewByEnumLabel.SITE,
          groupBySameAsDashboard: true,
          groupBy: 'Same as Dashboard',
          dateRangeSameAsDashboard: true
        }
        if (!isEdit) {
          this.chartsList.push(externalWidget);
        } else {
          let index = this.chartsList.findIndex((chart: Chart) => chart?.id === result.widgetData?.id);
          if (index > -1) {
            this.chartsList[index] = ObjectUtil.deepClone(externalWidget);
          }
        }
        this.selectedDashboard.widgetList = ObjectUtil.deepClone(this.chartsList);
        this.filterWidgetListOnDashboard(this.selectedDashboard.widgetList);
        this.commonService.setSelectDashboard(this.selectedDashboard);
        this.isDashboardUpdated = true;
      }
    })
  }

  /**Method to handle the chart remove
   * Gets the count of chart usage. Displays confirmation popup with the count of users, likes and bookmarks.
   * If user confirms to remove, the chart will be removed from the dashboard selected. **/
  removeChart(chart: Chart) {
    const id = chart?.widgetId ? chart?.widgetId : chart?.id;
    if (chart.isEmbedded) {
      const dialogData = {
        headerText: `Are you sure you want to remove ${this.selectedChart?.name}?`,
        actionMsg: ``,
        users: ``,
        confirmBtnText: 'Remove',
        cancelBtnText: 'Cancel',
        subMessageRequired: true,
        isCancelBtnRequired: true,
        discardMessage: true,
        action: 'remove'
      };
      this.handleExternalWidgetRemove(dialogData, chart);
    } else {
      const dialogData = {
        headerText: `Are you sure you want to remove ${this.selectedChart?.name}?`,
        actionMsg: ``,
        users: '',
        confirmBtnText: 'Remove',
        cancelBtnText: 'Cancel',
        subMessageRequired: true,
        isCancelBtnRequired: true,
        removeDasboard: true,
        action: 'remove'
      };
      if (this.commonService.isEntitySharedByMe(this.selectedDashboard)) {
        this.dashboardService.getChartUsageData(id).subscribe((chartUsageCount: any) => {
          if (chartUsageCount) {
            dialogData.users = `${chartUsageCount?.dashboardCount} ${chartUsageCount?.dashboardCount !== 1 ? 'dashboards' : 'dashboard'}.`;
            dialogData.actionMsg = `Removing the chart will impact users of`;
            this.handleChartRemoveDelete(dialogData, chart);
          }
        });
      } else {
        this.handleChartRemoveDelete(dialogData, chart);
      }
    }
  }

  /**
   * Handles the removal of an external widget from the dashboard layout.
   * Opens a confirmation dialog and, upon confirmation, removes the specified chart from the charts list.
   * Displays a success alert upon successful removal.
   *
   * @param dialogData - Data to be passed to the confirmation dialog.
   * @param chart - The chart object to be removed.
   */
  handleExternalWidgetRemove(dialogData: any, chart: Chart) {
    let self = this;
    let popupConfig: any = {
      panelClass: 'fs-mat-dialog-container-confirmation',
      width: '432px',
      height: 'auto',
      overflow: "hidden !important"
    };
    const dialogRef = this.dialogRef.open(ConfirmationPopupComponent, { data: dialogData, ...popupConfig });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.isDashboardUpdated = true; // Set the flag to true when the dashboard is updated.
        self.chartsList = self.chartsList.filter((chartObj: Chart) => chartObj?.id !== chart?.id);
        this.alertService.success(`Removed ${chart.name} successfully`);
      }
    });
  }

  /**
   * Handles the removal and deletion of a chart from the dashboard.
   * Opens a confirmation dialog and, upon confirmation, removes the chart.
   *
   * @param {any} dialogData - Data to be passed to the confirmation dialog.
   * @param {Chart} chart - The chart to be removed.
   * @returns {void}
   */
  handleChartRemoveDelete(dialogData: any, chart: Chart) {
    let popupConfig: any = {
      panelClass: 'fs-mat-dialog-container-confirmation',
      width: '432px',
      height: 'auto',
      overflow: "hidden !important"
    };
    const dialogRef = this.dialogRef.open(ConfirmationPopupComponent, { data: dialogData, ...popupConfig });

    dialogRef.afterClosed().subscribe(result => {
      // If user has confirmed the change it gets inside here and confirm popup will be closed else, the pop-up model will be closed from confirm model component.
      if (result === 'confirm') {
        const widgetId = chart?.widgetId ? chart?.widgetId : chart?.id;
        this.isDashboardUpdated = true; // Set the flag to true when the dashboard is updated.
        this.alertService.success(`Removed ${chart.name} successfully`);
        this.removeWidgetFromList(widgetId, chart);
      }
    });
  }

  removeWidgetFromList(widgetId: string, chart: Chart) {
    this.chartsList = this.chartsList.filter((item: Chart) => item?.id !== widgetId);
    if (this.selectedDashboard?.widgetList)
      this.selectedDashboard.widgetList = this.selectedDashboard?.widgetList.filter((item: any) => item?.widgetId !== widgetId);
    this.commonService.setSelectDashboard(this.selectedDashboard);
    this.widgetLayoutService.refreshWidgetListSubject.next({ "chart": chart, "removedChart": true });
  }

  //Method to get llogged in user's dashbaords which needs to be set in the copy and move sub-menu
  getDashboardListOfLoggedInUser() {
    this.dashboardService.getUserDashboardsList().subscribe((userDashboardList: any) => {
      this.loggedInUserDashboardList = userDashboardList ?? [];
      this.duplicateDashboards = ObjectUtil.deepClone(this.loggedInUserDashboardList);
    });
  }

  //Method to represent check and uncheck. Based on this items will be in unchecked ate innitially
  dashBoardIsChecked(chartId: string, dashboardId: string): boolean {
    return this.copiedDashBoardsMap[chartId]?.includes(dashboardId) || false;
  }

  //If the dashboard is selected for copy, the chart will be copied to the selected dashboard
  onDashboardSelectedForCopy(event: MatCheckboxChange, widgetId: string, dashboardId: string) {
    if (!this.copiedDashBoardsMap[widgetId]) {
      this.copiedDashBoardsMap[widgetId] = [];
    }
    if (event.checked) {
      // Add item if checked
      this.copiedDashBoardsMap[widgetId].push(dashboardId);
      this.copyChartToDashboard(widgetId, dashboardId);
    } else {
      // Remove item if unchecked
      this.copiedDashBoardsMap[widgetId] = this.copiedDashBoardsMap[widgetId].filter(id => id !== dashboardId);
    }
  }


  //Once the sub panel of move is closed, the search text will be cleared
  onMoveCopySubMenuClosed() {
    this.searchTextForDashBoard = '';
    this.dashBoardForMoveCopy = ObjectUtil.deepClone(this.duplicateDashboards);
    this.copiedDashBoardsMap = {};
  }

  //Method which calls api to copy thee chart to dashboard and dsiplays corresponding success or error pop-up
  copyChartToDashboard(widgetId: string, dashboardId: string) {

    //Find the details of selected dashboard to show name in success toast or error pop-up
    const findTargetDashBoardData = this.duplicateDashboards.find((dashboard: any) => dashboard.id === dashboardId);

    //API call to copy the chart to dashboard
    this.dashboardService.copyWidgetToDashboard({ widgetId: widgetId, sourceDashBoardId: this.selectedDashboard?.dashboardId || this.selectedDashboard?.id, targetDashBoardId: dashboardId }).pipe(
      map(() => ({ item: widgetId, success: true })),
      catchError(error => of({ item: widgetId, success: false, status: error.status, errorMsg: error.error.error }))
    ).subscribe((result: any) => {

      let isLimitExceeds = false;
      if (result?.errorMsg?.includes('15')) { // If 15 text contains in the error message, then limit exceeds
        isLimitExceeds = true;
      }
      //If the API call is success, show success toast else show error pop-up
      if (!result.success) {
        const dialogData = {
          headerText: `Error`,
          actionMsg: `Failed to copy the chart`,
          dashboardName: findTargetDashBoardData?.name,
          confirmBtnText: 'OKAY',
          subMessageRequired: false,
          isCancelBtnRequired: false,
          copyMoveDashboardWidgetExists: !isLimitExceeds,
          copyMoveDashboardWidgetExceeded: isLimitExceeds,
          action: 'copy'
        };

        let popupConfig: any = {
          panelClass: 'fs-mat-dialog-container-confirmation',
          width: '440px',
          height: 'auto',
          overflow: "hidden !important"
        };
        this.dialogRef.open(ConfirmationPopupComponent, { data: dialogData, ...popupConfig });
      } else {
        this.alertService.success(`Chart successfully copied to the ${findTargetDashBoardData?.name}`);
      }

    });


  }


  //Method which calls api for moving chart from one dashboard to another
  dashBoardSelectedForMove(dashboardSelected: any, chart: Chart) {
    //API call to copy the chart to dashboard
    let widgetId = chart?.id;
    this.dashboardService.moveWidgetToDashboard({ widgetId: widgetId, sourceDashBoardId: this.selectedDashboard?.dashboardId || this.selectedDashboard?.id, targetDashBoardId: dashboardSelected?.id }).pipe(
      map(() => ({ item: widgetId, success: true })),
      catchError(error => of({ item: widgetId, success: false, status: error.status, errorMsg: error.error.error }))
    ).subscribe((result: any) => {
      let isLimitExceeds = false;
      if (result?.errorMsg?.includes('15')) { // If 15 text contains in the error message, then limit exceeds
        isLimitExceeds = true;
      }
      //If the API call is success, show success toast else show error pop-up
      if (!result.success) {
        const dialogData = {
          headerText: `Error`,
          actionMsg: `Failed to move the chart`,
          dashboardName: dashboardSelected?.name,
          confirmBtnText: 'OKAY',
          subMessageRequired: false,
          isCancelBtnRequired: false,
          copyMoveDashboardWidgetExists: !isLimitExceeds,
          copyMoveDashboardWidgetExceeded: isLimitExceeds,
          action: 'move'
        };

        let popupConfig: any = {
          panelClass: 'fs-mat-dialog-container-confirmation',
          width: '440px',
          height: 'auto',
          overflow: "hidden !important"
        };
        this.dialogRef.open(ConfirmationPopupComponent, { data: dialogData, ...popupConfig });
      } else {
        if (chart.isEmbedded) {
          this.updateChartList(widgetId);
        }
        this.alertService.success(`Chart successfully moved to the ${dashboardSelected?.name}`);
        this.chartsList = this.chartsList.filter((item: Chart) => item?.id !== widgetId);
      }

    });
  }

  get gridsterOptions(): GridsterConfig {
    return this.gridsterService.gridsterOptions;
  }

  /**Method to handle the search text on dashboard list. Every chart will have it's own dashboard list which will be fetched and looked for search result 
   Ex: if the chartList length is 2 with ids 12 and 23 then searchStateForMove = {12:{searchText:'',results:[]},23:{searchText:'',results:[]}}**/
  searchDashboardForMoveCopy(searchText: String) {
    if (searchText && searchText.length) {
      const searchDashboards = ObjectUtil.deepClone(this.duplicateDashboards);
      const filteredResults = searchDashboards.filter((dashbaord: Chart) => dashbaord?.name?.toLowerCase()?.includes(searchText?.toLowerCase()));
      this.dashBoardForMoveCopy = filteredResults.length > 0 ? filteredResults : [{ name: 'No Result Found' }];
    } else {
      this.dashBoardForMoveCopy = ObjectUtil.deepClone(this.duplicateDashboards);
    }
  }

  dashboardGroupByChanged(fromClick: boolean) {
    this.dashboardService.dashboardGroupBy = this.dashboardGroupBy;
    if (fromClick) this.isDashboardUpdated = true; // Set the flag to true when the dashboard is updated.
    if (this.viewMode && fromClick) { // Save the dashboard configuration if the view mode is enabled.
      this.saveDashboardConfig(this.selectedDashboard?.dashboardId);
    }
    this.chartsList = ObjectUtil.deepClone(this.chartsList);
  }   

  ngOnDestroy() {

    // Unsubscribe to prevent memory leaks
    if (this.dashboardChangeSubscription) {
      this.dashboardChangeSubscription.unsubscribe();
    }

    if (this.gridsterChangeSubscription) {
      this.gridsterChangeSubscription.unsubscribe();
    }
  }

  saveDashboardConfig(dashboardId: string, fromBulkSave?: boolean, isOwnerSave?: boolean) {
    const labels: { [key: string]: string } = timeIntervalsEnum;
    const dateLabels: { [key: string]: string } = DateRangeEnum;
    const scopeData = this.dashboardService.dashboardConfig;
    const dashboardConfigPayload: DashboardConfig = {
      dashboardId: dashboardId,
      groupBy: labels[this.dashboardGroupBy],
      viewBy: viewByEnum.Site,
      startDate: this.dashboardDateSelected?.startDate?.format('YYYY-MM-DD'),
      endDate: this.dashboardDateSelected?.endDate?.format('YYYY-MM-DD'),
      excludedCcuRefs: scopeData?.excludedCcuRefs ?? [],
      excludedDeviceRefs: scopeData?.excludedDeviceRefs ?? [],
      excludedEquipRefs: scopeData?.excludedEquipRefs ?? [],
      excludedFloorRefs: scopeData?.excludedFloorRefs ?? [],
      excludedZoneRefs: scopeData?.excludedZoneRefs ?? [],
      dateRange: dateLabels[this.selectedRangeLabel],
      name: this.selectedDashboard.name,
      siteRefs: scopeData?.siteRefs,
      comment: 'Test',
    };
    if (this.selectedDashboard?.dashboardConfig?.configId) {
      dashboardConfigPayload.configId = this.selectedDashboard?.dashboardConfig?.configId;
    }
    this.dashboardService.saveDashboardConfiguration(dashboardConfigPayload).subscribe({
      next: (res: any) => {
        this.dashboardCalendarChanged = false; // Reset the flag to false when the dashboard configuration is saved.
        if (!isOwnerSave && fromBulkSave) {
          if (fromBulkSave) { // Save the widget configuration only if the bulk save is enabled.
          this.saveBulkWidgetConfig(dashboardId);
          }
        } else {
          this.selectDashboard(this.selectedDashboard);
        }
      },
      error: (error:any) => {
        this.alertService.error(error?.error?.error || 'Error Saving Dashboard Configuration');
        //Resetting the flags
        this.isDashboardUpdated = true;
        this.dashboardService.isDashboardEdited = true;
        this.crudActionLabel = CRUDOperationList.edit;
        this.viewMode = false;
      }
    });
  }
  /**
   * Saves the widget configuration for a specific dashboard.
   * 
   * @param dashboardId - The ID of the dashboard.
   * @param chart - The chart object containing the widget configuration.
   */

  /**
   * Saves the widget configuration for a given dashboard.
   *
   * @param dashboardId - The ID of the dashboard to which the widget belongs.
   * @param chart - The chart object containing the widget configuration.
   * @param chartIndex - The index of the chart in the charts list.
   *
   * This method checks if the chart is not embedded. If it is not, it generates a widget configuration payload
   * and sends it to the dashboard service to save the configuration. On successful save, it updates the chart's
   * widget configuration with the returned configuration ID and widget ID. If an error occurs during the save
   * operation, it displays an error message using the alert service.
   */
  saveWidgetConfig(dashboardId: string, chart: any, chartIndex: number) {
    if(!chart.isEmbedded) {
      const widgetConfigPayload = this.generateWidgetConfigPayload(dashboardId, chart);
      this.dashboardService.saveWidgetConfiguration(widgetConfigPayload).subscribe({
        next: (res: any) => {
          const configId = res.id;
          // Update the widget configuration with the returned configuration ID and widget ID
          this.chartsList[chartIndex].widgetConfig.configId = ObjectUtil.deepClone(configId);
          this.chartsList[chartIndex].widgetConfig.widgetId = ObjectUtil.deepClone(chart.id);
          this.chartsList[chartIndex] = ObjectUtil.deepClone(this.chartsList[chartIndex]);
        }, error: (error) => {
          this.alertService.error(error?.error?.error || 'Something went wrong, please try again');
        }
      });
    }
  }

  /**
   * Saves the bulk widget configuration for a dashboard.
   * 
   * @param {string} dashboardId - The ID of the dashboard.
   * @returns {void}
  */

  saveBulkWidgetConfig(dashboardId: string) {
    const widgetConfigPayload: WidgetConfig[] = [];
    if (this.chartsList?.length > 0) {
      this.chartsList.forEach((chart: any) => {
        if (!chart.isEmbedded) {
          const widgetConfig = this.generateWidgetConfigPayload(dashboardId, chart);
          widgetConfigPayload.push(widgetConfig);
        }
      });
      this.dashboardService.saveBulkWidgetConfiguration(widgetConfigPayload, dashboardId).subscribe({
        next: (res: any) => {
          console.log('Bulk save success');
          this.setDashboardDataOnSave(dashboardId);
        },
        error: (error) => {
          this.alertService.error(error?.error?.error || 'Something went wrong, please try again');
        }
      });
    }
  }
  
  //Method to handle the save of the dashboard
  setDashboardDataOnSave(dashboardId: string) {
    let selectedDashboard: Dashboard | any = {};
    selectedDashboard = this.dashboardList.find((dashboard) => dashboard.id === dashboardId);
    if (selectedDashboard) {
      this.commonService.setSelectDashboard(selectedDashboard);
      this.selectedDashboard.id = selectedDashboard?.id;
      this.selectedDashboard.dashboardId = selectedDashboard.id;
      this.selectDashboardFromNavigation(this.selectedDashboard); // Refresh the dashboard list after the bulk save.
    } else {
      this.getDefaultDashboardList();
    }
  }

  /**
   * Generates a widget configuration payload.
   * 
   * @param dashboardId - The ID of the dashboard.
   * @param chart - The chart object containing the configuration details.
   * @returns The widget configuration payload.
   */

  generateWidgetConfigPayload(dashboardId: string, chart: any) {
    const labels: { [key: string]: string } = timeIntervalsEnum;
    const dateLabels: { [key: string]: string } = DateRangeEnum;
    const viewByLabels: { [key: string]: string } = viewByEnum;
    const widgetConfigPayload: WidgetConfig = {
      dashboardId,
      widgetId: chart.id,
      groupBy: labels[chart.groupBy],
      viewBy: viewByLabels[chart.viewBy],
      startDate: chart?.startDateTime?.format('YYYY-MM-DD'), // Formart the date to 'YYYY-MM-DD' format
      endDate: chart?.endDateTime?.format('YYYY-MM-DD'), // Formart the date to 'YYYY-MM-DD' format
      excludedCcuRefs: chart.scopeSelectionData?.excludedCcuRefs ?? [],
      excludedDeviceRefs: chart.scopeSelectionData?.excludedDeviceRefs ?? [],
      excludedEquipRefs: chart.scopeSelectionData?.excludedEquipRefs ?? [],
      excludedFloorRefs: chart.scopeSelectionData?.excludedFloorRefs ?? [],
      excludedZoneRefs: chart.scopeSelectionData?.excludedZoneRefs ?? [],
      dateRange: dateLabels[chart.selectedRangeLabel] ? dateLabels[chart.selectedRangeLabel]: DateRangeEnum.CUSTOM,
      name: chart.name,
      siteRefs: chart.scopeSelectionData?.siteRefs,
      comment: 'Test',
      scopeSameAsDashboard: chart.scopeSameAsDashboard ?? true,
      groupBySameAsDashboard: chart.groupBySameAsDashboard ?? false,
      dateRangeSameAsDashboard: chart.dateRangeSameAsDashboard,
    };
    if (chart?.widgetConfig?.widgetId) {
      widgetConfigPayload.configId = chart.widgetConfig?.configId || null;
    }
    return widgetConfigPayload;
  }

  saveDashboard() {
    if (this.chartsList?.length > 0) {
      const dashBoardPayload: DashboardCreationData = this.formDashboardPayload(this.selectedDashboard);
      this.dashboardService.createDashboard(dashBoardPayload).subscribe({
        next: (res: any) => {
          if (this.crudActionLabel == this.crudOperationList.new) {
            this.alertService.success('Dashboard created successfully');
          } else {
            this.alertService.success('Dashboard saved successfully');
          }
          this.crudActionLabel = '';
          this.isDashboardUpdated = false;
          this.dashboardCalendarChanged = false;
          this.viewMode = true;
          this.dashboardService.isSaveasnewOrDuplicate = false; // Reset the flag to false when the dashboard is saved.
          this.dashboardService.isDashboardEdited = false; // Reset the flag to false when the dashboard is saved.
          // Update the dashboard list with the new dashboard details.
          const filteredDashboardList = this.filteredDashboardList.map((dashboard: any) => {
            if (dashboard.id === this.selectedDashboard.dashboardId) {
              dashboard.name = this.selectedDashboard.name;
              dashboard.what = this.selectedDashboard.what;
              dashboard.why = this.selectedDashboard.why;
              dashboard.shared = this.selectedDashboard.shared;
              dashboard.sharedTo = this.selectedDashboard.sharedTo;
              dashboard.noOfLikes = this.selectedDashboard.noOfLikes;
            }
            return dashboard;
          });
          this.filteredDashboardList = ObjectUtil.deepClone(filteredDashboardList);
          this.saveDashboardConfig(res.id, true);
          window.parent.postMessage(JSON.stringify({isDashBoardSaved:false}), '*');
        },
        error: (error) => {
          this.alertService.error(error?.error?.error || 'Something went wrong, please try again');
        }
      });
    }
  }

   // Method to handle the chart delete
   deleteChart(chart: Chart) {
    this.widgetLayoutService.openDeleteModal(chart);
  }

    /**
   * Checks if the dashboard data has been updated by comparing specific keys
   * between the current selected dashboard and a duplicate of the selected dashboard.
   **/
  isDashboardUpdatedInDuplicateMode() {
    const keysToCompare = ['why', 'what', 'shared', 'sharedTo', 'name'] as const;
    const previous_data = {
      why: this.duplicateSelectedDashboard.why,
      what: this.duplicateSelectedDashboard.what,
      shared: false,
      sharedTo: 'NONE',
      name: this.duplicateSelectedDashboard.name + ' - Copy'
    }
    this.isDashboardUpdated = keysToCompare.some(key =>
      key in this.dashboardService.dashboardData &&
      this.selectedDashboard[key] !== previous_data[key]
    );
  }

  saveAsNewDashboard() {
    if (this.selectedDashboard) {
      this.dashboardService.tempDashboardData = ObjectUtil.deepClone(this.selectedDashboard);
      this.dashboardService.tempChartsList = ObjectUtil.deepClone(this.chartsList);
      this.isDashboardUpdated = this.dashboardService.isDashboardEdited;
      this.viewMode = false;
      this.saveDashboardLabel = 'CREATE NEW DASHBOARD';
      this.dashboardService.isSaveasnewOrDuplicate = true;
      if (this.selectedDashboard?.dashboardConfig && this.selectedDashboard?.dashboardConfig?.configId) {
          this.selectedDashboard.dashboardConfig.configId = null;
          this.selectedDashboard.dashboardConfig.dashboardId = null;
      }
      this.selectedDashboard.dashboardId = '';
      const ownerDetails = { // Set the owner details for the new dashboard.
        userId: this.commonService.userId,
        emailAddress: this.commonService.loggedInUser.emailId,
        firstName: this.commonService.loggedInUser.firstName,
        lastName: this.commonService.loggedInUser.lastName
      };
      this.selectedDashboard.owner = ObjectUtil.deepClone(ownerDetails);
      if (!this.duplicateSelectedDashboard) {
        this.duplicateSelectedDashboard = {} as Dashboard;
      }
      this.duplicateSelectedDashboard.owner = ObjectUtil.deepClone(ownerDetails);
      this.selectedDashboard.name = this.dashboardService.dashboardData?.name;
      this.selectedDashboard.what = this.dashboardService.dashboardData?.what;
      this.selectedDashboard.why = this.dashboardService.dashboardData?.why;
      this.selectedDashboard.shared = this.dashboardService.dashboardData?.shared;
      this.selectedDashboard.sharedTo = this.dashboardService.dashboardData?.sharedTo;
      if (this.crudActionLabel != this.crudOperationList.duplicate) {
        this.dashboardService.dashboardData = ObjectUtil.deepClone(this.selectedDashboard);
      }
      this.dashboardService.dashboardData['owner'] = ObjectUtil.deepClone(ownerDetails);
      this.chartsList = this.chartsList.map((chart: Chart) => {
        if (chart.widgetConfig) {
          chart.widgetConfig.configId = null;
          chart.widgetConfig.dashboardId = null;
        }
        return chart;
      });
    }
  }

  //Duplicate Dashboard
  duplicateDashboard() {
    const selectedDashboard = ObjectUtil.deepClone(this.selectedDashboard);
    this.dashboardService.createNewDashboard(CRUDOperationList.duplicate, selectedDashboard, true);
    this.dashboardService.isSaveasnewOrDuplicate = true;
  }


  // Method to set the dashboard data while creating new dashboard.
  setDashboardData() {
    this.selectedDashboard = new Dashboard();
    this.selectedDashboard.name = this.dashboardService.dashboardData?.name;
    this.selectedDashboard.what = this.dashboardService.dashboardData?.what;
    this.selectedDashboard.why = this.dashboardService.dashboardData?.why;
    this.selectedDashboard.shared = this.dashboardService.dashboardData?.shared;
    this.selectedDashboard.sharedTo = this.dashboardService.dashboardData?.sharedTo;
    this.selectedDashboard.owner = {
      userId: this.commonService.userId,
      emailAddress: this.commonService.loggedInUser.emailId,
      firstName: this.commonService.loggedInUser.firstName,
      lastName: this.commonService.loggedInUser.lastName
    };
    if (this.dashboardService.isSaveasnewOrDuplicate) {
      this.selectedDashboard.widgetList = this.dashboardService.dashboardData?.widgetList ? ObjectUtil.deepClone(this.dashboardService.dashboardData?.widgetList): [];
      this.chartsList = this.dashboardService.dashboardData?.widgetList ? ObjectUtil.deepClone(this.dashboardService.dashboardData?.widgetList): [];
      this.dashboardDateSelected = { startDate: dayjs(), endDate: dayjs() };
      this.dashboardGroupBy = timeIntervalsEnumLabel.HOURLY;
      this.dashboardService.dashboardDate = this.dashboardDateSelected;
      this.dashboardService.dashboardGroupBy = this.dashboardGroupBy;
      this.dashboardService.dashboardConfig = null;
      this.dashboardService.dashboardScopeData = null;
      this.updateDashboardScopeData()
      this.commonService.setSelectDashboard(this.selectedDashboard);
    }
  }

  formDashboardPayload(dasboardData: Dashboard) {
    const dashboardLayout: any = {};
    const widgetList: WidgetData[] = [];
    this.chartsList.forEach((chart: Chart) => {
      dashboardLayout[chart.id] = {
        x: chart.x,
        y: chart.y,
        rows: chart.rows,
        cols: chart.cols,
        widgetId: chart.widgetId,
      };
      widgetList.push({
        widgetId: chart.id ?? chart.widgetId,
        externalWidget: chart.isEmbedded ? true : false
      });
    });
    const dashboardPayload: DashboardCreationData = {
      id: dasboardData?.dashboardId,
      name: dasboardData?.name,
      what: dasboardData?.what,
      why: dasboardData?.why,
      shared: dasboardData?.shared,
      sharedTo: dasboardData?.sharedTo,
      widgetList: widgetList,
      dashboardLayout
    };
    return dashboardPayload;
  }

  disableDashboard() {
    return (this.crudActionLabel == this.crudOperationList.new || this.crudActionLabel == this.crudOperationList.duplicate) ? (this.chartsList?.length === 0) : !this.isDashboardUpdated;
  }

  editNewlyCreatedDashboard() {
    if (this.crudActionLabel == this.crudOperationList.new) {
      this.dashboardService.dashboardData.widgetList = ObjectUtil.deepClone(this.chartsList);
    }
    this.dashboardService.createNewDashboard(this.crudActionLabel, this.dashboardService.dashboardData, false);
    this.dashboardService.isSaveasnewOrDuplicate = true;
  }

  /**
   * Discards the changes made to the dashboard.
   * Resets the dashboard data to the original values.
   * 
   * @remarks
   * This method performs the following actions:
   * - Resets the dashboard date selection to the original values.
   * - Resets the dashboard group by selection to the original value.
   * - Sets the dashboard update flag to false.
   * - Updates the dashboard scope data with the original configuration.
   * - Sets the selected dashboard to the duplicate selected dashboard.
   * - Sets the charts list to the duplicate selected dashboard's widget list.
   * - Updates the start and end date time of each chart in the charts list.
   * - Updates the scope selection data of each chart in the charts list.
   * - Updates the dashboard service configuration data with the duplicate selected dashboard's configuration.
   * 
   * @returns void
   */
  discardChanges() {

    if (this.duplicateSelectedDashboard.dashboardConfig) { // If the duplicate selected dashboard has a configuration, reset the dashboard date selection.
      // Reset the dashboard data
      let dashboardSelectedDate = {
        startDate: dayjs(this.duplicateSelectedDashboard.dashboardConfig.startDate),
        endDate: dayjs(this.duplicateSelectedDashboard.dashboardConfig.endDate)
      };

      this.dashboardDateSelected = dashboardSelectedDate;
      this.dashboardService.dashboardDate = this.dashboardDateSelected;
    } else {
      this.dashboardDateSelected = { startDate: dayjs(), endDate: dayjs() };
    }
    const groubyLabel: { [key: string]: string } = timeIntervalsEnumLabel;
    if (this.duplicateSelectedDashboard?.dashboardConfig?.groupBy) { // If the duplicate selected dashboard has a group by configuration, reset the dashboard group by selection.
      this.dashboardGroupBy = ObjectUtil.deepClone(groubyLabel[this.duplicateSelectedDashboard.dashboardConfig.groupBy]);
    } else {
      this.dashboardGroupBy = timeIntervalsEnumLabel.HOURLY;
    }
    this.updateDashboardScopeData(this.duplicateSelectedDashboard.dashboardConfig);
    this.selectedDashboard = ObjectUtil.deepClone(this.duplicateSelectedDashboard);
    if (this.crudActionLabel == this.crudOperationList.duplicate) { // If the duplicate operation is performed, append ' - Copy' to the dashboard name.
      this.selectedDashboard.name = this.selectedDashboard.name + ' - Copy';
    }
    this.chartsList = ObjectUtil.deepClone(this.duplicateSelectedDashboard.widgetList);
    this.chartsList = this.chartsList.map((chart: any) => {
      chart.startDateTime = dayjs(chart?.widgetConfig?.startDate).startOf('day');
      chart.endDateTime = dayjs(chart?.widgetConfig?.endDate).endOf('day');
      chart.scopeSelectionData = {
        'excludedCcuRefs': chart.widgetConfig?.excludedCcuRefs,
        'excludedDeviceRefs': chart.widgetConfig?.excludedDeviceRefs,
        'excludedEquipRefs': chart.widgetConfig?.excludedEquipRefs,
        'excludedFloorRefs': chart.widgetConfig?.excludedFloorRefs,
        'excludedZoneRefs': chart.widgetConfig?.excludedZoneRefs,
        'siteRefs': chart.widgetConfig?.siteRefs
      };
      return chart;
    });

    if (this.duplicateSelectedDashboard.dashboardConfig) { // If the duplicate selected dashboard has a configuration, update the dashboard service configuration data.
      // update the dashboard service config data.
      this.dashboardService.dashboardConfig.siteRefs = ObjectUtil.deepClone(this.duplicateSelectedDashboard.dashboardConfig.siteRefs);
      this.dashboardService.dashboardConfig.excludedCcuRefs = ObjectUtil.deepClone(this.duplicateSelectedDashboard.dashboardConfig.excludedCcuRefs);
      this.dashboardService.dashboardConfig.excludedFloorRefs = ObjectUtil.deepClone(this.duplicateSelectedDashboard.dashboardConfig.excludedFloorRefs);
      this.dashboardService.dashboardConfig.excludedZoneRefs = ObjectUtil.deepClone(this.duplicateSelectedDashboard.dashboardConfig.excludedZoneRefs);
      this.dashboardService.dashboardConfig.excludedEquipRefs = ObjectUtil.deepClone(this.duplicateSelectedDashboard.dashboardConfig.excludedEquipRefs);
      this.dashboardService.dashboardConfig.excludedDeviceRefs = ObjectUtil.deepClone(this.duplicateSelectedDashboard.dashboardConfig.excludedDeviceRefs);
    }

    if (this.crudActionLabel != this.crudOperationList.duplicate) {
      if (this.isPersonal(this.selectedDashboard) || this.isSharedByMe(this.selectedDashboard)) {
        this.crudActionLabel = this.crudOperationList.edit;
      } else if (this.isSharedByOthers(this.selectedDashboard)) {
        this.crudActionLabel = '';
      }
    } else {
      this.dashboardService.dashboardData = ObjectUtil.deepClone(this.duplicateSelectedDashboard);
      this.dashboardService.dashboardData.name = ObjectUtil.deepClone(this.duplicateSelectedDashboard.name + ' - Copy');
      this.dashboardService.dashboardData.shared = false; // Reset the shared to false while discarding the changes (default - personal).
      this.dashboardService.dashboardData.sharedTo = configurationLevels.none;
      this.selectedDashboard = ObjectUtil.deepClone(this.dashboardService.dashboardData);
      this.selectedDashboard.id = ''; // Reset the dashboard ID when the duplicate operation is performed.
      this.selectedDashboard.dashboardId = ''; // Reset the dashboard ID when the duplicate operation is performed.
    }
    this.isDashboardUpdated = false;
    this.dashboardService.isDashboardEdited = false;
  
    
    //Reverting the changes made to the filted dashboard list
    this.filterWidgetListOnDashboard(this.selectedDashboard.widgetList);
    this.commonService.setSelectDashboard(this.selectedDashboard);
    this.alertService.success('Changes discarded successfully');
  }
  

  /**
   * Sets the default widget data.
   * Subscribes to the chartAddedOrRemoved$ observable and performs actions based on the received data.
   * If the data type is 'added', it clones the chartData object, sets additional properties, and adds it to the chartsList array.
   * If the data type is not 'added', it removes the corresponding item from the chartsList array.
   * Finally, it sets the isDashboardUpdated flag to true.
   */
  setDefaultWidgetData() {
    this.widgetService.chartAddedOrRemoved$?.subscribe((data) => {
      if (data && data.type === 'added') {
        let configData = ObjectUtil.deepClone(data.chartData);
        configData.widgetId = data.chartData.id;
        configData.startDateTime = dayjs().startOf('day');
        configData.endDateTime = dayjs().endOf('day');
        configData.viewBy = viewByEnumLabel.SITE;
        configData.groupBySameAsDashboard = true;
        configData.groupBy = 'Same as Dashboard';
        configData.selectedRangeLabel = this.selectedRangeLabel;
        configData.dateRangeSameAsDashboard = true;
        configData.scopeSameAsDashboard = true;
        // if (this.crudActionLabel == this.crudOperationList.new) {
        configData.scopeLabel = this.selectedScopeLabel;
        configData.scopeSelectionData = {
          siteRefs: this.siteIds,
          excludedCcuRefs: this.dashboardService.dashboardConfig?.excludedCcuRefs ?? [],
          excludedDeviceRefs: this.dashboardService.dashboardConfig?.excludedDeviceRefs ?? [],
          excludedEquipRefs: this.dashboardService.dashboardConfig?.excludedEquipRefs ?? [],
          excludedFloorRefs: this.dashboardService.dashboardConfig?.excludedFloorRefs ?? [],
          excludedZoneRefs: this.dashboardService.dashboardConfig?.excludedZoneRefs ?? []
        };
        // }
        this.isDashboardUpdated = true;
        this.chartsList.push(configData);
      } else {
        this.chartsList = this.chartsList.filter((item: any) => item?.widgetId !== data.chartData.id);
      }
      this.isDashboardUpdated = true;
    });
  }

  handleQueryParam() {
    const url = new URL(window.location.href);
    const queryParams = url.searchParams;
    if (queryParams.has('siteRef')) {
      this.siteRef = queryParams.get('siteRef') || '';
    }
  }

  // Method to fetch the data for scope selection dropdown.
  getScopeDropDownData() {
    const siteIds = [];
    if (this.portalParam === PORTALS.FACILISIGHT) {
      if (this.siteRef) {
        siteIds.push(this.siteRef);
      }
    }
    this.dashboardService.getSitesForUser(siteIds).subscribe(data => {
      if (data && data.length) {
        data = data.filter((site: any) => site.id != null);
        this.scopeDropdownSitesList = data;
        this.dashboardService.scopeDropdownSitesList = _.cloneDeep(data);
        if (this.selectedDashboard?.dashboardConfig) {
          this.updateDashboardScopeData(this.selectedDashboard.dashboardConfig);
        }
      } else {
        this.scopeDropdownSitesList = [];
      }
    }, (err) => {
      this.scopeDropdownSitesList = [];
    });
  }

  /**
   * Updates the dashboard scope data based on the provided dashboard configuration data.
   * 
   * @param dashboardConfigData - Optional parameter containing the dashboard configuration data.
   * If provided and contains site references, updates the site IDs and selected scope label/value.
   * If not provided or does not contain site references, defaults to the first site in the scope dropdown sites list.
   * 
   * - If `dashboardConfigData` is provided and contains site references:
   *   - Updates `siteIds` with the site references from `dashboardConfigData`.
   *   - Sets a timeout to update the `selectedScopeLabel` and `selectedScopeValue` based on the selected site data.
   * 
   * - If `dashboardConfigData` is not provided or does not contain site references:
   *   - Defaults `siteIds` to the first site ID in the scope dropdown sites list.
   *   - Updates `selectedScopeLabel` and `selectedScopeValue` based on the first site in the scope dropdown sites list.
   */
  updateDashboardScopeData(dashboardConfigData?: any) {
    if (dashboardConfigData && dashboardConfigData?.siteRefs?.length) {
      this.siteIds = dashboardConfigData?.siteRefs;
      setTimeout(() => {
        const selectedSiteData = this.scopeDropdownSitesList.flatMap((org: any) =>
          org.sites.filter((site: any) => this.siteIds.includes(site.id))
        );
        this.selectedScopeLabel = this.commonService.formatScopeLabel(selectedSiteData);
        this.selectedScopeValue = this.siteIds;
        this.dashboardService.dashboardScopeData = {'label':this.selectedScopeLabel , 'siteRefs': this.selectedScopeValue};
      }, 100);
    } else {
      this.siteIds = [this.scopeDropdownSitesList?.[0]?.sites[0]?.id];
      this.selectedScopeLabel = this.commonService.formatScopeLabel([this.scopeDropdownSitesList?.[0]?.sites[0]]);
      this.selectedScopeValue = this.siteIds;
      this.dashboardService.dashboardConfig = {
        'siteRefs': this.siteIds,
        'excludedCcuRefs': [],
        'excludedDeviceRefs': [],
        'excludedEquipRefs': [],
        'excludedFloorRefs': [],
        'excludedZoneRefs': [],
      };
      this.dashboardService.dashboardScopeData = {'label':this.selectedScopeLabel , 'siteRefs': this.selectedScopeValue};
    }
  }


  /**
   * Updates the widget scope label based on the provided configuration data.
   * 
   * @param ConfigData - Optional configuration data that contains site references.
   * If provided and contains site references, the method updates the site IDs and 
   * formats the scope label using the selected site data.
   * 
   * @returns The formatted scope label if configuration data is provided and contains site references.
   */
  updateWidgetScopeLabel(ConfigData?: any) {
    if (ConfigData && ConfigData?.siteRefs?.length) {
      const selectedSiteData = this.scopeDropdownSitesList.flatMap((org: any) =>
        org.sites.filter((site: any) => ConfigData?.siteRefs.includes(site.id))
      );
      return this.commonService.formatScopeLabel(selectedSiteData);
    }
  }

  disablePreviewDashboardBtn() {
    return this.filteredDashboardList?.length == 15; // restrict the preview button if the filtered list length is 15.
  }

  /**
   * Applies the selected dashboard to the view.
   * 
   * If the length of the filteredDashboardList is less than 15, the selected dashboard is applied to the view.
   * 
   * @returns {void}
   */
  applyDashboardToView() {
    if (this.filteredDashboardList?.length < 15) {
      const dasboardId: string = this.selectedDashboard?.dashboardId;
      this.dashboardService.applyDashboardToView(dasboardId).subscribe({
        next: (response: string) => {
          this.alertService.success('Dashboard added successfully');
          this.previewMode = false;
          if (this.gridsterService.gridsterOptions.resizable) {
            this.gridsterService.gridsterOptions.resizable.enabled = true;
          }
          this.getDefaultDashboardList();
        },
        error: (error: any) => {
          this.alertService.error(error.error.error || 'Something went wrong, please try again');
        },
      });
    }
  }

  /**
   * Updates the gridster options.
   * If the gridster options are resizable, it disables resizing in preview mode.
   * If there are any changes on the options, it updates the gridster options.
   */
  updateGridSterOptions() {
    if (this.gridsterService.gridsterOptions.resizable) {
      this.gridsterService.gridsterOptions.resizable.enabled = !this.previewMode; // Disable resizing in preview mode
    }
    if (this.gridsterService.gridsterOptions && this.gridsterService.gridsterOptions.api) {
      (this.gridsterService.gridsterOptions.api as any).optionsChanged(); // Update gridster options, if any change on options.
    }
  }

  /**
   * Retrieves the details of a specific widget by its ID and updates the corresponding chart in the charts list.
   * @param widgetId - The unique identifier of the widget to retrieve details for.
   * This method calls the `getWidgetConfigForDashboard` method of the `dashboardService` to fetch the widget configuration
   * for the selected dashboard. Once the data is retrieved, it updates the corresponding chart in the `chartsList` with
   * the new widget details such as builder type, chart configuration, name, owner, point definitions, shared information,
   * and other properties. It also marks the dashboard as updated.
   */
  getWidgetDetails(widgetId: string, widgetData:any) {
    this.dashboardService.getWidgetConfigForDashboard(this.selectedDashboard.dashboardId, [widgetId]).subscribe((widgetData: any) => {
      const chartIndex = this.chartsList?.findIndex((chartItem: any) => chartItem?.widgetId == widgetId);
      if (this.chartsList[chartIndex]) {
        const chartData = widgetData.widgetList?.length ? widgetData.widgetList[0] : null;
        if(chartData) {
          this.chartsList[chartIndex].builderType = chartData?.builderType;
          this.chartsList[chartIndex].chartConfig = chartData?.chartConfig;
          this.chartsList[chartIndex].name = chartData?.name;
          this.chartsList[chartIndex].owner = chartData?.owner;
          this.chartsList[chartIndex].pointDefinitions = chartData?.pointDefinitions;
          this.chartsList[chartIndex].sharedTo = chartData?.sharedTo;
          this.chartsList[chartIndex].shared = chartData?.shared;
          this.chartsList[chartIndex].why = chartData?.why;
          this.chartsList[chartIndex].what = chartData?.what;
        }
        this.chartsList[chartIndex] = ObjectUtil.deepClone(this.chartsList[chartIndex]);
        this.isDashboardUpdated = true;
      }
    },(error) => {
      const chartIndex = this.chartsList?.findIndex((chartItem: any) => chartItem?.widgetId == widgetId);
      if (this.chartsList[chartIndex]) {
        const chartData = widgetData;
        this.chartsList[chartIndex].builderType = chartData?.builderType;
        this.chartsList[chartIndex].chartConfig = chartData?.chartConfig;
        this.chartsList[chartIndex].name = chartData?.name;
        this.chartsList[chartIndex].pointDefinitions = chartData?.pointDefinitions;
        this.chartsList[chartIndex].sharedTo = chartData?.sharedTo;
        this.chartsList[chartIndex].shared = chartData?.shared;
        this.chartsList[chartIndex].why = chartData?.why;
        this.chartsList[chartIndex].what = chartData?.what;
        this.chartsList[chartIndex] = ObjectUtil.deepClone(this.chartsList[chartIndex]);
        this.isDashboardUpdated = true;
      }
    });
  }

  // Method to update the widget details in new dashboard creation mode
  updateWigetDetails(widgetId: string, chartData: any, likeUpdated?: boolean) {
    const chartIndex = this.chartsList?.findIndex((chartItem: any) => chartItem?.widgetId == widgetId);
    if (this.chartsList[chartIndex] && chartData) {
      if (likeUpdated) {
        this.chartsList[chartIndex].noOfLikes = chartData?.noOfLikes;
        this.chartsList[chartIndex].likedByLoggedInUser = chartData?.likedByLoggedInUser;
      } else {
        this.chartsList[chartIndex].builderType = chartData?.builderType;
        this.chartsList[chartIndex].chartConfig = chartData?.chartConfig;
        this.chartsList[chartIndex].name = chartData?.name;
        this.chartsList[chartIndex].pointDefinitions = chartData?.pointDefinitions;
        this.chartsList[chartIndex].sharedTo = chartData?.sharedTo;
        this.chartsList[chartIndex].shared = chartData?.shared;
        this.chartsList[chartIndex].why = chartData?.why;
        this.chartsList[chartIndex].what = chartData?.what;
        this.chartsList[chartIndex] = ObjectUtil.deepClone(this.chartsList[chartIndex]);
        this.isDashboardUpdated = true;
      }
    }
  }

  /**
   * Checks if a dashboard is present in user selected list.
   * 
   * @returns {boolean} Returns true if a dashboard is present, otherwise false.
   */
  isDashboardPresent() {
    let found = false;
    if (this.filteredDashboardList?.length && this.selectedDashboard) {
      found = this.filteredDashboardList.find((dashboard: Dashboard) => dashboard.id === this.selectedDashboard.dashboardId);
    }
    return found ? true : false;
  }

  //MEthod which brings pop-up when user tries to navigate or switch or add new dashboard with unsaved dashbaord
  getConfirmationOnSwitchDashboard(): Promise<string> {
    const dialogData = {
      headerText: `Unsaved Dashboard`,
      actionMsg: `You’re creating a new dashboard and if you navigate away now, your creation will be lost. Do you want to create it before exiting?`,
      confirmBtnText: 'Create New Dashboard',
      cancelBtnText: 'Discard and Exit',
      isCancelBtnRequired: true,
      discardMessage: true,
    };

    const popupConfig: any = {
      panelClass: 'fs-mat-dialog-container-confirmation',
      width: '432px',
      height: 'auto',
      overflow: "hidden !important",
    };

    const dialogRef = this.dialogRef.open(ConfirmationPopupComponent, { data: dialogData, ...popupConfig });
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result == userConfirmationState.CANCEL) {
        this.dashboardService.isSaveasnewOrDuplicate = false;
        window.parent.postMessage(JSON.stringify({ isDashBoardSaved: false }), '*')
      };
      return !result ? userConfirmationState.NOACTION : result;
    });
  }

  /**
   * Updates the layout on view mode.
   */
  updateLayoutOnViewMode(chartConfig: any) {
    this.chartsList = this.chartsList.map((chart: any) => {
      if (chart.widgetId == chartConfig.widgetId) {
        chart.cols = chartConfig.cols;
        chart.rows = chartConfig.rows;
        chart.x = chartConfig.x;
        chart.y = chartConfig.y;
      }
      return chart;
    });
    const payload = this.formDashboardLayoutPayload();
    this.dashboardService.updateDashboardLayout(payload).subscribe({
      next: (res: any) => {},
      error: (error) => {
        this.alertService.error(error?.error?.error || 'Something went wrong, please try again');
      }
    })
  }

  /**
   * Generates a payload object for the dashboard layout.
   * @returns {Object} The payload object containing the dashboard ID and layout information.
   */
  formDashboardLayoutPayload() {
    const dashbaordLayout: any = {};
    this.chartsList.forEach((chart: any) => {
      dashbaordLayout[chart.id] = {
        x: chart.x,
        y: chart.y,
        rows: chart.rows,
        cols: chart.cols,
        widgetId: chart.widgetId,
      };
    });
    const payload = {
      dashboardId: this.selectedDashboard?.dashboardId,
      layout: dashbaordLayout
    };
    return payload;
  }

  getCrudLabel() {
    if (this.crudActionLabel) {
      if (this.crudActionLabel == this.crudOperationList.edit) {
        return this.isDashboardUpdated ? this.crudActionLabel : null;
      } else if (this.crudActionLabel == this.crudOperationList.preview && this.isDashboardPresent()) {
        return null;
      }
      return this.crudActionLabel; // Default case when no other conditions are true
    } else {
      return null;
    }
  }

  /**
   * Filters the widget list on the dashboard based on the provided chart list.
   * 
   * @param chartList - An array of chart objects, each containing a `widgetId` property.
   * 
   * This method updates the `filteredDashboardList` by filtering the `widgetList` of each dashboard.
   * It retains only those widgets whose `widgetId` is present in the `chartList`.
   * The filtering is applied only to the dashboard that matches the `selectedDashboard` by `dashboardId` or `id`.
  */
  filterWidgetListOnDashboard(chartList: any) {
    if (chartList) {
      const selectedWidgetIds = new Set(chartList.map((widget: any) => widget.widgetId));
      this.filteredDashboardList = this.filteredDashboardList.map((dashboard: any) => {
        if (this.selectedDashboard?.dashboardId === dashboard.dashboardId || this.selectedDashboard.dashboardId === dashboard.id) {
          dashboard.widgetList = dashboard.widgetList.filter((widget: any) => selectedWidgetIds.has(widget.widgetId));
        }
        return dashboard;
      });
    }
  }

  useFeatureToggles() {
    this.featureToggleService.featureFlagsSubject.subscribe((flags: any) => {
      this.setFlags(flags);
    });
    this.featureToggleService.getFlags();
  }

  setFlags(flags: any) {
    this.commonService.likecountmultiplier = flags['likecountmultiplier'].value || 1;
  }
  
}