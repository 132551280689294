import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationPopupComponent } from '../_components/confirmation-popup/confirmation-popup.component';
import { Chart } from '../models/chart';
import { ChartBuilderLayoutComponent } from '../../components/chart-builder-layout/chart-builder-layout.component';
import { Subject } from 'rxjs';
import { DashboardService } from './dashboard.service';
import { AlertService } from './alert.service';
import { axisOrientation } from '../constants/widget';
import { chartType } from '../enums/charts.enum';
import { LocalStorageService } from './local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class WidgetLayoutService {

  loggedInUser: any = {};
  refreshWidgetListSubject = new Subject<any>();
  refreshDashboardChartSubject = new Subject<any>();
  isPopupOpen: boolean = false;

  constructor(private commonservice: CommonService,
    public dialogRef: MatDialog,
    private localStorageService: LocalStorageService,
    public dashBoardService: DashboardService,
    public alertService: AlertService) {
    this.loggedInUser = this.localStorageService.user_data || '{}';
  }

  /**
   * Creates or updates charts based on the edit mode.
   *
   * @param {boolean} isEdit - Indicates whether the operation is an edit.
   * @param {string[]} siteIds - An array of site IDs associated with the charts.
   * @param {Chart} [widgetData] - Optional chart data to be used in the operation.
   */
  createOrUpdateCharts(isEdit: boolean, siteIds: string[], widgetData?: Chart) {
    if (!isEdit) {
      this.openChartsBuilderModal(isEdit, siteIds, widgetData);
    } else {
      this.openEditModal(isEdit, siteIds, widgetData);
    }
  }

  /**
   * Opens a modal dialog for editing or deleting a widget. If the logged-in user is the owner of the widget,
   * a confirmation popup is shown before proceeding to the chart builder modal. If the user confirms the action,
   * the chart builder modal is opened. If the logged-in user is not the owner, the chart builder modal is opened directly.
   *
   * @param {boolean} isEdit - Indicates whether the action is to edit the widget.
   * @param {string[]} siteIds - An array of site IDs associated with the widget.
   * @param {any} widgetData - The data of the widget to be edited or deleted.
   */
  openEditModal(isEdit: boolean, siteIds: string[], widgetData: any) {
    this.dashBoardService.getChartUsageData(widgetData.id).subscribe((chartUsageCount: any) => {
      if (chartUsageCount) {
        const self = this;
        if (this.loggedInUser.userId == widgetData?.owner?.userId) {
          if (!this.isPopupOpen) {
            this.isPopupOpen = true;
            let dialogData = {
              headerText: `Are you sure you want to edit ${widgetData?.name}?`,
              actionMsg: `Editing the chart will impact users of`,
              users: `${chartUsageCount?.dashboardCount} ${chartUsageCount?.dashboardCount !== 1 ? 'dashboards' : 'dashboard'}`,
              likes: `(${this.likeCountFormat(chartUsageCount?.likeCount)} ${(chartUsageCount?.likeCount * this.commonservice.likecountmultiplier) !== 1 ? 'likes' : 'like'})`,
              bookmarks: `(${chartUsageCount?.bookmarkCount} ${chartUsageCount?.bookmarkCount !== 1 ? 'individuals' : 'individual'})`,
              confirmBtnText: 'Continue',
              cancelBtnText: 'Cancel',
              subMessageRequired: true,
              isCancelBtnRequired: true,
              editOrDelete: true
            };

            let popupConfig: any = {
              panelClass: 'fs-mat-dialog-container-confirmation',
              width: '432px',
              height: 'auto',
              overflow: "hidden !important"
            };
            const dialogRef = this.dialogRef.open(ConfirmationPopupComponent, { data: dialogData, ...popupConfig });
            dialogRef.afterClosed().subscribe(result => {
              //If user has confirmed the change it gets inside here and confirm popup will be closed else, the pop-up model will be closed from confirm model component.
              if (result == 'confirm') {
                self.dialogRef.closeAll()
                this.openChartsBuilderModal(isEdit, siteIds, widgetData);
              }
              this.isPopupOpen = false;
            })
          }
        } else {
          this.openChartsBuilderModal(isEdit, siteIds, widgetData);
        }
      }
    });
  }
  /**
   * Opens the Chart Builder Modal.
   *
   * @param {boolean} isEdit - Indicates whether the modal is in edit mode.
   * @param {string[]} siteIds - Array of site IDs associated with the widget.
   * @param {Chart} [widgetData] - Optional widget data for the chart being edited.
   *
   * @returns {void}
   *
   * This method retrieves the logged-in user data from local storage and determines if the chart is editable based on the user ID.
   * It then opens the Chart Builder Modal with the specified configuration and data.
   * After the modal is closed, if there is a result, it triggers a refresh of the widget list.
   */
  openChartsBuilderModal(isEdit: boolean, siteIds: string[], widgetData?: Chart) {
    this.loggedInUser = this.localStorageService.user_data || {};
    const isEditable = isEdit ? (this.loggedInUser.userId == widgetData?.owner?.userId) : true; // needs to update with proper owner data
    const dialogRef = this.dialogRef.open(ChartBuilderLayoutComponent, {
      panelClass: 'chartBuilderModalConfig',
      disableClose: true,
      data: {
        name: widgetData?.name,
        sharedByOthers: !isEditable,
        siteId: siteIds,
        isEdit: isEdit,
        widgetData: widgetData,
      }
    });
    dialogRef?.updatePosition({ top: '20px' });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshWidgetListSubject.next({chartData: result.chartData, widgetData: result.widgetData});
      }
    });
  }

   /**
   * Formats the given like count using a common service.
   *
   * @param count - The number of likes to format.
   * @returns The formatted like count as a string.
   */
  likeCountFormat(count: number) {
    return this.commonservice.likeCountFormat(count);
  }

  /**Method to handle the chart delete
   * Gets the count of chart usage. Displays confirmation popup with the count of users, likes and bookmarks.
   * If user confirms the delete, the chart will be deleted. **/
  openDeleteModal(chart: Chart) {
    if(chart.isEmbedded){
      const dialogData = {
        headerText: `Are you sure you want to delete ${chart?.name}?`,
        actionMsg: ``,
        users: ``,
        likes: ``,
        bookmarks: ``,
        confirmBtnText: 'Continue',
        cancelBtnText: 'Cancel',
        subMessageRequired: true,
        isCancelBtnRequired: true,
        discardMessage: true,
        action: 'delete'
      };
      this.handleExternalChartDelete(dialogData, chart);
    } else {
      this.dashBoardService.getChartUsageData(chart.id).subscribe((chartUsageCount: any) => {
        if (chartUsageCount) {
          const dialogData = {
            headerText: `Are you sure you want to delete ${chart?.name}?`,
            actionMsg: `Deleting the chart will impact users of`,
            users: `${chartUsageCount?.dashboardCount} ${chartUsageCount?.dashboardCount !== 1 ? 'dashboards' : 'dashboard'}`,
            likes: `(${this.likeCountFormat(chartUsageCount?.likeCount)} ${(chartUsageCount?.likeCount * this.commonservice.likecountmultiplier) !== 1 ? 'likes' : 'like'})`,
            bookmarks: `(${chartUsageCount?.bookmarkCount} ${chartUsageCount?.bookmarkCount !== 1 ? 'individuals' : 'individual'})`,
            confirmBtnText: 'Continue',
            cancelBtnText: 'Cancel',
            subMessageRequired: true,
            isCancelBtnRequired: true,
            editOrDelete: true,
            action: 'delete'
          };
          this.handleChartDelete(dialogData, chart);
        }
      });
    }
  }

  handleExternalChartDelete(dialogData: any, chart: Chart) {
    let popupConfig: any = {
      panelClass: 'fs-mat-dialog-container-confirmation',
      width: '432px',
      height: 'auto',
      overflow: "hidden !important"
    };
    const dialogRef = this.dialogRef.open(ConfirmationPopupComponent, { data: dialogData, ...popupConfig });
    dialogRef.afterClosed().subscribe(result => {
      if(result == 'confirm') {
        this.dialogRef.closeAll();
        if (dialogData.action === 'delete') {
          this.dashBoardService.deleteExternalChart(chart?.id).subscribe(() => {
            this.refreshDashboardChartSubject.next(chart?.id);
            this.alertService.success(`Deleted ${chart.name} successfully`);
          }, (error) => {
            error.status == 200 ?  this.alertService.success(`Deleted ${chart.name} successfully`) : this.alertService.error(error?.error?.error || 'Something went wrong, please try again');
          });
        }
      }
    });
  }

  /**
   * Handles the deletion of a chart by opening a confirmation dialog.
   * If the user confirms the deletion, the chart is deleted and the widget list is refreshed.
   * @param dialogData - Data to be passed to the confirmation dialog.
   * @param chart - The chart object to be deleted.
   */
  handleChartDelete(dialogData: any, chart: Chart) {
    let popupConfig: any = {
      panelClass: 'fs-mat-dialog-container-confirmation',
      width: '432px',
      height: 'auto',
      overflow: "hidden !important"
    };
    const dialogRef = this.dialogRef.open(ConfirmationPopupComponent, { data: dialogData, ...popupConfig });

    dialogRef.afterClosed().subscribe(result => {
      //If user has confirmed the change it gets inside here and confirm popup will be closed else, the pop-up model will be closed from confirm model component.
      if (result == 'confirm') {
        this.dialogRef.closeAll();
        if (dialogData.action === 'delete') {
          this.dashBoardService.deleteChart(chart?.id).subscribe(() => {
            this.refreshWidgetListSubject.next({"chart":chart, "removedChart":true});
            this.refreshDashboardChartSubject.next(chart?.id);
            this.alertService.success(`Deleted ${chart.name} successfully`);
          }, (error) => {
            error.status == 200 ?  this.alertService.success(`Deleted ${chart.name} successfully`) : this.alertService.error(error?.error?.error || 'Something went wrong, please try again');
          });
        }
      }
    });
  }

  updateAxisLabelPosition(chart: any, chartOptions: any, chartAxisPositions: string[], selectedChartType?:string): void {
    chart.on('finished', () => {
      if(selectedChartType !== chartType.TERRAIN) {
      // Get the labels for the left Y-axis
      const yAxisLeftComponent = chart.getModel().getComponent('yAxis');
      const yAxisRightComponent = chart.getModel().getComponent('yAxis', 1);
      const hasNameLabelLeft = yAxisLeftComponent && yAxisLeftComponent.option.name;
      const hasNameLabelRight = yAxisRightComponent && yAxisRightComponent.option.name;
      const offSet = 10;

      var yAxisFormatter = function (value: number) {
        if (value >= 1000 && value < 1e6) {
          const result = value / 1000;
          return result % 1 === 0 ? `${result}k` : `${result.toFixed(1)}k`;
        } else if (value >= 1e6) {
          const result = value / 1e6;
          return result % 1 === 0 ? `${result}M` : `${result.toFixed(1)}M`;
        }
        return value;
      };

      const yAxisLabelsLeft = chart
        .getModel()
        .getComponent('yAxis')
        .axis.scale.getTicks()
        .map((tick: any) => yAxisFormatter(tick.value)) || [];

      // Get the labels for the right Y-axis (if exists)
      const yAxisLabelsRight = chart
        .getModel()
        .getComponent('yAxis', 1)
        ?.axis.scale.getTicks()
        .map((tick: any) => yAxisFormatter(tick.value)) || [];

      let gridOptions = {};

      // update if only left y-axis and x both is present
      if (yAxisLabelsLeft.length > 0 && (chartAxisPositions.includes(axisOrientation.left) && chartAxisPositions.includes(axisOrientation.left)) && (hasNameLabelLeft && hasNameLabelRight)) {
        const maxLabelLengthLeft = Math.max(...yAxisLabelsLeft.map((label: any) => label.toString().length));
        const maxLabelLengthRight = Math.max(...yAxisLabelsRight.map((label: string) => label.toString().length));
        gridOptions = {
          ...chartOptions.grid,
          left: `${maxLabelLengthLeft * (offSet + 5)}`,
          right: `${maxLabelLengthRight * (offSet + 10)}`
        };
      }
      // update if only left y-axis is present
      else if (yAxisLabelsLeft.length > 0 && (chartAxisPositions.includes(axisOrientation.left)) && hasNameLabelLeft) {
        const maxLabelLengthLeft = Math.max(...yAxisLabelsLeft.map((label: any) => label.toString().length));
        gridOptions = {
          ...chartOptions.grid,
          left: `${maxLabelLengthLeft > 2 ? maxLabelLengthLeft * offSet : maxLabelLengthLeft * (offSet + 5)}`,
        };
      }
      // update if only right y-axis is present
      else if (yAxisLabelsLeft.length > 0 && chartAxisPositions.includes(axisOrientation.right) && hasNameLabelLeft) {
        const maxLabelLengthLeft = Math.max(...yAxisLabelsLeft.map((label: any) => label.toString().length));
        gridOptions = {
          ...chartOptions.grid,
          right: `${maxLabelLengthLeft > 2 ? maxLabelLengthLeft * (offSet + 2) : maxLabelLengthLeft * (offSet + 8)}`,
        };
      }

      // update if only time is present in y axis
      else if (yAxisLabelsLeft.length > 0 && (chartAxisPositions.includes(axisOrientation.top) || chartAxisPositions.includes(axisOrientation.bottom)) && hasNameLabelLeft) {
        const maxLabelLengthLeft = Math.max(...yAxisLabelsLeft.map((label: any) => label.toString().length));
        gridOptions = {
          ...chartOptions.grid,
          left: `${maxLabelLengthLeft * 2.5}`,
        };
      }

      // Check if there are any values in the right Y-axis and adjust the grid for right side
      else if (yAxisLabelsRight.length > 0 && chartAxisPositions.includes(axisOrientation.right) && hasNameLabelRight) {
        const maxLabelLengthRight = Math.max(...yAxisLabelsRight.map((label: string) => label.toString().length));
        gridOptions = {
          ...gridOptions,
          right: `${maxLabelLengthRight * (offSet + 5)}`,
        };
      }

      chart.setOption({
        grid: gridOptions,
      });
    }
    });
  }
}
