import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HostRoute } from 'src/app/shared/enums/host.enum';
import { DashboardService } from '../../_services/dashboard.service';
import { CommonService } from '../../_services/common.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ObjectUtil } from '../../utils/object-util';
import { AlertService } from '../../_services/alert.service';
import { timeIntervalsEnum } from '../../enums/dateRange.enum';
import { viewByEnum } from '../../enums/charts.enum';
import { CRUDOperationList, HIERARCHY_ENTITY_TYPES } from '../../constants/constants';
import { LocalStorageService } from '../../_services/local-storage.service';

@Component({
  selector: 'app-scope-selection-modal',
  templateUrl: './scope-selection-modal.component.html',
  styleUrls: ['./scope-selection-modal.component.scss']
})
export class ScopeSelectionModalComponent implements OnInit {
  componentType!: string;
  componentName!: string;
  scopeSameAsDashboard!: boolean;
  scopeDropdownList: any[] = [];
  selectedEquipData: any = {};
  selectedScopeData: any;
  portalParam: any;
  siteRef: any;
  selectorKeys: any;
  isLoading = false;
  isHierarchyLoading = false;
  _searchText: string = '';
  searchTextChanged: Subject<string> = new Subject<string>();
  scopeHierarchyList!: any;
  filteredHierarchyList!: any;
  tempHierarchyList!:any;
  accordionStates: { [key: string]: boolean } = {
    ccu: false,
    floor: false,
    room: false,
    equip: false
  };
  expandCollapeClickCounter:number = 0;
  configData: any = {
    siteRefs: [],
    excludedCcuRefs: [],
    excludedFloorRefs: [],
    excludedZoneRefs: [],
    excludedEquipRefs: [],
    excludedDeviceRefs: []
  };
  preselectedSiteIds:string[] = [];
  isConfigEdit = false;
  hierarchyEntityTypes: any = HIERARCHY_ENTITY_TYPES;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ScopeSelectionModalComponent>,
    public alertService: AlertService,
    private localStorageService: LocalStorageService,
    private dashboardService: DashboardService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.componentType = this.data.componentType;
    this.updateConfigData();
    this.portalParam = this.localStorageService.portal_type || '';
    this.handleQueryParam();
    this.getScopeDropDownData();
    this.searchTextChanged.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.filterHierarchyList(text);
    });
  }

  // Setter for search text
  set searchText(value: string) {
    this._searchText = value;
    this.searchTextChanged.next(value);
  }

  // Getter for search text
  get searchText(): string {
    return this._searchText;
  }

  // Method to clear the search text
  clearSearch() {
    this.searchText = '';
    this.searchTextChanged.next('');   
  }

  updateConfigData() {
    if (this.data?.componentType == 'dashboard') {
      this.updatedDashboardConfig();
    } else {
      this.updatedWidgetConfig();
    }
  }

  updatedDashboardConfig() {
    const dashboardConfig = this.data?.dashboard?.dashboardConfig;
    if (dashboardConfig) {
      this.configData = dashboardConfig;
      this.preselectedSiteIds = dashboardConfig?.siteRefs;
      this.isConfigEdit = true;
    } else if (!this.dashboardService.dashboardConfig && (this.data?.action == CRUDOperationList.new || this.data?.action == CRUDOperationList.edit)) {
      this.configData['name'] = this.data?.dashboard?.name;
      this.configData['comment'] = this.data?.dashboard?.name;
      this.configData['dashboardId'] = this.data?.dashboard?.dashboardId;
      this.configData['viewBy'] = viewByEnum.Site;
    } else if (this.dashboardService.dashboardConfig && (this.data?.action == CRUDOperationList.new || this.data?.action == CRUDOperationList.edit)) {
      this.configData = this.dashboardService.dashboardConfig;
      this.preselectedSiteIds = this.dashboardService.dashboardConfig?.siteRefs;
      this.isConfigEdit = true;
    }
    this.configData['startDate'] = this.data?.date?.startDate;
    this.configData['endDate'] = this.data?.date?.endDate;
    this.configData['groupBy'] = timeIntervalsEnum[this.data?.groupBy as keyof typeof timeIntervalsEnum];
  }

  updatedWidgetConfig() {
    const widgetConfig = this.data?.chart?.widgetConfig;
    //Updating widgetConfig while in view mode
    if (widgetConfig && !(this.data?.action == CRUDOperationList.edit)) {
      this.configData = ObjectUtil.deepClone(widgetConfig);
      this.configData['widgetId'] = widgetConfig.widgetId ? widgetConfig.widgetId : this.data?.chart?.widgetId;
      this.scopeSameAsDashboard = this.data?.chart?.scopeSameAsDashboard;
      this.updateSelectionIfSameAsDashboard(this.data?.chart?.scopeSelectionData?.siteRefs ?? []);
      this.isConfigEdit = true;
    }
    //Updating widgetConfig while creating new dashboard and editing the widget
    else if ((widgetConfig && this.data?.action == CRUDOperationList.edit) || !widgetConfig && (this.data?.action == CRUDOperationList.new || this.data?.action == CRUDOperationList.edit)) {
      this.scopeSameAsDashboard = this.data?.chart?.scopeSameAsDashboard;
      this.updateSelectionIfSameAsDashboard(this.data?.chart?.scopeSelectionData?.siteRefs ?? []);
      this.configData['widgetId'] = this.data?.chart?.widgetId;
      this.configData['scopeSameAsDashboard'] = this.scopeSameAsDashboard;
    }
  }

  /**
   * Updates the scope selection based on whether it matches the dashboard scope.
   * If the current scope is the same as the dashboard scope, it handles the selected scope
   * using the dashboard scope data. Otherwise, it preselects site IDs from the dashboard scope data.
   * @returns {void}
   */
  updateSelectionIfSameAsDashboard(siteRefs: string[]) {
    if (this.scopeSameAsDashboard) {
      this.updateDashboardScopeData();
      this.handleSelectedScope(this.data?.dashboardScopeData);
    } else {
      this.preselectedSiteIds = siteRefs;
      if(this.data?.action == CRUDOperationList.new || this.data?.action == CRUDOperationList.edit || this.data?.viewMode) {
        this.isConfigEdit = true;
        const scopeSelectionData = this.data?.chart?.scopeSelectionData;
        this.configData['siteRefs'] = siteRefs;
        this.configData['excludedCcuRefs'] = scopeSelectionData?.excludedCcuRefs ?? [];
        this.configData['excludedFloorRefs'] = scopeSelectionData?.excludedFloorRefs ?? [];
        this.configData['excludedZoneRefs'] = scopeSelectionData?.excludedZoneRefs ?? [];
        this.configData['excludedEquipRefs'] = scopeSelectionData?.excludedEquipRefs ?? [];
        this.configData['excludedDeviceRefs'] = scopeSelectionData?.excludedDeviceRefs ?? [];
      }
    }
  }

  /**
   * Updates the dashboard hierarchy selection based on the current dashboard configuration.
   * If the dashboard configuration is available, it sets the `isConfigEdit` flag to true
   * and updates the `configData` object with the site references, excluded CCU references,
   * excluded floor references, excluded zone references, excluded equipment references,
   * and excluded device references from the dashboard configuration.
   */
  updateDashboardScopeData() {
    if(this.dashboardService.dashboardConfig){
      this.isConfigEdit = true;
      this.configData['siterefs'] = this.dashboardService.dashboardConfig?.siteRefs;
      this.configData['excludedCcuRefs'] = this.dashboardService.dashboardConfig?.excludedCcuRefs;
      this.configData['excludedFloorRefs'] = this.dashboardService.dashboardConfig?.excludedFloorRefs;
      this.configData['excludedZoneRefs'] = this.dashboardService.dashboardConfig?.excludedZoneRefs;
      this.configData['excludedEquipRefs'] = this.dashboardService.dashboardConfig?.excludedEquipRefs;
      this.configData['excludedDeviceRefs'] = this.dashboardService.dashboardConfig?.excludedDeviceRefs;
    }
  }

  // Method to handle the query param to get the site ref
  handleQueryParam() {
    const url = new URL(window.location.href);
    const queryParams = url.searchParams;
    if (queryParams.has('siteRef')) {
      this.siteRef = queryParams.get('siteRef');
    }
  }

  // Method to fetch the data for scope selection dropdown.
  getScopeDropDownData() {
    this.isLoading = true;
    const siteIds = [];
    if(this.portalParam === HostRoute.FACILISIGHT) {
      if (this.siteRef) {
        siteIds.push(this.siteRef);
      }
    }
    this.dashboardService.getSitesForUser(siteIds).subscribe(data => {
      if(data && data.length) {
        data = data.filter((site: any) => site.id != null);
        this.dashboardService.scopeDropdownSitesList = data;
        data = this.commonService.formatScopeForDropdown(data, 'sites');
        this.scopeDropdownList = data;
        this.isLoading = false;
      } else {
        this.scopeDropdownList = [];
        this.isLoading = false;
      }
    }, (err) => {
      this.scopeDropdownList = [];
      this.isLoading = false;
    });
  }

  /**
   *  Method to handle the event when the user toggles the same as dashboard toggle button.
   *
   * @param event - The event object.
   */
  toggleDashboardScope(event: any) {
    this.scopeSameAsDashboard = event.checked;
    if (this.scopeSameAsDashboard && this.selectedScopeData) {
      this.isConfigEdit = true;
      this.handleSelectedScope(this.data?.dashboardScopeData);
    } else {
      this.preselectedSiteIds = this.data?.dashboardScopeData?.value;
    }
    this.updateDashboardScopeData();
    this.configData['scopeSameAsDashboard'] = this.scopeSameAsDashboard;
  }

    /**
   *  Method to store the selected scope value from the dropdown..
   *
   * @param selectedScopeObj - The selected scope object with the label and value.
   */
  handleSelectedScope(selectedScopeObj: any) {
    if (selectedScopeObj && selectedScopeObj.label) {
      this.selectedScopeData = selectedScopeObj;
      this.getScopeHierarchyList();
    } else {
      this.selectedScopeData = null;
    }
  }

  // Method to get the scope hierarchy list when the user selects the sites from scope selection dropdown
  getScopeHierarchyList() {
    this.isHierarchyLoading = true;
    if(this.selectedScopeData.value) {
      const scopeIds = this.selectedScopeData.value;
      this.dashboardService.getScopeHierarchy(scopeIds)
        .subscribe((data: any) => {
          if (data) {
            this.scopeHierarchyList = this.addKeysForSelectedScopeData(data);
            this.filteredHierarchyList = ObjectUtil.deepClone(this.scopeHierarchyList);
            this.isHierarchyLoading = false;
            if (this.isConfigEdit) {
              this.populateHierarchyData();
              this.updateParentCheckboxSelection();
            }
            this.tempHierarchyList = ObjectUtil.deepClone(this.filteredHierarchyList);
            this.resetHierarchyAccordionState();
          }
        }, err => {
          this.alertService.error('Error fetching scope hierarchy data', err);
          this.isHierarchyLoading = false;
        });
    }
  }

    /**
   * Expands the scope list by setting the state of each accordion item to true.
   * The method iterates through the accordion keys and sets their state to true
   * up to the current value of `expandCollapeClickCounter`. The counter is then
   * incremented by one.
   **/
  expandScopeList() {
    const accordionKeys = Object.keys(this.accordionStates);
    if (this.expandCollapeClickCounter <= accordionKeys.length) {
      const key = accordionKeys[this.expandCollapeClickCounter];
      for (let i = 0; i <= this.expandCollapeClickCounter; i++) {
        if (accordionKeys[i] !== undefined) {
          this.accordionStates[accordionKeys[i]] = true;
        }
      }
      this.expandCollapeClickCounter++;
    }
  }

  /**
   * Calculates the number of opened accordions.
   */
  getOpenedAccordionLength(): number {
    return Object.values(this.accordionStates).filter(state => state === true).length;
  }

  /**
   * Collapses the scope list by toggling the state of accordion items.
   * This method decreases the `expandCollapeClickCounter` and toggles the 
   * accordion state for the corresponding key. If the accordion item is 
   * already collapsed, it briefly expands it before collapsing it again.
   * @remarks
   * - The method ensures that the accordion state is toggled correctly 
   *   based on the `expandCollapeClickCounter`.
   * - Uses a `setTimeout` with a delay of 0 to ensure the state change 
   *   is processed correctly.
   */
  collapseScopeList() {
    const accordionKeys = Object.keys(this.accordionStates);
    if (this.expandCollapeClickCounter > 0) {
      this.expandCollapeClickCounter--;
      const key = accordionKeys[this.expandCollapeClickCounter];
      if (!this.accordionStates[key]) {
        this.accordionStates[key] = true;
        setTimeout(() => {
          this.accordionStates[key] = false;
        }, 0);
      } else {
        this.accordionStates[key] = false;
      }
    }
  }

  // Method to close the modal and pass the selected scope data
  closeModalWithData() {
    const result = this.selectedScopeData ? this.selectedScopeData : null;
    let data = {
      scopeData: result,
      action: this.data?.action,
      componentType: this.componentType,
      configData: this.configData
    }
    this.dialogRef.close(data);
  }

  /**
   * Closes the modal dialog.
   */
  closeModal() {
    this.dialogRef.close();
  }

  /**
   * Recursively sets the `enabled` and `indeterminate` properties for the given entity and its nested entities.
   */
  setDefaults(entity: any, enabled: boolean, indeterminate: boolean) {
    entity.enabled = enabled;
    entity.indeterminate = indeterminate;

    if (entity.floors) {
      entity.floors.forEach((floor: any) => this.setDefaults(floor, enabled, indeterminate));
    }
    if (entity.rooms) {
      entity.rooms.forEach((room: any) => this.setDefaults(room, enabled, indeterminate));
    }
    if (entity.equips) {
      entity.equips.forEach((equip: any) => this.setDefaults(equip, enabled, indeterminate));
    }
    if (entity.devices) {
      entity.devices.forEach((device: any) => {
        device.enabled = enabled;
      });
    }
  }

  /**
   * Adds default keys to the selected scope data.
   * This function iterates over the `sites` array in the provided data object,
   * and for each site, it iterates over the `ccus` array (if it exists).
   * It then calls the `setDefaults` method on each `ccu` object with specific parameters.
   */
  addKeysForSelectedScopeData(data: any) {
    data.sites.forEach((site: any) => {
      site.ccus?.forEach((ccu: any) => {
        this.setDefaults(ccu, true, false);
      });
    });
    return data;
  }

  /**
   * Handles the change event for hierarchy selection.
   * This method updates the selection state of child and parent checkboxes.
   * If a search text is present, it updates the scope hierarchy from the filtered list.
   * Otherwise, it clones the filtered hierarchy list to a temporary list.
   */
  onHierarchySelectionChange(event: { isSelected: boolean }, entity: any, childKey: string) {
    this.updateChildCheckboxSelection(event.isSelected, entity, childKey);
    this.updateParentCheckboxSelection();

    if (this._searchText) {
      this.updateScopeHierarchyFromFiltered();
    } else {
      this.tempHierarchyList = ObjectUtil.deepClone(this.filteredHierarchyList);
    }
  }

  /**
   * Updates the selection state of a checkbox and its child checkboxes recursively.
   */
  updateChildCheckboxSelection(isEnabled: boolean, entity: any, childkey: string) {
    entity.enabled = isEnabled;
    entity.indeterminate = false;
    if (entity[childkey]) {
      entity[childkey].forEach((child: any) => {
        this.updateChildCheckboxSelection(isEnabled, child, this.commonService.getChildKeyForHierarchy(childkey));
      });
    }
  }

  /**
   * Updates the selection state of parent checkboxes based on the selection state of child checkboxes.
   * Iterates through the filtered hierarchy list of sites and their respective CCUs (Control and Communication Units),
   * and updates the parent selection state for each CCU's floors.
   */
  updateParentCheckboxSelection() {
    this.filteredHierarchyList.sites.forEach((site: any) => {
      site.ccus.forEach((ccu: any) => {
        this.updateParentSelectionState(ccu, 'floors');
      });
    });
  }

  /**
   * Updates the selection state of a parent node based on the selection states of its child nodes.
   * This method recursively checks each child node and updates the parent's `enabled` and `indeterminate` properties.
   */
  updateParentSelectionState(parent: any, childKey: string) {
    if (parent[childKey] && parent[childKey].length) {

      // Recursively check each child
      parent[childKey].forEach((child: any) => {
        this.updateParentSelectionState(child, this.commonService.getChildKeyForHierarchy(childKey));

        if (parent && parent[childKey] && childKey !== '') {
          const allChecked = parent[childKey].every((child: any) => child.enabled);
          const anyChecked = parent[childKey].some((child: any) => child.enabled || child.indeterminate);
          parent.enabled = allChecked;
          parent.indeterminate = !allChecked && anyChecked;
        }
      });
    }
  }

  /**
   * Updates the temporary hierarchy list with the selection data from the filtered hierarchy list.
   * This method iterates through the hierarchy levels (sites, CCUs, floors, rooms, equips, devices)
   * and updates the `enabled` and `indeterminate` properties of each corresponding item in the
   * temporary hierarchy list based on the filtered hierarchy list.
   * The hierarchy structure is as follows:
   * - Sites
   *   - CCUs
   *     - Floors
   *       - Rooms
   *         - Equips
   *           - Devices
   * Each level's selection data is updated if a corresponding item is found in the temporary hierarchy list.
  */
  updateScopeHierarchyFromFiltered() {
    this.filteredHierarchyList.sites.forEach((filteredSite: any) => {
      const targetSite = this.tempHierarchyList.sites.find((site: any) => site.siteRef === filteredSite.id);
      if (targetSite) {
        // Update site-level selection data
        targetSite.enabled = filteredSite.enabled;
        targetSite.indeterminate = filteredSite.indeterminate;
        filteredSite.ccus.forEach((filteredCcu: any) => {
          const targetCcu = targetSite.ccus.find((ccu: any) => ccu.id === filteredCcu.id);
          if (targetCcu) {
            // Update CCU-level selection data
            targetCcu.enabled = filteredCcu.enabled;
            targetCcu.indeterminate = filteredCcu.indeterminate;

            filteredCcu.floors.forEach((filteredFloor: any) => {
              const targetFloor = targetCcu.floors.find((floor: any) => floor.id === filteredFloor.id);
              if (targetFloor) {
                // Update floor-level selection data
                targetFloor.enabled = filteredFloor.enabled;
                targetFloor.indeterminate = filteredFloor.indeterminate;

                filteredFloor.rooms.forEach((filteredRoom: any) => {
                  const targetRoom = targetFloor.rooms.find((room: any) => room.id === filteredRoom.id);
                  if (targetRoom) {
                    // Update room-level selection data
                    targetRoom.enabled = filteredRoom.enabled;
                    targetRoom.indeterminate = filteredRoom.indeterminate;

                    filteredRoom.equips.forEach((filteredEquip: any) => {
                      const targetEquip = targetRoom.equips.find((equip: any) => equip.id === filteredEquip.id);
                      if (targetEquip) {
                        // Update equip-level selection data
                        targetEquip.enabled = filteredEquip.enabled;
                        targetEquip.indeterminate = filteredEquip.indeterminate;

                        filteredEquip.devices.forEach((filteredDevice: any) => {
                          const targetDevice = targetEquip.devices.find((device: any) => device.id === filteredDevice.id);
                          if (targetDevice) {
                            // Update device-level selection data
                            targetDevice.enabled = filteredDevice.enabled;
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  /**
   * Filters the hierarchy list based on the provided search text.
   * If the search text is empty, it resets the filtered hierarchy list to the original list.
   * Otherwise, it filters the sites and their respective ccuList based on the search text.
   */
  filterHierarchyList(searchText: string) {
    if (!searchText) {
      this.filteredHierarchyList = ObjectUtil.deepClone(this.tempHierarchyList);
      this.updateParentCheckboxSelection();
      return;
    }

    const lowerSearchText = searchText.toLowerCase();
    this.filteredHierarchyList.sites = this.tempHierarchyList.sites.map((site: any) => {
      const filteredSite = { ...site };

      // Filter the ccuList inside each site
      filteredSite.ccus = this.filterHierarchyPath(site.ccus, lowerSearchText);

      return filteredSite;
    }).filter(Boolean);
    this.openHierarchyAccordions();
  }

  /**
   * Filters a hierarchical list of items based on a search text. The function recursively traverses
   * through the hierarchy and returns a new list containing only the items (and their parents) that
   * match the search text.
   */
  filterHierarchyPath(list: any[], searchText: string): any[] {
    return list.map(item => {
      const filteredItem = { ...item };

      // Check if the current item's name matches the search text at this level
      if (filteredItem.name?.toLowerCase().includes(searchText)) {
        return filteredItem;
      }

      // Recursive filtering based on the hierarchy structure
      if (filteredItem.floors) {
        filteredItem.floors = this.filterHierarchyPath(filteredItem.floors, searchText);
        if (filteredItem.floors.length > 0) return filteredItem;
      }

      if (filteredItem.rooms) {
        filteredItem.rooms = this.filterHierarchyPath(filteredItem.rooms, searchText);
        if (filteredItem.rooms.length > 0) return filteredItem;
      }

      if (filteredItem.equips) {
        filteredItem.equips = this.filterHierarchyPath(filteredItem.equips, searchText);
        if (filteredItem.equips.length > 0) return filteredItem;
      }

      if (filteredItem.devices) {
        filteredItem.devices = filteredItem.devices.filter((device: any) =>
          device.name.toLowerCase().includes(searchText)
        );
        if (filteredItem.devices.length > 0) return filteredItem;
      }

      return null;
    }).filter(Boolean);
  }


  /**
   * Saves the current dashboard configuration.
   * This method updates the scope hierarchy to the configuration and then
   * saves the configuration based on the action specified in `this.data`.
   * If the action is either 'new' or 'edit', it directly assigns the configuration
   * data to the dashboard service and shows a success alert. 
   * If the action is not 'new' or 'edit', it calls the `saveDashboardConfiguration`
   * method of the dashboard service to save the configuration and handles the
   * response asynchronously. On success, it shows a success alert and updates the
   * dashboard service with the new configuration. On error, it shows an error alert.
   */
  saveDashboardConfig() {
    this.updateScopeHierarchyToConfig();
    if (this.data?.action == CRUDOperationList.new || this.data?.action == CRUDOperationList.edit || this.data?.action == CRUDOperationList.duplicate) {
      this.dashboardService.dashboardConfig = this.configData;
      this.alertService.success('Dashboard configuration saved successfully');
      this.closeModalWithData();
    } else {
      const isDateFormatted = (date: string) => /^\d{4}-\d{2}-\d{2}$/.test(date);

      if (!isDateFormatted(this.configData['startDate'])) {
        this.configData['startDate'] = this.configData['startDate'].format('YYYY-MM-DD');
      }

      if (!isDateFormatted(this.configData['endDate'])) {
        this.configData['endDate'] = this.configData['endDate'].format('YYYY-MM-DD');
      }

      const defaultConfig = {
        dateRange: 'TODAY',
        dashboardId: this.data?.dashboard?.dashboardId,
        name: this.data?.dashboard?.name,
        viewBy: viewByEnum.Site,
        groupBy: timeIntervalsEnum['Hourly' as keyof typeof timeIntervalsEnum]
      };

      for (const [key, value] of Object.entries(defaultConfig)) {
        if (!this.configData.hasOwnProperty(key) || !this.configData[key]) {
          this.configData[key] = value;
        }
      }

      this.dashboardService.saveDashboardConfiguration(this.configData).subscribe({
        next: (res: any) => {
          this.alertService.success('Dashboard configuration saved successfully');
          this.dashboardService.dashboardConfig = this.configData;
          this.closeModalWithData();
        },
        error: (err: any) => {
          this.alertService.error(err?.error?.error || 'Error saving dashboard configuration');
        }
      });
    }
  }

  /**
   * Saves the widget configuration.
   * This method updates the scope hierarchy to the configuration and then
   * performs different actions based on the type of CRUD operation.
   * If the action is either 'new' or 'edit', it displays a success message
   * and closes the modal with data.
   * Otherwise, it calls the dashboard service to save the widget configuration
   * and handles the response by displaying a success message on success or
   * an error message on failure.
   */
  saveWidgetConfig() {
    this.updateScopeHierarchyToConfig();
    if ((this.data?.action == CRUDOperationList.new || this.data?.action == CRUDOperationList.edit || this.data?.action == CRUDOperationList.duplicate)) {
      this.alertService.success('Widget configuration saved successfully');
      this.closeModalWithData();
    } else {
      if (!this.data.chart?.widgetConfig?.widgetId) {
        delete this.configData['configId'];
      }
      this.dashboardService.saveWidgetConfiguration(this.configData).subscribe({
        next: (res: any) => {
          this.alertService.success('Widget configuration saved successfully');
          const configId = res.id;
          // Update the widget configuration with the returned configuration ID and widget ID
          this.configData['configId'] = ObjectUtil.deepClone(configId);
          this.configData['widgetId'] = ObjectUtil.deepClone(this.data?.chart?.id);
          this.closeModalWithData();
        },
        error: (err: any) => {
          this.alertService.error('Error saving Widget configuration');
        }
      });
    }
  }

  /**
   * Updates the scope hierarchy configuration by resetting the configuration data and processing the filtered hierarchy list.
   * It iterates through sites, CCUs, floors, rooms, equipment, and devices to update the configuration data with unique references
   * for excluded items based on their enabled state.
   * - Adds unique site references to `configData.siteRefs`.
   * - Adds unique excluded CCU references to `configData.excludedCcuRefs` if the CCU is not enabled.
   * - Adds unique excluded zone references to `configData.excludedZoneRefs` if the room is not enabled.
   * - Adds unique excluded equipment references to `configData.excludedEquipRefs` if the equipment is not enabled.
   * - Adds unique excluded device references to `configData.excludedDeviceRefs` if the device is not enabled.
   * Additionally, handles the case where the same floor is present in multiple CCUs by ensuring that if all instances of a floor
   * are not enabled, it adds the floor reference to `configData.excludedFloorRefs`.
   */
  updateScopeHierarchyToConfig() {
    this.resetConfigData();
    const allFloorEnabledState: { [floorId: string]: boolean[] } = {};

    this.filteredHierarchyList.sites.forEach((site: any) => {

      this.addUniqueExcludeRef(this.configData.siteRefs, site.id);

      site.ccus.forEach((ccu: any) => {
        if (!ccu.enabled && !ccu.indeterminate) {
          this.addUniqueExcludeRef(this.configData.excludedCcuRefs, ccu.id);
        }
        ccu.floors?.forEach((floor: any) => {

          if (!allFloorEnabledState[floor.id]) {
            allFloorEnabledState[floor.id] = [];
          }
          allFloorEnabledState[floor.id].push(floor.enabled || floor.indeterminate);

          floor?.rooms?.forEach((room: any) => {
            if (!room.enabled && !room.indeterminate) {
              this.addUniqueExcludeRef(this.configData.excludedZoneRefs, room.id);
            }
            room?.equips?.forEach((equip: any) => {
              if (!equip.enabled && !equip.indeterminate) {
                this.addUniqueExcludeRef(this.configData.excludedEquipRefs, equip.id);
              }
              equip?.devices?.forEach((device: any) => {
                if (!device.enabled && !device.indeterminate) {
                  this.addUniqueExcludeRef(this.configData.excludedDeviceRefs, device.id);
                }
              });
            });
          });
        });
      });
    });

    //Handle the case where same floor is present in multiple CCUs
    Object.keys(allFloorEnabledState).forEach((floorId) => {
      if (allFloorEnabledState[floorId].every((isEnabled) => !isEnabled)) {
        this.addUniqueExcludeRef(this.configData.excludedFloorRefs, floorId);
      }
    });
  }

  /**
   * Adds a reference to the array if it does not already exist in the array.
   */
  addUniqueExcludeRef(array: any[], ref: any) {
    if (!array.includes(ref)) {
      array.push(ref);
    }
  }

  /**
   * Checks if the hierarchy of a given site is empty.
   * @param site - The site object to check.
   * @returns A boolean indicating whether the site's hierarchy is empty (true) or not (false).
   */
  checkIfHierarchyIsEmpty(site: any) {
    return site?.ccus?.length == 0;
  }

  /**
   * Opens all hierarchy accordions by setting their states to true.
   * This method updates the `accordionStates` object to expand all sections
   * (ccu, floor, room, and equip) and triggers the `onAccordionChange` event
   * with the entity set to 'equips' and the state set to open.
   */
  openHierarchyAccordions() {
    this.accordionStates = {
      ccu: true,
      floor: true,
      room: true,
      equip: true
    };
    this.onAccordionChange({ isOpen: true, entity: this.hierarchyEntityTypes.EQUIP });
  }

  /**
   * Resets the hierarchy selection by collapsing all accordion states
   * and resetting the expand/collapse click counter.
   */
  resetHierarchyAccordionState() {
    this.accordionStates = {
      ccu: false,
      floor: false,
      room: false,
      equip: false
    };
    this.expandCollapeClickCounter = 0;
  }

  /**
   * Populates the hierarchy data by applying exclusions to the items based on the provided configuration.
   * The function iterates through the filtered hierarchy list of sites and applies exclusions to each site's CCUs.
   * It uses a recursive helper function `applyExclusions` to traverse and apply exclusions at multiple levels.
   */
  populateHierarchyData() {
    const applyExclusions = (items: any[], refKey: string, excludedRefs: string[], nextLevelKey?: string) => {
      items?.forEach((item: any) => {
        item.enabled = !excludedRefs.includes(item[refKey]);

        if (nextLevelKey && item[nextLevelKey]) {
          const nextLevelConfig = this.getNextLevelConfig(nextLevelKey);
          applyExclusions(item[nextLevelKey], 'id', nextLevelConfig.excludedRefs, nextLevelConfig.nextLevelKey);
        }
      });
    };

    this.filteredHierarchyList.sites.forEach((site: any) => {
      applyExclusions(site.ccus, 'id', this.configData.excludedCcuRefs, 'floors');
    });
  }

  /**
   * Retrieves the configuration for the next hierarchical level based on the provided level.
   * The returned object has the following structure:
   * - `excludedRefs`: An array of references to be excluded for the current level.
   * - `nextLevelKey`: The key for the next level in the hierarchy. If the current level is 'devices', this will be `null`.
   */
  getNextLevelConfig(level: string) {
    const levelConfig: any = {
      floors: { excludedRefs: this.configData.excludedFloorRefs, nextLevelKey: 'rooms' },
      rooms: { excludedRefs: this.configData.excludedZoneRefs, nextLevelKey: 'equips' },
      equips: { excludedRefs: this.configData.excludedEquipRefs, nextLevelKey: 'devices' },
      devices: { excludedRefs: this.configData.excludedDeviceRefs, nextLevelKey: null },
    };
    return levelConfig[level];
  }

  /**
   * Resets the configuration data by clearing all the reference arrays.
   * This includes site references, excluded CCU references, excluded floor references,
   * excluded zone references, excluded equipment references, and excluded device references.
   */
  resetConfigData() {
    this.configData.siteRefs = [];
    this.configData.excludedCcuRefs = [];
    this.configData.excludedFloorRefs = [];
    this.configData.excludedZoneRefs = [];
    this.configData.excludedEquipRefs = [];
    this.configData.excludedDeviceRefs = [];
  }
  
  /**
   * Handles the accordion change event.
   * 
   * @param event - The event object containing the state of the accordion and the entity type.
   * @param event.isOpen - Indicates whether the accordion is open.
   * @param event.entity - The type of entity associated with the accordion.
   * 
   * If the accordion is open, this method sets the `expandCollapeClickCounter` based on the entity type:
   * - 'ccu': sets `expandCollapeClickCounter` to 1.
   * - 'floor': sets `expandCollapeClickCounter` to 2.
   * - 'zone' or 'SM Zone': sets `expandCollapeClickCounter` to 3.
   * - 'zone equip' or 'SM Zone Equip': sets `expandCollapeClickCounter` to 4.
   */
  onAccordionChange(event: { isOpen: boolean, entity: string }) {
    if (event.isOpen) {
      const entity = event.entity;
      if (entity == this.hierarchyEntityTypes.CCU) {
        this.expandCollapeClickCounter = 1;
      } else if (entity == this.hierarchyEntityTypes.FLOOR) {
        this.expandCollapeClickCounter = 2;
      } else if (entity == this.hierarchyEntityTypes.ZONE || entity == this.hierarchyEntityTypes.SMZONE) {
        this.expandCollapeClickCounter = 3;
      } else if (entity == this.hierarchyEntityTypes.EQUIP || entity == this.hierarchyEntityTypes.SMEQUIP) {
        this.expandCollapeClickCounter = 4;
      }
    }
  }

}
