<div class="multi-select-dropdown-wrapper">
	<mat-select #select [placeholder]="selectedItemstoDisplay && selectedItemstoDisplay.length ? selectedItemstoDisplay : selectPanelPlaceholder"
		[disableOptionCentering]="true" [disableRipple]="true" class="nodes-select border" [disabled]="disabledState" panelClass="nodes-select-panel"
		id="filter-nodes-select" (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu" [ngClass]="{'dropdown-selected': selectedItemstoDisplay && selectedItemstoDisplay.length}" (menuOpened)="isMatPanelOpen=true" (menuClosed)="isMatPanelOpen=false; onCloseSelectPanel()">
	</mat-select>

	<mat-menu #menu="matMenu" class="scope-menu-list" yPosition="below" (click)="$event.stopPropagation()" [hasBackdrop]="false">
		<div class="scope-selection-dropdown-panel" id="scope-selection-menu" (click)="$event.stopPropagation();">
			<div class="scope-selection-search-wrapper">
				<div class="w-100 search-form-field mat-elevation-z4">
					<i class="fas fa-search node-search-icon"></i>
					<input matInput #searchSelectInput class="w-100" id="searchNodesChildren" type="text" title="Node Name"
						[placeholder]="inputPlaceholder" (keydown)="$event.stopPropagation()"
						ng-aria-disable [(ngModel)]='searchText'>
					<i class="fas fa-times clear-icon" *ngIf="searchSelectInput.value" (click)="clearScopeSearch()"></i>
				</div>
			</div>
			<div class="data-container">
				<cdk-virtual-scroll-viewport templateCacheSize="0" appendOnly itemSize="30" minBufferPx="500" maxBufferPx="800" [style.height.%]="100">
					<ul class="data-list selected" *ngIf="selectedData && selectedData.length > 0">
					  <div class="data-list-label"><span>Selected Sites</span></div>
					  <li class="data-list-item" *ngFor="let org of selectedData">
						<div class="checkbox-container">
							<mat-checkbox [checked]="true"
							(change)="toggleOrgCheck($event.checked, org, 'selectedSites')">
							  {{ org.name }}
							</mat-checkbox>
						</div>
						<ul class="data-list nested">
						  <li class="data-list-item nested" *ngFor="let site of org.children">
							<mat-checkbox [checked]="true" [(ngModel)]="site.checked"
							(change)="toggleSiteCheck(org, site, $event.checked,'selectedSites')">{{ site.name }}</mat-checkbox>
						  </li>
						</ul>
					  </li>
					</ul>
		
					<ul class="data-list" [ngClass]="{'isFiltering': isFilteringData}">
						<div class="data-list-label"><span>All Sites</span></div>
						<li class="data-list-item" *cdkVirtualFor="let org of (isFilteringData ? filteredNodes : nodes)">
							<div class="checkbox-container">
								<mat-checkbox [checked]="org.checked"
											  [indeterminate]="org.indeterminate"
											  (change)="toggleOrgCheck($event.checked, org)" [disabled]="siteSelectionConfig.isRestrictionEnabled && (selectedSiteCount >= siteSelectionConfig.maxSelectableSites && !org.checked && !org.indeterminate)">
								  {{org.name}}
								</mat-checkbox>
							</div>
							<ul class="data-list nested">
							  <li class="data-list-item nested" *ngFor="let site of org.children">
								<mat-checkbox [(ngModel)]="site.checked" (change)="toggleSiteCheck(org, site, $event.checked, 'allSites')"
									[disabled]="siteSelectionConfig.isRestrictionEnabled && (selectedSiteCount >= siteSelectionConfig.maxSelectableSites && !site.checked)">
									{{site.name}}
								</mat-checkbox>
							  </li>
							</ul>
		
						</li>
						<ng-container >
							<div class="text-center" *ngIf="searchSelectInput.value && filteredNodes.length == 0">
								No Results Found
							</div>
						</ng-container>
					</ul>
				</cdk-virtual-scroll-viewport>
			</div>
		</div>
	</mat-menu>
</div>